import React from "react";
import CompanyLogo from "./CompanyLogo";
import MenuOptions from "./MenuOptions";

function TitleTabsContainer() {
  const url = window.location.href.split("/");

  return (
    <React.Fragment>
      <div className={` w-full  flex ${url[url.length - 1] == "admin" ? "justify-center" : "justify-between"}  items-center pt-[8px] pb-[13px]`}>
        <CompanyLogo />
        {url[url.length - 1] == "admin" ? (
          <></>
        ) : (
          <>
            <MenuOptions />
          </>
        )}
      </div>
    </React.Fragment>
  );
}

export default TitleTabsContainer;
