import React, { useEffect, useRef, useState } from "react";
// import img1 from "./carOne.png";
// import img2 from "./carTwo.png";
// import img3 from "./carThree.png";

import img1 from "./Yaris.jpg";
import img2 from "./Yaris-1.jpg";
import img3 from "./Yaris-2.jpg";

function SubscriptionHeading() {
  const [images, setImages] = useState([img1, img2, img3]);
  const intervalIdRef = useRef(null);

  useEffect(() => {
    intervalIdRef.current = setInterval(() => {
      setImages((prevImages) => [
        prevImages[2], // Set the first image to the last one
        prevImages[0], // Set the second image to the first one
        prevImages[1], // Set the third image to the second one
      ]);
    }, 5000);

    return () => clearInterval(intervalIdRef.current);
  }, []);

  return (
    <>
      <div className="flex justify-between max-sm:flex-col items-stretch">
        <div className="headingAndSmallImages w-full sm:w-6/12">
          <div className="gap-[20px] max-sm:h-auto max-sm:mb-4 flex flex-col heading justify-start items-start max-sm:justify-between max-sm:items-center max-sm:px-8">
            <div className="w-full lg:w-10/12">
              <h1 className="text-[#0F0A34] tracking-[1px] text-center text-[28px] lg:text-[38px] font-bold sm:text-start leading-[36px] lg:leading-[48px] ">
                Drive hassle-free with our Car Subscription
              </h1>
            </div>
            <div>
              <h2 className="text-[20px] text-[#5F5F5F]  text-center sm:text-start font-thin leading-[30px]">
                Unlock your dream ride today with WTi Car{" "}
                <br className="md:inline-block hidden" /> Rental. Dive into a
                seamless subscription service!
              </h2>
            </div>
            <div className="smallImages h-[50%] max-sm:hidden flex justify-between items-center w-full">
              {images.slice(1).map((img, index) => (
                <img
                  key={index}
                  src={img}
                  alt=""
                  className="rounded-xl shadow-[-2px_-0px_12px_16px_#e8e8e8] object-contain w-[47%] max-sm:w-full max-sm:mr-10 h-[100%] cursor-pointer"
                />
              ))}
            </div>
          </div>
        </div>
        <div className="overflow-hidden">
          <div className="smallImages h-[50%] hidden max-sm:flex max-sm:h-auto  py-5 -translate-x-12 justify-between items-center w-[130%]">
            {images.slice(0).map((img, index) => (
              <img
                key={index}
                src={img}
                alt=""
                className={`rounded-xl w-[47%] ${
                  index == 1 ? "scale-[1.30]  shadow-2xl shadow-white" : ""
                } max-sm:w-[30%]  h-[100%] cursor-pointer`}
              />
            ))}
          </div>
        </div>

        <div className="flex justify-end w-[47%] max-sm:hidden">
          <img
            src={images[0]}
            alt=""
            className="rounded-xl shadow-[-2px_-0px_12px_16px_#eeeeee] max-sm:w-full h-full object-fill max-sm:h-auto cursor-pointer"
          />
        </div>
      </div>
    </>
  );
}

export default SubscriptionHeading;

// <div className="w-full flex mb-12 max-sm:mt-8">
// <div className="w-full flex mb-12 max-sm:mt-8">
// <div className="headingAndSmallImages grow">
//   <div className="h-[50%] max-sm:h-auto max-sm:mb-4 flex flex-col heading justify-start items-start max-sm:justify-between max-sm:items-center max-sm:px-8">
//     <div className="w-full lg:w-10/12">
//       <h1 className="text-[#0F0A34] tracking-[1px] text-center text-[28px] lg:text-[38px] font-bold sm:text-start leading-[36px] lg:leading-[48px] mb-3">
//         Drive hassle-free with our Car Subscription Service in Dubai.
//       </h1>
//     </div>
//     <h2 className="text-[20px] text-[#5F5F5F]  text-center sm:text-start font-thin leading-[30px]">
//       Unlock your dream ride today with WTi Car <br className="md:inline-block hidden"/> Rental. Dive into a
//       seamless subscription service!
//     </h2>
//   </div>
//   <div className="smallImages h-[50%] max-sm:hidden flex justify-between items-center w-full">
//     {images.slice(1).map((img, index) => (
//       <img
//         key={index}
//         src={img}
//         alt=""
//         className="rounded-xl w-[47%] max-sm:w-full max-sm:mr-10 h-[100%] cursor-pointer"
//       />
//     ))}
//   </div>
//   <div className="overflow-hidden">
//     <div className="smallImages h-[50%] hidden max-sm:flex max-sm:h-auto  py-5 -translate-x-12 justify-between items-center w-[130%]">
//       {images.slice(0).map((img, index) => (
//         <img
//           key={index}
//           src={img}
//           alt=""
//           className={`rounded-xl w-[47%] ${
//             index == 1 ? "scale-[1.30]  shadow-2xl shadow-white" : ""
//           } max-sm:w-[30%]  h-[100%] cursor-pointer`}
//         />
//       ))}
//     </div>
//   </div>
// </div>
// <div className="grow max-sm:hidden h-full">
//   <img
//     src={images[0]}
//     alt=""
//     className="rounded-xl max-sm:w-full max-sm:h-auto cursor-pointer"
//   />
// </div>
// </div>
