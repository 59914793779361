import React from "react";
import { Link, useLocation } from "react-router-dom";
import { capitalizeWords } from "../utilityFunctions/capitalizeFirstLetter";

const BreadcrumbAll = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <nav
      aria-label="breadcrumb"
      className="mt-2 py-2 md:pl-24 lg:pl-[8.6rem] border pl-4"
    >
      <ol className="list-none">
        <li
          className="inline mr-2 font-normal text-[#8E8E8E] hover:underline text-sm"
        >
          <Link to="/">Home</Link>
        </li>
        {pathnames?.map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
          const isLast = index === pathnames.length - 1;
          return (
            <li
              key={name}
              className={`inline text-sm inline mr-2 font-normal hover:underline ${isLast ? "text-[#0F0A34] hover:no-underline" : "text-[#8E8E8E]"}`}
              aria-current={isLast ? "page" : null}
            >
              {isLast ? <span><span className="text-base">{">"} </span>{capitalizeWords(name.replaceAll('-', ' '))}</span> : <Link to={routeTo}><span className="text-base">{">"} </span>{capitalizeWords(name.replaceAll('-', ' '))}</Link>}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default BreadcrumbAll;