import React from 'react'
import PromotionBannerImg from "../../images/self_drive_banner_img.svg"
import { Link } from 'react-router-dom'

const PromotionBanner = () => {
    return (
        <div className='mt-[140px] bg-[#efefef] hidden md:block'>
            <div className='w-full xl:w-[1200px] mx-auto h-full flex justify-between items-center' >
                <div className='h-[246px] relative'>
                    <img src={PromotionBannerImg} alt="" className='object-fit h-full' />
                    <div className='flex w-full justify-between items-center absolute top-0 px-14  h-full'>
                        <div>
                            <div className='bg-white rounded-[8px] px-3 py-1'><h3 className='text-[#004E8E] text-[24px] font-bold text-center'>Need A Car?</h3></div>
                            <span className='text-[#FFFFFF] text-[24px] font-medium'>
                                Special Offers upto
                            </span>
                            <h3 className='text-[#FFFFFF] text-[48px] font-bold'>20 % Off</h3>
                        </div>
                        <Link>
                            <button className='rounded-[12px] text-[#fff] text-[24px] font-semibold bg-gradient-to-r from-[#EB840C] to-[#EF5600] py-2 px-4'>Rent Now</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PromotionBanner
