import React, { useState } from "react";
import { ReactComponent as AirCondIcon } from "../carSelectType/carSearchResults/images/icons/air-conditioner.svg";
import { ReactComponent as CarDoor } from "../carSelectType/carSearchResults/images/icons/car-door.svg";
import { ReactComponent as Transmission } from "../carSelectType/carSearchResults/images/icons/transmission.svg";
import LuggageIcon from "@mui/icons-material/Luggage";
import PersonIcon from "@mui/icons-material/Person";
import CloseIcon from "@mui/icons-material/Close";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SoldOutImage from "./sold_out_image.png";

const SubscriptionPage = () => {
  const [divIndex, setDivIndex] = useState(null);

  const closeTenureModal = () => {
    setDivIndex(null);
  };
  return (
    <div className="w-full px-[9.27%] max-sm:px-0 bg-[#FFFFFF]">
      <>
        <div
          className={`w-full grid grid-cols-3 gap-y-4 mt-10 relative max-sm:static max-sm:grid-cols-1`}
        >
          {[1, 2, 3].map((ele, index) => (
            <div
              // style={{
              //   marginTop:
              //     divIndex !== null &&
              //     Math.floor(index / 3) === Math.floor(divIndex / 3) + 1
              //       ? `${spaceBetween + 10}px`
              //       : "0",
              // }}
              className={`h-[460px] w-[90%] rounded-xl outline outline-gray-300 cursor-pointer m-5 hover:outline-cyan-600  `}
              onClick={() => {
                setDivIndex(true);
              }}
            >
              <div className="flex w-full p-1">
                <span className="w-[65%] text-[16px] font-extrabold py-4 px-5">
                  Suzuki Ciaz
                </span>
                <span className="w-[35%] text-[16px] font-medium py-4 px-3">
                  Sold Out
                </span>
              </div>
              <div className="mb-14 flex justify-center items-center ">
                {/* <div className=" aspect-w-[309px] aspect-h-[176px]"> */}
                <img
                  src={
                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlsNggQ1J3w_iK_1pno0nIPaqDwP5nhystQQ&usqp=CAU"
                  }
                  alt=""
                  onError={(e) => {
                    e.target.src = "../static/staticcarimage.png";
                  }}
                  className="aspect-w-[309px] aspect-h-[176px]"
                />
                {/* </div> */}
              </div>
              <div className="flex px-5">
                <div className="w-[45%] mr-2 ">
                  <span className="text-[16px] font-bold ">
                    6{" " + "months"}
                  </span>
                  <br></br>
                  <span className="text-[14px] font-normal text-[#666666] whitespace-nowrap">
                    Contract term
                  </span>
                </div>
                <div className="w-[45%]">
                  <span className="text-[16px] font-bold">2500 KM</span>
                  <br></br>
                  <span className="text-[14px] font-normal text-[#666666]">
                    Mileage
                  </span>
                </div>
                <div className="w-[45%] ">
                  <span className="text-[20px] font-bold text-cyan-600">
                    AED 1500
                    <span className="text-[#666666] font-thin text-[14px] ">
                      /months
                    </span>
                  </span>
                  <br></br>
                  <span className="text-[14px] font-normal text-[#666666]">
                    VAT Inclusive
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>

        {divIndex ? (
          <div className="w-full relative mx-auto h-auto">
            <div
              id="carTenureModalUnq"
              className={`carTenureModal flex z-10  border border-[#D1D5DB] text-white max-sm:w-[100%] max-sm:top-0 max-sm:h-full max-sm:overflow-y-auto  w-[97%] h-fit mt-28 mb-7 rounded-xl left-5 max-sm:block max-sm:left-0`}
            >
              <div className="bigCarImage w-[50%] max-sm:w-full flex flex-col justify-center items-center border-r bg-white rounded-l-xl border-[#D1D5DB] py-10">
                <img
                  src={
                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlsNggQ1J3w_iK_1pno0nIPaqDwP5nhystQQ&usqp=CAU"
                  }
                  alt=""
                  onError={(e) => {
                    e.target.src = "../static/staticcarimage.png";
                  }}
                />
                <div className="w-full px-4 items-center grid grid-cols-3 gap-3">
                  <div className="flex flex-col items-center justify-start my-4">
                    <Transmission className=" text-base" />
                    <span className=" text-xs font-light text-slate-500">
                      Transmission Type
                    </span>
                    <span className="text-sm font-semibold text-black">
                      Automaic
                    </span>
                  </div>
                  <div className="flex flex-col items-center justify-start my-4">
                    <AirCondIcon className=" text-base" />
                    <span className=" text-xs font-light text-slate-500">
                      Air Conditioning
                    </span>
                    <span className="text-sm font-semibold text-black">
                      Available
                    </span>
                  </div>
                  <div className="flex flex-col items-center justify-start my-4">
                    <CarDoor className=" text-base" />
                    <span className=" text-xs font-light text-slate-500">
                      Doors
                    </span>
                    <span className="text-sm font-semibold text-black">
                      {4 + " Doors"}
                    </span>
                  </div>
                  <div className="flex flex-col items-center justify-start my-4">
                    <LuggageIcon className=" text-base text-black " />
                    <span className=" text-xs font-light text-slate-500">
                      Luggage capacity
                    </span>
                    <span className="text-sm font-semibold text-black">
                      {3}
                      {" " + "Suitcase"}
                    </span>
                  </div>
                  <div className="flex flex-col items-center justify-start my-4">
                    <PersonIcon className=" text-base text-black" />
                    <span className=" text-xs font-light text-slate-500">
                      Seats
                    </span>
                    <span className="text-sm font-semibold text-black">
                      {5 + " seats"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="w-[50%] max-sm:w-full tenureDiv bg-white rounded-r-xl pb-4">
                <div className=" w-full flex justify-between items-center py-2 px-3 mb-3 bg-[#F9F9F9] rounded-tr-xl">
                  <span className=" text-[28px] font-bold py-4 px-5 text-black">
                    Suzuki Ciaz
                  </span>
                  <CloseIcon
                    className=" z-10 text-black max-sm:absolute max-sm:top-2 max-sm:right-2"
                    onClick={closeTenureModal}
                  />
                </div>
                <span className=" text-[20px] font-semibold text-black pl-8 pt-2">
                  Subscription tenure
                </span>

                <div className="flex flex-col py-14 px-5 relative justify-center ">
                  <div className="blur-sm">
                    <div className="flex  justify-evenly items-center ">
                      {[1, 2, 3].map((month, index) => {
                        return (
                          <div
                            key={index}
                            className={` hover:ring-2 flex w-[69.32px] bg-cyan-600 flex-col items-center justify-center py-2 px-3  border-b-[2.5px] border-[#D1D2D3]  rounded-md drop-shadow-2xl 
                        `}
                          >
                            <span
                              className={`text-white text-[18px] font-bold`}
                            >
                              {month}
                            </span>
                            <span
                              className={`text-white  text-black text-[12px] font-semibold`}
                            >
                              {index > 0 ? "Months" : "Month"}
                            </span>
                          </div>
                        );
                      })}
                    </div>

                    <div className="w-full px-6 pt-1 max-sm:mt-8 max-sm:mb-8 flex items-center max-sm:items-end ">
                      <span className=" text-[32px] max-sm:text-3xl font-semibold text-black mr-1">
                        AED 2300
                      </span>
                      <span className="text-[20px] max-sm:text-base font-normal text-black mr-1">
                        {""}
                        /month
                      </span>
                      <span className=" line-through max-sm:hidden text-[#656565]">
                        AED 2875
                        {/* {Object.values(subscriptionTenureArr[tenureDivIndex])[0] *
                        1.25} */}
                      </span>
                    </div>
                  </div>
                  <div className="absolute left-1/2 -translate-x-[50%] overflow-hidden">
                    {/* <h3 className=" text-[#ff4949] text-[2.5rem] rotate-[335deg]">
                      SOLD OUT !
                    </h3> */}
                    <img
                      src={SoldOutImage}
                      alt="SoldOutImage"
                      className="w-[300px] rotate-[335deg]"
                    />
                  </div>
                </div>
                {/* <div className="w-full flex justify-between items-center pt-2 px-6 mb-4">
                  <div className=" border-[1.5px] rounded-md  border-slate-400 w-[45%] h-fit flex  justify-around items-center py-3 px-2">
                    <div className=" flex rounded-full items-center justify-center p-1 border-[1.5px] border-cyan-600">
                      <div className="px-2 rounded-full py-2 bg-cyan-600"></div>
                    </div>

                    <div className=" flex flex-col ">
                      <span className="text-black font-semibold text-[18px]">
                        {
                          carArr[divIndex].mileage[
                            Object.keys(
                              subscriptionTenureArr[tenureDivIndex]
                            )[0]
                          ]
                        }
                        km
                      </span>
                      <span className=" text-slate-600 text-[12px]">
                        Included
                      </span>
                    </div>
                  </div>
                 
                </div> */}

                <div className=" w-full px-6 flex flex-col justify-center items-center gap-2">
                  <div className=" w-full flex flex-col md:flex-row justify-center items-center  rounded-md py-1">
                    <div className="flex justify-between items-center w-full">
                      <div className="md:w-[48%] w-full">
                        <input
                          type="text"
                          className="rounded placeholder:text-[#bababa]   px-2 w-full h-[3rem] text-sm text-[#bababa] bg-white border-[1px] border-b-2 border-[#C4C4C4] appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder="Name"
                        />
                      </div>
                      <div className="md:w-[48%] w-full">
                        <input
                          type="number"
                          className="rounded placeholder:text-[#bababa]   px-2 w-full h-[3rem] text-sm text-[#bababa] bg-white border-[1px] border-b-2 border-[#C4C4C4] appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder="Contact"
                        />
                      </div>
                    </div>
                  </div>

                  <div className=" w-full flex justify-center items-center bg-cyan-600 rounded-md py-4 hover:cursor-pointer">
                    <span className=" text-white font-semibold">
                      Request Quote
                    </span>
                    <ChevronRightIcon className=" text-white " />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </>
    </div>
  );
};

export default SubscriptionPage;
