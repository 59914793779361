import React, { useEffect, useState } from "react";
import logo from "./pac.png";
import { FormControlLabel, Checkbox } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addModifyExtras, removeModifyExtras, togglePersonalInsurance, togglePersonalInsuranceCheck } from "../../utils/modifyBookingSlices/modifyBooking";

function PersonalInsurance() {

  const PAICheck = useSelector((store)=>store.modifyBookingSlice.personalInsuranceCheck);
  const [personalInsuranceCheck, setPersonalInsuranceCheck] = useState(PAICheck);
  const insuranceArr = useSelector((store)=>store.insuranceSlice.insuranceArr);
  const [personalInsurance,setPersonalInsurance] = useState(insuranceArr[1]);

  const dispatch = useDispatch();
  const currencyName = useSelector((store) => store.currencySlice.currentCurr);
  const currencyPrice = useSelector(
    (store) => store.currencySlice.currentCurrRate
  );
  const modifyReservationDays = useSelector(
    (store) => store.modifyBookingSlice.modifyReservationDays
  );


  useEffect(()=>{
    setPersonalInsurance(insuranceArr[1])
  }, [insuranceArr])


  const handleInsurance = (personalInsurance, insurancePrice) => {

    if (personalInsuranceCheck) {

      const PAIObj = {
        personalInsurancePrice: 0,
        personalInsuranceState: false
      }
      dispatch(togglePersonalInsuranceCheck(false));
      dispatch(togglePersonalInsurance(PAIObj));
      dispatch(removeModifyExtras(personalInsurance.id));
    } else {

      const PAIObj = {
        personalInsurancePrice: Number(insurancePrice),
        personalInsuranceState: true
      }
      dispatch(togglePersonalInsuranceCheck(true));
      dispatch(togglePersonalInsurance(PAIObj));
      dispatch(addModifyExtras(personalInsurance));
    }


    setPersonalInsuranceCheck(!personalInsuranceCheck);
  };


  return (
    <React.Fragment>
      <div className="shadow-xl rounded-md mb-4 cursor-pointer hover:border hover:border-black bg-white">
        <div className="icon flex">
          <div className="img flex justify-center items-center  pl-4 pr-4 ">
            <img src={logo} alt="" className="border rounded-full max-w-sm " />
          </div>
          <div className="flex flex-col justify-center items-start pt-6  pb-2 pr-4 pl-4">
            <span className="font-semibold text-sm mb-2">
            Personal Accident Insurance (PAI)
            </span>
            <span className="text-[12px] font-normal leading-4">
            Personal Accident Insurance offers financial protection against accidental injury, disability, or death.
            </span>
          </div>
        </div>
        <div className="check flex pl-8 pb-2">
          <FormControlLabel
            label={
              <span className="text-sm font-semibold  text-[#092C85] leading-4">
                {currencyName +
                  " " +
                  Number(
                    (
                      Number(
                        Object.values(
                          personalInsurance.price
                        )[0]
                      ) *
                      currencyPrice *
                      modifyReservationDays
                    )?.toFixed(2)
                  ) +
                  ` - ${modifyReservationDays} DAY`}
              </span>
            }
            control={
              <Checkbox
                checked={personalInsuranceCheck}
                onChange={() => {
                  let clickInsurancePrice = Object.values(
                    personalInsurance?.price
                  );
                  clickInsurancePrice =
                    parseFloat(clickInsurancePrice[0]) * modifyReservationDays;
                  handleInsurance(personalInsurance, clickInsurancePrice);
                }}
              />
            }
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default PersonalInsurance;
