import React, { useEffect, useState } from "react";
import LocationBtn from "./radiobtns/LocationBtn";
import DriverAge from "./radiobtns/DriverAge";
import { useSelector } from "react-redux";
import { getDaysInBetween } from "../../utils/utilityFunctions/getDaysInBetween";
import { LuCalendarDays } from "react-icons/lu";




function RadioBtnCont(){
    const itineraryModalStatus = useSelector(
        (store) => store.modalToggleSlice.updateItinerary
      );
      const pickupMMDDYYYY = useSelector(
        (store) => store.bookingInfoSlice.pickupMMDDYYYY
      );
      const dropMMDDYYYY = useSelector(
        (store) => store.bookingInfoSlice.dropMMDDYYYY
      );

      const [reservationDays, setReservationDays] = useState(1);
      const [colorChange, setColorChange] = useState(false);
      
  useEffect(() => {
    try {
        let days = getDaysInBetween(pickupMMDDYYYY, dropMMDDYYYY);
        setReservationDays(days) 
    } catch (error) {
        console.log(error)
    }
    if(window.location.pathname == "/reservation/vehicles" || window.location.pathname == "/reservation/extras"){
      setColorChange(true)
    }
  }, [pickupMMDDYYYY, dropMMDDYYYY])


    return(
        <React.Fragment>
            <div id="radioButtonFill" className="w-full  flex  max-sm:flex-col sm:items-center">
                
                <LocationBtn/>
                <div className="max-sm:hidden">
                    <DriverAge/>
                </div>
                <div className={`flex  sm:items-center sm:justify-center mr-[1rem] text-xs font-thin leading-4 ${!itineraryModalStatus ? "text-white" : "text-black"} `}><span className={`inline sm:pl-2 pr-2 text-[22px] ${colorChange ? 'text-gray-200' : 'text-blue-950'}`}><LuCalendarDays /></span> <span className="pt-1">Reservation Days: {reservationDays}</span></div>
                {/* <NeedDeliveryAndPickup/> */}
                
            </div>
        </React.Fragment>
    )
}

export default RadioBtnCont