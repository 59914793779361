import React from 'react'
// import HeroSectionBgImg from "../../assets/images/hero_section_bg_img.svg"
import "./index.css"

const HeroBackgroundBanner = (props) => {
    const { searchEngine } = props

    return (
        <div className='lg:h-[464px] h-[474px]  bg-banner-image bg-center bg-no-repeat bg-cover px-3'>
            <div className='w-full xl:w-[1200px] mx-auto h-full'>
                <div className='w-full items-center flex flex-col h-full relative'>
                    <div className='w-full mx-auto sm:block hidden'>
                        <div className='w-full items-center flex flex-col gap-4 mt-[8%] '>
                            <div>
                                <h2 className='text-[36px] text-[#fff] font-semibold'> Rent a Car in Dubai | On Demand Car Lease & Rentals</h2>
                            </div>
                            <div className='py-2 w-fit text-center px-4 rounded-[12px] bg-gradient-to-r from-[#0077B6] to-[#00B4D8]'>
                                <span className='text-white text-[16px] font-normal tracking-wide'>Search For Best Car Rental Discounts & Offers</span>
                            </div>
                        </div>
                    </div>
                    {/* <div className='w-full absolute -bottom-[60%] md:-bottom-[25%]'> */}
                    <div className='w-full mt-[10%]'>
                        {searchEngine}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroBackgroundBanner
