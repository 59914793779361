import React, { useEffect, useState } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useSelector, useDispatch } from "react-redux";

import PersonIcon from "@mui/icons-material/Person";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { ReactComponent as CarDoorIcon } from "../carSearchResults/images/icons/car-door.svg";
import { ReactComponent as AirCondIcon } from "../carSearchResults/images/icons/air-conditioner.svg";
import { ReactComponent as TransmissionIcon } from "../carSearchResults/images/icons/transmission.svg";
import CarFeaturesModal from "./CarFeaturesModal";
import ModifyItinerary from "./ModifyItinerary";
import ModifyCarModal from "./ModifyCarModal";
import {
  toggleUpdateItinerary,
  switchCarModal,
} from "../../../utils/ModalSlice/modalToggleSlice";
import { getCarApiCall } from "../../getCarsApi";
import { switchToPayNow } from "../../../utils/CarSlices/carDetailSlice";
import { addInsuranceArr } from "../../../utils/insuranceSlice";
import { addBaseRateCarModal } from "../../../utils/invoice/invoiceSlice";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import { addExcessInsurance } from "../../../utils/invoice/invoiceSlice";
import { baseUrl } from "../../../config";
import unbeatable_price from "../horizontalStepper/carDetailImg/unbeatable_price.gif"

function StepperCustom() {
  const dispatch = useDispatch();

  const pickLoc = useSelector((store) => store.bookingInfoSlice.pickupLocation);
  const [toggleBase, setToggleBase] = useState(true);
  const [toggleExtras, setToggleExtras] = useState(false);

  const dropLoc = useSelector((store) => store.bookingInfoSlice.dropLocation);

  const totalDays = useSelector(
    (store) => store.bookingInfoSlice.totalNumberOfDays
  );
  const pickupMMDDYYYY = useSelector(
    (store) => store.bookingInfoSlice.pickupMMDDYYYY
  );
  const dropMMDDYYYY = useSelector(
    (store) => store.bookingInfoSlice.dropMMDDYYYY
  );

  const one = useSelector((store) => store.stepperSlice.stepOne);
  const two = useSelector((store) => store.stepperSlice.stepTwo);
  const three = useSelector((store) => store.stepperSlice.stepThree);
  const four = useSelector((store) => store.stepperSlice.stepFour);

  //--> base price
  let basePrice = useSelector((store) => store.invoiceSlice.baseRate);
  const excessInsurancePrice = useSelector(
    (store) => store.invoiceSlice.excessInsurancePrice
  );

  let carDeliveryCharge = useSelector(
    (store) => store.invoiceSlice.carDeliveryCharge
  );

  const deliveryCharge = useSelector(
    (store) => store.pickDropSlice.carDropLocation.locationRate
  );
  const collectionCharge = useSelector(
    (store) => store.pickDropSlice.carPickLocation.locationRate
  );

  let extrasRate = useSelector((store) => store.invoiceSlice.extras);

  let depositPremiumObject = useSelector((store) => store?.invoiceSlice?.depositChargesBoolean);


  const payNowStatus = useSelector((store) => store.carDetailSlice.payNow);

  const currencyName = useSelector((store) => store.currencySlice.currentCurr);
  let currencyPrice = useSelector(
    (store) => store.currencySlice.currentCurrRate
  );

  // car details modal status
  const detailModalStatus = useSelector(
    (store) => store.modalToggleSlice.carDetail
  );
  // update itinerary
  const itineraryModalStatus = useSelector(
    (store) => store.modalToggleSlice.updateItinerary
  );
  // car modal status
  const carModalStatus = useSelector(
    (store) => store.modalToggleSlice.openCarModal
  );

  const allEquipmentArray = useSelector(
    (store) => store.insuranceSlice.allEquipments
  );

  const selectedExtraArray = useSelector((store) => store.extraSlice.extraArr);

  //--> car details  object
  const carObj = useSelector((store) => store.carDetailSlice.carDetailObj);
  let payNowPercentageArray;
  if (three) {
    payNowPercentageArray = Object.values(carObj?.percentageHikePayNow);
  }

  const {cdwBoolean, babySeatBoolean, additionalDriverBoolean, personalInsuranceBoolean} = useSelector((store) => store.extraSlice.allExtraBooleans);
  const [addOnsNames, setAddOnsNames] = useState([])

  useEffect(() => {
    const extras = [];
    if (cdwBoolean) extras.push('CDW'); 
    if (personalInsuranceBoolean) extras.push('PAI'); 
    if (babySeatBoolean) extras.push('Baby Seat'); 
    if (additionalDriverBoolean) extras.push('Additional Driver'); 
      setAddOnsNames(extras)
  }, [cdwBoolean, babySeatBoolean, additionalDriverBoolean, personalInsuranceBoolean])


  const steps = [
    "Select Itinerary",
    "Select a car",
    "Choose Extras",
    "Info & Payment",
  ];


  const modifyIti = () => {
    dispatch(toggleUpdateItinerary());

    document.documentElement.style.overflow = "hidden";
  };

  const openModifyCarModal = () => {
    dispatch(switchCarModal());

    document.documentElement.style.overflow = "hidden";
  };

  useEffect(() => {

    const fetchData = async () => {
      try {
        const result = await getCarApiCall(totalDays);

        let itineraryDispatchObj = changeBaseAndCar(result);

        updateExtras(itineraryDispatchObj);

        if (depositPremiumObject.fullDepositCheck == true) {

          for (let i = 0; i < result.length; i++) {
            let newCarObj = result[i];
            if (newCarObj.vehicleID == carObj.vehicleID) {
              // add excessInsuranceCharge
              dispatch(addExcessInsurance(newCarObj.excessInsuranceCharge))
            }
          }


        } else if (depositPremiumObject.depositPremiumCheck == true) {

          for (let i = 0; i < result.length; i++) {
            let newCarObj = result[i];
            if (newCarObj.vehicleID == carObj.vehicleID) {
              // add excessInsuranceChargePremiumPercentage
              dispatch(addExcessInsurance(Object.entries(
                newCarObj.excessInsuranceChargePremiumPercentage)[0][1]))

            }
          }

        }


      } catch (error) {
        // Handle any errors that occurred during the API call
        console.error("Error:", error);
      }
    };
    fetchData();
  }, [carObj?.vehicleID]);



  useEffect(() => {

    if (three) {

      const fetchData = async () => {
        try {
          const result = await getCarApiCall(totalDays);

          let itineraryDispatchObj = changeBaseAndCar(result);

          updateExtras(itineraryDispatchObj);
          // fullDepositCheck: false,
          // depositPremiumCheck: false

          if (depositPremiumObject.fullDepositCheck == true) {

            for (let i = 0; i < result.length; i++) {
              let newCarObj = result[i];
              if (newCarObj.vehicleID == carObj.vehicleID) {
                // add excessInsuranceCharge
                dispatch(addExcessInsurance(newCarObj.excessInsuranceCharge))
              }
            }


          } else if (depositPremiumObject.depositPremiumCheck == true) {

            for (let i = 0; i < result.length; i++) {
              let newCarObj = result[i];
              if (newCarObj.vehicleID == carObj.vehicleID) {
                // add excessInsuranceChargePremiumPercentage
                dispatch(addExcessInsurance(Object.entries(
                  newCarObj.excessInsuranceChargePremiumPercentage)[0][1]))

              }
            }

          }

        } catch (error) {
          // Handle any errors that occurred during the API call
          console.error("Error:", error);
        }
      };
      fetchData();
    }
  }, [totalDays]);



  const changeBaseAndCar = (updatedCarArray) => {
    // let cdwPrice = 1;
    let itineraryDispatchObj = {
      payNowArrayPrice: 1,
      payNowPercentageArrayPrice: 1,
      cdwPrice: 1,
      personalInsurancePrice: 1,
    };
    for (let i = 0; i < updatedCarArray.length; i++) {
      if (carObj.imageName === updatedCarArray[i].imageName) {
        itineraryDispatchObj.cdwPrice = parseFloat(
          Object.values(updatedCarArray[i].insuranceAndDamageCovers[0].price)[0]
        );
        itineraryDispatchObj.personalInsurancePrice = parseFloat(
          Object.values(updatedCarArray[i].insuranceAndDamageCovers[1].price)[0]
        );
        dispatch(switchToPayNow(updatedCarArray[i]));
        dispatch(addInsuranceArr(updatedCarArray[i].insuranceAndDamageCovers));
        const payNowArray = Object.values(updatedCarArray[i].payNowPrice);

        const payNowPercentageArray = Object.values(
          updatedCarArray[i].percentageHikePayNow
        );

        itineraryDispatchObj.payNowArrayPrice = payNowArray[0];
        itineraryDispatchObj.payNowPercentageArrayPrice =
          payNowPercentageArray[0];

      }
    }
    return itineraryDispatchObj;
  };

  const getUpdatedPriceOfBabySeat = (equipmentsObj, days) => {
    if (days >= 7 && days <= 21) {
      let actualPrice = equipmentsObj?.price.daily - 10;
      let totalPrice = actualPrice * days;
      if (totalPrice <= equipmentsObj?.price.maximum) {
        return parseFloat((totalPrice).toFixed(2));
      } else {
        return parseFloat((equipmentsObj?.price.maximum).toFixed(2));
      }

    } else if (days >= 30) {
      let totalMonths = Math.floor(days / 30); // Extract whole months
      let remainingDays = days % 30;
      let monthsPrice = parseFloat((equipmentsObj?.price.maximum).toFixed(2)) * totalMonths;
      let daysPrice = remainingDays * (equipmentsObj?.price.daily - 10);
      let total = monthsPrice + daysPrice
      return parseFloat((total).toFixed(2));
    } else {
      if (days > 21 && days < 30) {
        return parseFloat((equipmentsObj?.price.maximum).toFixed(2));
      } else {
        let totalPrice = equipmentsObj?.price.daily * days;
        return parseFloat((totalPrice).toFixed(2));
      }
    }
  }

  const getUpdatedPriceOfAdditionalDriver = (equipmentsObj, days) => {
    if (days > 30 && days < 61) {
      let daysRemainigFromMonth = days - 30;
      let remainingDaysRate = daysRemainigFromMonth * 1.3;
      let finalPrice = remainingDaysRate + equipmentsObj?.price.daily;
      return parseFloat((finalPrice).toFixed(2));
    }
    if (days >= 30) {
      let totalMonths = days / 30;
      return (parseFloat((equipmentsObj?.price.daily).toFixed(2)) * totalMonths);
    } else {
      return parseFloat((equipmentsObj?.price.daily).toFixed(2));
    }
  }





  const updateExtras = (itineraryDispatchObj) => {
    let extraSum = 0;


    for (let i = 0; i < selectedExtraArray.length; i++) {
      let char = selectedExtraArray[i].slice(-1);   
      if (char == "E") {
        for (let j = 0; j < allEquipmentArray.length; j++) {

          if (selectedExtraArray[i].substring(0, 2) 
              == allEquipmentArray[j].id
            ) {
            
            if (
              allEquipmentArray[j]?.title == "Baby Safety Seat"
            ) {


              // babay saftey seat 
              let priceOfBabySeat = getUpdatedPriceOfBabySeat(allEquipmentArray[j], totalDays);

              extraSum += priceOfBabySeat;
            } else {
              let priceOfAdditionalDriver = getUpdatedPriceOfAdditionalDriver(allEquipmentArray[j], totalDays);
              extraSum += priceOfAdditionalDriver;
            }
          }
        }
      } else if(char == 'C') {
        extraSum += parseFloat(itineraryDispatchObj.cdwPrice * totalDays);
      } else if(char == 'P') {
        extraSum += parseFloat(itineraryDispatchObj.personalInsurancePrice * totalDays);
      }
    }

    let totalAmountObject = {
      totalBase:
        (itineraryDispatchObj.payNowArrayPrice) +
        ((itineraryDispatchObj.payNowArrayPrice) *
          itineraryDispatchObj.payNowPercentageArrayPrice) /
        100,
      totalExtras: extraSum,
    };
    dispatch(addBaseRateCarModal(totalAmountObject));
  };


  return (
    <>
      <div className="px-[10.27%] pt-[2.5%] pb-[1%] bg-[#EBEBEB] max-sm:bg-white">
        <div className="px-[5%] max-sm:p-0">
          <Stepper
            activeStep={four ? 3 : three ? 2 : two ? 1 : one ? 0 : 0}
            alternativeLabel
          >
            {steps?.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>

        <div className="w-full mt-8  flex justify-between max-sm:block">
          <div className="w-[60%] max-sm:w-full">
            <div className="bg-white max-sm:bg-[#EBEBEB] max-sm:rounded w-full p-4  rounded max-sm:block flex justify-between mb-4">
              <div className="w-[30%] max-sm:w-full">
                <div className="font-bold text-black text-base">Pick-Up</div>
                <div className="text-[#2F2F2F] mt-2 text-sm font-normal">
                  {pickLoc}
                </div>
                <div className="text-[#092C85] mt-2 text-sm font-semibold">
                  {pickupMMDDYYYY.replace(/,(?=\d{2})/, " ")}
                </div>
              </div>
              <div className="w-[15%]  max-sm:w-full flex justify-center items-center max-sm:block">
                <div className="text-center text-xs mb-2 max-sm:inline-flex max-sm:justify-center max-sm:items-center w-full">
                  <span className="hidden max-sm:block">-----</span>
                  <span>{totalDays} Days</span>
                  <span className="hidden max-sm:block">-----</span>
                </div>
                {/* <div className="w-[70px] border border-dashed relative max-sm:hidden">
                  <div className="absolute top-[-13px] right-[-6px]">
                    <ArrowForwardIosIcon className="text-[#555555]" />
                  </div>
                </div> */}
              </div>
              <div className="w-[30%]  max-sm:w-full">
                <div className="font-bold text-black">Drop-off</div>
                <div className="text-[#2F2F2F] mt-2 text-sm font-normal">
                  {dropLoc}
                </div>
                <div className="text-[#092C85] mt-2 text-sm font-semibold">
                  {dropMMDDYYYY.replace(/,(?=\d{2})/, " ")}
                </div>
              </div>
              <div className="flex justify-between  items-center h-fit  max-sm:w-full max-sm:justify-end max-sm:mt-4 max-sm:p-2">
                {four ? (
                  <></>
                ) : (
                  <div className=" whitespace-normal flex  items-center pr-2 pl-2 pt-1 pb-1 bg-[#DBEAFE] rounded md:whitespace-nowrap">
                    <EditOutlinedIcon className="text-sm mr-1" />
                    <span
                      className="text-sm w-fit flex justify-evenly items-center cursor-pointer"
                      onClick={modifyIti}
                    >
                      {" "}
                      Modify Itinerary
                    </span>
                  </div>
                )}
              </div>
            </div>
            {three ? (
              <div className="bg-white w-full pt-4 pl-4 pr-4  rounded flex  justify-between max-sm:block max-sm:border-2 max-sm:border-[#EBEBEB]">
                <div className="flex justify-between max-sm:block">
                  <div className="w-[38%] max-sm:w-full flex items-center justify-center">
                    <img
                      src={
                        `${baseUrl}/app/v1/aws/getImage/carimages/` +
                        carObj?.imageName.split(" ").join("") +
                        ".png"
                      }
                      alt=""
                      className={`object-cover rounded mb-2 ${
                        carObj?.imageName == undefined
                          ? "missing-image rounded mb-2"
                          : ""
                      }`}
                      onError={(e) => {
                        e.target.src = "../static/staticcarimage.png";
                      }}
                    />
                  </div>
                  <div className="w-[60%] max-sm:w-full py-2 pb-4">
                    <div className="font-bold text-base">{carObj?.type}</div>
                    <div className="mt-1 text-sm">{carObj?.title}</div>
                    <div className="mt-4 grid grid-cols-3 gap-4">
                      <div className="inline-flex w-auto">
                        <div className="mr-2 flex items-center">
                          <PersonIcon className="text-base" />
                        </div>
                        <div className="text-[11px] max-sm:text-[10px]">
                          {carObj?.seats + " Passenger"}
                        </div>
                      </div>
                      <div className="inline-flex w-auto">
                        <div className="mr-2 flex items-center">
                          <BusinessCenterIcon className="text-base" />
                        </div>
                        <div className="text-[11px] max-sm:text-[10px]">
                          {carObj?.luggageCapacity + " Suitcases"}
                        </div>
                      </div>
                      <div className="inline-flex w-auto">
                        <div className="mr-2 flex items-center">
                          <CarDoorIcon className="text-base text-black" />
                        </div>
                        <div className="text-[11px] max-sm:text-[10px]">
                          {carObj?.doors + " Doors"}
                        </div>
                      </div>
                      <div className="inline-flex w-auto">
                        <div className="mr-2 flex items-center">
                          <AirCondIcon className="text-base" />
                        </div>
                        <div className="text-[11px] max-sm:text-[10px]">
                          {carObj?.ac == "yes" ? "Air Condition" : ""}
                        </div>
                      </div>
                      <div className="inline-flex w-auto">
                        <div className="mr-2 flex items-center">
                          <TransmissionIcon className="text-base" />
                        </div>
                        <div className="text-[11px] max-sm:text-[10px]">
                          {carObj?.transmissionType}
                        </div>
                      </div>
                      {/* <div className="inline-flex w-auto">
                                <div className="underline text-xs">
                                    More Details +
                                </div>
                            </div> */}
                    </div>
                  </div>
                </div>
                <div
                  className=" flex justify-evenly pl-10 items-center whitespace-nowrap h-fit cursor-pointer max-sm:justify-end max-sm:mt-4 max-sm:p-2"
                  onClick={openModifyCarModal}
                >
                  {four ? (
                    <></>
                  ) : (
                    <div className="bg-[#DBEAFE] pr-2 pl-2 pt-1 pb-1 rounded">
                      <EditOutlinedIcon className=" mr-1 text-xs " />
                      <span className="text-sm">Modify Car</span>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          {three ? (
            <div className=" w-[38%]  max-sm:w-full flex flex-col justify-start items-center ">
              <div className="bg-white max-sm:bg-[#EBEBEB] max-sm:rounded max-sm:mt-4 max-sm:pb-4 h-fit pb-2 pt-2 pl-4 pr-4 rounded w-full max-sm:w-full">
                <div className="font-bold text-base">
                  Payment method : {payNowStatus ? "PAY NOW" : "PAY LATER"}
                </div>
                <div className="text-sm mt-1">Included in the rates</div>
                <div
                  className="flex justify-between text-xs mt-1  "
                  onClick={() => setToggleBase(!toggleBase)}
                >
                  <div className=" flex items-center ">
                    <span>
                      {toggleBase ? (
                        <RemoveCircleOutlineIcon className=" text-sm mr-1 cursor-pointer" />
                      ) : (
                        <AddCircleOutlineIcon className=" text-sm mr-1 cursor-pointer" />
                      )}
                    </span>{" "}
                    <span>Base Rate</span>
                  </div>
                  {toggleBase ? (
                    <></>
                  ) : (
                    <div>
                      {currencyName +
                        " " +
                        (basePrice * totalDays * currencyPrice).toFixed(2)}
                    </div>
                  )}
                </div>
                {toggleBase ? (
                  <>
                    <div className="flex justify-between text-xs mt-1 pl-[1.15rem]">
                      <div>Base Rate</div>
                      <div>
                        {currencyName +
                          " " +
                          (
                            Number(basePrice) * totalDays * currencyPrice +
                            Number(basePrice) * totalDays * currencyPrice * 0.18
                          ).toFixed(2)}
                      </div>
                    </div>
                    <div className="flex justify-between text-xs mt-1 border-b-[1.5px] pb-2 pl-[1.15rem]">
                      <div className="text-red-500">
                        {"Discount" + " " + "(" + "18" + "%" + ")"}
                      </div>
                      <div className="text-red-500">
                        {" - " +
                          currencyName +
                          " " +
                          (
                            Number(basePrice) *
                            totalDays *
                            currencyPrice *
                            0.18
                          ).toFixed(2)}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div
                  className="flex justify-between text-xs mt-1"
                  onClick={() => setToggleExtras(!toggleExtras)}
                >
                  <div className="flex items-center">
                    <span>
                      {toggleExtras ? (
                        <RemoveCircleOutlineIcon className=" text-sm mr-1 cursor-pointer" />
                      ) : (
                        <AddCircleOutlineIcon className=" text-sm mr-1 cursor-pointer" />
                      )}
                    </span>{" "}
                    Extras
                  </div>
                  {toggleExtras ? (
                    <></>
                  ) : (
                    <div>
                      {currencyName +
                        " " +
                        (
                          (excessInsurancePrice +
                            deliveryCharge +
                            collectionCharge +
                            extrasRate) *
                          currencyPrice
                        ).toFixed(2)}
                    </div>
                  )}
                </div>
                {toggleExtras ? (
                  <>
                    <div className="flex justify-between text-xs mt-1 pl-[1.15rem]">
                      {/* {excessInsurancePrice == 0?<></>:<> */}
                      <div>Deposit</div>
                      <div>
                        {currencyName +
                          " " +
                          (excessInsurancePrice * currencyPrice).toFixed(2)}
                      </div>
                      {/* </>} */}
                    </div>
                    <div className="flex justify-between text-xs mt-1 pl-[1.15rem]">
                      <div> Delivery charge</div>
                      <div>
                        {currencyName +
                          " " +
                          (deliveryCharge * currencyPrice).toFixed(2)}
                      </div>
                    </div>
                    <div className="flex justify-between text-xs mt-1 pl-[1.15rem]">
                      <div> Collection charge</div>
                      <div>
                        {currencyName +
                          " " +
                          (collectionCharge * currencyPrice).toFixed(2)}
                      </div>
                    </div>
                    <div className="flex justify-between mt-1 text-xs pl-[1.15rem] border-b-[1.5px] pb-2">
                      <div>
                        Add-ons{" "}
                        {addOnsNames.length > 0 && (
                          <span>
                            (
                            {addOnsNames
                              .map((item) => {
                                return item;
                              })
                              .join(", ")}
                            )
                          </span>
                        )}
                      </div>
                      <div>
                        {currencyName +
                          " " +
                          (extrasRate * currencyPrice).toFixed(2)}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div className="flex justify-between mt-1 text-xs pl-[1.15rem]">
                  <div className="text-blue-400">Taxes (5% VAT)</div>
                  <div>
                    {
                      currencyName +
                        " " +
                        (
                          (basePrice * totalDays + // Base rental cost
                            extrasRate + // Additional charges
                            carDeliveryCharge + // Delivery charge
                            excessInsurancePrice) * // Excess insurance charge
                          currencyPrice * // Conversion to selected currency
                          0.05
                        ) // 5% tax or fee
                          .toFixed(2) // Fix to 2 decimal places
                    }
                  </div>
                </div>
                <div className="flex justify-between mt-1 font-bold text-xs pl-[1.15rem]">
                  <div>Estimated Total</div>
                  <div>
                    {`${currencyName} ${(
                      (basePrice * totalDays +
                        extrasRate +
                        carDeliveryCharge +
                        excessInsurancePrice +
                        (basePrice * totalDays +
                          extrasRate +
                          carDeliveryCharge +
                          excessInsurancePrice) *
                          0.05) *
                      currencyPrice
                    ).toFixed(2)}`}
                  </div>
                </div>
              </div>

              {/* new offer */}

              <div className="w-full max-sm:h-fit border-[1.5px] mt-2 border-[#60A5FA] bg-blue-100 rounded pr-3 pl-3 pt-1 pb-1 box-border">
                <span className=" text-sm font-semibold">For just {currencyName} {(basePrice * totalDays * currencyPrice).toFixed(2)} you're getting an unbeatable bargain on this car...</span><br />
                <span className="text-sm ">Generally, a {carObj.type.substring(0, carObj.type.length - 1)} in Dubai costs {currencyName} {((basePrice * totalDays * currencyPrice) * 1.30).toFixed(2)}!</span>
              </div>
              {/* <div className="relative w-full h-full max-sm:h-fit mt-2 rounded pt-0 pb-1 ">
                <img src={unbeatable_price} alt="" className="w-full h-full" />
                <span className="text-[36px] max-sm:text-[22px] sm:text-[24px] md:text-[26px] lg:text-[36px] absolute text-white right-[6%] bottom-[49%] font-semibold ">
                  <span className="text-[24px] max-sm:text-[20px] sm:text-[20px] lg:text-[22px]">
                    {currencyName}
                  </span>{" "}
                  {(basePrice * totalDays * currencyPrice).toFixed(2)}
                </span>
                <span className="text-[24px] max-sm:text-[16px] sm:text-[22px] md:text-[24px] absolute line-through text-[#ffd9b9] right-[20%] bottom-[22%]">
                  {(basePrice * totalDays * currencyPrice * 1.3).toFixed(2)}
                </span>
              </div> */}
            </div>
          ) : (
            <></>
          )}
        </div>
        {detailModalStatus ? <CarFeaturesModal /> : <></>}

        {itineraryModalStatus ? <ModifyItinerary /> : <></>}
        {carModalStatus ? <ModifyCarModal /> : <></>}
      </div>
    </>
  );
}

export default StepperCustom;
