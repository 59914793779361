import { createSlice } from "@reduxjs/toolkit";



const subscriptionSliceMain = createSlice({
    name:'subscriptionSliceMain',
    initialState:{
        subscriptionCarObj:{},
        tenureWords:"",
        tenureNumber:0,
    },
    reducers:{

        setSubscriptionData:(state,action)=>{
            state.subscriptionCarObj = action.payload.subscriptionCarObj;
            state.tenureWords = action.payload.tenureWords;
            state.tenureNumber = action.payload.tenureNumber;
        },  
        unsetSubscriptionData:(state,action)=>{
            state.subscriptionCarObj = {};
            state.tenureWords = "";
            state.tenureNumber = 0;
        },  

    }
})

export const {
    setSubscriptionData,
    unsetSubscriptionData
} = subscriptionSliceMain.actions;

export default subscriptionSliceMain.reducer;