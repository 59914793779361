import React, { useState } from "react";
import CarImage1 from "./images/1.png";
import CarImage2 from "./images/2.png";
import CarImage3 from "./images/3.png";
import CarImage4 from "./images/4.png";
import CarImage5 from "./images/5.png";
import Slider from "react-slick";
import "./specification.css"

const carImageArray = [
  CarImage1,
  CarImage2,
  CarImage3,
  CarImage4,
  CarImage5,
  CarImage3,
  CarImage4,
  CarImage5,
];


const CarSpecifiHero = () => {
  const [activeImg, setActiveImage] = useState(carImageArray[0]);

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1500,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className="w-full ">
      <div className="flex flex-col gap-7 py-5">
        <div className="">
          <h1 className="md:text-[40px] text-[24px] text-[#092C85] font-bold">
            Rent Hyundai Accent 2024
          </h1>
        </div>
        <div
          className="bigger-car-image-wrapper h-[300px] md:h-[658px] rounded-[12px] shadow-[0px_4px_4px_0px_#00000040]"
        >
          <img
            src={activeImg}
            alt="Car-Image"
            className="w-full h-[100%] rounded-[12px]"
          />
        </div>

        <>
          <Slider {...settings}>
            {carImageArray.map((carImg, index) => {
              return (
                <div
                  key={index}
                  className="w-[17%] rounded-[12px] hover:cursor-pointer shadow-[0px_4px_4px_0px_#00000040]"
                 
                  onClick={() => setActiveImage(carImg)}
                >
                  <img src={carImg} alt="" className="rounded-[12px]" />
                </div>
              );
            })}
          </Slider>
        </>

        {/* <div className="flex justify-between items-center">
          {carImageArray.map((carImg, index) => {
            return (
              <div key={index} className="w-[17%] rounded-[12px] hover:cursor-pointer" style={smallerImgBoxShadow} onClick={()=>setActiveImage(carImg)}>
                <img src={carImg} alt="" className="rounded-[12px]" />
              </div>
            );
          })}
        </div> */}
      </div>
    </div>
  );
};

export default CarSpecifiHero;
