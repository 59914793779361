import { createSlice } from "@reduxjs/toolkit";

const invoiceSlice = createSlice({
  name: "invoiceSlice",
  initialState: {
    baseRate: 0,
    excessInsurancePrice: 0,
    carDeliveryCharge: 0,
    extras: 0,
    taxes: 0.05,
    discount: 0,
    estimatedTotal: 0,
    depositChargesBoolean:{
      fullDepositCheck: false,
      depositPremiumCheck: false
    },
    calculatedTax: 0,
    finalAmountPaymentFailed: 0
  },
  reducers: {
    addExcessInsurance: (state, action) => {
      state.excessInsurancePrice = action.payload;
    },
    removeExcessInsurance: (state, action) => {
      state.excessInsurancePrice = 0;
    },
    addFullDepositCheck: (state, action) => {
      state.depositChargesBoolean.fullDepositCheck = true;
    },
    addDepositPremiumCheck: (state, action) => {
      state.depositChargesBoolean.depositPremiumCheck = true;
    },
    removefullDepositCheck: (state, action) => {
      state.depositChargesBoolean.fullDepositCheck = false;
    },
    removeDepositPremiumCheck: (state, action) => {
      state.depositChargesBoolean.depositPremiumCheck = false;
    },
    addBaseRateCarModal: (state, action) => {
      state.estimatedTotal =
        action.payload.totalBase +
        action.payload.totalExtras +
        (action.payload.totalBase + action.payload.totalExtras) * 0.05;
      state.baseRate = action.payload.totalBase
      state.extras = action.payload.totalExtras;
    },
    addBaseRate: (state, action) => {
      state.baseRate = action.payload
      state.extras = 0;
      state.estimatedTotal =
        (action.payload +
        state.excessInsurancePrice +
        state.carDeliveryCharge) +
        ((action.payload +
          state.excessInsurancePrice +
          state.carDeliveryCharge) *
          0.05);
    },
    addExtras: (state, action) => {

      // state.estimatedTotal =
      //   state.baseRate +
      //   state.excessInsurancePrice +
      //   state.carDeliveryCharge +
      //   (state.extras + action.payload) +
      //   (state.baseRate +
      //     state.excessInsurancePrice +
      //     state.carDeliveryCharge +
      //     (state.extras + action.payload)) *
      //     0.05;

      state.extras = state.extras + action.payload;
    },
    removeExtras: (state, action) => {
      // state.estimatedTotal =
      //   state.baseRate +
      //   state.excessInsurancePrice +
      //   state.carDeliveryCharge +
      //   (state.extras - action.payload) +
      //   (state.baseRate +
      //     state.carDeliveryCharge +
      //     state.excessInsurancePrice +
      //     (state.extras - action.payload)) *
      //     0.05;
      state.extras = state.extras - action.payload;
    },
    addCarDeliveryCharge: (state, action) => {
      state.carDeliveryCharge = action.payload;
    },
    //removing baseRate from emptyInvoice adding new function to remove
    emptyInvoice: (state, action) => {
      // state.baseRate = 0;
      state.extras = 0;
      state.taxes = 0.05;
      state.discount = 0;
      state.estimatedTotal = 0;
    },
    // use this function explicitely to empty baseRate with emptyInvoice
    emptyBaseRate: (state, action) => {
      state.baseRate = 0;
    },
    inrToAed: (state, action) => {
      let x = state.baseRate;
      let y = state.extras;
      state.baseRate = x / 22.62;
      state.extras = y / 22.62;
      state.taxes = (x / 22.62 + y / 22.62) * 0.05;
      state.estimatedTotal =
        x / 22.62 + y / 22.62 + (x / 22.62 + y / 22.62) * 0.05;
    },
    inrToUsd: (state, action) => {
      let x = state.baseRate;
      let y = state.extras;
      state.baseRate = x / 83.03;
      state.extras = y / 83.03;
      state.taxes = (x / 83.03 + y / 83.03) * 0.05;
      state.estimatedTotal =
        x / 83.03 + y / 83.03 + (x / 83.03 + y / 83.03) * 0.05;
    },
    aedToInr: (state, action) => {
      let x = state.baseRate;
      let y = state.extras;
      state.baseRate = x * 22.62;
      state.extras = y * 22.62;
      state.taxes = (x * 22.62 + y * 22.62) * 0.05;
      state.estimatedTotal =
        x * 22.62 + y * 22.62 + (x * 22.62 + y * 22.62) * 0.05;
    },
    aedToUsd: (state, action) => {
      let x = state.baseRate;
      let y = state.extras;
      state.baseRate = x / 3.67;
      state.extras = y / 3.67;
      state.taxes = (x / 3.67 + y / 3.67) * 0.05;
      state.estimatedTotal = x / 3.67 + y / 3.67 + (x / 3.67 + y / 3.67) * 0.05;
    },
    usdToAed: (state, action) => {
      let x = state.baseRate;
      let y = state.extras;
      state.baseRate = x * 3.67;
      state.extras = y * 3.67;
      state.taxes = (x * 3.67 + y * 3.67) * 0.05;
      state.estimatedTotal = x * 3.67 + y * 3.67 + (x * 3.67 + y * 3.67) * 0.05;
    },
    usdToInr: (state, action) => {
      let x = state.baseRate;
      let y = state.extras;
      state.baseRate = x * 83.03;
      state.extras = y * 83.03;
      state.taxes = (x * 83.03 + y * 83.03) * 0.05;
      state.estimatedTotal =
        x * 83.03 + y * 83.03 + (x * 83.03 + y * 83.03) * 0.05;
    },
    addCalculatedTax: (state, action) => {
      state.calculatedTax = action.payload
    },
    addFinalAmountForPaymentFailed: (state, action) => {
      state.finalAmountPaymentFailed = action.payload
    }
  },
});

export const {
  addBaseRate,
  addExtras,
  addFullDepositCheck,
  addDepositPremiumCheck,
  removefullDepositCheck,
  removeDepositPremiumCheck,
  removeExtras,
  emptyInvoice,
  emptyBaseRate,
  inrToAed,
  inrToUsd,
  aedToInr,
  aedToUsd,
  usdToAed,
  usdToInr,
  addBaseRateCarModal,
  addCarDeliveryCharge,
  addExcessInsurance,
  removeExcessInsurance,
  addCalculatedTax,
  addFinalAmountForPaymentFailed
} = invoiceSlice.actions;

export default invoiceSlice.reducer;
