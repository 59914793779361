import React from 'react'

function FaqContact() {
  return (
    <div className='w-[25%] max-sm:hidden'>
        <div className='w-full font-medium text-base bg-[#0F0A34]  py-2 flex justify-center items-center text-white border rounded-t-lg'>
            Support 
        </div>
        <div className='w-full border rounded-b-lg '>
            <div className='pt-6 flex flex-col py-4'>
                <span className='font-semibold text-lg text-[#0F0A34] px-6 my-2'>Dubai:</span>
                <span className='px-6'>+971 569097524</span>
                <span className='px-6'>bookings@wtimobility.ae</span>
                <span className='font-semibold text-lg text-[#0F0A34] px-6 my-2'> Operations:</span>
                <span className='px-6'>+971 56 909 6864</span>
                <span className='font-semibold text-lg text-[#0F0A34] px-6 my-2'> Business Development:</span>
                <span className='px-6'>+971 403 9093</span>
            </div>
        </div>
    </div>
  )
}

export default FaqContact