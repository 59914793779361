import { createSlice } from "@reduxjs/toolkit";

const paymentSession = createSlice({
  name: "paymentSession",
  initialState: {
    sessionID: "",
    paymentPage: false,
    stripeCustomerID: "",
    clientSec: "",
    paymentFailed: false,
  },
  reducers: {
    saveClientSec: (state, action) => {
      state.clientSec = action.payload;
    },
    removeClientSec: (state, action) => {
      state.clientSec = action.payload;
    },
    onPaymentFailed: (state, action) => {
      state.paymentFailed = true;
    },
    offPaymentFailed: (state, action) => {
      state.paymentFailed = false;
    },
    addStripePaymentId: (state, action) => {
      state.stripeCustomerID = action.payload;
    },
    removeStripePaymentId: (state, action) => {
      state.stripeCustomerID = "";
    },

    addSessionID: (state, action) => {
      state.sessionID = action.payload;
    },
    clearSessionID: (state, action) => {
      state.sessionID = "";
    },
    togglePaymentPage: (state, action) => {
      state.paymentPage = !state.paymentPage;
    },
  },
});

export const {
  addSessionID,
  clearSessionID,
  togglePaymentPage,
  addStripePaymentId,
  removeStripePaymentId,
  saveClientSec,
  removeClientSec,
  onPaymentFailed,
  offPaymentFailed
} = paymentSession.actions;

export default paymentSession.reducer;
