import { Suspense } from 'react';
import Loader from '../Loader';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<></>}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
