import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Helmet } from "react-helmet";

function ContactThanku() {
  return (
    <>
      <Helmet>
        <title>
          Thank You
        </title>
        <link rel="canonical" href="https://www.wticarrental.ae/contact/thankyou" />
      </Helmet>
      <div className="w-full pl-[10.27%] pr-[10.27%] py-6 flex justify-center">
        <div className="py-4 px-10 rounded-md shadow-2xl flex flex-col justify-start items-center">
          <CheckCircleIcon className=" text-8xl text-[#94E523] my-8" />
          <span className="text-center font-semibold text-xl text-[#0F0A34] mb-4">
            Thank you for getting in touch with us.
            <br /> We'll be in touch shortly.
          </span>
        </div>
      </div>
    </>
  );
}

export default ContactThanku;
