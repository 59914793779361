import React from "react";
import pointer from "./point.svg";

function AboutPoints() {
  return (
    <div className="pl-[10.27%] pr-[10.27%] flex justify-center items-center">
      <div className="w-full flex flex-col justify-start items-center mt-20">
        <span className="text-center font-semibold leading-8 text-2xl mb-6">
        <h3>Our Unique Propositions</h3>
        </span>
        <span className="text-center font-normal text-sm leading-5 px-20 max-sm:px-4">
          In today's dynamic world, efficient, safe, and sustainable
          transportation solutions are not just a luxury but a necessity. As we
          journey through the intricate maze of urban mobility, WTi emerges
          as a beacon, illuminating the path with its unique strengths. Here are
          the distinct USP's that position WTi ahead of the curve, offering
          unparalleled value to our clients.
        </span>
        <div className=" mt-10 grid grid-cols-3 max-sm:grid-cols-1 gap-x-20 gap-y-4">
          <div className="flex  items-center">
            <img src={pointer} alt="" className="mr-4"/>
            <span className="whitespace-nowrap font-semibold text-sm leading-5">Tech-Enhanced Rides</span>
          </div>
          <div className="flex  items-center">
            <img src={pointer} alt="" className="mr-4"/>
            <span className="whitespace-nowrap font-semibold text-sm leading-5">Nationwide Presence with Local Insight
</span>
          </div>
          <div className="flex  items-center">
            <img src={pointer} alt="" className="mr-4"/>
            <span className="whitespace-nowrap font-semibold text-sm leading-5">Custom-Tailored Rental Solutions</span>
          </div>
          <div className="flex  items-center">
            <img src={pointer} alt="" className="mr-4"/>
            <span className="whitespace-nowrap font-semibold text-sm leading-5">EV Adoption for a Greener Future
</span>
          </div>
          <div className="flex  items-center">
            <img src={pointer} alt="" className="mr-4"/>
            <span className="whitespace-nowrap font-semibold text-sm leading-5">24/7 Dedicated Customer Support
</span>
          </div>
          <div className="flex  items-center">
            <img src={pointer} alt="" className="mr-4"/>
            <span className="whitespace-nowrap font-semibold text-sm leading-5">Committed to Safe, Quality Commutes
</span>
          </div>
       
        </div>
      </div>
    </div>
  );
}

export default AboutPoints;
