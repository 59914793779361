import React from "react";
import { Box, FormControlLabel, Checkbox } from "@mui/material";
import { useSelector } from "react-redux";

function DriverAge() {
  const itineraryModalStatus = useSelector(
    (store) => store.modalToggleSlice.updateItinerary
  );
  return (
    <React.Fragment>
      <Box className="mr-[1rem]">
        <FormControlLabel
          label={
            <span
              className={`text-xs font-thin leading-4 ${
                !itineraryModalStatus ? "text-white" : "text-black"
              }`}
            >
              Driver aged 21+
            </span>
          }
          control={<Checkbox checked={true} />}
        />
      </Box>
    </React.Fragment>
  );
}

export default DriverAge;
