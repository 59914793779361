import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ReactSlider from "react-slider";

function SubscriptionFilters() {
  return (
    <div className="w-full flex justify-between items-center mt-8 mb-20 max-sm:hidden">
      <div className=" h-fit w-fit flex flex-col items-start justify-between ">
        {/* <span className="text-[14px] text-[#666666] mb-2 leading-8">
          Select a plan
        </span>
        <span className="text-black font-bold border-b-4 border-b-[#0F0A34]">
          Monthly
        </span> */}
      </div>
      {/* <div className=" h-fit border-2 border-[#F4F4F4] rounded-xl flex">
        <div className=" pl-3 pr-8 py-3 flex flex-col items-start justify-between border-r cursor-pointer relative ">
          <span className="text-[14px] font-semibold text-[#0F0A34]">
            Price
          </span>
          <span className="text-[12px] font-medium text-[#66667F]">
            Select price range
          </span>
          <div className="absolute w-[18rem] h-[12rem] bg-white bottom-[-12.1rem] left-0 border rounded-xl flex flex-col px-3 py-3">
            <span className=" text-base font-bold ">Price range</span>
            <ReactSlider
              className="horizontal-slider"
              thumbClassName="example-thumb"
              trackClassName="example-track"
              defaultValue={[0, 100]}
              ariaLabel={["Lower thumb", "Upper thumb"]}
              ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
              renderThumb={(props, state) => (
                <div {...props}>{state.valueNow}</div>
              )}
              pearling
              minDistance={10}
            />
          </div>
        </div>
        <div className=" pl-3 pr-8 py-3 flex flex-col items-start justify-between border-r cursor-pointer ">
          <span className="text-[14px] font-semibold text-[#0F0A34]">
            Brands
          </span>
          <span className="text-[12px] font-medium text-[#66667F]">
            Select a Brand
          </span>
        </div>
        <div className=" pl-3 pr-8 py-3 flex flex-col items-start justify-between border-r cursor-pointer ">
          <span className="text-[14px] font-semibold text-[#0F0A34]">
            Category
          </span>
          <span className="text-[12px] font-medium text-[#66667F]">
            Select a category
          </span>
        </div>
        <div className=" pl-3 pr-8 py-3 flex  items-center justify-start cursor-pointer">
          <span className="text-[14px] font-semibold text-[#0F0A34] mr-2">
            Sort by Price: Low to high
          </span>
          <KeyboardArrowDownIcon />
        </div>
      </div> */}
    </div>
  );
}

export default SubscriptionFilters;
