import { createSlice } from "@reduxjs/toolkit";


const loginSlice = createSlice({
    name:"loginSlice",
    initialState:{
        loginModal:false,
        otpPhoneNumber:"",
        adminUser:"",
        emailID: ""
    },
    reducers:{
        toggleLogin:(state,action)=>{
            state.loginModal = !state.loginModal;
        },
        savePhoneNumber:(state,action)=>{
            state.otpPhoneNumber = action.payload;
        },
        clearLogin:(state,action)=>{
            state.loginModal = false;
            state.otpPhoneNumber ="";
        },
        adminUserSave:(state,action)=>{
            state.adminUser = action.payload;
        },
        addUserEmail:(state,action)=>{
            state.emailID = action.payload
        }
    }
})

export const {toggleLogin,clearLogin,savePhoneNumber,adminUserSave,addUserEmail} = loginSlice.actions;

export default loginSlice.reducer;