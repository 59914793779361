import axios from "axios";
import { baseUrl } from "../config";

export const getCarApiCall = async (totalDays) => {
  const config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}/app/v1/vehicles/getSelfVehicles/${totalDays}`,
  };

  try {
    const response = await axios.request(config);
    return response.data.result;
  } catch (error) {
    // Handle errors here if needed
    throw error; // You can rethrow the error for further handling
  }
};
