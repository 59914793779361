import { createSlice } from "@reduxjs/toolkit";

const formControlSlice = createSlice({
  name: "formControlSlice",
  initialState: {
    reservationType: 0,
    differentLocation: false,
    deliveryServiceRequired: false,
    dropServiceRequired: false,
    openCalender:false
  },
  reducers: {
    selfReservationType: (state, action) => {
      state.reservationType = 0;
    },
    chauffeurReservationType: (state, action) => {
      state.reservationType = 1;
    },
    enableDifferentLocation: (state, action) => {
      state.differentLocation = true;
    },
    disableDifferentLocation: (state, action) => {
      state.differentLocation = false;
    },
    toggleDifferentLocation: (state, action) => {
      state.differentLocation = !state.differentLocation;
    },
    toggleDeliveryService: (state, action) => {
      state.deliveryServiceRequired = !state.deliveryServiceRequired;
    },
    toggleDropService: (state, action) => {
      state.dropServiceRequired = !state.dropServiceRequired;
    },
    diableDeliveryAndDrop: (state, action) => {
      state.deliveryServiceRequired = false;
      state.dropServiceRequired = false;
    },
    clearFormControl:(state,action)=>{
      state.reservationType = 0;
      state.differentLocation = false;
      state.deliveryServiceRequired = false;
      state.dropServiceRequired = false;
      state.openCalender = false;

    },
    toggleCalender:(state,action)=>{
      state.openCalender = !state.openCalender;
    },
    closeCalender:(state,action)=>{
      state.openCalender = false
    } 

  },
});

export const {
  selfReservationType,
  chauffeurReservationType,
  enableDifferentLocation,
  disableDifferentLocation,
  toggleDifferentLocation,
  toggleDeliveryService,
  toggleDropService,
  diableDeliveryAndDrop,
  clearFormControl,
  toggleCalender,
  closeCalender
} = formControlSlice.actions;

export default formControlSlice.reducer;
