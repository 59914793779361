import React, { useEffect, useRef, useState } from 'react'
import SelfDriveIconImg from "../../images/self_drive_icon_img.png"
import SubscriptionIconImg from "../../images/subscription_icon_img.png"
import AirportTransferIconImg from "../../images/airport_transfer_icon_img.png"
import ChhaueffuerDriveIconImg from "../../images/chhaueffeur_drive_icon_img.png"
import WtiPickUpHubImg from "../../images/wti_pickup_hub_img.png"
import { Checkbox } from '@mui/material'
// import MyCustomDatePicker from "../../components/CustomCalender/MyCustomDatePicker"
import { Link } from 'react-router-dom';
import "./searchEngine.css"
import MyCustomDatePicker from '../CustomDatePicker/CustomDatePicker'

const SearchEngine = () => {
    const [isDifferentLocationActive, setIsActiveDifferentActive] = useState(false)
    const [isCalenderOpen, setIsCalenderOpen] = useState(false)
    const [isOpenAutoComplete, setIsOpenAutoComplete] = useState(false)
    const [startDateHour, setStartDateHour] = useState("");
    const [startDateMinute, setStartDateMinute] = useState("");
    const [endDateHour, setEndDateHour] = useState("");
    const [endDateMinute, setEndDateMinute] = useState("");
    const calenderWrapperRef = useRef();
    const autocomplteWrapperRef = useRef();
    const currentDate = new Date();
    const nextDay = new Date(currentDate);
    const nextToNextDay = new Date(currentDate);
    nextDay.setDate(currentDate.getDate() + 1);
    nextToNextDay.setDate(currentDate.getDate() + 2);

    const [selectDate, setSelectDateRange] = useState({
        startDate: currentDate,
        endDate: nextToNextDay,
        key: 'selection',
    })







    const myHandleClickOutSide = (event) => {
        if (calenderWrapperRef.current && !calenderWrapperRef.current.contains(event.target)) {
            setIsCalenderOpen(false)
        }
    }



    useEffect(() => {
        document.addEventListener("dblclick", myHandleClickOutSide, true)
        return () => {
            document.removeEventListener("click", myHandleClickOutSide, false)
        }
    }, [])



    const clickOutSideOfAutoCompleteHandler = (event) => {
        if ((autocomplteWrapperRef.current && !autocomplteWrapperRef.current.contains(event.target))) {
            setIsOpenAutoComplete(false)
        }
    }


    useEffect(() => {
        document.addEventListener("click", clickOutSideOfAutoCompleteHandler, true)

        return () => {
            document.removeEventListener("click", clickOutSideOfAutoCompleteHandler, false)
        }
    }, [])



    const locationArr = [
        {
            img: "",
            location: "Zabeel Business Centre (Smark 9) - Umm Hurair Road - Dubai - United Arab Emirates"
        },
        {
            img: "",
            location: "Dubai - United Arab Emrates"
        },
        {
            img: "",
            location: "Dubai Mall- Dubai United Arab Emrates"
        },
        {
            img: "",
            location: "Dubai Hills Mall- Al Khail Road - Dubai United Arab Emrates"
        },
        {
            img: "",
            location: "Dubai City Center- 8th Street Dubai United Arab Emrates"
        },
        {
            img: "",
            location: "Deira - Dubai - United Arab Emrates"
        },

    ]








    return (
        <div className='w-full flex flex-col items-center rounded-[12px] shadow-[0px_3px_20px_0px_#00000029]'>
            <div className='block sm:hidden w-full mt-[60px]'>
                <div className='grid grid-cols-2 gap-2 '>
                    <div className='grow'>
                        <Link>
                            <div className='flex w-full items-center flex-col gap-1 py-3  text-center px-4 rounded-[12px] bg-gradient-to-r from-[#0077B6] to-[#00B4D8]'>
                                <img src={SelfDriveIconImg} alt="" />
                                <span className='text-[#fff] font-normal'>SELF DRIVE</span>
                            </div>
                        </Link>
                    </div>
                    <div className='grow'>
                        <Link>

                            <div className='flex items-center flex-col gap-1 py-3 w-full text-center px-4 rounded-[12px] bg-[#fff]'>
                                <img src={SubscriptionIconImg} alt="" />
                                <span className='text-[#151515] font-normal'>SUBSCRIPTION</span>
                            </div>
                        </Link>
                    </div>
                    <div className='grow'>
                        <Link>
                            <div className='flex items-center flex-col gap-1 py-3 w-full text-center px-4 rounded-[12px] bg-[#fff]'>
                                <img src={AirportTransferIconImg} alt="" />
                                <span className='text-[#151515] font-normal'>AIRPORT TRANSFER</span>
                            </div>
                        </Link>
                    </div>
                    <div className='grow'>
                        <Link>
                            <div className='flex items-center flex-col gap-1 py-3 w-full text-center px-4 rounded-[12px] bg-[#fff]'>
                                <img src={ChhaueffuerDriveIconImg} alt="" />
                                <span className='text-[#151515] font-normal'>CHHAUEFFEUR DRIVE</span>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='sm:block hidden'>
                <div className='flex justify-evenly gap-4'>
                    <Link>

                        <div className='flex items-center gap-1 py-3 w-fit text-center px-4 rounded-t-[12px] bg-gradient-to-r from-[#0077B6] to-[#00B4D8]'>
                            <img src={SelfDriveIconImg} alt="" />
                            <span className='text-[#fff] font-normal'>SELF DRIVE</span>
                        </div>
                    </Link>
                    <Link>
                        <div className='flex items-center gap-1 py-3 w-fit text-center px-4 rounded-t-[12px] bg-[#fff]'>
                            <img src={SubscriptionIconImg} alt="" />
                            <span className='text-[#151515] font-normal'>SUBSCRIPTION</span>
                        </div>
                    </Link>
                    <Link>
                        <div className='flex items-center gap-1 py-3 w-fit text-center px-4 rounded-t-[12px] bg-[#fff]'>
                            <img src={AirportTransferIconImg} alt="" />
                            <span className='text-[#151515] font-normal'>AIRPORT TRANSFER</span>
                        </div>
                    </Link>
                    <Link>
                        <div className='flex items-center gap-1 py-3 w-fit text-center px-4 rounded-t-[12px] bg-[#fff]'>
                            <img src={ChhaueffuerDriveIconImg} alt="" />
                            <span className='text-[#151515] font-normal'>CHHAUEFFEUR DRIVE</span>
                        </div>
                    </Link>

                </div>
            </div>
            <div className='w-full flex flex-col gap-2 py-4 px-3 bg-[#fff]  rounded-[12px] relative sm:mt-[0px] mt-[16px]'>
                <div className='flex gap-2 items-center'>
                    <Checkbox
                        checked={isDifferentLocationActive}
                        onChange={(e) => setIsActiveDifferentActive(e.target.checked)}
                    />
                    <span className='text-[#898989] text-[16px font-semibold leading-[16px]'>Return car to a different location</span>
                </div>
                <div className='w-full flex flex-wrap flex-col lg:flex-row gap-3'>
                    <div className='md:grow w-full relative'>
                        <div className='w-full flex-wrap flex gap-3'>
                            <div className='md:grow w-full bg-[#F1F1F1] border-[1px] border-[#C8C8C8] py-2 px-4 rounded-[12px]' onClick={() => setIsOpenAutoComplete(true)}>
                                <div className='rounded-[12px] flex flex-col gap-[4px]'>
                                    <span className='text-[16px] text-[#898989] font-normal'>Pickup & drop-off location</span>
                                    <input type="text" className='bg-transparent border-none focus:outline-none font-normal text-[20px]' />
                                </div>
                            </div>
                            {
                                isDifferentLocationActive ? (<>
                                    <div className='md:grow w-full  bg-[#F1F1F1] border-[1px] border-[#C8C8C8] py-2 px-4 rounded-[12px]'>
                                        <div className='rounded-[12px] flex flex-col gap-[4px]'>
                                            <span className='text-[16px] text-[#898989] font-normal'>Pickup & drop-off location</span>
                                            <input type="text" className='bg-transparent border-none focus:outline-none font-normal text-[20px]' />
                                        </div>
                                    </div>
                                </>) : (<></>)
                            }

                        </div>

                        {
                            isOpenAutoComplete && (
                                <div className='absolute top-[90px] z-10 overflow-y-scroll shadow-[0px_3px_30px_0px_#00000029] h-[360px] bg-white py-4 rounded-[12px]' id='search-engine' ref={autocomplteWrapperRef}>
                                    
                                    {
                                        locationArr.map((location, index) => {
                                            return (
                                                <div className='flex flex-col py-2 px-3 hover:cursor-pointer w-full hover:bg-[#e7e7e7]' key={index}>
                                                    <div className='flex items-center  gap-4 w-full'>

                                                        <img src={WtiPickUpHubImg} className='w-[90px] rounded-[12px] shadow-[0px_3px_20px_0px_#55555529]' alt="" />

                                                        <div className=''>
                                                            <p className='text-[#222222] text-wrap text-[16px] font-normal leading-[24px]'>
                                                                {location.location}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        }
                    </div>
                    <div className='md:grow w-full'>
                        <div className='w-full flex-wrap flex gap-3 relative'>
                            <div className='md:grow w-full bg-[#F1F1F1] border-[1px] border-[#C8C8C8] py-2 px-4 rounded-[12px]' onClick={() => setIsCalenderOpen(!isCalenderOpen)}>
                                <div className='rounded-[12px] flex flex-col gap-[4px]'>
                                    <span className='text-[16px] text-[#898989] font-normal'>Pickup date & time</span>
                                    <div>
                                        <span className='font-normal text-[20px] text-[#222222]'>23 Apr 2024 </span>
                                        <span className='font-normal text-[16px] text-[#0077B6]'>10:00 PM</span>
                                    </div>
                                </div>
                            </div>
                            <div className='md:grow w-full bg-[#F1F1F1] border-[1px] border-[#C8C8C8] py-2 px-4 rounded-[12px]'>
                                <div className='rounded-[12px] flex flex-col gap-[4px]'>
                                    <span className='text-[16px] text-[#898989] font-normal'>Drop-off date & time</span>
                                    <div>
                                        <span className='font-normal text-[20px] text-[#222222]'>24 Apr 2024 </span>
                                        <span className='font-normal text-[16px] text-[#0077B6]'>10:00 PM</span>
                                    </div>
                                </div>
                            </div>
                            {
                                isCalenderOpen && (
                                    <div className='absolute -top-[12px] sm:w-full rounded-[20px] -left-6 z-50' ref={calenderWrapperRef}>
                                        <div className='bg-[#fff]'>
                                            <MyCustomDatePicker
                                                selectDate={selectDate}
                                                setSelectDateRange={setSelectDateRange}
                                                isCalenderOpen={isCalenderOpen}
                                                setIsCalenderOpen={setIsCalenderOpen}
                                                setStartDateHour={setStartDateHour}
                                                setStartDateMinute={setStartDateMinute}
                                                setEndDateHour={setEndDateHour}
                                                setEndDateMinute={setEndDateMinute}

                                            />
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className='w-full'>
                    <span>Driver’s age :</span>
                </div>
                <button className='absolute -translate-x-[50%] left-[50%] -bottom-[20px] bg-gradient-to-r from-[#0077B6] to-[#00B4D8] text-[#fff] rounded-[12px] py-2 px-6 w-fit text-[20px]'>Search</button>
            </div>
        </div>
    )
}

export default SearchEngine
