import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import WtiCabsLogo from "../../images/wti_car_rental_logo.svg"
import { Drawer } from '@mui/material'

const Navbar = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)



    const closeDrawerHandler = (value) => {
        setIsDrawerOpen(value)
    }

    const menuLinks = [
        {
            to: "#",
            text: "Company"
        },
        {
            to: "#",
            text: "Services"
        },
        {
            to: "#",
            text: "Corporate"
        },
        {
            to: "#",
            text: "Contact Us"
        },
    ]
    return (
        <div className='absolute top-0 z-[999] w-full px-3 h-[70px] flex items-center' >
            <div className='w-full xl:w-[1200px] mx-auto'>
                <div className='w-full flex justify-between items-center'>
                    <NavLink to="/">
                        <img src={WtiCabsLogo} className='w-[120px]' alt="Wti Cabs Logo" />
                    </NavLink>

                    <div className='hidden md:block'>
                        <div className='flex  gap-8 items-center'>
                            {
                                menuLinks.map((linkItem, index) => {
                                    return (

                                        <NavLink className="text-white text-[15px] tracking-[1px] font-normal" to={linkItem.to} key={index}>
                                            {linkItem.text}
                                        </NavLink>
                                    )
                                })
                            }
                            <div className="py-2 px-4 rounded-[12px] bg-gradient-to-r from-[#0077B6] to-[#00B4D8] hover:cursor-pointer">
                                <span className='text-[#fff] font-normal text-[14px] tracking-[1px'>Login/SignUp</span>
                            </div>



                        </div>
                    </div>
                    <button onClick={() => {
                        setIsDrawerOpen(true)
                    }


                    } className='text-white text-[28px] md:hidden block'>#</button>

                    <>
                        <Drawer open={isDrawerOpen} onClose={() => closeDrawerHandler(false)}>
                            <NavLink to="#" className="bg-[#191919]">
                                <img src={WtiCabsLogo} className='w-[120px]' alt="Wti Cabs Logo" />
                            </NavLink>
                            <div className='flex flex-col'>
                                {
                                    menuLinks.map((linkItem, index) => {
                                        return (

                                            <NavLink className="text-[#121212] text-[15px] tracking-[1px] font-normal" to={linkItem.to} key={index}>
                                                {linkItem.text}
                                            </NavLink>
                                        )
                                    })
                                }
                            </div>
                        </Drawer>
                    </>
                </div>
            </div>
        </div>
    )
}

export default Navbar
