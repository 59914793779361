import React from "react";
import VehicleTypeIcon from "../../../images/vehicle_type_icon.png";
import VehicleAcIcon from "../../../images/vehicle_ac_icon.png";
import VehicleSeatCapacityIcon from "../../../images/vehicle_seat_capacity_icon.png";
import WhatsAppIcon from "../../../images/whatsapp_icon.svg";
import EmailIcon from "../../../images/email_icon.svg";
import DoubleArrowIcon from "../../../images/double_arrow_icon.svg";
import "./Booking.css";
import { baseUrl } from "../../../config";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addCarDetail, toggleCarDetail } from "../../../utils/ModalSlice/modalToggleSlice";
import { addCarId, addModifyBasePrice, addModifyCarDetail } from "../../../utils/modifyBookingSlices/modifyBooking";
import { addInsuranceArr } from "../../../utils/insuranceSlice";

const connectOnWhatsApp = () => {
  let url = "https://api.whatsapp.com/send?phone=971527687206&text=Hi";
  window.open(url);
};

const getGeoInfo = (type) => {
  axios
    .get("https://ipapi.co/json/")
    .then(async (response) => {
      let responseData = response.data;
      let data = JSON.stringify({
        date: "",
        location: responseData,
        fromPage: 2,
        type: type.toString(),
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseUrl}/app/v1/track/tracking`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then(() => {
          console.log("tracking entry");
        })
        .catch((error) => {
          console.log(error);
        });
    })
    .catch((error) => {
      console.log(error);
    });
};

const handleWhatsapp = () => {
  let calltype = "whatsapp";
  getGeoInfo(calltype);
  connectOnWhatsApp();
};

const VehicleCard = (props) => {
  const currencyPrice = useSelector(
    (store) => store.currencySlice.currentCurrRate
  );
  const currencyName = useSelector((store) => store.currencySlice.currentCurr);
  const modifyReservationDays = useSelector((store) => store.modifyBookingSlice.modifyReservationDays);
  const dispatch = useDispatch();
  const {
    title,
    seats,
    imageName,
    type,
    ac,
    vehicleID,
    payNowPrice,
    percentageHikePayNow,
    percentageHikePayLater,
    excessInsuranceCharge,
    insuranceAndDamageCovers,
    excessInsuranceChargePremiumPercentage,
  } = props.vehicleObj;
 

  let payNowPriceActual = 0;
  let percentageHikePayNowActual = 0;
  if (payNowPrice?.daily) {
    payNowPriceActual = payNowPrice?.daily;
    percentageHikePayNowActual = percentageHikePayNow?.daily;
  } else if (payNowPrice?.weekly) {
    payNowPriceActual = payNowPrice.weekly;
    percentageHikePayNowActual = percentageHikePayNow?.weekly;
  } else if (payNowPrice?.monthly) {
    payNowPriceActual = payNowPrice.monthly;
    percentageHikePayNowActual = percentageHikePayNow.monthly;
  }

  const basePrice = ( payNowPriceActual + payNowPriceActual*percentageHikePayNowActual/100 ) * modifyReservationDays
  const total = ( payNowPriceActual + payNowPriceActual*percentageHikePayNowActual/100 )* modifyReservationDays * currencyPrice

  const vehicleFeatureArr = [
    // {
    //   icon: VehicleTypeIcon,
    //   text: "Sedan",
    // },
    {
      icon: VehicleAcIcon,
      text: "AC",
    },
    {
      icon: VehicleTypeIcon,
      // text: "SUV",
        text: `${type}`,
    },
    {
      icon: VehicleSeatCapacityIcon,
      text: `${seats} Seater`,
    },
  ];

  const handleDetailModal = (carInfo) => {
    dispatch(addCarDetail(carInfo));
    dispatch(toggleCarDetail());
    document.documentElement.style.overflow = "hidden";
  };

  const onPayNow = () => {
    const carDetail = {
      type: type,
      typeID: vehicleID,
      src: imageName,
      title: title,
      basePrice: basePrice
    };

    props.setTypeID(vehicleID)
    dispatch(addInsuranceArr(insuranceAndDamageCovers));
    dispatch(addCarId(vehicleID))
    dispatch(addModifyBasePrice(basePrice))
    dispatch(addModifyCarDetail(carDetail))
    window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" })
  }

  return (
    <div className=" w-full md:px-3 mb-6 group" key={props.index}>
      <div className={`relative card-wrapper w-full border-[2px] duration-150 hover:scale-[1.05] ${vehicleID == props.typeID ? 'border-[#EB840C]': ''} bg-[#ffffff]  rounded-[12px] overflow-hidden`}>
        <div className="h-[148%] w-[160%] absolute rotate-[351deg] -bottom-[82%] -left-[30%] bg-tilt"></div>
        <div className="absolute top-[16px] py-1 px-6 rounded-[20px] right-[16px] bg-gradient-to-r from-[#ED5500] to-[#FF3EA5]">
          <span className="text-[#fff] text-[14px] font-normal leading-[16px]">
          {vehicleID == props.typeID ? 'Selected' : 'New'}
          </span>
        </div>
        <div className="card flex w-full flex-col items-center gap-3 p-4 relative z-[10]">
          {/* ======Car Name ======== */}
          <div className="w-full">
            <div className="flex flex-col gap-0">
              <h3 className="w-[80%]">{imageName}</h3>
              {/* <span>|</span> */}
              <h3 className="text-xs text-[#5F5F5F]">2024</h3>
            </div>
            <div className="w-[130px] h-[5px] rounded-[8px] bg-gradient-to-r from-[#03045E] to-[#00D5FF] mt-[16px]"></div>
            <div className="w-[80px] h-[5px] rounded-[8px] bg-gradient-to-r from-[#03045E] to-[#00D5FF] mt-[8px]"></div>
          </div>
          <div className="flex justify-center items-center">
            <img
              //   src={FeatureVehicleCarImg}
              src={
                "../images/" +
                (imageName ? imageName.split(" ").join("") : "") +
                ".png"
              }
              onError={(e) => {
                e.target.src = "../static/staticcarimage.png";
                e.target.style.height = "100px";
                e.target.style.width = "130px";
                e.target.style.display = "block";
                e.target.style.margin = "0 auto";
              }}
              alt=""
              className="w-[80%] group-hover:scale-[1] duration-150"
            />
          </div>
          <div className="flex gap-2 items-center w-full">
            {vehicleFeatureArr.map((el, index) => {
              return (
                <div
                  className="grow backdrop-filter backdrop-blur-[12px] rounded-[8px] bg-[#FFFFFF33] shadow-[0px_3px_12px_0px_#0000001F]"
                  key={index}
                >
                  <div className="flex flex-col gap-1 items-center py-2">
                    <img src={el.icon} alt="" className="w-[16px] h-[16px]" />
                    <span className="text-center text-[10px] text-[#fff] font-medium">
                      {el.text}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex flex-col  items-center w-full pt-4">
            <div className="flex justify-between  items-center w-full">
              <h3 className="text-[#FFFFFF] text-[20px] md:text-[22px] font-normal leading-[24px] md:leading-[32px]">
                {total.toFixed(2)}
                <span className="text-[18px] font-normal">
                  {" "}
                  {currencyName.toUpperCase()}
                </span>
              </h3>
              <div>
                <button className="relative  bg-gradient-to-r text-[16px]  text-[#fff]  from-[#EB840C] hover:text-[#03045E] hover:from-[#fff] hover:to-[#fff] to-[#EF5600] py-2 px-4 rounded-[8px]" onClick={onPayNow}>
                  {/* <span className="absolute z-20 bg-[#fff] -top-[12px] left-[6%] text-[10px] text-[#03045E] font-normal inline-block py-1 px-2 rounded-[4px] leading-[12px]">
                    20% off
                  </span>{" "} */}
                  Pay Now
                </button>
              </div>
            </div>
            <div className="h-[1px] bg-[#fbb2fb38] rounded-[4px] w-full  mt-2 md:mt-4"></div>
          </div>
          {/* <div className="flex flex-col  items-center w-full">
            <div className="flex justify-between  items-center w-full">
              <h3 className="text-[#FFFFFF] text-[20px]  md:text-[22px] font-normal leading-[24px] md:leading-[32px]">
                {total.toFixed(2)}
                <span className="text-[18px] font-normal">
                  {" "}
                  {currencyName.toUpperCase()}
                </span>
              </h3>
              <div>
                <button className="relative bg-[#fff] text-[16px] text-[#03045E] py-2 px-4 rounded-[8px]">
                  Pay Later
                </button>
              </div>
            </div>
            <div className="h-[1px] bg-[#fbb2fb38] rounded-[4px] w-full mt-2 md:mt-4"></div>
          </div> */}
          <div className="flex items-center w-full">
            <div className="flex gap-4 items-center w-full">
              <img
                src={WhatsAppIcon}
                alt=""
                className="w-[32px] h-[32px] hover:cursor-pointer"
                type="button"
                onClick={handleWhatsapp}
              />
              <img src={EmailIcon} alt="" className="w-[32px] h-[32px]" />
            </div>
            <div className="flex justify-end gap-3 w-full items-center hover:cursor-pointer" onClick={() => handleDetailModal(props?.vehicleObj)}>
              <span className="text-[#fff] whitespace-nowrap underline text-[14px] font-normal leading-[14px]">
                More details
              </span>
              <img src={DoubleArrowIcon} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleCard;
