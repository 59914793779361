import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EquipmentCard from "./EquipmentCardNew";
import { addModifyExcessInsurance } from "../../utils/modifyBookingSlices/modifyBooking";
import { addDepositPremiumCheck, addFullDepositCheck } from "../../utils/invoice/invoiceSlice";

const Deposit = ({
  carObj
}) => {

  const dispatch = useDispatch();
  const [fullyDeposit, setFullyDeposit] = useState(false);
  const [premiumDeposit, setPremiumDeposit] = useState(false);
  const excessInsuranceSelectedBefore = useSelector((store) => store.invoiceSlice.excessInsurancePrice)
  const modifyNoDeposit = useSelector((store) => store.modifyBookingSlice.modifyNoDeposit)
  const modifyFullDeposit = useSelector((store) => store.modifyBookingSlice.modifyFullDeposit)

  const depositData = [
    {

      id: "03",
      name: "Full Deposit (Refundable)",
      type: "fully_deposit",
      title: "Full Deposit (Refundable)",
      checked: false,
      img: "babySeat",
      description:
        "Reserve now with a full deposit, refunded upon return.",
      price: {
        daily: modifyFullDeposit,
        // daily: carObj["excessInsuranceCharge"],
      },
    },
    {
      id: "05",
      name: "No Deposit Premium",
      type: "deposit_premium",
      title: "No Deposit Premium",
      checked: false,
      img: "additionalDriver",
      description:
        "Skip the deposit, choose our convenient premium option.",
      price: {
        daily: modifyNoDeposit
          // carObj?.excessInsuranceChargePremiumPercentage?.threeDaysOrLess ||
          // carObj?.excessInsuranceChargePremiumPercentage?.weeklyOrMore ||
          // carObj?.excessInsuranceChargePremiumPercentage?.monthlyOrLess ||
          // carObj?.excessInsuranceChargePremiumPercentage?.moreThanMonth,
      },
    },
  ];

  useEffect(() => {
    if(excessInsuranceSelectedBefore == depositData[0].price.daily){
      setFullyDeposit(true)
      setPremiumDeposit(false);
      dispatch(addFullDepositCheck());
      dispatch(addModifyExcessInsurance(depositData[0].price.daily));
    } 
    if(excessInsuranceSelectedBefore == depositData[1].price.daily){
      setPremiumDeposit(true);
      setFullyDeposit(false)
      dispatch(addDepositPremiumCheck());
      dispatch(addModifyExcessInsurance(depositData[1].price.daily));
    }
  }, [])

  return (
    <>
      <div className="w-full pt-4">
        <span className="font-semibold leading-5">Deposit</span>
        <div className="grid grid-cols-2 gap-4 mt-2 max-sm:grid-cols-1">
          <EquipmentCard
            equipmentsObj={depositData[0]}
            currentFullyDepositState={fullyDeposit}
            setFullyDepositState={setFullyDeposit}
            currentDepositPremiumState={premiumDeposit}
            setDepositPremiumState={setPremiumDeposit}
            sectionType={"Deposit"}
            indexValue={0}
          />
          <EquipmentCard
            equipmentsObj={depositData[1]}
            currentDepositPremiumState={premiumDeposit}
            setDepositPremiumState={setPremiumDeposit}
            currentFullyDepositState={fullyDeposit}
            setFullyDepositState={setFullyDeposit}
            sectionType={"Deposit"}
            indexValue={1}
          />
        </div>
      </div>
    </>
  );
};

export default Deposit;
