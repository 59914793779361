import React, { useEffect, useRef, useState } from 'react'
import SelfDriveIconImg from "../../images/self_drive_icon_img.png"
import SubscriptionIconImg from "../../images/subscription_icon_img.png"
import AirportTransferIconImg from "../../images/airport_transfer_icon_img.png"
import ChhaueffuerDriveIconImg from "../../images/chhaueffeur_drive_icon_img.png"
import ArrowDropDownImg from "../../images/arrow_drop_down_img.png"
import { Link } from 'react-router-dom'


const SubscrptionSearchEngine = () => {

    const [isOpenDropDown, setIsOpenDropDown] = useState(false)
    const [selectedLocation, setSelectedLocation] = useState("")


    const selectDropDownRef = useRef()

    const handleClickOutside = (event) => {

        if (selectDropDownRef.current && !selectDropDownRef.current.contains(event.target)) {
            setIsOpenDropDown(false)
        }
    }

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true)

        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        }
    }, [])







    return (
        <>
            <div className='w-full flex flex-col items-center rounded-[12px] shadow-[0px_3px_20px_0px_#00000029]'>
                <div className='block sm:hidden w-full mt-[60px]'>
                    <div className='grid grid-cols-2 gap-2 '>
                        <div className='grow'>
                            <Link>
                                <div className='flex w-full items-center flex-col gap-1 py-3  text-center px-4 rounded-[12px] bg-gradient-to-r from-[#0077B6] to-[#00B4D8]'>
                                    <img src={SelfDriveIconImg} alt="" />
                                    <span className='text-[#fff] font-normal'>SELF DRIVE</span>
                                </div>
                            </Link>
                        </div>
                        <div className='grow'>
                            <Link>

                                <div className='flex items-center flex-col gap-1 py-3 w-full text-center px-4 rounded-[12px] bg-[#fff]'>
                                    <img src={SubscriptionIconImg} alt="" />
                                    <span className='text-[#151515] font-normal'>SUBSCRIPTION</span>
                                </div>
                            </Link>
                        </div>
                        <div className='grow'>
                            <Link>
                                <div className='flex items-center flex-col gap-1 py-3 w-full text-center px-4 rounded-[12px] bg-[#fff]'>
                                    <img src={AirportTransferIconImg} alt="" />
                                    <span className='text-[#151515] font-normal'>AIRPORT TRANSFER</span>
                                </div>
                            </Link>
                        </div>
                        <div className='grow'>
                            <Link>
                                <div className='flex items-center flex-col gap-1 py-3 w-full text-center px-4 rounded-[12px] bg-[#fff]'>
                                    <img src={ChhaueffuerDriveIconImg} alt="" />
                                    <span className='text-[#151515] font-normal'>CHHAUEFFEUR DRIVE</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='sm:block hidden'>
                    <div className='flex justify-evenly gap-4'>
                        <Link>

                            <div className='flex items-center gap-1 py-3 w-fit text-center px-4 rounded-t-[12px] bg-gradient-to-r from-[#0077B6] to-[#00B4D8]'>
                                <img src={SelfDriveIconImg} alt="" />
                                <span className='text-[#fff] font-normal'>SELF DRIVE</span>
                            </div>
                        </Link>
                        <Link>
                            <div className='flex items-center gap-1 py-3 w-fit text-center px-4 rounded-t-[12px] bg-[#fff]'>
                                <img src={SubscriptionIconImg} alt="" />
                                <span className='text-[#151515] font-normal'>SUBSCRIPTION</span>
                            </div>
                        </Link>
                        <Link>
                            <div className='flex items-center gap-1 py-3 w-fit text-center px-4 rounded-t-[12px] bg-[#fff]'>
                                <img src={AirportTransferIconImg} alt="" />
                                <span className='text-[#151515] font-normal'>AIRPORT TRANSFER</span>
                            </div>
                        </Link>
                        <Link>
                            <div className='flex items-center gap-1 py-3 w-fit text-center px-4 rounded-t-[12px] bg-[#fff]'>
                                <img src={ChhaueffuerDriveIconImg} alt="" />
                                <span className='text-[#151515] font-normal'>CHHAUEFFEUR DRIVE</span>
                            </div>
                        </Link>

                    </div>
                </div>
                <div className='w-full flex flex-col gap-2 py-8 px-3 bg-[#fff]  rounded-[12px] relative sm:mt-[0px] mt-[16px]'>
                    <div className='w-full flex flex-wrap flex-col lg:flex-row gap-3'>
                        <div className='grow-[1]'>
                            <div className='w-full flex-wrap flex gap-3'>
                                <div className='grow bg-[#F1F1F1] border-[1px] border-[#C8C8C8] py-2 px-4 rounded-[12px]'>
                                    <div className='rounded-[12px] flex flex-col gap-[4px] py-3 x-4'>
                                        <span className='text-[16px] text-[#898989] font-normal'>Pickup & drop-off location</span>
                                        {/* <input type="text" className='bg-transparent border-none focus:outline-none font-normal text-[20px]' /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='grow-[4] '>
                            <div className='w-full flex-wrap flex gap-3 relative h-full'>
                                <div className='grow h-full bg-[#ffffff] border-[1px] hover:border-[#6877ff] border-[#C8C8C8] py-3 px-4 rounded-[12px] relative'>
                                    <div className='flex justify-between items-center h-full  '>
                                        <span onClick={() => setIsOpenDropDown(!isOpenDropDown)} className='bg-transparent border-none focus:outline-none w-full font-normal text-[20px] block h-full'>
                                            {selectedLocation}
                                        </span>
                                        <img src={ArrowDropDownImg} alt="" />
                                    </div>

                                    {/* <input type="text" onClick={() => setIsOpenDropDown(true)} className='bg-transparent border-none focus:outline-none w-full font-normal text-[20px]' /> */}


                                    {
                                        isOpenDropDown && (
                                            <div className='flex items-center flex-col w-full overflow-y-scroll h-[320px] bg-[#f33737] absolute top-[68px] left-0 z-10 rounded-[12px] shadow-[0px_3px_30px_0px_#00000029]' ref={selectDropDownRef}>
                                                {
                                                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map((location, index) => {
                                                        return (
                                                            <div className='w-full'
                                                                onClick={() => {
                                                                    setSelectedLocation(location)
                                                                    setIsOpenDropDown(false)
                                                                }}
                                                                key={index}>
                                                                <span className='text-[#6A6A6A] hover:text-[#fff] text-[16px] font-normal leading-[24px] h-full hover:bg-gradient-to-r hover:from-[#00B4D8] hover:to-[#90E0EF] py-3 px-6  bg-[#ffffff] block' >{location}</span>
                                                            </div>

                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    }



                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full'>
                        <span>Driver’s age :</span>
                    </div>
                    <button className='absolute -translate-x-[50%] left-[50%] -bottom-[20px] bg-gradient-to-r from-[#0077B6] to-[#00B4D8] text-[#fff] rounded-[12px] py-2 px-6 w-fit text-[20px]'>Search</button>
                </div>
            </div>
        </>
    )
}

export default SubscrptionSearchEngine
