import {React,useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMemo } from "react";
import { useNavigate } from 'react-router-dom';
import { toggleCancelModal } from "../../../utils/ModalSlice/modalToggleSlice";
import axios from "axios";
import { emptySelectedCancelCard } from "../../../utils/ModalSlice/modalToggleSlice";
import { baseUrl } from "../../../config";
import { switchOffLoader, switchOnLoader } from "../../../utils/loaderSlice";

function CancelConfirmation(){
    const cancelModalStatus = useSelector((store)=>store.modalToggleSlice.cancelModal);
    const selectedCancelCard = useSelector((store)=>store.modalToggleSlice.selectedCancelCard);
    const selectedCancelModifiedCard = useSelector((store)=>store.modalToggleSlice.selectedCancelModifiedCard);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const firstName = useSelector((store) => store.userSlice.firstName);
    const lastName = useSelector((store) => store.userSlice.lastName);
    const emailId = useSelector(
      (store) => store.loginSlice.emailID
    );


    useEffect(()=>{
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        document.documentElement.style.overflow = "hidden";
        return ()=>{
            document.documentElement.style.overflow = "visible";
        }
    },[])

    const pressYes = ()=>{
      
      dispatch(toggleCancelModal());
      dispatch(switchOnLoader());

        let data = JSON.stringify({
          "reservationID": `${selectedCancelCard}`,
          "modifiedReservationID": `${selectedCancelModifiedCard}`,
          "emailId": emailId,
          "name": firstName+ " "+ lastName,
        });
        
        let config = {
          method: 'put',
          maxBodyLength: Infinity,
          url: `${baseUrl}/app/v1/reservation/cancelReservation`,
          headers: {
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        axios.request(config)
        .then((response) => {
          dispatch(emptySelectedCancelCard());
          dispatch(switchOffLoader());
          navigate(0);
        })
        .catch((error) => {
          console.log(error);
        });
    
    }

    const pressNo = ()=>{
        dispatch(toggleCancelModal());

    }
    return(
        <div className="absolute w-[100%] h-screen bg-black  z-40 bg-opacity-70 flex justify-center items-center">
            <div className="flex flex-col justify-center items-center bg-white w-[30%] rounded-md">
                <span className="mb-10 mt-4 font-semibold text-xl ">Are you sure?</span>
                <div className="flex justify-evenly items-center w-full mb-4">
                    <button className="w-[30%] border-[1px] broder-[#092c85] hover:bg-[#092C85] hover:text-white border-black py-2  rounded-md" onClick={pressYes}>Yes</button>
                    <button className="w-[30%] border-[1px] broder-[#092c85] border-black py-2 hover:bg-[#092C85] hover:text-white  rounded-md" onClick={pressNo}>No</button>
                </div>
            </div>
        </div>
    )
}

export default CancelConfirmation;