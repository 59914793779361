import React from 'react'
import FeatureVehicleCarImg from '../../images/feature_vehicle_img1.png';
import VehicleTypeIcon from '../../images/vehicle_type_icon.png';
import VehicleDoorIcon from '../../images/vehicle_door_img.png';
import VehicleSeatCapacityIcon from '../../images/vehicle_seat_capacity_icon.png';
import HeartImgIcon from '../../images/heart_img.svg';


const VehicleFleetCard = () => {
    return (
        <>
            <div className='card-wrapper w-full py-6 '>
                <div className='mx-[20px] border-[2px] border-transparent hover:cursor-pointer hover:border-[#21DAFF] backdrop-blur-[12px] bg-[#FFFFFF14] shadow-[0px_3px_20px_0px_#00D4FF66] rounded-[12px] '>
                    <div className='card flex flex-col items-center gap-10 p-4 relative z-[10]'>
                        <div className='py-2 px-4 flex items-center justify-center'>
                            <img src={FeatureVehicleCarImg} className='object-fit w-[70%]' alt="" />
                        </div>
                        <div className='flex gap-[19px] flex-col  w-full'>
                            <div className='flex justify-between items-center'>
                                <h4 className='text-[20px] text-[#ffffff] leading-[24px] font-semibold'>Kaiyi X3 Pro</h4>
                                <img src={HeartImgIcon} alt="" />
                            </div>
                            <div className='flex gap-2 items-center'>
                                <div className='flex justify-start gap-1 items-center'>
                                    <img src={VehicleSeatCapacityIcon} className='w-[26px] h-[26px]' alt="" />
                                    <span className='text-[16px] text-[#ffffff] leading-[20px] font-semibold'>5 Seats</span>
                                </div>
                                <div className='flex justify-start gap-1 items-center'>
                                    <img src={VehicleDoorIcon} className='w-[26px] h-[26px]' alt="" />
                                    <span className='text-[16px] text-[#ffffff] leading-[20px] font-semibold'>5 Door</span>
                                </div>
                                <div className='flex justify-start gap-1 items-center'>
                                    <img src={VehicleTypeIcon} className='w-[26px] h-[26px]' alt="" />
                                    <span className='text-[16px] text-[#ffffff] leading-[20px] font-semibold'>SUV</span>
                                </div>
                            </div>
                            <div className='h-[1px] rounded-[8px] w-full bg-[#fff]'>
                            </div>
                            <div className='flex justify-between items-center'>
                                <div className='flex flex-col gap-[12px]'>
                                    <span className='text-[16px] text-[#fff] leading-[16px] font-normal '>Daily rate from</span>
                                    <h4 className='text-[24px] text-[#ffffff] leading-[32px] font-semibold'>120 AED</h4>
                                </div>
                                <button className='rounded-[12px] text-[#fff] text-[16px] font-semibold bg-gradient-to-r from-[#EB840C] to-[#EF5600] py-2 px-4 shadow-[0px_3px_12px_0px_#0000001F]'>Rent Now</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default VehicleFleetCard
