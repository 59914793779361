import React from "react";
import trustImg from "./trust.png";
import equality from  "./equality.png";
import csImg from "./csSuc.png";
import inv8Img from "./inno.png";
import sustain from "./sustainability-img.png";
function AboutValues() {
  return (
    <div className="w-full mt-20 ">
      <div className="w-full flex justify-center items-center">
        <span className="text-[#0F0A34] font-semibold text-2xl leading-8 p-4"><h4>Our Core Values</h4></span>
      </div>
      <div className="w-full flex max-sm:flex-col justify-evenly">
      <div className="w-[280px] h-[412px] max-sm:w-full bg-blue-400 bg-cover flex flex-col items-center justify-between relative"  >
          <img src={trustImg} alt="" className="w-full h-full object-cover object-center absolute" />
          <div className="w-full h-full bg-black opacity-70 absolute"></div>
            <span className="font-normal text-xl text-white top-14  absolute">TRUST</span>
            <span className="text-center font-normal text-white text-sm top-52 p-6 absolute">We are committed to stakeholder relationships, data privacy and lead with ethics and integrity.</span>
        </div>
        <div className="w-[280px] h-[412px] max-sm:w-full bg-blue-400 bg-cover flex flex-col items-center justify-between relative"  >
          <img src={csImg} alt="" className="w-full h-full object-cover object-center absolute" />
          <div className="w-full h-full bg-black opacity-80 absolute"></div>
            <span className="font-normal text-xl text-white top-14  absolute">CUSTOMER SUCCESS</span>
            <span className="text-center font-normal text-white text-sm top-52 p-6 absolute">We believe in Customer first Approach. Working together as one, we guide our clients to deliver transformative outcomes and mutual success</span>
        </div>
        <div className="w-[280px] h-[412px] max-sm:w-full bg-blue-400 bg-cover flex flex-col items-center justify-between relative"  >
          <img src={inv8Img} alt="" className="w-full h-full object-cover object-center absolute" />
          <div className="w-full h-full bg-black opacity-80 absolute"></div>
            <span className="font-normal text-xl text-white top-14 absolute">INNOVATION</span>
            <span className="text-center font-normal text-white text-sm top-52 p-6 absolute">We are committed to stakeholder relationships, data privacy and lead with ethics and integrity. We strive to bring innovative solutions to ease People Mobility.</span>
        </div>
       
        <div className="w-[280px] h-[412px] max-sm:w-full bg-blue-400 bg-cover flex flex-col items-center justify-between relative"  >
          <img src={equality} alt="" className="w-full h-full object-cover object-center absolute" />
          <div className="w-full h-full bg-black opacity-80 absolute"></div>
            <span className="font-normal text-xl text-white top-14  absolute">EQUALITY</span>
            <span className="text-center font-normal text-white text-sm top-52 p-6 absolute">We are initiating some steps towards a more equal, inclusive, & sustainable practices by hiring robust workforce & building cohesive teams</span>
        </div>
        <div className="w-[280px] h-[412px] max-sm:w-full bg-blue-400 bg-cover flex flex-col items-center justify-between relative"  >
          <img src={sustain} alt="" className="w-full h-full object-cover object-center absolute" />
         
            <span className="font-normal text-xl text-white top-14  absolute">SUSTAINABILITY</span>
            <span className="text-center font-normal  text-white text-sm top-52 p-6 absolute">We are working towards a common goal towards our planet earth. We are initiating practices such as plantation drives, self- sustainable fuel plants, etc.</span>
        </div>
      </div>
    </div>
  );
}

export default AboutValues;
