import React from 'react'
import BlogCard from './BlogCard'
import ContactForm from './ContactForm'

const Blogs = () => {
    return (
        <div className='py-[4rem] px-3 bg-[#EFEFEF]'>
            <div className='w-full xl:w-[1200px] mx-auto h-full'>
                <div className='flex flex-col items-center gap-8 w-full'>
                    <div className='backdrop-filter backdrop-blur-[12px] bg-gradient-to-b from-[#0077B6] to-[#00B4D8] py-2 px-4 rounded-[8px]'>
                        <span className='text-[#fff] font-medium leading-[20px]'>Blogs</span>
                    </div>
                    <div className='flex flex-col items-center gap-4'>
                        <h2 className='font-semibold leading-[44px] text-[40px] bg-clip-text bg-gradient-to-b from-[#0077B6] to-[#00B4D8] text-transparent'>Latest News</h2>
                        <div>
                            <p className='text-[#222222] text-center text-[16px] leading-[24px] font-normal'>
                                Driving your dreams to reality with an exquisite fleet of versatile vehicles for <br />
                                unforgettable journeys.
                            </p>
                        </div>
                    </div>
                    <div className='flex flex-wrap w-full mt-8'>
                        <BlogCard />
                        <BlogCard />
                        <BlogCard />
                    </div>
                    <ContactForm />
                </div>
            </div>
        </div>
    )
}

export default Blogs
