import React from "react";
import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  return (
    <div>
      <Helmet>
      <title>Privacy Policy - WTi Rent A Car LLC</title>
      <meta
        name="description"
        content="This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website www.wticarrental.ae."
      />
      <meta
        name="keywords"
        content="privacy policy wti car rental, privacy policy wti uae, privacy policy wti mobility, privacy policy wti rent a car llc"
      />
      <meta name="revisit after" content="7 days" />
      <meta name="robots" content="follow, index" />
      <meta name="language" content="English" />
      <meta name="country" content="United Arab Emirates (UAE)" />
      <meta name="copyright" content="WTi Rent A Car LLC" />
      <meta name="publisher" content="wticarrental.ae" />
      <meta name="distribution" content="Global" />
      <link rel="canonical" href="https://www.wticarrental.ae/privacy-policy" />
    </Helmet>
    <div className="w-full p-4 md:pl-[10.27%] md:pr-[10.27%]">
      <div className="w-full flex justify-center items-center py-6">
        <h1><span className="font-semibold text-xl ">Privacy Policy</span></h1>
      </div>
      <div className="px-4 md:px-6 flex flex-col items-start justify-evenly border rounded-md shadow-2xl py-4">
        <h2 className="font-semibold text-lg mb-4">Introduction</h2>
        <p className="font-light text-sm mb-4">
          WTi Rent A Car LLC (“we,” “us,” or “our”) respects the privacy of our users (“user,”
          “you,” or “your”) and is committed to protecting your personal information. This Privacy Policy
          explains how we collect, use, disclose, and safeguard your information when you visit our website
          <a href="/" className="text-blue-500">{" "}www.wticarrental.ae</a>.
        </p>

        <h2 className="font-semibold text-lg mb-4">Scope and Consent</h2>
        <p className="font-light text-sm mb-4">
          By accessing our services, you agree to the collection, processing, and use of data as described in this
          Privacy Policy. Your use of our website and any dispute over privacy is subject to this Policy and our
          Terms of Service, including limitations on damages and the resolution of disputes.
        </p>

        <h2 className="font-semibold text-lg mb-4">Information Collection and Use</h2>
        <p className="font-light text-sm mb-4">
          We collect both “Personal Data” and “Non-Personal Data” about our users. Personal Data includes
          your name, address, email address, and payment information, which you submit to us through the
          registration process at our Site. Non-personal data includes non-identifiable information that may
          include your IP address, analytics code, etc.
        </p>

        <h2 className="font-semibold text-lg mb-4">Legal Basis for Processing (GDPR Compliance)</h2>
        <p className="font-light text-sm mb-4">
          For our users based in the European Union, we process your Personal Data under the
          following legal bases:
          <ul className="list-disc list-inside">
            <li>Your consent;</li>
            <li>The need to perform a contract with you;</li>
            <li>The need to comply with legal obligations;</li>
            <li>The need to protect your vital interests;</li>
            <li>The need to perform a task carried out in the public interest;</li>
            <li>Our legitimate interests, which do not override your fundamental rights and freedoms.</li>
          </ul>
        </p>

        <h2 className="font-semibold text-lg mb-4">Data Recipients and International Data Transfers</h2>
        <p className="font-light text-sm mb-4">
          Your Personal Data may be transferred to — and maintained on — computers located outside of
          your state, province, country, or other governmental jurisdiction where the data protection laws
          may differ from those of your jurisdiction.
        </p>

        <h2 className="font-semibold text-lg mb-4">Your Data Protection Rights</h2>
        <p className="font-light text-sm mb-4">
          You have the right to access, correct, update, or request deletion of your Personal Data. You can also
          object to processing, ask us to restrict processing, or request portability of your Personal Data.
        </p>

        <h2 className="font-semibold text-lg mb-4">Security Measures</h2>
        <p className="font-light text-sm mb-4">
          We implement appropriate technical and organizational measures to protect the security of your
          personal data, including encryption and secure servers.
        </p>

        <h2 className="font-semibold text-lg mb-4">Cookies and Tracking Technologies</h2>
        <p className="font-light text-sm mb-4">
          Our Site uses cookies to identify you and your interests and to track your use of the Site. We also
          utilize cookies and other technologies to manage access to certain content on our Site, protect the
          Site, and to process any requests that you make to us.
        </p>

        <h2 className="font-semibold text-lg mb-4">Children’s Privacy</h2>
        <p className="font-light text-sm mb-4">
          Our Site is not intended for children under the age of 16, and we do not knowingly collect data from
          this age group.
        </p>

        <h2 className="font-semibold text-lg mb-4">Data Retention</h2>
        <p className="font-light text-sm mb-4">
          We retain personal data only for as long as necessary to provide the services you have requested
          and thereafter for a variety of legitimate legal or business purposes.
        </p>

        <h2 className="font-semibold text-lg mb-4">Changes to This Privacy Policy</h2>
        <p className="font-light text-sm mb-4">
          The Company may update this Privacy Policy from time to time. We will notify you of any changes by
          posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically
          for any changes.
        </p>

        <h2 className="font-semibold text-lg mb-4">Contact Information</h2>
        <p className="font-light text-sm mb-4">
          If you have any questions about this Privacy Policy, please contact us at
          <a href="mailto:bookings@wtimobility.ae" className="text-blue-500">{" "}bookings@wtimobility.ae</a>.
        </p>
      </div>
    </div>
    </div>
  );
}

export default PrivacyPolicy;
