import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import { useDispatch } from "react-redux";
import {
  switchToPayLater,
  switchToPayNow,
} from "../../../utils/CarSlices/carDetailSlice";
import { useSelector } from "react-redux";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { ReactComponent as CarDoorIcon } from "./images/icons/car-door.svg";
import { ReactComponent as AirCondIcon } from "./images/icons/air-conditioner.svg";
import { ReactComponent as TransmissionIcon } from "./images/icons/transmission.svg";
import { addBaseRate } from "../../../utils/invoice/invoiceSlice";
import { switchOnStepThree } from "../../../utils/stepperSlice";
import { tillTwo } from "../../../utils/stepperSlice";
import { emptyExtraArr } from "../../../utils/extraSlice";
import { addInsuranceArr } from "../../../utils/insuranceSlice";

import {
  toggleCarDetail,
  addCarDetail,
} from "../../../utils/ModalSlice/modalToggleSlice";
import { switchCarModal } from "../../../utils/ModalSlice/modalToggleSlice";
import { switchOnLoader, switchOffLoader } from "../../../utils/loaderSlice";
import { switchOnThirstStepFlag } from "../../../utils/ModalSlice/modalToggleSlice";
import { baseUrl } from "../../../config";

function Cars(props) {
  const { state } = useLocation();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const addSliceOneStatus = useSelector(
    (store) => store.addSliceOne.addSliceOne
  );
  const pickUpDes = useSelector(
    (store) => store.bookingInfoSlice.pickupLocation
  );
  const currencyPrice = useSelector(
    (store) => store.currencySlice.currentCurrRate
  );
  const currencyName = useSelector((store) => store.currencySlice.currentCurr);
  const totalDays = useSelector(
    (store) => store.bookingInfoSlice.totalNumberOfDays
  );


  const carModalStatus = useSelector(
    (store) => store.modalToggleSlice.openCarModal
  );
  //--> this use effect will disable all the stepper other than two
  useEffect(() => {
    //this change is done for new marketing adds
    if (!addSliceOneStatus) {
      if (pickUpDes == "") {
        navigate("/");
      }
    }

    if (!carModalStatus) {
      dispatch(tillTwo());
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handlePayNow = (carData) => {
    // const excessInsurancePrice = calculateExcessInsurance(carData.excessInsuranceCharge,totalDays);
    // dispatch(addExcessInsurance(excessInsurancePrice));
    dispatch(switchToPayNow(carData));
    dispatch(addInsuranceArr(carData.insuranceAndDamageCovers));
    if (!carModalStatus) {
      dispatch(emptyExtraArr());
    }
    dispatch(switchOnLoader());
    if (carModalStatus) {
      dispatch(switchCarModal());
      document.documentElement.style.overflow = "visible";
      dispatch(switchOffLoader());
    }
    // .toFixed(2))));
    dispatch(
      addBaseRate(
        // parseInt(payNowArray[0]*totalDays) +
        // (parseInt(payNowArray[0]) * payNowPercentageArray[0]) / 100

        parseInt(payNowArray[0]) + (parseInt(payNowArray[0]) * payNowPercentageArray[0] / 100)
      )
    );
    // *totalDays);
    dispatch(switchOnStepThree());
    if (pickUpDes == "") {
    } else {
      setTimeout(() => {
        dispatch(switchOnThirstStepFlag());
      }, 3000);
      navigate("/reservation/extras");
    }
  };

  //note * -pay now = 0 and pay later = 1

  const handlePayLater = (carData) => {
    dispatch(switchToPayLater(carData));
    if (!carModalStatus) {
      dispatch(emptyExtraArr());
    }

    dispatch(switchOnLoader());
    if (carModalStatus) {
      dispatch(switchCarModal());
      document.documentElement.style.overflow = "visible";
      dispatch(switchOffLoader());
    }
    dispatch(
      addBaseRate(
        (parseInt(payNowArray[0]) +
          (parseInt(payNowArray[0]) * payNowPercentageArray[0]) / 100) * 1.05
      )
    );
    dispatch(switchOnStepThree());

    navigate("/reservation/extras");
  };

  const handleDetailModal = (carInfo) => {
    dispatch(addCarDetail(carInfo));
    dispatch(toggleCarDetail());
    document.documentElement.style.overflow = "hidden";
  };

  const payNowArray = Object.values(props?.carDetails?.payNowPrice);

  const payNowPercentageArray = Object.values(
    props?.carDetails?.percentageHikePayNow
  );

  return (
    <>
      <div
        className={`shadow-lg ${props?.carDetails?.type == "Hot Sellers"
          ? " border-[#0891B2] border-2"
          : ""
          }  hover:ring-[2px] hover:ring-[#172554] rounded max-sm:block flex justify-between p-4 relative overflow-hidden`}
      >
        <div className="hidden max-sm:block ">
          {/* <div className="">{props?.carDetails?.type}</div> */}
          <div className="font-bold text-base">{props?.carDetails?.title}</div>
          <div className="mt-1 text-sm flex justify-between items-center">
            <div>or similar</div>
            <div className={`text-xs ${props.sorted ? "block" : "hidden"}`}>
              ({props?.carDetails?.type})
            </div>
          </div>
        </div>
        <div className="w-[38%] overflow-hidden px-2 flex-col max-sm:w-full max-sm:mt-2 flex items-center justify-center ">
          <img
            src={
              `${baseUrl}/app/v1/aws/getImage/carimages/` +
              (props.carDetails.imageName
                ? props?.carDetails?.imageName.split(" ").join("")
                : "static") +
              ".png"
            }
            alt=""
            onError={(e) => {
              e.target.src = "../static/staticcarimage.png";
            }}
            className={`scale-[1.2] object-cover ${props?.carDetails?.imageName == undefined
              ? "missing-image rounded mb-2"
              : ""
              }`}
          />
          <Link className="text-[12px] whitespace-nowrap text-[#0891B2] mt-12 underline font-medium leading-[16px] mb-4 inline-block" to={"/terms-of-use"} target="_blank">*Terms and Conditions Apply</Link>
          {/* <LazyLoadImage
          // effect="blur"
      // alt={image.alt}
      // height={image.height}
      // src={props?.carDetails?.image[0].src} 
      // use normal <img> attributes as props
      // width={image.width} 
      /> */}
        </div>
        <div className="w-[60%] max-sm:w-full ">
          <div className=" block ">
            <div className="font-bold text-base  flex justify-between items-center ">
              {props?.carDetails?.title.replace(" or similar", "")}
              {props?.carDetails?.type == "Hot Sellers" ? (
                <div className=" w-fit flex justify-center box-content items-center rounded-md bg-[#0891B2] h-[2rem] origin-center absolute top-2 right-[-34px] rotate-45 mr-2">
                  <span className=" text-sm font-semibold text-white px-6 py-1">
                    15% OFF
                  </span>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="mt-1 text-sm flex justify-between items-center">
              <div>or similar</div>
              <div className={`text-xs ${props.sorted ? "block" : "hidden"}`}>
                ({props?.carDetails?.type})
              </div>
            </div>
          </div>

          <div className="mt-4 grid grid-cols-3 gap-4">
            <div className=" w-auto  flex justify-center items-center">
              <div className="mr-2 flex items-center">
                <PersonIcon className="text-base" />
              </div>
              <div className="text-[11px]">
                {props?.carDetails?.seats + " Passenger"}
              </div>
            </div>
            <div className="w-auto  flex justify-center items-center">
              <div className="mr-2 flex items-center">
                <BusinessCenterIcon className="text-base" />
              </div>
              <div className="text-[11px]">
                {props?.carDetails?.luggageCapacity + " Suitcases"}
              </div>
            </div>
            <div className=" w-auto  flex justify-center items-center">
              <div className="mr-2 flex items-center">
                <CarDoorIcon className="text-base text-black" />
              </div>
              <div className="text-[11px]">
                {props?.carDetails?.doors + " Doors"}
              </div>
            </div>
            <div className="w-auto  flex justify-center items-center">
              <div className="mr-2 flex items-center">
                <AirCondIcon className="text-base" />
              </div>
              <div className="text-[11px]">
                {props?.carDetails?.ac.toLowerCase() == "yes"
                  ? "Air Condition"
                  : "No A/C"}
              </div>
            </div>
            <div className="w-auto flex justify-center items-center">
              <div className="mr-2 flex items-center">
                {" "}
                <TransmissionIcon className="text-base" />
              </div>
              <div className="text-[11px]">
                {props?.carDetails?.transmissionType.charAt(0).toUpperCase() +
                  props?.carDetails?.transmissionType.slice(1)}
              </div>
            </div>
            <div className="w-auto flex justify-center items-center">
              <span
                className="text-[#092C85] underline font-normal text-xs cursor-pointer"
                onClick={() => handleDetailModal(props?.carDetails)}
              >
                More details+
              </span>
            </div>
          </div>

          {/* my later start */}

          <div className="flex gap-2 my-2 w-full justify-end">
            {/* <div
              onClick={() => {
                handlePayLater(props?.carDetails);
                
              }}
              className="hover:cursor-pointer border grow border-blue-950 bg-white text-blue-950 px-5 py-2 text-[10px] text-center font-bold rounded max-sm:text-sm"
            >
              <div className="flex items-center">
                <div className="flex items-center justify-center pr-2 group-hover:scale-[1.05] transition-all delay-1">
                  Pay Later
                </div>

                <div >
                  <div className="text-[11px] max-sm:text-sm">
                    {currencyName}
                    {" " +
                    (
                     ( (parseInt(payNowArray[0]) +
                        (parseInt(payNowArray[0]) * payNowPercentageArray[0]) /
                          100) *
                      totalDays *
                      currencyPrice)*1.05
                    ).toFixed(2)}
                  </div>
                  <div className="line-through max-sm:mr-0 max-sm:text-[11px]">
                    {currencyName}
                    {" " +
                      (
                        (parseInt(payNowArray[0]) +
                          (parseInt(payNowArray[0]) *
                            payNowPercentageArray[0]) /
                            100) *
                          totalDays *
                          currencyPrice +
                        (parseInt(payNowArray[0]) +
                          (parseInt(payNowArray[0]) *
                            payNowPercentageArray[0]) /
                            100) *
                          totalDays *
                          currencyPrice *
                          0.18
                      ).toFixed(2)}
                  </div>
                </div>
              </div>
            </div> */}

            <div
              onClick={() => {
                handlePayNow(props?.carDetails);
              }}
              className="bg-blue-950  hover:cursor-pointer max-sm:mt-2 text-white px-5 py-2 text-[10px] font-bold rounded text-center max-sm:text-sm flex items-center justify-between max-sm:block"
            >
              <div className="flex items-center justify-center pr-2 group-hover:scale-[1.05] transition-all delay-1">
                Pay Now
              </div>
              <div>
                <div className="text-[11px] max-sm:text-sm">
                  {currencyName}
                  {" " +
                    // (
                    //   (parseInt(payNowArray[0]) +
                    //     (parseInt(payNowArray[0]) * payNowPercentageArray[0]) /
                    //     100) *
                    //   totalDays *
                    //   currencyPrice
                    // ).toFixed(2)


                    (
                      (Number(payNowArray[0]) +
                        (Number(payNowArray[0]) * payNowPercentageArray[0]) /
                        100) *
                      totalDays *
                      currencyPrice
                    ).toFixed(2)


                    // ("")
                  }
                </div>

                <div className="line-through max-sm:mr-0 max-sm:text-[11px]">
                  {currencyName}
                  {" " +
                    (
                      (parseInt(payNowArray[0]) +
                        (parseInt(payNowArray[0]) * payNowPercentageArray[0]) /
                        100) *
                      totalDays *
                      currencyPrice +
                      (parseInt(payNowArray[0]) +
                        (parseInt(payNowArray[0]) * payNowPercentageArray[0]) /
                        100) *
                      totalDays *
                      currencyPrice *
                      0.18
                    ).toFixed(2)}
                </div>
              </div>
            </div>




          </div>

          {/* my later end */}

          {/* <div className="flex max-sm:block justify-evenly mt-4">
            {props?.carDetails?.onDemand ? (
              <div
                onClick={() => {
                  handlePayLater(props?.carDetails);
                  props.handleClick();
                }}
                className="hover:cursor-pointer border border-blue-950 bg-white text-blue-950 px-5 py-2 text-[10px] text-center font-bold rounded max-sm:text-sm"
              >
                Pay Later <br /> {currencyName}
                {" " +
                  (
                    (parseInt(payNowArray[0]) +
                      parseInt(payNowArray[0]) * 0.06 +
                      (parseInt(payNowArray[0]) * payNowPercentageArray[0]) /
                        100) *
                    totalDays *
                    currencyPrice
                  ).toFixed(2)}
              </div>
            ) : (
              <div
                onClick={() => {
                  handlePayNow(props?.carDetails);
                }}
                className="bg-blue-950 hover:cursor-pointer max-sm:mt-2 text-white px-5 py-2 text-[10px] font-bold rounded text-center max-sm:text-sm flex items-center justify-between max-sm:block"
              >
                <div className="flex items-center justify-center pr-2 group-hover:scale-[1.05] transition-all delay-1">
                  Pay Now
                </div>
                <div>
                  <div className="text-[11px] max-sm:text-sm">
                    {currencyName}
                    {" " +
                      (
                        (parseInt(payNowArray[0]) +
                          (parseInt(payNowArray[0]) *
                            payNowPercentageArray[0]) /
                            100) *
                        totalDays *
                        currencyPrice
                      ).toFixed(2)}
                  </div>

                  <div className="line-through max-sm:mr-0 max-sm:text-[11px]">
                    {currencyName}
                    {" " +
                      (
                        (parseInt(payNowArray[0]) +
                          (parseInt(payNowArray[0]) *
                            payNowPercentageArray[0]) /
                            100) *
                          totalDays *
                          currencyPrice +
                        (parseInt(payNowArray[0]) +
                          (parseInt(payNowArray[0]) *
                            payNowPercentageArray[0]) /
                            100) *
                          totalDays *
                          currencyPrice *
                          0.18
                      ).toFixed(2)}
                  </div>
                </div>
              </div>
            )}
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Cars;
