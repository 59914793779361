import React from 'react'

function DocumentUpload() {
  return (
    <>
        <div className='text-xl font-bold'>
            Document Upload (User)
        </div>
        <div>
          <img src={``} alt="" />
        </div>
        <div>
            
        </div>

    </>
  )
}

export default DocumentUpload