import React, { useState } from "react";

import { toggleCancelModal } from "../../../utils/ModalSlice/modalToggleSlice";
import { useDispatch, useSelector } from "react-redux";
import { fillSelectedCancelCard } from "../../../utils/ModalSlice/modalToggleSlice";
import axios from "axios";
import { switchOnLoader, switchOffLoader } from "../../../utils/loaderSlice";
import { baseUrl } from "../../../config";
import EditIcon from "../../../images/edit_icom.svg"
import ConfirmIcon from "../../../images/confirm_icon.svg"
import CancelIcon from "../../../images/cancel_icon.svg"
import { Alert, Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { addCalculatedExtras, addModifiedPrice, addModifyBasePrice, addModifyBookingDetails, addModifyCarDetail, addModifyExcessInsurance, addModifyExtras, addModifyReservationDays, addModifyTravelItenary, clearTogglePrices, toggleAdditionalDriver, toggleBabySeat, toggleBabySeatCheck, toggleCDW, toggleCDWCheck, toggleAdditionalDriverCheck, addCarId, emptyModifyExtras, addModifiedPriceWithoutCurrency, clearModifyCarDetail, addModifyInvoiceObj, togglePersonalInsurance, togglePersonalInsuranceCheck } from "../../../utils/modifyBookingSlices/modifyBooking";
import { addRes } from "../../../utils/ReservationSlices/reservationSlice";
import { addExcessInsurance, removeDepositPremiumCheck, removefullDepositCheck } from "../../../utils/invoice/invoiceSlice";
import { updateCarDropLocation, updateCarPickLocation } from "../../../utils/picDropSlices/pickDropSlice";
import { changeCurrency } from "../../../utils/currencySlice";
import { setUserId } from "../../../utils/userSlices/userSlice";
import modifiedCheckIcon from "./modified_check.svg";
import { checkOneDayBefore } from "../../../utils/commonFunction/dateTime";

function BookingCard(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const {
    car,
    reservationID,
    reservationStatus,
    payType,
    extras,
    reservationDays,
    travelItenary,
    currencyInfo,
    userID,
    payment,
    modifiedReservationID,
    isModifiedFlag
  } = props?.profileData[0] || ""

  const {
    baseRate,
    paymentStatus,
    totalCharges,
    deliveryCharge,
    excessInsuranceCharge,
    addOnCharges,
    collectionCharge,
    taxes,
    advanceInvoiceID,
    modifiedInvoiceID,
    paymentMethod,
    modifiedCount
  } = props.profileData[1] || ""

  const currencyPrice = useSelector(
    (store) => store.currencySlice.currentCurrRate
  );
  const currencyName = useSelector((store) => store.currencySlice.currentCurr);
  let  isCancellable = false; 
  const [open, setOpen] = useState(false)
    
  if(travelItenary.pickupDate && travelItenary.pickupTime){
    isCancellable = checkOneDayBefore(travelItenary.pickupDate, travelItenary.pickupTime)
  }


  const openCancelConfirmationModal = () => {
    dispatch(fillSelectedCancelCard({modifiedReservationID: modifiedReservationID, reservationID: reservationID}));
    dispatch(toggleCancelModal());
  };

  //pending --> yellow
  //enroute --> blue
  //complete --> green
  //no show --> orange
  //cancelled --> red
  //overdue --> purple

  const printConfirmation = async () => {
    // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    dispatch(switchOnLoader());

    try {
      let resId = modifiedReservationID ? modifiedReservationID : reservationID
      let modifiedFlag = modifiedReservationID ? 'Modified' : 'Original'
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl}/app/v1/user/printConfirmation/${resId}/${modifiedFlag}`,
        responseType: "blob",
      };
  
      let response = await axios.request(config);
      if (response) {
        dispatch(switchOffLoader());
        window.open(URL.createObjectURL(response.data));
      }
    } catch (error) {
      console.log(error)
    }
    
  };


  const calculatePrice = (indexValue, days, equipmentsObj)=>{


    if(indexValue == 2){
      if(days>=7 && days<=21){
        let actualPrice = equipmentsObj?.price.daily-10;
        let totalPrice = actualPrice*days;
        if (totalPrice <= equipmentsObj?.price.maximum) {
          return Number(totalPrice.toFixed(2));
        } else {
          return Number((equipmentsObj?.price.maximum).toFixed(2));
        }
        
      }else if (days>=30){
        let totalMonths = Math.floor(days / 30); // Extract whole months
        let remainingDays = days % 30; 
        let monthsPrice = Number((equipmentsObj?.price.maximum).toFixed(2))*totalMonths;
        let daysPrice = remainingDays*(equipmentsObj?.price.daily-10);
        let total = monthsPrice+daysPrice
        return Number((total).toFixed(2));
      } else {
        if(days>21 && days<30){
        return Number((equipmentsObj?.price.maximum).toFixed(2));
        }else{
          let totalPrice = equipmentsObj?.price.daily*days;
          return Number((totalPrice).toFixed(2));
        }      }
    }
    if(indexValue == 3){
      if(days>30 && days<61){
        let daysRemainigFromMonth = days-30;
        let remainingDaysRate = daysRemainigFromMonth*1.3;
        let finalPrice = remainingDaysRate+equipmentsObj?.price.daily;
        return Number((finalPrice).toFixed(2));
      }
      if(days>=30){
        let totalMonths = days/30;
        return (Number((equipmentsObj?.price.daily).toFixed(2))*totalMonths);
      }else{
        return Number((equipmentsObj?.price.daily).toFixed(2));
      }
    }
  }




  const handleModifyBooking = async() => {
    dispatch(switchOnLoader());

    const insuranceCharge = excessInsuranceCharge ? Number(excessInsuranceCharge) : 0
    const ID = modifiedReservationID ? modifiedReservationID : ""
    const mdfInvoiceID = modifiedInvoiceID ? modifiedInvoiceID : ""
    const modifyBookingObj = { 
    car: car,
    reservationID: reservationID,
    reservationStatus: reservationStatus,
    reservationDays: reservationDays,
    travelItenary: travelItenary,
    currencyInfo: currencyInfo,
    baseRate: baseRate,
    totalCharges: totalCharges,
    deliveryCharge: deliveryCharge ? deliveryCharge : 0,
    excessInsuranceCharge: insuranceCharge,
    addOnCharges: addOnCharges ? addOnCharges : 0,
    collectionCharge: collectionCharge ? collectionCharge : 0,
    taxes: taxes ? taxes : 0,
    extras: extras,
    advanceInvoiceID: advanceInvoiceID,
    paymentMethod: paymentMethod,
    payType: payType,
    paymentStatus: paymentStatus,
    lastModifiedReservationID: ID,
    lastModifiedInvoiceID: mdfInvoiceID,
    }


    const modifyTravelItenaryObj = {
      pickupLocation: travelItenary?.pickupLocation,
      pickupDate: travelItenary?.pickupDate,
      pickupTime: travelItenary?.pickupTime,
      dropLocation: travelItenary?.dropLocation,
      dropDate: travelItenary?.dropDate,
      dropTime: travelItenary?.dropTime,
    }

    dispatch(
      changeCurrency({
        currentCurr: currencyInfo.currency,
        currentCurrRate: currencyInfo.currencyRate,
      })
    );



    dispatch(removeDepositPremiumCheck())
    dispatch(removefullDepositCheck())
    dispatch(setUserId(userID))
    dispatch(emptyModifyExtras())
    dispatch(addModifyInvoiceObj({}))
    dispatch(clearModifyCarDetail())
    dispatch(clearTogglePrices())
    dispatch(addRes(reservationID));
    dispatch(addCarId(car.typeID));
    dispatch(addModifyBookingDetails(modifyBookingObj))
    dispatch(addModifyReservationDays(reservationDays))

    dispatch(addExcessInsurance(insuranceCharge)) //without multiplication with currencyRate // for comparing selected before
    dispatch(addModifyExcessInsurance(insuranceCharge)) //without multiplication with currencyRate

    dispatch(addModifiedPrice(0))
    dispatch(addModifiedPriceWithoutCurrency(0))
    dispatch(addModifyTravelItenary(modifyTravelItenaryObj))

    const carDetailObj = {
      type: car?.type,
      typeID: car?.typeID,
      src: car?.src,
      title: car?.title,
      basePrice: baseRate
    }
    dispatch(addModifyCarDetail(carDetailObj))

    dispatch(addModifyBasePrice(baseRate))
    dispatch(addCalculatedExtras(addOnCharges))
    
    dispatch(
      updateCarPickLocation({
        location: travelItenary?.pickupLocation,
        locationRate: deliveryCharge ? deliveryCharge : 0,
      })
    );
    dispatch(
      updateCarDropLocation({
        location: travelItenary?.dropLocation,
        locationRate: collectionCharge ? collectionCharge : 0,
      })
    );
    

    // console.log('extras coming from api', extras)


    if (extras.length) {

      extras?.forEach((obj) => {
        dispatch(addModifyExtras(obj))
      })
      const cdw = extras.find((ele) => {
        return ele.id == "01";
      });
      const PAI = extras.find((ele) => {
        return ele.id == "02";
      });
      if (cdw) {
        const firstPriceKey = Object.keys(cdw.price)[0];
        const firstPriceValue = cdw.price[firstPriceKey];

        const cdwObj = {
          cdwPrice: firstPriceValue? parseFloat(firstPriceValue*reservationDays) : 0,
          cdwState: true,
        };
        dispatch(toggleCDW(cdwObj));
        dispatch(toggleCDWCheck(true));
      }
      if (PAI) {
        const firstPriceKey = Object.keys(PAI.price)[0];
        const firstPriceValue = PAI.price[firstPriceKey];

        const PAIObj = {
          personalInsurancePrice: firstPriceValue? parseFloat(firstPriceValue*reservationDays) : 0,
          personalInsuranceState: true,
        };
        dispatch(togglePersonalInsurance(PAIObj));
        dispatch(togglePersonalInsuranceCheck(true));
      }

      const driver = extras.find((ele) => {
        return ele.id == "05";
      });
      
      if (driver) {
        const driverPrice = calculatePrice(3, reservationDays, driver)
        const additionalDriverObj = {
          additionalDriverPrice: driverPrice? Number(driverPrice) : 0,
          additionalDriverState: true,
        };
        dispatch(toggleAdditionalDriver(additionalDriverObj));
        dispatch(toggleAdditionalDriverCheck(true));
      }

      const babySeat = extras.find((ele) => {
        return ele.id == "03";
      });
      if (babySeat) {
        
        const babySeatPrice = calculatePrice(2, reservationDays, babySeat)
        const babySeatObj = {
          babySeatPrice: babySeatPrice? Number(babySeatPrice) : 0,
          babySeatState: true,
        };
        dispatch(toggleBabySeat(babySeatObj));
        dispatch(toggleBabySeatCheck(true));
      }
    }

    navigate("/modify-booking")
  }

  return (
    <>
      <div className="grid lg:grid-cols-[70%_30%] grid-cols-1  w-full my-8 mt-12">
        <div className="w-full flex justify-center items-center pe-3 ps-3 h-full ">
          <div className="bg-[#fff] w-full flex items-center py-6 px-4 lg:px-6 lg:shadow-[0px_3px_20px_0px_#00000029] lg:rounded-b-[20px] rounded-b-[0] rounded-[20px] h-full">
            <div className="w-full flex flex-col gap-1 relative">
              <div className="w-full flex justify-between items-center ">
                <div
                  className={`text-[#fff] absolute lg:-top-[60px] -top-[44px]  text-[14px] tracking-[1px] font-semibold leading-[16px] ${
                    isModifiedFlag == true && reservationStatus != "Cancelled"
                      ? "bg-gray-400"
                      : reservationStatus == "Pending"
                      ? "bg-[#DCC52A]"
                      : reservationStatus == "Completed"
                      ? "bg-[#25C661]"
                      : reservationStatus == "Enroute"
                      ? "bg-[#2AC7DC]"
                      : reservationStatus == "NoShow"
                      ? "bg-[#982ADC]"
                      : reservationStatus == "Cancelled"
                      ? "bg-[#DC2A2A]"
                      : "bg-[#DC8A2A]"
                  } py-3 px-4 rounded-[12px]`}
                >
                  {isModifiedFlag == true && reservationStatus != "Cancelled"
                    ? "Booking Abandoned"
                    : reservationStatus}
                </div>
                <h4 className="text-[#1c1c1c] whitespace-nowrap  text-[14px] font-semibold leading-[16px]">
                  Advance Receipt
                </h4>
                {reservationStatus == "Completed" ||
                reservationStatus == "Cancelled" ||
                paymentStatus == "Pending" ? (
                  ""
                ) : isModifiedFlag == true || paymentStatus == "Refund" ? (
                  <div className="flex justify-start gap-2 items-center  border-2 border-sky-600 bg-gradient-to-r  py-2 px-3 rounded-[12px] shadow-[0px_3px_20px_0px_#0000001F] transition-all">
                    <img src={modifiedCheckIcon} alt="" />
                    <span className="text-[#0077B6]  text-[14px] font-semibold leading-[16px] lg:block hidden">
                      Modified
                    </span>
                  </div>
                ) : (
                  <button
                    className="flex justify-start gap-2 items-center bg-gradient-to-r from-[#0077B6] to-[#00B4D8] py-2 px-3 rounded-[12px] shadow-[0px_3px_20px_0px_#0000001F] transition-all active:scale-[1.2]"
                    onClick={handleModifyBooking}
                  >
                    <img src={EditIcon} alt="" />
                    <span className="text-[#fff]  text-[14px] font-semibold leading-[16px] lg:block hidden">
                      Modify Details
                    </span>
                  </button>
                )}
              </div>
              <h3 className="text-[#1c1c1c] text-[14px] lg:text-[14px] font-semibold leading">
                Confirmation Number:{" "}
                <span className="text-[#0077B6]">{modifiedReservationID ? modifiedReservationID : reservationID}</span>
              </h3>
              <div className="flex justify-between items-center my-3">
                <div className="flex flex-col items-start gap-1">
                  <h4 className="text-[#1c1c1c]  text-[14px] font-semibold leading-[20px]">
                    Pick Up
                  </h4>
                  <div className="w-[85%] flex items-end">
                    <span className="text-[#474747] text-[14px] text-left font-normal  leading-[20px]">
                      {travelItenary?.pickupLocation}
                    </span>
                  </div>
                  <h4 className="text-[#0193C5]  text-[14px] font-semibold leading-[16px]  mt-1">
                    {travelItenary?.pickupDate}
                  </h4>
                  <span className="text-[#474747]  text-[14px] font-normal leading-[16px]  mt-1">
                    {travelItenary?.pickupTime}
                  </span>
                </div>
                <span className="inline-block py-2 px-5 md:py-1 md:px-3 rounded-[12px] text-[12px]  bg-[#727272] text-[#fff] whitespace-nowrap font-semibold">
                  {reservationDays} Days
                </span>
                <div className="flex flex-col items-end gap-1">
                  <h4 className="text-[#1c1c1c]  text-[14px] font-semibold leading-[20px]">
                    Drop Off
                  </h4>
                  <div className="w-[85%] flex items-end">
                    <span className="text-[#474747] text-[14px] font-normal text-right leading-[20px]">
                      {travelItenary?.dropLocation}
                    </span>
                  </div>
                  <h4 className="text-[#0193C5]  text-[14px] font-semibold leading-[16px] mt-1">
                    {travelItenary?.dropDate}
                  </h4>
                    <span className="text-[#474747] text-[14px] font-normal text-right leading-[20px]">
                      {travelItenary?.dropTime}
                    </span>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div className="grow">
                  <img
                    src={
                      `${baseUrl}/app/v1/aws/getImage/carimages/` +
                      car?.src.split(" ").join("") +
                      ".png"
                    }
                    className="w-[60%]"
                    alt=""
                  />
                </div>
                <div className="flex flex-col gap-4 px-1 lg:px-6  grow-[2]">
                  <div className="flex items-baseline gap-2">
                    <h3 className="text-[#494949] whitespace-nowrap  text-[14px] lg:text-[28px] font-semibold leading-[16px] lg:leading-[16px]">
                      {car?.type}
                    </h3>
                    <button className="py-1 grow-[1]  px-2 border border-[#0193C5] text-[#0193C5] text-[14px] leading-[20px] rounded-[12px] lg:hidden whitespace-nowrap">
                      Need Help?
                    </button>
                  </div>
                  <span className="text-[#494949]  text-[14px] font-normal leading-[14px] lg:leading-[20px]">
                    {car?.title}
                  </span>
                </div>
                <button className="py-1 grow-[1] px-2 border border-[#0193C5] text-[#0193C5]  text-[14px] leading-[20px] rounded-[12px] lg:block hidden whitespace-nowrap">
                  Need Help?
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="px-6 w-full lg:hidden block ">
          <div className="h-[1px] bg-[#A3A3A3] w-full"></div>
        </div>
        <div className="w-full px-3 lg:-mt-[0] -mt-[0px] h-full">
          <div className="py-6 px-6 bg-[#fff] lg:rounded-t-[20px] rounded-t-[0] rounded-[20px]  lg:shadow-[0px_3px_20px_0px_#00000029] h-full">
            <div className="flex flex-col gap-1 w-full  ">
              <div className="flex justify-between items-center w-full">
                <h4 className="text-[#222222] text-[16px] font-medium leading-[24px]">
                  Payment method:
                </h4>
                <h4 className="text-[#0077B6] text-[14px] font-semibold leading-[24px]">
                  {payType === "payNow" ? "PAY NOW" : "PAY LATER"}
                </h4>
              </div>
              <div className="flex justify-between items-center w-full ">
                <span className="text-[#5F5F5F] text-[16px] font-medium leading-[24px]">
                  Payment Status :
                </span>
                <button className=" text-[#008ac1] text-[14px] tracking-[1px] font-semibold ">
                  {paymentStatus}
                </button>
              </div>

              <div className="flex flex-col gap-1 mt-1">
                <h4 className="text-[#222222] text-[16px] font-medium leading-[24px]">
                  Included in the rates
                </h4>
                <div className="w-full flex gap-0 justify-between  flex-col">
                  <div className="flex justify-between items-center w-full">
                    <h4 className="text-[#5F5F5F] text-[14px] font-normal leading-[24px]">
                      Base Rate
                    </h4>
                    <h4 className="text-[#222222] text-[14px] font-normal leading-[24px]">
                      {currencyName +
                        " " +
                        (baseRate * currencyPrice).toFixed(2)}
                    </h4>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <h4 className="text-[#5F5F5F] text-[14px] font-normal leading-[24px]">
                      Deposit Premium
                    </h4>
                    <h4 className="text-[#222222] text-[14px] font-normal leading-[24px]">
                      {currencyName +
                        " " +
                        (excessInsuranceCharge * currencyPrice).toFixed(2)}
                    </h4>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <h4 className="text-[#5F5F5F] text-[14px] font-normal leading-[24px]">
                      Delievery Charge
                    </h4>
                    <h4 className="text-[#222222] text-[14px] font-normal leading-[24px]">
                      {currencyName +
                        " " +
                        (deliveryCharge * currencyPrice).toFixed(2)}
                    </h4>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <h4 className="text-[#5F5F5F] text-[14px] font-normal leading-[24px]">
                      Collection Charge
                    </h4>
                    <h4 className="text-[#222222] text-[14px] font-normal leading-[24px]">
                      {currencyName +
                        " " +
                        (collectionCharge * currencyPrice).toFixed(2)}
                    </h4>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <h4 className="text-[#5F5F5F] text-[14px] font-normal leading-[24px]">
                      Extras
                    </h4>
                    <h4 className="text-[#222222] text-[14px] font-normal leading-[24px]">
                      {currencyName +
                        " " +
                        (addOnCharges * currencyPrice).toFixed(2)}
                    </h4>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <h4 className="text-[#5F5F5F] text-[14px] font-normal leading-[24px]">
                      Taxes & Fees
                    </h4>
                    <h4 className="text-[#222222] text-[14px] font-normal leading-[24px]">
                      {" "}
                      {currencyName + " " + (taxes * currencyPrice).toFixed(2)}
                    </h4>
                  </div>
                </div>
                <div className="flex flex-col w-full gap-3 ">
                  <div className="flex justify-between items-center bg-[#F6F6F6] rounded-[8px] py-2 px-3">
                    <h4 className="text-[#222] tracking-[1px] text-[14px] font-normal leading-[24px]">
                      Total
                    </h4>
                    <h4 className="text-[#0088c0] text-[14px] font-semibold leading-[24px]">
                      {currencyName} {(totalCharges * currencyPrice).toFixed(2)}
                    </h4>
                  </div>
                  <div className="flex justify-between items-center gap-2">
                    <button
                      className="flex justify-start gap-2 items-center bg-gradient-to-r from-[#0077B6] to-[#00B4D8] py-2 px-3 rounded-[12px] shadow-[0px_3px_20px_0px_#0000001F] transition-all active:scale-[1.2]"
                      onClick={() => printConfirmation()}
                    >
                      <img src={ConfirmIcon} alt="" className="w-4" />
                      <span className="text-[#fff] text-[14px] font-medium leading-[20px]">
                        Confirmation
                      </span>
                    </button>
                    {reservationStatus == "Cancelled" ||
                    reservationStatus == "Completed" ||
                    reservationStatus == "Enroute" || 
                    paymentStatus == "Refund" || 
                    isModifiedFlag == true ? (
                      ""
                    ) : (
                      <button
                        className="flex justify-start gap-2 items-center border border-[#0077B6] py-2 px-3 rounded-[12px]"
                        onClick={() => {
                          if(isCancellable){
                            openCancelConfirmationModal(
                              modifiedReservationID,
                              reservationID
                            );
                          } else {
                            setOpen(true)
                          }
                        }}
                      >
                        <img src={CancelIcon} alt="" />
                        <span className="text-[#0077B6] text-[14px] font-medium leading-[20px] ">
                          Cancel
                        </span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center", color: "red" }}
          open={open}
          autoHideDuration={6000}
          onClose={() => setOpen(false)}
        >
          <Alert
            onClose={() => setOpen(false)}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Sorry, you can't cancel the booking if it's less than 24 hours before pickup time.
          </Alert>
        </Snackbar>
    </>
  );
}

export default BookingCard;













// <div className="mt-12 max-sm:mt-4 max-sm:pt-6 max-sm:pb-6 max-sm:border-b  max-sm:border-slate-500 relative ">
// <div
//   className={` ml-4 w-36 top-[-0.9rem] max-sm:top-[5px] rounded-md h-8  ${
//     props.profileData[0]?.reservationStatus === "Pending"
//       ? "bg-yellow-400"
//       : props.profileData[0]?.reservationStatus === "En-route"
//       ? "bg-blue-400"
//       : props.profileData[0]?.reservationStatus === "Overdue"
//       ? "bg-purple-400"
//       : props.profileData[0]?.reservationStatus === "Noshow"
//       ? "bg-orange-400"
//       : props.profileData[0]?.reservationStatus === "Cancelled"
//       ? "bg-red-400"
//       : "bg-blue-400"
//   }   absolute flex justify-center items-center`}
// >
//   <span className="text-white font-semibold">
//     {props.profileData[0]?.reservationStatus}
//   </span>
// </div>
// <div className="flex border border-slate-300 rounded max-sm:block max-sm:w-full h-[21rem] max-sm:h-auto">
//   <div className="w-[60%] max-sm:w-full border-r border-slate-300 p-4 relative">
//   <div className=" pt-4">
//     <span className="pl-4 font-semibold text-xl">Advance Receipt</span>
//   </div>
//     <div className="confirmation pt-4 pl-4 pr-4 max-sm:bg-[#EBEBEB] max-sm:pb-4 max-sm:text-center max-sm:rounded">
//       <span>Confirmation number:</span>
//       <br className="max-sm:block hidden" />
//       <span className=" uppercase text-blue-700 ml-2 max-sm:ml-0">
//         {props.profileData[0]?.reservationID}
//       </span>
//     </div>
//     <div className="pickUpDateAndDropDate mt-4">
//       <div className="bg-white w-full pt-4 pl-4 pr-4  rounded flex justify-between mb-4 max-sm:block">
//         <div>
//           <div className="font-bold text-black text-base">Pick-Up</div>
//           <div className="text-[#2F2F2F] mt-2 text-sm font-normal">
//             {props.profileData[0]?.travelItenary?.pickupLocation}
//           </div>
//           <div className="text-[#092C85] mt-2 text-sm font-semibold">
//             {props.profileData[0]?.travelItenary?.pickupDate +
//               ", " +
//               props.profileData[0]?.travelItenary?.pickupTime}
//           </div>
//         </div>
//         <div className="p-10 max-sm:w-full">
//           <div className="text-center text-xs mb-2 max-sm:inline-flex max-sm:w-full max-sm:justify-center">
//             <span className="hidden max-sm:block">-----</span>
//             {props.profileData[0]?.reservationDays} Days
//             <span className="hidden max-sm:block">-----</span>
//           </div>
//           <div className="w-[70px] border border-dashed relative max-sm:hidden">
//             <div className="absolute top-[-13px] right-[-6px]">
//               <ArrowForwardIosIcon className="text-[#555555]" />
//             </div>
//           </div>
//         </div>
//         <div>
//           <div className="font-bold text-black">Drop-off</div>
//           <div className="text-[#2F2F2F] mt-2 text-sm font-normal">
//             {props.profileData[0]?.travelItenary?.dropLocation}
//           </div>
//           <div className="text-[#092C85] mt-2 text-sm font-semibold">
//             {props.profileData[0]?.travelItenary?.dropDate +
//               ", " +
//               props.profileData[0]?.travelItenary?.dropTime}
//           </div>
//         </div>
//       </div>
//     </div>
//     <div className="carSelected">
//       <div className="flex justify-between max-sm:block">
//         <div className="w-[20%] max-sm:w-full flex items-center">
//           <img
//             src={
//               "https://wticarrental.ae:3000/app/v1/aws/getImage/carimages/" +
//               props.profileData[0]?.car?.src.split(" ").join("") +
//               ".png"
//             }
//             alt=""
//             className="object-cover max-sm:w-full max-sm:h-auto"
//             onError={(e) => {
//               e.target.src = "../static/staticcarimage.png";
//             }}
//           />
//         </div>
//         <div className="w-[75%] py-2 pb-4 max-sm:w-full">
//           <div className="font-bold text-base">
//             {props.profileData[0]?.car?.type}
//           </div>
//           <div className="mt-1 text-sm">
//             {props.profileData[0]?.car?.title}
//           </div>
//         </div>
//       </div>
//     </div>
//     <div className="buttonNeedHelp absolute bottom-10 right-10 max-sm:static max-sm:flex max-sm:justify-end ">
//       <div
//         className={`w-fit px-[25px] py-[10px] flex justify-center items-center text-xs font-normal cursor-pointer border border-[#092C85] rounded mr-4 text-[#092C85] `}
//       >
//         Need Help?
//       </div>
//     </div>
//   </div>
//   <div className="w-[40%] max-sm:w-full p-4 relative ">
//     <div className="bg-white h-auto pt-4 pl-4 pr-4 rounded w-[100%]">
//       <div className="font-bold text-base">
//         Payment method :{" "}
//         {props.profileData[0]?.payType === "payNow"
//           ? "PAY NOW"
//           : "PAY LATER"}
//       </div>
//       <div className="text-sm mt-6">Included in the rates</div>
//       <div className="flex justify-between text-xs mt-5">
//         <div>Base Rate</div>
//         <div>
//           {props.profileData[0]?.currencyInfo?.currency +
//             " " +
//             (
//               props.profileData[1]?.baseRate *
//               props.profileData[0]?.currencyInfo?.currencyRate
//             ).toFixed(2)}
//         </div>
//       </div>
//       <div className="flex justify-between text-xs mt-1">
//         <div>No-Deposit Premium</div>
//         <div>
//           {props.profileData[0]?.currencyInfo?.currency +
//             " " +
//             (
//               props.profileData[1]?.excessInsuranceCharge *
//               props.profileData[0]?.currencyInfo?.currencyRate
//             ).toFixed(2)}
//         </div>
//       </div>
//       <div className="flex justify-between text-xs mt-1">
//         <div>Delivery charge</div>
//         <div>{props.profileData[0]?.currencyInfo?.currency + " " + (props.profileData[1]?.deliveryCharge*props.profileData[0]?.currencyInfo?.currencyRate).toFixed(2)}</div>
//       </div>
//       <div className="flex justify-between text-xs mt-1">
//         <div>Collection charge</div>
//         <div>{props.profileData[0]?.currencyInfo?.currency + " " + (props.profileData[1]?.collectionCharge*props.profileData[0]?.currencyInfo?.currencyRate).toFixed(2)}</div>
//       </div>
//       <div className="flex justify-between mt-1 text-xs">
//         <div>Extras</div>
//         <div>
//           {props.profileData[0]?.currencyInfo?.currency +
//             " " +
//             (
//               props.profileData[1]?.addOnCharges *
//               props.profileData[0]?.currencyInfo?.currencyRate
//             ).toFixed(2)}
//         </div>
//       </div>
//       <div className="flex justify-between mt-1 text-xs">
//         <div className="text-blue-400">Taxes & Fees</div>
//         <div>
//           {props.profileData[0]?.currencyInfo?.currency +
//             " " +
//             (
//               props.profileData[1]?.taxes *
//               props.profileData[0]?.currencyInfo?.currencyRate
//             ).toFixed(2)}
//         </div>
//       </div>
//       <div className="flex justify-between mt-1 font-bold text-xs">
//         <div>Estimated Total</div>
//         <div>
//           {props.profileData[0]?.currencyInfo?.currency +
//             " " +
//             (
//               props.profileData[1]?.totalCharges *
//               props.profileData[0]?.currencyInfo?.currencyRate
//             ).toFixed(2)}
//         </div>
//       </div>
//     </div>
//     <div className="absolute my-4 flex w-full justify-around px-4 max-sm:static max-sm:mt-6 ">
//       <div
//         // onClick={handlePrintConfirmation}
//         className={`px-[25px] py-[10px] max-sm:px-3 max-sm:py-1 hover:bg-[#092C85] hover:text-white flex justify-center items-center text-xs font-normal cursor-pointer border border-[#092C85] rounded mr-4 text-[#092C85] max-sm:text-center `}
//         onClick={() =>
//           openCancelConfirmationModal(
//             props.profileData[0]?.reservationID
//           )
//         }
//       >
//         <span className="ml-2 font-normal max-sm:ml-0">
//           Cancel Reservation
//         </span>
//       </div>
//       <div
//         // onClick={handleCancelReservation}
//         className={` px-[25px] py-[10px] max-sm:px-3 max-sm:py-1 flex justify-center items-center text-xs font-normal cursor-pointer border border-[#092C85] rounded bg-[#092C85] text-white max-sm:text-center`}
//       >
//         <span
//           className="ml-2 font-normal  max-sm:ml-0"
//           onClick={() => printConfirmation()}
//         >
//           Print Confirmation
//         </span>
//       </div>
//     </div>
//   </div>
// </div>
// </div>
































// ===========================================================================================





// <div className='grid lg:grid-cols-[70%_30%] grid-cols-1  w-full my-8 lg:mt-4 mt-5 '>
// <div className='w-full flex justify-center items-center pe-3 ps-3 h-full '>
//   <div className='bg-[#fff] w-full flex items-center py-6 px-4 lg:px-6 lg:shadow-[0px_3px_20px_0px_#00000029] lg:rounded-b-[20px] rounded-b-[0] rounded-[20px] h-full'>
//     <div className='w-full flex flex-col gap-1 relative'>
//       <div className='w-full flex justify-between items-center '>
//         <button className=' text-[#fff] absolute lg:-top-[68px] -top-[44px]  text-[14px] tracking-[1px] font-semibold leading-[16px] bg-[#DCC52A] py-3 px-4 rounded-[12px]'>
//           Pending
//         </button>
//         <h4 className='text-[#1c1c1c] whitespace-nowrap  text-[14px] font-semibold leading-[16px]'>Advance Receipt</h4>
//         <button className='flex justify-start gap-2 items-center bg-gradient-to-r from-[#0077B6] to-[#00B4D8] py-2 px-3 rounded-[12px] shadow-[0px_3px_20px_0px_#0000001F] transition-all active:scale-[1.2]'>
//           <img src={EditIcon} alt="" />
//           <span className='text-[#fff]  text-[14px] font-semibold leading-[16px] lg:block hidden'>Modify Details</span>
//         </button>
//       </div>
//       <h3 className='text-[#1c1c1c] text-[14px] lg:text-[14px] font-medium leading'>Confirmation Number: <span className='text-[#0077B6]'>RES1700123456789</span></h3>
//       <div className='flex justify-between items-center my-3'>
//         <div className='flex flex-col items-start gap-1'>
//           <h4 className='text-[#1c1c1c]  text-[14px] font-medium leading-[20px]'>Pick Up</h4>
//           <div className='w-[85%] flex items-end'>
//             <span className='text-[#474747] text-[14px] text-left font-normal  leading-[20px]'>Dubai Marina- Dubai- United Arab Emirates
//             </span>
//           </div>
//           <h4 className='text-[#0193C5]  text-[14px] font-semibold leading-[16px]  mt-1'>15 May 2024</h4>
//         </div>
//         <span className='inline-block py-2 px-5 md:py-1 md:px-3 rounded-[12px] text-[12px]  bg-[#727272] text-[#fff] whitespace-nowrap font-semibold'>5 Days</span>
//         <div className='flex flex-col items-end gap-1'>
//           <h4 className='text-[#1c1c1c]  text-[14px] font-medium leading-[20px]'>Drop Off</h4>
//           <div className='w-[85%] flex items-end'>
//             <span className='text-[#474747] text-[14px] font-normal text-right leading-[20px]'>Dubai Marina- Dubai- United Arab Emirates
//             </span>
//           </div>
//           <h4 className='text-[#0193C5]  text-[14px] font-semibold leading-[16px] mt-1'>15 May 2024</h4>
//         </div>
//       </div>
//       <div className='flex justify-between items-center'>
//         <div className='grow'>
//           <img src={BookedCarImg} className='w-[60%]' alt="" />
//         </div>
//         <div className='flex flex-col gap-4 px-1 lg:px-6  grow-[2]'>
//           <div className='flex items-baseline gap-2'>
//             <h3 className='text-[#494949] whitespace-nowrap  text-[14px] lg:text-[28px] font-semibold leading-[16px] lg:leading-[16px]'>SUV Cars</h3>
//             <button className='py-1 grow-[1]  px-2 border border-[#0193C5] text-[#0193C5] text-[14px] leading-[20px] rounded-[12px] lg:hidden whitespace-nowrap'>Need Help?</button>
//           </div>
//           <span className='text-[#494949]  text-[14px] font-normal leading-[14px] lg:leading-[20px]'>Kaiyi X3 Pro 1.2l or similar</span>
//         </div>
//         <button className='py-1 grow-[1] px-2 border border-[#0193C5] text-[#0193C5]  text-[14px] leading-[20px] rounded-[12px] lg:block hidden whitespace-nowrap'>Need Help?</button>
//       </div>
//     </div>
//   </div>
// </div>
// <div className='px-6 w-full lg:hidden block '>
//   <div className='h-[1px] bg-[#A3A3A3] w-full'></div>
// </div>
// <div className='w-full px-3 lg:-mt-[0] -mt-[0px] h-full'>
//   <div className='py-6 px-6 bg-[#fff] lg:rounded-t-[20px] rounded-t-[0] rounded-[20px]  lg:shadow-[0px_3px_20px_0px_#00000029] h-full'>
//     <div className='flex flex-col gap-1 w-full  '>
//       <div className='flex justify-between items-center w-full'>
//         <h4 className='text-[#222222] text-[16px] font-medium leading-[24px]'>Payment method:</h4>
//         <h4 className='text-[#0077B6] text-[14px] font-semibold leading-[24px]'>PAY LATER</h4>
//       </div>
//       <div className='flex justify-between items-center w-full '>
//         <span className='text-[#5F5F5F] text-[16px] font-medium leading-[24px]'>Payment Status :</span>
//         <button className=' text-[#008ac1] text-[14px] tracking-[1px] font-semibold '>
//           PENDING
//         </button>
//       </div>


//       <div className='flex flex-col gap-1 mt-1'>
//         <h4 className='text-[#222222] text-[16px] font-medium leading-[24px]'>Included in the rates</h4>
//         <div className='w-full flex gap-0 justify-between  flex-col'>
//           <div className='flex justify-between items-center w-full'>
//             <h4 className='text-[#5F5F5F] text-[14px] font-normal leading-[24px]'>Base Rate</h4>
//             <h4 className='text-[#222222] text-[14px] font-normal leading-[24px]'>₹ 228.00</h4>
//           </div>
//           <div className='flex justify-between items-center w-full'>
//             <h4 className='text-[#5F5F5F] text-[14px] font-normal leading-[24px]'>No-Deposit Premium</h4>
//             <h4 className='text-[#222222] text-[14px] font-normal leading-[24px]'>₹ 120.00</h4>
//           </div>
//           <div className='flex justify-between items-center w-full'>
//             <h4 className='text-[#5F5F5F] text-[14px] font-normal leading-[24px]'>Delievery Charge</h4>
//             <h4 className='text-[#222222] text-[14px] font-normal leading-[24px]'>₹ 20.00</h4>
//           </div>
//           <div className='flex justify-between items-center w-full'>
//             <h4 className='text-[#5F5F5F] text-[14px] font-normal leading-[24px]'>Collection Charge</h4>
//             <h4 className='text-[#222222] text-[14px] font-normal leading-[24px]'>₹ 100.00</h4>
//           </div>
//           <div className='flex justify-between items-center w-full'>
//             <h4 className='text-[#5F5F5F] text-[14px] font-normal leading-[24px]'>Extras</h4>
//             <h4 className='text-[#222222] text-[14px] font-normal leading-[24px]'>₹ 56.00</h4>
//           </div>
//           <div className='flex justify-between items-center w-full'>
//             <h4 className='text-[#5F5F5F] text-[14px] font-normal leading-[24px]'>Taxes & Fees</h4>
//             <h4 className='text-[#222222] text-[14px] font-normal leading-[24px]'>₹ 32.00</h4>
//           </div>
//         </div>
//         <div className='flex flex-col w-full gap-3 '>
//           <div className='flex justify-between items-center bg-[#F6F6F6] rounded-[8px] py-2 px-3'>
//             <h4 className='text-[#222] tracking-[1px] text-[14px] font-normal leading-[24px]'>Total</h4>
//             <h4 className='text-[#0088c0] text-[14px] font-semibold leading-[24px]'>₹ 556.00</h4>
//           </div>
//           <div className='flex justify-between items-center gap-2'>
//             <button className='flex justify-start gap-2 items-center bg-gradient-to-r from-[#0077B6] to-[#00B4D8] py-2 px-3 rounded-[12px] shadow-[0px_3px_20px_0px_#0000001F] transition-all active:scale-[1.2]'>
//               <img src={ConfirmIcon} alt="" />
//               <span className='text-[#fff] text-[14px] font-medium leading-[20px]'>Confirmation</span>
//             </button>
//             <button className='flex justify-start gap-2 items-center border border-[#0077B6] py-2 px-3 rounded-[12px]'>
//               <img src={CancelIcon} alt="" />
//               <span className='text-[#0077B6] text-[14px] font-medium leading-[20px] '>Cancel</span>
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
// </div>