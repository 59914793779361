import React, { useState, useEffect } from "react";
import PersonIcon from "@mui/icons-material/Person";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import AirIcon from "@mui/icons-material/Air";

import DoneIcon from "@mui/icons-material/Done";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import NoCrashIcon from "@mui/icons-material/NoCrash";
import Box from "@mui/material/Box";
import { Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { allCountries } from "../../utils/countryData/allcountries";
import { useSelector } from "react-redux";
import axios from "axios";
import { ReactComponent as AddDriver } from "./additionalDriver.svg";
import {ReactComponent as  ChildSeat} from "./childseaticon.svg"
import insuranceLogo from "./pac.png";
import {
  addSubscriptionExtras,
  addSubscriptionBaseRate,
  addExtrasOnTenureChange,
} from "../../utils/subscriptionSlices/subscriptionInvoiceSlice";
import { useDispatch } from "react-redux";
import { setSubscriptionData } from "../../utils/subscriptionSlices/subscriptionSliceMain";
import { setFalseSubscriptionCarModal } from "../../utils/ModalSlice/modalToggleSlice";
import { pickPlaceFinder } from "../../utils/commonFunction/placeFinder";
import { updateCarDropLocation } from "../../utils/picDropSlices/pickDropSlice";
import { checkIfLocationInDubai } from "../../utils/commonFunction/checkBounds";
import { switchOnLoader, switchOffLoader } from "../../utils/loaderSlice";
import { setUserId } from "../../utils/userSlices/userSlice";
import { addStripePaymentId } from "../../utils/paymentSession";
import { addSessionID } from "../../utils/paymentSession";
import { addSubDetails, addSubReservationId } from "../../utils/subscriptionSlices/subscriptionTripDetailSlice";
import { addAllAddOnNames } from "../../utils/subscriptionSlices/subscriptionInvoiceSlice";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../../config";


const SubscriptionLast = () => {
  const dispatch = useDispatch();
  const {carname} = useParams();
  const mainPickCity =
    "Zabeel Business Centre (Smark 9) - Umm Hurair Road - Dubai - United Arab Emirates";
  const [arr, setArr] = useState([]);
  //area for all input field states
  const [text, setText] = useState("");
  const [dropDate, setDropDate] = useState("");
  const [dropTime, setDropTime] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const subscriptionTripDetails = useSelector(state => state.subscriptionTripDetailSlice);

  const deliveryCharge = useSelector(
    (store) => store.pickDropSlice.carDropLocation.locationRate
  );
  // Now, you can access individual properties
  const currencyPrice = useSelector(
    (store) => store.currencySlice.currentCurrRate
  );
  const [open, setOpen] = useState(true)

  useEffect(() => {
    if (open) {
      const delay = 500;
      const debounce = setTimeout(() => {
        handleApiCall();
        //
      }, delay);
      return () => {
        clearTimeout(debounce);
      };
    }
  }, [text]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  //validation states
  const [validation, setValidation] = useState({
    textVal: true,
    dropDateVal: true,
    dropTimeVal: true,
    firstNameVal: true,
    lastNameVal: true,
    phoneNumberVal: true,
    emailVal: true,
    addressVal: true,
    cityVal: true,
    countryVal: true,
  });

  const storeRateInfo = async () => {
    try {
      const reponse = await axios.get(
        `${baseUrl}/app/v1/rates/getDeliveryCollectionRates`
      );
      return reponse.data.result[0];
    } catch (error) {
      console.log(error);
    }
  };

  // Validation function
  const validateFields = async () => {
    // Validation logic for each field
    //proper delivery address check
    let boolDeliveryValidity = true;
    if (text == "") {
      boolDeliveryValidity = false;
    }

    //check if its a google adddress
    const correctPlace = await pickPlaceFinder(text);
    if (correctPlace == false) {
      boolDeliveryValidity = false;
    } else {
      const rateObj = await storeRateInfo();
      const pickDropLocKeyWord = await checkIfLocationInDubai(text);

      const apiRate = text == mainPickCity ? 0 : rateObj[pickDropLocKeyWord];

      let apiObj = {
        location: pickDropLocKeyWord,
        locationRate: Number(apiRate),
      };
      dispatch(updateCarDropLocation(apiObj));
    }

    const isTextValid = boolDeliveryValidity;

    const isDropDateValid = dropDate.trim() !== "";
    const isDropTimeValid = dropTime.trim() !== "";
    const isFirstNameValid = firstName.trim() !== "";
    const isLastNameValid = lastName.trim() !== "";
    const isPhoneNumberValid =
      phoneNumber.length >= 6 && phoneNumber.length <= 10
        ? phoneNumber.trim() !== "" && !isNaN(phoneNumber)
        : false;
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const isAddressValid = address.trim() !== "";
    const isCityValid = city.trim() !== "";
    const isCountryValid = country.trim() !== "";
    // Update validation state
    setValidation({
      textVal: isTextValid,
      dropDateVal: isDropDateValid,
      dropTimeVal: isDropTimeValid,
      firstNameVal: isFirstNameValid,
      lastNameVal: isLastNameValid,
      phoneNumberVal: isPhoneNumberValid,
      emailVal: isEmailValid,
      addressVal: isAddressValid,
      cityVal: isCityValid,
      countryVal: isCityValid,
    });

    // Return overall validation status
    return (
      isTextValid &&
      isDropDateValid &&
      isDropTimeValid &&
      isFirstNameValid &&
      isLastNameValid &&
      isPhoneNumberValid &&
      isEmailValid &&
      isAddressValid &&
      isCityValid &&
      isCountryValid
    );
  };

  const subscriptionTenureWords = useSelector(
    (store) => store.subscriptionSliceMain.tenureWords
  );

  const carDropObj = useSelector(
    (store) => store.pickDropSlice.carDropLocation
  );

  const [allExtras, setAllExtras] = useState([]);

  const [subTenure, setSubTenure] = useState(subscriptionTenureWords);
  const [selectedCards, setSelectedCards] = useState([]);
  const [extraNames, setExtrasName] = useState([]);
  const [cdwStatus, setCdwStatus] = useState(false);
  const [PAIStatus, setPAIStatus] = useState(false);


  const totalBaseRate = useSelector(
    (store) => store.subscriptionInvoiceSlice.subscriptionBaseRate
  );
  const totalExtrasRate = useSelector(
    (store) => store.subscriptionInvoiceSlice.subscriptionExtrasTotal
  );

  const subscriptionObject = useSelector(
    (store) => store.subscriptionSliceMain.subscriptionCarObj
  );

  const currencyName = useSelector((store) => store.currencySlice.currentCurr);
  const currencyRate = useSelector(
    (store) => store.currencySlice.currentCurrRate
  );

  const subscriptionTenureNumber = useSelector(
    (store) => store.subscriptionSliceMain.tenureNumber
  );



  const options = [
    { label: "00:30", value: "00:30" },
    { label: "01:00", value: "01:00" },
    { label: "01:30", value: "01:30" },
    { label: "02:00", value: "02:00" },
    { label: "02:30", value: "02:30" },
    { label: "03:00", value: "03:00" },
    { label: "03:30", value: "03:30" },
    { label: "04:00", value: "04:00" },
    { label: "04:30", value: "04:30" },
    { label: "05:00", value: "05:00" },
    { label: "05:30", value: "05:30" },
    { label: "06:00", value: "06:00" },
    { label: "06:30", value: "06:30" },
    { label: "07:00", value: "07:00" },
    { label: "07:30", value: "07:30" },
    { label: "08:00", value: "08:00" },
    { label: "08:30", value: "08:30" },
    { label: "09:00", value: "09:00" },
    { label: "09:30", value: "09:30" },
    { label: "10:00", value: "10:00" },
    { label: "10:30", value: "10:30" },
    { label: "11:00", value: "11:00" },
    { label: "11:30", value: "11:30" },
    { label: "12:00", value: "12:00" },
    { label: "12:30", value: "12:30" },
    { label: "13:00", value: "13:00" },
    { label: "13:30", value: "13:30" },
    { label: "14:00", value: "14:00" },
    { label: "14:30", value: "14:30" },
    { label: "15:00", value: "15:00" },
    { label: "15:30", value: "15:30" },
    { label: "16:00", value: "16:00" },
    { label: "16:30", value: "16:30" },
    { label: "17:00", value: "17:00" },
    { label: "17:30", value: "17:30" },
    { label: "18:00", value: "18:00" },
    { label: "18:30", value: "18:30" },
    { label: "19:00", value: "19:00" },
    { label: "19:30", value: "19:30" },
    { label: "20:00", value: "20:00" },
    { label: "20:30", value: "20:30" },
    { label: "21:00", value: "21:00" },
    { label: "21:30", value: "21:30" },
    { label: "22:00", value: "22:00" },
    { label: "22:30", value: "22:30" },
    { label: "23:00", value: "23:00" },
    { label: "23:30", value: "23:30" },
  ];


  useEffect(() => {
    dispatch(setFalseSubscriptionCarModal());
  }, []);

  useEffect(() => {
    //update base rate and extras price

    if (subTenure === "twelveMonths") {
      
      calculateExtrasRateWithTenureChange(12)

      dispatch(
        setSubscriptionData({
          subscriptionCarObj: subscriptionObject,
          tenureWords: "twelveMonths",
          tenureNumber: 12,
        })
      );
    } else if (subTenure === "nineMonths") {

      calculateExtrasRateWithTenureChange(9)
      dispatch(
        setSubscriptionData({
          subscriptionCarObj: subscriptionObject,
          tenureWords: "nineMonths",
          tenureNumber: 9,
        })
      );
    } else if (subTenure === "sixMonths") {
      calculateExtrasRateWithTenureChange(6)
      dispatch(
        setSubscriptionData({
          subscriptionCarObj: subscriptionObject,
          tenureWords: "sixMonths",
          tenureNumber: 6,
        })
      );
    } else if (subTenure === "threeMonths") {
      calculateExtrasRateWithTenureChange(3)
      dispatch(
        setSubscriptionData({
          subscriptionCarObj: subscriptionObject,
          tenureWords: "threeMonths",
          tenureNumber: 3,
        })
      );
    } else {
      calculateExtrasRateWithTenureChange(1)
      dispatch(
        setSubscriptionData({
          subscriptionCarObj: subscriptionObject,
          tenureWords: "monthly",
          tenureNumber: 1,
        })
      );
    }

    dispatch(
      addSubscriptionBaseRate(subscriptionObject.subscriptionPrice[subTenure])
    );
  }, [subTenure]);

  const calculateExtrasRateWithTenureChange = (months) => {
    if (cdwStatus && PAIStatus) {
      let total = 0;

      // subtract before selected cdw 
      total = (totalExtrasRate - subscriptionObject.insuranceAndDamageCovers[0].price.monthly *
        subscriptionTenureNumber) 

      // subtract before selected PAI 
      total =  (total -
        subscriptionObject.insuranceAndDamageCovers[1].price.monthly *
        subscriptionTenureNumber);

      // add new cdw as per months
        total +=
        subscriptionObject.insuranceAndDamageCovers[0].price.monthly * months;

      // add new PAI as per months
        total +=
        subscriptionObject.insuranceAndDamageCovers[1].price.monthly * months;

        dispatch(addExtrasOnTenureChange(total));
    } else if(PAIStatus){
      let calcExtra =
      totalExtrasRate -
      subscriptionObject.insuranceAndDamageCovers[1].price.monthly *
        subscriptionTenureNumber;
      calcExtra +=
        subscriptionObject.insuranceAndDamageCovers[1].price.monthly * months;
        dispatch(addExtrasOnTenureChange(calcExtra));
    } else if(cdwStatus){
      let calcExtra =
      totalExtrasRate -
      subscriptionObject.insuranceAndDamageCovers[0].price.monthly *
        subscriptionTenureNumber;
      calcExtra +=
        subscriptionObject.insuranceAndDamageCovers[0].price.monthly * months;
        dispatch(addExtrasOnTenureChange(calcExtra));
    }
  }

  const handleCardClick = (index) => {
    const updatedExtras = [...extraNames];
    // Check if the card is already selected
    if (selectedCards.includes(index)) {
      // If selected, remove it from the list
      //also add and subtract the values of extras
      setSelectedCards(selectedCards.filter((item) => item !== index));
      if (allExtras[index].title === "Additional Driver") {
        const indexToRemove = updatedExtras.indexOf("Additional Driver");
        if (indexToRemove !== -1) {
          updatedExtras.splice(indexToRemove, 1);
        }
        setExtrasName(updatedExtras);
        //subtract daily
        dispatch(addSubscriptionExtras(allExtras[index].price.daily * -1));
      } else {
        //subtract maximum\
        const indexToRemove = updatedExtras.indexOf("Baby Safety Seat");
        if (indexToRemove !== -1) {
          updatedExtras.splice(indexToRemove, 1);
        }
        setExtrasName(updatedExtras);
        dispatch(addSubscriptionExtras(allExtras[index].price.maximum * -1));
      }
    } else {
      // If not selected, add it to the list
      //also add and subtract the values of extras

      setSelectedCards([...selectedCards, index]);
      if (allExtras[index].title === "Additional Driver") {
        //subtract daily
        updatedExtras.push("Additional Driver");
        setExtrasName(updatedExtras);
        dispatch(addSubscriptionExtras(allExtras[index].price.daily));
      } else {
        updatedExtras.push("Baby Safety Seat");
        setExtrasName(updatedExtras);
        //subtract maximum
        dispatch(addSubscriptionExtras(allExtras[index].price.maximum));
      }
    }
  };

  const checkCdwStatus = () => {
    const updatedExtras = [...extraNames];
    if (cdwStatus) {
      const indexToRemove = updatedExtras.indexOf("CDW");
      if (indexToRemove !== -1) {
        updatedExtras.splice(indexToRemove, 1);
      }
      setExtrasName(updatedExtras);
      //remove cdw
      setCdwStatus(false);
      dispatch(
        addSubscriptionExtras(
          subscriptionObject.insuranceAndDamageCovers[0].price.monthly * subscriptionTenureNumber * -1
        )
      );
    } else {
      //add cdw
      updatedExtras.push("CDW");
      setExtrasName(updatedExtras);
      setCdwStatus(true);
      dispatch(
        addSubscriptionExtras(
          subscriptionObject.insuranceAndDamageCovers[0].price.monthly * subscriptionTenureNumber
        )
      );
    }
  };


  const checkPersonalInsurance = () => {
    const updatedExtras = [...extraNames];
    if (PAIStatus) {
      const indexToRemove = updatedExtras.indexOf("PAI");
      if (indexToRemove !== -1) {
        updatedExtras.splice(indexToRemove, 1);
      }
      setExtrasName(updatedExtras);
      //remove PAI
      setPAIStatus(false);
      dispatch(
        addSubscriptionExtras(
          subscriptionObject.insuranceAndDamageCovers[1].price.monthly * subscriptionTenureNumber * -1
        )
      );
    } else {
      //add PAI
      updatedExtras.push("PAI");
      setExtrasName(updatedExtras);
      setPAIStatus(true);

      dispatch(
        addSubscriptionExtras(
          subscriptionObject.insuranceAndDamageCovers[1].price.monthly * subscriptionTenureNumber
        )
      );
    }
  };


  const handleApiCall = async () => {
    try {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseUrl}/app/v1/google/:${text}`,
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      };
      axios
        .request(config)
        .then((response) => {
          let nArr = [];
          for (let i = 0; i < response.data.predictions.length; i++) {
            let str = response.data.predictions[i].description;
            nArr.push(str);
          }
          nArr.unshift(mainPickCity);
          setArr(nArr);
        })
        .catch((error) => {});
    } catch (error) {
      console.error("Google API Error : " + error);
    }
  };

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/app/v1/extras/getAllExtras`,
    };

    axios
      .request(config)
      .then((response) => {

        setAllExtras(response.data.result);
      })
      .catch((error) => {});
  }, []);

  const tenureArray = Object.keys(subscriptionObject.subscriptionPrice)
    .filter((key) => subscriptionObject.subscriptionPrice[key] !== 0)
    .map((key) => ({ label: key }));

  // Now, tenureArray is an array of objects with the structure [{ label: "monthly" }, { label: "twoMonths" }, ...]


  const setPickValue = (pickVal) => {
    setText(pickVal);
    setArr([]);
    setOpen(false)
  };

  const getTomorrowDate = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);

    const year = currentDate.getFullYear();
    let month = currentDate.getMonth() + 1;
    let day = currentDate.getDate();

    // Add leading zeros for months and days less than 10
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  };

  const makeANewCustomer = async () => {
    try {
      let data = JSON.stringify({
        name: firstName + " " + lastName,
        phone: phoneNumber,
        email: email,
        address: {
          line1: address,
          postal_code: null,
          city: city,
          country: country,
        },
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseUrl}/app/v1/billing/createCustomer`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      const result = await axios.request(config);

      if (result) {
        return result.data.customerID;
      } else {
        console.log("Error fetching customer id");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkoutPage = (custId, custAmount, custCurr, userID, subscriptionID) => {
    let data = JSON.stringify({
      customerID: custId,
      carType: subscriptionObject.title,
      subscriptionDuration: subscriptionTenureNumber,
      amount: custAmount,
      currency: custCurr,
      userID: userID,
      subscriptionID: subscriptionID
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/app/v1/billing/subscriptionCheckout`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        dispatch(addSessionID(response.data.sessionID));
        //created session
        //session url
        //session id
        dispatch(switchOffLoader());
        window.location = response.data.sessionURL;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendSubscription = async (userID, stripeCustomerID) => {
    let data = JSON.stringify({
        "subscription": {
          "subscriptionID": "",
          "userID": userID,
          "travelItenary": {
            "deliveryLocation": text,
            "deliveryDate": dropDate.toString(),
            "deliveryTime": dropTime
          },
          "car": {
            "type": subscriptionObject.type,
            "typeID": subscriptionObject.vehicleID,
            "src": subscriptionObject.imageName,
            "title": subscriptionObject.title
          },
          "extras": extraNames,
          "currencyInfo": {
            "currency": currencyName,
            "currencyRate": currencyPrice
          },
          "payment": "payNow",
          "subscriptionDate": dropDate.toString(),
          "subscriptionMonths": subscriptionTenureNumber,
          "subscriptionMileage": "2500 km/month",
          "subscriptionStatus": "Pending",
          "allocatedCarID": "",
          "allocatedDriverID": "",
          "promoCode": "",
          "finalInvoice": false
        },
        "invoice": {
          "subscriptionRecieptID": "",
          "subscriptionID": "",
          "userID": "",
          "baseRate": totalBaseRate,
          "excessInsuranceCharge": 0,
          "deliveryCharge": deliveryCharge,
          "collectionCharge": 0,
          "addOnCharges": totalExtrasRate,
          "taxes": (totalBaseRate+totalExtrasRate+deliveryCharge)*0.05,
          "totalCharges": (totalBaseRate+totalExtrasRate+deliveryCharge)*1.05,
          "promoCodeDeductions": null,
          "chargesAfterPromoCode": 0,
          "paymentStatus": "Pending",
          "paymentMethod": "card"
        }
      });
      
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseUrl}/app/v1/subscription/createSubscription`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      setTimeout(async() => {
        await axios.request(config)
        .then((response) => {
          dispatch(addSubReservationId(response.data.subscriptionID));
          console.log("subscription created successfully")
          
          if (
            response.data?.invoiceCreated &&
            response.data?.subscriptionCreated &&
            response.data?.subscriptionID && userID
          ) {
            // calling the checkout function
            let custTotalAmount =
              (totalBaseRate + totalExtrasRate + carDropObj.locationRate) *
              1.05;
            checkoutPage(
              stripeCustomerID,
              custTotalAmount * currencyRate,
              currencyName,
              userID,
              response.data?.subscriptionID
            );
          } else {
            console.log("Error creating subscription or subscription receipt");
          }
        })
        .catch((error) => {
          console.log(error);
          return {subscriptionCreated: false, invoiceCreated: false}
        });
      }, 500)
  };

  const givePrice = async () => {
    try {
      const isValid = await validateFields();
      if (isValid) {
        dispatch(switchOnLoader());
        dispatch(
          addSubDetails({
            subDeliveryLocation: text,
            subDeliveryDate: dropDate,
            subDeliveryTime: dropTime,
            subFirstName: firstName,
            subLastName: lastName,
            subPhone: phoneNumber,
            subEmail: email,
            subAddress: address,
            subCity: city,
            subCountry: country,
          })
        );

        dispatch(addAllAddOnNames(extraNames));

        const stripeCustomerID = await makeANewCustomer();
        dispatch(addStripePaymentId(stripeCustomerID));
        // dispatch(addTotalExtras(extraObjects));

        // setUserVal(false);

        let data = JSON.stringify({
          firstName: firstName,
          lastName: lastName,
          contact: phoneNumber,
          country: country,
          address: address,
          city: city,
          postalCode: "",
          emailID: email,
          password: "",
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${baseUrl}/app/v1/user/createUser`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        await axios
          .request(config)
          .then(async(response) => {
            // );
            //

            // setUserId(response.data.userID);
            dispatch(setUserId(response.data.userID));
            console.log("user created");

             await sendSubscription(response.data.userID, stripeCustomerID)

          })
          .catch((error) => {
            console.log(error);
            //
          });

      } else {
        // setUserVal(true);
        window.scrollTo({ top: 300, left: 0, behavior: "smooth" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const calcDeliveryCharge = async (location) => {
    const rateObj = await storeRateInfo();
    const pickDropLocKeyWord = await checkIfLocationInDubai(location);

    const apiRate = text == mainPickCity ? 0 : rateObj[pickDropLocKeyWord];

    let apiObj = {
      location: pickDropLocKeyWord,
      locationRate: Number(apiRate),
    };
    dispatch(updateCarDropLocation(apiObj));
  };
  const [priceDetail, setPriceDetail] = useState(false);



  function capitalizeFirstLetter(sentence) {
    return sentence.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}


  return (
    <>
    <Helmet>
        <title>Subscription Details - {capitalizeFirstLetter(carname)}</title>
        <link
          rel="canonical"
          href={`https://www.wticarrental.ae/dubai/subscription/${carname}`}
        />

        
      </Helmet>
    <div className="w-full p-9 flex max-sm:flex-col  gap-8 relative">
      <div className="w-[65%] max-sm:w-full px-4 ">
        <div className="flex justify-start max-sm:block">
          <div className="flex flex-col items-start w-[50%] max-sm:w-full ">
            <span className="text-[22px] font-semibold  text-blue mx-2">
              Tenure
            </span>
            <div className="w-full m-2">
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 0, width: "100%" },
                }}
                noValidate
                autoComplete="off"
              >
                <div>
                  {/* <InputLabel id="Tenure">
                        Tenure
                    </InputLabel> */}
                  <Select
                    className="w-[80%] h-[3rem]"
                    value={subTenure}
                    onChange={(e) => setSubTenure(e.target.value)}
                  >
                    {tenureArray.map((option) => (
                      <MenuItem key={option.label} value={option.label}>
                        {option.label === "monthly"
                          ? "1 Month"
                          : option.label === "threeMonths"
                          ? "3 Months"
                          : option.label === "sixMonths"
                          ? "6 Months"
                          : option.label === "nineMonths"
                          ? "9 Months"
                          : option.label === "twelveMonths"
                          ? "12 Months"
                          : ""}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </Box>
            </div>
          </div>

          <div className="flex flex-col items-start w-[50%] max-sm:w-full ">
            <span className="text-[22px] font-semibold  text-blue mx-2">
              Mileage
            </span>
            <div className="w-full m-2 ">
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 0, width: "100%" },
                }}
                noValidate
                autoComplete="off"
              >
                <div>
                  <Select
                    className="w-[80%] h-[3rem]"
                    value={"2500"}
                    onChange={(e) => setSubTenure(e.target.value)}
                    defaultValue={"2500 km / month"}
                    // readOnly={true}
                  >
                    <MenuItem value={"2500"}>2500 km / month</MenuItem>
                  </Select>
                </div>
              </Box>
            </div>
          </div>
        </div>
        <div className="text-[22px] font-semibold  text-blue ml-2 mt-2">
          Personal Details
        </div>
        <div className=" mt-4 rounded drop-shadow  grid grid-cols-2 max-sm:grid-cols-1 ">
          <div className="w-[95%] m-2 max-sm:w-full">
            <div className="relative w-full">
              <input
                type="text"
                placeholder="Delivery location"
                id="floating_filled"
                className={` rounded placeholder:text-[#bababa] px-2 w-[85%] h-[3rem] text-sm text-[#bababa] bg-white  border-[1px] border-b-2 ${
                  validation.textVal ? "border-[#C4C4C4] " : "border-red-500"
                } appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
                onChange={(e) => {
                  setText(e.target.value);
                }}
                value={text}
                onFocus={() => {setOpen(true)}}
              />

              {arr.length > 2 && open ? (
                <div className="w-[85%] absolute  h-[15rem] z-30 overflow-y-auto">
                  {arr.map((ele) => (
                    <div
                      className="w-full h-fit py-2 px-2 flex justify-start cursor-pointer bg-white items-center hover:bg-slate-300"
                      onClick={() => {
                        setPickValue(ele);
                        calcDeliveryCharge(ele);
                      }}
                    >
                      <span className="text-black">{ele}</span>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="w-[95%] m-2 max-sm:w-full">
            <div className="relative w-full ">
              <input
                type="date"
                id="floating_filled"
                className={`hover:cursor-pointer rounded placeholder:text-[#bababa]  px-2 w-[85%] h-[3rem] text-sm text-[#bababa] bg-white border-[1px] border-b-2 ${
                  validation.dropDateVal ? "border-[#C4C4C4]" : "border-red-500"
                } appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
                placeholder="Delivery Date"
                onChange={(e) => setDropDate(e.target.value)}
                value={dropDate}
                min={getTomorrowDate()}
              />
            </div>
          </div>
          <div className="w-[95%] m-2 max-sm:w-full">
            <div className="relative">
              <div
                className={`relative placeholder:text-[#bababa]  rounded  px-2 w-[85%] h-[3rem] text-sm text-[#9CA3AF] bg-white border-[1px] border-b-2 ${
                  validation.dropTimeVal ? "border-[#C4C4C4]" : "border-red-500"
                } appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
              >
                <select
                  type="text"
                  id="floating_filled"
                  className="w-full h-full"
                  onChange={(e) => setDropTime(e.target.value)}
                >
                  <option value="" disabled selected>
                    <span className=" text-[#9CA3AF]">Delivery time</span>
                  </option>
                  {options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="w-[95%] m-2 max-sm:w-full">
            <div className="relative">
              <input
                type="text"
                id="floating_filled"
                className={`rounded  placeholder:text-[#bababa]  px-2 w-[85%] h-[3rem] text-sm text-[#bababa] bg-white border-[1px] border-b-2 ${
                  validation.firstNameVal
                    ? "border-[#C4C4C4]"
                    : "border-red-500"
                } appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
                placeholder="First Name"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
          </div>

          <div className="w-[95%] m-2 max-sm:w-full">
            <div className="relative">
              <input
                type="text"
                id="floating_filled"
                className={`rounded placeholder:text-[#bababa]   px-2 w-[85%] h-[3rem] text-sm text-[#bababa] bg-white border-[1px] border-b-2 ${
                  validation.lastNameVal ? "border-[#C4C4C4]" : "border-red-500"
                } appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
                placeholder="Last Name"
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>
          <div className="w-[95%] m-2 max-sm:w-full">
            <div className="relative">
              <input
                type="number"
                id="floating_filled"
                className={`rounded placeholder:text-[#bababa]  px-2 w-[85%] h-[3rem] text-sm text-[#bababa] bg-white  border-[1px] border-b-2 ${
                  validation.phoneNumberVal
                    ? "border-[#C4C4C4]"
                    : "border-red-500"
                } appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
                placeholder="Phone Number"
                min={6}
                max={10}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
          </div>
          <div className="w-[95%] m-2 max-sm:w-full">
            <div className="relative ">
              <input
                type="text"
                id="floating_filled"
                className={`rounded placeholder:text-[#bababa] px-2 w-[85%] h-[3rem] text-sm text-[#bababa] bg-white border-[1px] border-b-2 ${
                  validation.emailVal ? "border-[#C4C4C4]" : "border-red-500"
                } appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="w-[95%] m-2 max-sm:w-full">
            <div className="relative w-full">
              <input
                type="text"
                id="floating_filled"
                className={`rounded placeholder:text-[#bababa]  px-2 w-[85%] h-[3rem] text-sm text-[#bababa] bg-white  border-[1px] border-b-2 ${
                  validation.addressVal ? "border-[#C4C4C4]" : "border-red-500"
                } appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
                placeholder="Address"
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
          </div>
          <div className="w-[95%] m-2 max-sm:w-full">
            <div className="relative w-full">
              <input
                type="text"
                id="floating_filled"
                className={`rounded placeholder:text-[#bababa]   px-2 w-[85%] h-[3rem] text-sm text-[#bababa] bg-white  border-[1px] border-b-2 ${
                  validation.cityVal ? "border-[#C4C4C4]" : "border-red-500"
                } appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
                placeholder="City"
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
          </div>
          {/* <div className="w-[95%] m-2">
            <div>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 0, width: "96.5%" },
                }}
                noValidate
                autoComplete="off"
              >
                <div>
                  <Select
                    id=""
                    select
                    label="Select Country"
                    placeholder="Select Country"
                    defaultValue=""
                    variant="filled"
                    className="w-[85%] h-[3rem] border-[1px] bg-white border-[#C4C4C4] border-b-2"
                  >
             

                  
                    {allCountries.map((option) => (
                      <MenuItem key={option.name} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </Box>
            </div>
          </div> */}
          <div className="w-[95%] m-2 max-sm:w-full">
            <div className="relative">
              <div
                className={`relative placeholder:text-[#bababa]  rounded px-2 w-[85%] h-[3rem] text-sm text-[#C0C5CC] bg-white border-[1px] border-b-2 ${
                  validation.countryVal ? "border-[#C4C4C4]" : "border-red-500"
                } appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
              >
                <select
                  type="text"
                  id="floating_filled"
                  className="w-full h-full"
                  onChange={(e) => {
                    setCountry(e.target.value);
                  }}
                >
                  <option value="" disabled selected>
                    <span className=" placeholder:text-black text-black">
                      Select Country
                    </span>
                  </option>
                  {allCountries.map((option) => (
                    <option key={option.name} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* <select
                type="text"
                id="floating_filled"
                className={`rounded  px-2 w-[85%] h-[3rem] text-sm text-[#C0C5CC] bg-white  dark:bg-gray-700 border-[2px] border-b-2 ${
                  validation.countryVal ? "border-[#C4C4C4]" : "border-red-500"
                } appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
              >
               
                <option value="" disabled selected>
                  <span className=" text-slate-200">Select Country</span>
                </option>
                {allCountries.map((option) => (
                  <option key={option.name} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </select> */}
            </div>
          </div>
        </div>

        <div className="text-[22px] font-semibold py-4 text-blue">Extras</div>
        <div className="grid grid-cols-3 gap-x-2 grid-rows-2 max-sm:grid-cols-1">
          {allExtras.map((ele, index) => (
            <div
              key={index}
              className={`border shadow-md rounded-lg py-6 hover:ring-2 cursor-pointer relative ${
                selectedCards.includes(index) ? "border-[#3659B2] border-2" : ""
              }`}
              onClick={() => handleCardClick(index)}
            >
              <div className="flex ">
                <div
                  style={{
                    color: "#0891B2",
                    paddingLeft: "10px",
                    paddingTop: "8px",
                  }}
                >
                  {ele.title === "Additional Driver" ? (
                    <AddDriver className=" rounded-full" />
                  ) : (
                    <ChildSeat className=" rounded-full" />
                  )}
                </div>
                <div className="px-4">
                  <div className="py-2 font-semibold">
                    {ele.title}
                    <button
                      type="button"
                      className="w-full text-right px-1 text-gray-500 absolute top-0 right-0  hover:text-gray-700 focus:outline-none group"
                    >
                      <span className=" w-full invisible bg-black group-hover:visible text-[12px] bg-opacity-75 rounded p-1 text-white absolute top-10 right-4  ">
                        {ele.description}
                      </span>
                      <InfoOutlinedIcon style={{ fontSize: "16px" }} />
                    </button>
                  </div>
                  <div className="text-[15px] font-semibold text-gray-700">
                    {currencyName}{" "}
                    {ele.title === "Additional Driver"
                      ?( ele.price.daily * currencyRate).toFixed(2)
                      : (ele.price.maximum * currencyRate).toFixed(2)} - {subscriptionTenureNumber == 1
                        ? `${subscriptionTenureNumber} Month`
                        : `${subscriptionTenureNumber} Months`}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="text-[22px] font-semibold   mb-4 max-sm:mt-6">
          Insurance
        </div>
        <div className="flex max-sm:flex-col gap-2">
            <div
              className={` cursor-pointer shadow-md rounded-md w-[22rem] max-sm:w-full ${
                cdwStatus ? "border-[#3659B2] border-2" : "border"
              }`}
              onClick={checkCdwStatus}
            >
              <div className="relative flex">
                <div
                  style={{
                    color: "#0891B2",
                    paddingLeft: "10px",
                    paddingTop: "16px",
                  }}
                >
                  <img src={insuranceLogo} alt="" className="w-[3.2rem] rounded-full" />
                </div>
                <div className="px-4">
                  <div className="pt-4 pb-1 font-semibold">{subscriptionObject.insuranceAndDamageCovers[0].title}</div>
            
                  <div className="text-[15px] py-5 font-semibold text-gray-700">
                    {currencyName} {(subscriptionObject.insuranceAndDamageCovers[0].price.monthly * subscriptionTenureNumber* currencyRate).toFixed(2)} -{" "}
                    {subscriptionTenureNumber == 1
                      ? `${subscriptionTenureNumber} Month`
                      : `${subscriptionTenureNumber} Months`}
                  </div>
                   
                  <button
                        type="button"
                        className="w-full text-right px-1 text-gray-500 absolute top-0 right-0  hover:text-gray-700 focus:outline-none group"
                      >
                        <span className=" w-full invisible bg-black group-hover:visible text-[12px] bg-opacity-75 rounded p-1 text-white absolute top-10 right-4  ">
                          {subscriptionObject.insuranceAndDamageCovers[0].description}
                        </span>
                        <InfoOutlinedIcon style={{ fontSize: "16px" }} />
                  </button>
                </div>
              </div>
            </div>
            <div
              className={` cursor-pointer shadow-md rounded-md w-[22rem] max-sm:w-full ${
                PAIStatus ? "border-[#3659B2] border-2" : "border"
              }`}
              onClick={checkPersonalInsurance}
            >
              <div className="relative flex">
                <div
                  style={{
                    color: "#0891B2",
                    paddingLeft: "10px",
                    paddingTop: "16px",
                  }}
                >
                  <img src={insuranceLogo} alt="" className="w-[3.2rem] rounded-full" />
                </div>
                <div className="px-4">
                  <div className="pt-4 pb-1 font-semibold">{subscriptionObject.insuranceAndDamageCovers[1].title} (PAI)</div>
                  <div className="text-[15px] py-5 font-semibold text-gray-700">
                    {currencyName} {(subscriptionObject.insuranceAndDamageCovers[1].price.monthly * subscriptionTenureNumber* currencyRate).toFixed(2)} -{" "}
                    {subscriptionTenureNumber == 1
                      ? `${subscriptionTenureNumber} Month`
                      : `${subscriptionTenureNumber} Months`}
                  </div>
                  <button
                        type="button"
                        className="w-full text-right px-1 text-gray-500 absolute top-0 right-0  hover:text-gray-700 focus:outline-none group"
                      >
                        <span className=" w-full invisible bg-black group-hover:visible text-[12px] bg-opacity-75 rounded p-1 text-white absolute top-10 right-4  ">
                          {subscriptionObject.insuranceAndDamageCovers[1].description}
                        </span>
                        <InfoOutlinedIcon style={{ fontSize: "16px" }} />
                  </button>
                </div>
              </div>
            </div>
        </div>
      </div>

      <div className="w-[35%]  max-sm:w-full sticky  ">
        <div className="w-full flex flex-col items-center mt-4 rounded border-[1px] border-gray-200 shadow-[0.2px_0.2px_6px_1px_rgba(0,0,0,0.3)] ">
          <div className="px-6 pt-4 text-[20px] font-bold">
            {subscriptionObject.title}
          </div>
          <img
            src={
              `${baseUrl}/app/v1/aws/getImage/carimages/` +
              (subscriptionObject.imageName
                ? subscriptionObject.imageName.split(" ").join("")
                : "static") +
              ".png"
            }
            alt=""
            onError={(e) => {
              e.target.src = "../static/staticcarimage.png";
            }}
            className="hover:scale-[1.05] max-sm:hover:scale-100"
          />

          <div className="grid grid-cols-5 w-full text-[13px] py-3 text-center border-t rounded border-gray-200 ">
            <div className="">
              <span className="px-1">
                <PersonIcon className="text-[#3659B2]" />
              </span>
              <br></br>
              {subscriptionObject.seats}
            </div>
            <div className="">
              <span className="px-1">
                <BusinessCenterIcon className="text-[#3659B2]  " />
              </span>
              <br></br>
              {subscriptionObject.luggageCapacity}
            </div>
            <div className=" ">
              <span className="px-2">
                <AirIcon className="text-[#3659B2]" />
              </span>
              <br></br>
              AC
            </div>
            <div className=" ">
              <span className="px-2">
                <NoCrashIcon className="text-[#3659B2]" />
              </span>
              <br></br>
              {subscriptionObject.doors} Doors
            </div>
            <div className="">
              <span className="px-2">
                <AutoModeIcon className="text-[#3659B2]" />
              </span>
              <br></br>
              {subscriptionObject.transmissionType.substring(0,4)}
            </div>
          </div>

          <div className="py-2 mx-8 text-slate-600 border-y  ">
            {/* <div className="text-slate-700 pt-3 flex items-center">
              <span className="pr-1">
                <DoneIcon style={{ color: "gray", fontSize: "16px" }} />
              </span>
              <div className="break-words text-[12px]">
                Includes theft protection
              </div>
              <div className="ml-auto">
                <button className="p-1 text-gray-500 hover:text-gray-700 focus:outline-none">
                  <InfoOutlinedIcon style={{ fontSize: "16px" }} />
                </button>
              </div>
            </div> */}

            <div className="text-slate-700 pt-3 flex items-center">
              <span className="pr-1">
                <DoneIcon style={{ color: "gray", fontSize: "16px" }} />
              </span>
              <div className="break-words text-[12px]">
                Includes theft protection
              </div>
              <div className="ml-auto">
                <button className="p-1 text-gray-500 hover:text-gray-700 focus:outline-none">
                  <InfoOutlinedIcon style={{ fontSize: "16px" }} />
                </button>
              </div>
            </div>
            <div className="text-slate-700 pt-3 flex ">
              <span className="pr-1">
                <DoneIcon style={{ color: "gray", fontSize: "16px" }} />
              </span>
              <div className="break-words pr-2 text-[12px]">
                Included Loss Damage Waiver up to {currencyName} 7,000.00
                financial responsibility
              </div>
              <div className="ml-auto">
                <button className="p-1 text-gray-500 hover:text-gray-700 focus:outline-none">
                  <InfoOutlinedIcon style={{ fontSize: "16px" }} />
                </button>
              </div>
            </div>
            {/* <div className="text-slate-700 pt-3 flex items-center">
              <span className="pr-1">
                <DoneIcon style={{ color: "gray", fontSize: "16px" }} />
              </span>
              <div className="break-words text-[12px]">
                Includes theft protection
              </div>
              <div className="ml-auto">
                <button className="p-1 text-gray-500 hover:text-gray-700 focus:outline-none">
                  <InfoOutlinedIcon style={{ fontSize: "16px" }} />
                </button>
              </div>
            </div> */}
          </div>
          <div className="w-full">
            {priceDetail ? (
              <></>
            ) : (
              <div className="flex justify-between font-semibold pt-5 px-8">
                <div>Total Price</div>
                <div className="text-[20px]">
                  {currencyName}{" "}
                  {!isNaN(
                    (totalBaseRate +
                      totalExtrasRate +
                      carDropObj.locationRate) *
                      1.05 *
                      currencyRate
                  )
                    ? (
                        (totalBaseRate +
                          totalExtrasRate +
                          carDropObj.locationRate) *
                        1.05 *
                        currencyRate
                      ).toFixed(2)
                    : (
                        (totalBaseRate + totalExtrasRate) *
                        1.05 *
                        currencyRate
                      ).toFixed(2)}
                </div>
              </div>
            )}

            {priceDetail ? (
              <>
                <div className="w-full flex justify-between items-center h-2 mb-1 px-8 pt-5">
                  <span className="text-[13px] font-semibold">Base rate</span>
                  <span className="text-[13px] font-semibold">
                    {currencyName + " " + (totalBaseRate * currencyRate).toFixed(2)}
                  </span>
                </div>
                <div className="w-full flex justify-between items-center h-2 mb-1  px-8 pt-5">
                  <span className="text-[13px] font-semibold">
                    Delivery charge
                  </span>
                  <span className="text-[13px] font-semibold">
                    {currencyName +
                      " " +
                      (carDropObj.locationRate * currencyRate).toFixed(2)}
                  </span>
                </div>
                <div className="w-full flex justify-between items-center h-2 mb-1  px-8 pt-5">
                  <span className="text-[13px] font-semibold">Add on's</span>
                  <span className="text-[13px] font-semibold">
                    {currencyName + " " + (totalExtrasRate * currencyRate).toFixed(2)}
                  </span>
                </div>
                <div className="w-full flex justify-between items-center h-2 mb-2  px-8 pt-5 ">
                  <span className="text-[13px] font-semibold">Tax</span>
                  <span className="text-[13px] font-semibold">
                    {currencyName +
                      " " +
                      (
                        (totalBaseRate +
                          totalExtrasRate +
                          carDropObj.locationRate) *
                        0.05 *
                        currencyRate
                      ).toFixed(2)}
                  </span>
                </div>
              </>
            ) : (
              <></>
            )}

            <div className="flex justify-between mx-8 font-bold cursor-pointer text-[#3659B2] text-[14px] ">
              {priceDetail ? (
                <div onClick={() => setPriceDetail(!priceDetail)}>
                  Hide Price Details
                </div>
              ) : (
                <div onClick={() => setPriceDetail(!priceDetail)}>
                  Show Price Details
                </div>
              )}
            </div>
            <div className="flex justify-center items-center my-6">
              <button
                className="w-[70%] bg-[#3659B2] text-[20px] font-bold p-1 text-white"
                onClick={() => givePrice()}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default SubscriptionLast;
