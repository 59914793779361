import React from "react";
import FaqHeading from "./FaqHeading";
import FaqContact from "./FaqContact";
import FaqLargeTile from "./FaqLargeTile";
import { allFaqData } from "./faqData";
import { allAdditionalData } from "./faqData";
import { useEffect } from "react";
import BreadcrumbAll from "../../utils/breadcrumb/BreadCrumbAll";


function FaqMain(){
    useEffect(()=>{
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },[])
    return(
        <div className="w-full">
            <BreadcrumbAll />
            <FaqHeading/>
            <div className="w-full flex pl-[10.27%] pr-[10.27%] py-8">
                <FaqContact/>
                <div className="w-[75%] px-5 max-sm:w-[100%]">
                    <span className="font-bold text-3xl text-[#0F0A34]">FAQs</span>
                    {allFaqData.map((ele)=><FaqLargeTile faqData={ele}/>)}
                    <div className="mt-6"></div>
                    <span className="font-bold text-3xl text-[#0F0A34] ">Additional Questions</span>
                    {allAdditionalData.map((ele)=><FaqLargeTile faqData={ele}/>)}
                    
                </div>
            </div>
        </div>
    )
}
export default FaqMain;