import axios from "axios";
import { baseUrl } from "../../config";

export const pickPlaceFinder = async (pickLoc) => {
  if(pickLoc=="Dubai International Airport (DXB) (DXB) - Dubai - United Arab Emirates"){
    return true;
  }
    try {
      const response = await axios.post(`${baseUrl}/app/v1/google/:${pickLoc}`,{
        headers: {
            'Access-Control-Allow-Origin': '*',
          },
      });
  
      for (let i = 0; i < response.data.predictions.length; i++) {
        if (response.data.predictions[i].description === pickLoc) {
          return true;
        }
      }
  
      return false;
    } catch (error) {
      console.error(error);
      return false; // Handle the error and return false
    }
  };

 

  

  export const dropPlaceFinder = async (dropLoc) => {
    if(dropLoc=="Dubai International Airport (DXB) (DXB) - Dubai - United Arab Emirates"){
      return true;
    }
    try {
      const response = await axios.post(`${baseUrl}/app/v1/google/:${dropLoc}`,{
      });
  
      for (let i = 0; i < response.data.predictions.length; i++) {
        if (response.data.predictions[i].description === dropLoc) {
          return true;
        }
      }
  
      return false;
    } catch (error) {
      console.error(error);
      return false; // Handle the error and return false
    }
  };