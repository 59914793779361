import Blogs from "../../components/Blogs/Blogs"
import HeroBackgroundBanner from "../../components/HeroBackgroundBanner/HeroBackgoundBanner"
import MyFAQ from "../../components/MyFAQ/MyFAQ"
import MyFooter from "../../components/MyFooter/MyFooter"
import Navbar from "../../components/Navbar/Navbar"
import SubscrptionSearchEngine from "../../components/SearchEngine/SubscriptionSearchEngine"
import PromotionBanner from "../../components/StripBanner/PromotionBanner"
import Testimonial from "../../components/Testimnial/Testimnial"
import TextContentStrip from "../../components/TextContentStrip/TextContentStrip"
import TopBrands from "../../components/TopBrands/TopBrands"
import VehicleFleet from "../../components/VehicleFleet/VehicleFleet"

const NewSubscriptionPage = () => {
    return (
        <div>
            <Navbar />
            <HeroBackgroundBanner searchEngine={<SubscrptionSearchEngine />} />
            <PromotionBanner />
            <TextContentStrip />
            <VehicleFleet />
            <TopBrands bgColor={"#EFEFEF"} />
            {/* <HowItWorks /> */}
            <Testimonial />
            <MyFAQ />
            <Blogs />
            <MyFooter />
        </div>
    )
}

export default NewSubscriptionPage
