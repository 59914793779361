import React, { useEffect } from "react";

import PopularBrand1 from "./brands logo/mazda.png";
import PopularBrand2 from "./brands logo/Kia.png";
import PopularBrand3 from "./brands logo/Volkswagen.png";
import PopularBrand4 from "./brands logo/Mercedes.png";
import PopularBrand5 from "./brands logo/Gac.png";
import PopularBrand6 from "./brands logo/Hyundai.png";
import PopularBrand7 from "./brands logo/Audi.png";
import PopularBrand8 from "./brands logo/Toyota.png";
import PopularBrand9 from "./brands logo/GMC.png";
import PopularBrand10 from "./brands logo/Chevrolet.png";
import PopularBrand11 from "./brands logo/Skoda.png";
import PopularBrand12 from "./brands logo/Exeed.png";
import PopularBrand13 from "./brands logo/MG.png";
import PopularBrand14 from "./brands logo/Fiat.png";
import PopularBrand15 from "./brands logo/Geely.png";
import PopularBrand16 from "./brands logo/Alfa Romeo.png";
import PopularBrand17 from "./brands logo/1.png";
import PopularBrand18 from "./brands logo/2.png";
import PopularBrand19 from "./brands logo/3.png";
import PopularBrand20 from "./brands logo/4.png";
import PopularBrand21 from "./brands logo/5.png";
import PopularBrand22 from "./brands logo/6.png";
import PopularBrand23 from "./brands logo/7.png";
import PopularBrand24 from "./brands logo/8.png";
import PopularBrand25 from "./brands logo/9.png";
import PopularBrand26 from "./brands logo/10.png";
import PopularBrand27 from "./brands logo/11.png";
import PopularBrand28 from "./brands logo/12.png";
import PopularBrand29 from "./brands logo/13.png";
import PopularBrand30 from "./brands logo/14.png";
import PopularBrand31 from "./brands logo/15.png";
import PopularBrand32 from "./brands logo/16.png";

import BrandCard from "./BrandCard";
import { Helmet } from "react-helmet";
import BreadcrumbAll from "../../utils/breadcrumb/BreadCrumbAll";

let popularBrandArray = [
  {
    brandImg: PopularBrand1,
    brandName: "Mazda",
    modelName: "",
    offers: 5,
    alt: "Rent Mazda Cars in Dubai",
  },
  {
    brandImg: PopularBrand2,
    brandName: "Kia",
    modelName: "",
    offers: 5,
    alt: "Rent Kia Cars in Dubai",
  },
  {
    brandImg: PopularBrand3,
    brandName: "Volkswagen",
    modelName: "",
    offers: 6,
    alt: "Rent Volkswagen Cars in Dubai",
  },
  {
    brandImg: PopularBrand4,
    brandName: "Mercedes",
    modelName: "",
    offers: 2,
    alt: "Rent Mercedes Cars in Dubai",
  },
  {
    brandImg: PopularBrand5,
    brandName: "Gac",
    modelName: "",
    offers: 8,
    alt: "Rent Gac Cars in Dubai",
  },
  {
    brandImg: PopularBrand6,
    brandName: "Hyundai",
    modelName: "",
    offers: 6,
    alt: "Rent Hyundai Cars in Dubai",
  },
  {
    brandImg: PopularBrand7,
    brandName: "Audi",
    modelName: "",
    offers: 8,
    alt: "Rent Audi Cars in Dubai",
  },
  {
    brandImg: PopularBrand8,
    brandName: "Toyota",
    modelName: "",
    offers: 3,
    alt: "Rent Toyota Cars in Dubai",
  },
  {
    brandImg: PopularBrand9,
    brandName: "GMC",
    modelName: "",
    offers: 8,
    alt: "Rent GMC Cars in Dubai",
  },
  {
    brandImg: PopularBrand10,
    brandName: "Chevrolet",
    modelName: "",
    offers: 4,
    alt: "Rent Chevrolet Cars in Dubai",
  },
  {
    brandImg: PopularBrand11,
    brandName: "Skoda",
    modelName: "",
    offers: 4,
    alt: "Rent Skoda Cars in Dubai",
  },
  {
    brandImg: PopularBrand12,
    brandName: "Exeed",
    modelName: "",
    offers: 8,
    alt: "Rent Exeed Cars in Dubai",
  },
  {
    brandImg: PopularBrand13,
    brandName: "MG",
    modelName: "",
    offers: 2,
    alt: "Rent MG Cars in Dubai",
  },
  {
    brandImg: PopularBrand14,
    brandName: "Fiat",
    modelName: "",
    offers: 9,
    alt: "Rent Fiat Cars in Dubai",
  },
  {
    brandImg: PopularBrand15,
    brandName: "Geely",
    modelName: "",
    offers: 3,
    alt: "Rent Geely Cars in Dubai",
  },
  {
    brandImg: PopularBrand16,
    brandName: "Alfa Romeo",
    modelName: 1,
    offers: "",
    alt: "Rent Alfa Romeo Cars in Dubai ",
  },

  {
    brandImg: PopularBrand17,
    brandName: "Rolls Royce",
    modelName: "",
    offers: 2,
    alt: "Rent Rolls Royce Cars in Dubai",
  },
  {
    brandImg: PopularBrand18,
    brandName: "Porsche",
    modelName: "",
    offers: 9,
    alt: "Rent Porsche Cars in Dubai",
  },
  {
    brandImg: PopularBrand19,
    brandName: "Volvo",
    modelName: "",
    offers: 3,
    alt: "Rent Volve Cars in Dubai",
  },
  {
    brandImg: PopularBrand20,
    brandName: "Mini",
    modelName: 1,
    offers: "",
    alt: "Rent Mini Cars in Dubai",
  },
  {
    brandImg: PopularBrand21,
    brandName: "Mitsubishi",
    modelName: "",
    offers: 2,
    alt: "Rent Mitsubishi Cars in Dubai",
  },
  {
    brandImg: PopularBrand22,
    brandName: "Lexus",
    modelName: "",
    offers: 9,
    alt: "Rent Lexus Cars in Dubai",
  },
  {
    brandImg: PopularBrand23,
    brandName: "Jaguar",
    modelName: "",
    offers: 3,
    alt: "Rent Jaguar Cars in Dubai",
  },
  {
    brandImg: PopularBrand24,
    brandName: "Kaiyi",
    modelName: 1,
    offers: "",
    alt: "Rent Kaiyi Cars in Dubai",
  },
  {
    brandImg: PopularBrand25,
    brandName: "Ford",
    modelName: "",
    offers: 2,
    alt: "Rent Ford Cars in Dubai",
  },
  {
    brandImg: PopularBrand26,
    brandName: "Bentley",
    modelName: "",
    offers: 9,
    alt: "Rent Bentley Cars in Dubai",
  },
  {
    brandImg: PopularBrand27,
    brandName: "Austin Martin",
    modelName: "",
    offers: 3,
    alt: "Rent Austin Martin Cars in Dubai",
  },
  {
    brandImg: PopularBrand28,
    brandName: "Land Rover",
    modelName: 1,
    offers: 4,
    alt: "Rent Land Rover Cars in Dubai",
  },
  {
    brandImg: PopularBrand29,
    brandName: "Renault",
    modelName: "",
    offers: 9,
    alt: "Rent Renault Cars in Dubai",
  },
  {
    brandImg: PopularBrand30,
    brandName: "Nissan",
    modelName: "",
    offers: 3,
    alt: "Rent Nissan Cars in Dubai",
  },
  {
    brandImg: PopularBrand31,
    brandName: "Tesla",
    modelName: 1,
    offers: "",
    alt: "Rent Tesla Cars in Dubai",
  },
  {
    brandImg: PopularBrand32,
    brandName: "Cadillac",
    modelName: "",
    offers: 3,
    alt: "Rent Cadillac Cars in Dubai cadillac",
  },
];
const BrandsPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Cars On Rent by Brand Across UAE | WTi Car Rental</title>

        <meta
          name="description"
          content="Explore cars from 30+ auto brands available for car rental across UAE. Search your dream car to rent and drive like; Audi, Mercedes, Nissan, Kia, MG, Hyundai, BMW, etc."
        />
        <meta
          name="keywords"
          content="car rental by brands, car rental brands, car rental brands in dubai, cars on rent by brands, explore car rental brands, search car rental brands"
        />

        <meta name="revisit after" content="7 days" />
        <meta name="robots" content="follow, index" />
        <meta name="language" content="English" />
        <meta name="country" content="United Arab Emirates (UAE)" />
        <meta name="copyright" content="WTi Rent A Car LLC" />
        <meta name="publisher" content="wticarrental.ae" />
        <meta name="distribution" content="Global" />

        <link rel="canonical" href="https://www.wticarrental.ae/brands" />

        <meta
          property="og:title"
          content="Cars on Rent by Brand in Dubai, UAE | WTi Car Rental"
        />
        <meta
          property="og:description"
          content="Explore cars from 30+ auto brands available for car rental in Dubai, UAE. Search your dream car to rent and drive like; Audi, Mercedes, Nissan, Kia, MG, Hyundai, BMW, etc."
        />
        <meta property="og:image" content="" />
        <meta property="og:url" content="https://www.wticarrental.ae/brands" />
      </Helmet>
      <BreadcrumbAll />
      <div className="bg-[#f8f8f8] mb-5">
        <div className="w-full px-[9.27%] max-sm:px-0">
          <div className="flex flex-col gap-5">
            <div className="flex justify-center items-center flex-col gap-5 py-10 px-2">
              <div className="flex justify-center items-center flex-col gap-2">
                <h1 className="text-[1.8rem] text-[#373737]">
                  Explore rental cars by brand
                </h1>
              </div>
              <div className="w-full md:w-9/12">
                <p className="text-[15px] text-center text-[#373737]">
                  If you're looking to experience driving a particular car brand
                  model in the UAE, you're at the right place. WTi Car Rental
                  offers the most extensive array of rental cars available in
                  the UAE. Listed below are cars available for rent by every
                  auto brand. Whether it's vehicles from Ferrari, Lamborghini,
                  Rolls Royce, Hyundai, Toyota, Honda, Kia, and more.
                </p>
              </div>
            </div>
            <div className="flex flex-wrap">
              {popularBrandArray.map((el, index) => {
                return <BrandCard brand={el} key={index} />;
              })}
            </div>

            <div className="flex flex-col gap-5 my-4">
              <div>
                <h2 className="text-[22px] text-[#1c1c1c]">
                  Why are cars on rent by brand
                </h2>
                <p className="text-base text-[#373737]">
                  Opting for car rental provides a reliable and safe means of
                  transportation, affording you the luxury of traveling in your
                  private vehicle. When considering car rental, do you typically
                  choose a company or brand with which you are unfamiliar, or do
                  you prefer one you have prior experience with?
                </p>
              </div>
              <div>
                <h2 className="text-[22px] text-[#1c1c1c]">
                  Renting your favorite brand
                </h2>
                <p className="text-base text-[#373737] mt-2">
                  The rising popularity of brand-based car rentals can be
                  attributed to the simplified selection process it offers when
                  choosing a rental vehicle. Most rental options feature
                  well-known models from reputable automotive brands,
                  streamlining the decision-making process.
                </p>
                <p className="text-base text-[#373737] mt-2">
                  Whether individuals are traveling for business or leisure, the
                  need for a temporary vehicle during trips abroad is a common
                  requirement. Additionally, many individuals prefer to avoid
                  the responsibilities of car ownership and maintenance, making
                  car rentals a convenient alternative.
                </p>
                <p className="text-base text-[#373737] mt-2">
                  Discover a wide range of rental cars available for your
                  enjoyment throughout the UAE. Explore your future rental car
                  options today at WTicarrental.ae!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandsPage;
