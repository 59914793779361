import React, { useEffect, useState } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import ScheduleImg from "../../images/schedule_img.png";
import "./index.css";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useSelector } from "react-redux";
import { RxCross2 } from "react-icons/rx";

const MyCustomDatePicker = (props) => {
  const modifyBookingObj = useSelector(
    (store) => store.modifyBookingSlice.modifyDetails
  );

  const isEnroute =
    modifyBookingObj?.reservationStatus == "Enroute" ? true : false;
    
  const {
    selectDate,
    setSelectDateRange,
    setIsCalenderOpen,
    startDateHour,
    setStartDateHour,
    startDateMinute,
    setStartDateMinute,
    endDateHour,
    setEndDateHour,
    endDateMinute,
    setEndDateMinute,
    pickUpDate,
  } = props;

  const optionsHour = [];
  const optionsMin = [];

  for (let i = 1; i <= 24; i++) {
    optionsHour.push({
      value: i.toString(),
      label: i.toString(),
    });
  }
  for (let i = 1; i <= 60; i++) {
    optionsMin.push({
      value: i.toString(),
      label: i.toString(),
    });
  }

  const deviceInnerWidth = window.innerWidth;
  const [count, setCount] = useState(0);
  const minDate = new Date(pickUpDate.startDate);

  const handleChangeStartDateHour = (event) => {
    event.preventDefault();
    if (isEnroute) {
      setStartDateHour(startDateHour);
    } else {
      setStartDateHour(event.target.value);
    }
  };
  const handleChangeStartDateMinute = (event) => {
    event.preventDefault();
    if (isEnroute) {
      setStartDateMinute(startDateMinute);
    } else {
      setStartDateMinute(event.target.value);
    }
  };
  const handleChangeEndDateHour = (event) => {
    event.preventDefault();
    setEndDateHour(event.target.value);
  };
  const handleChangeEndDateMinute = (event) => {
    event.preventDefault();
    setEndDateMinute(event.target.value);
  };

  useEffect(() => {
    document.addEventListener("dblclick", myHandleClickOutSide, true);
    return () => {
      document.removeEventListener("dblclick", myHandleClickOutSide, false);
    };
  }, []);

  const myHandleClickOutSide = (event) => {
    if (
      props.calenderWrapperRef.current &&
      !props.calenderWrapperRef.current.contains(event.target)
    ) {
      setIsCalenderOpen(false); 
    }
  };

  const handleRangeChange = (ranges) => {
    if (isEnroute) {
      setSelectDateRange({
        startDate: new Date(modifyBookingObj?.travelItenary?.pickupDate),
        endDate: ranges.selection.endDate,
        key: "selection",
      });
      // setIsCalenderOpen(false); 
      // window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" })
    } else {
      setSelectDateRange(ranges.selection);
      // setIsCalenderOpen(false); 
      // window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" })
    }

    if (count == 0) {
      setCount(1);
    }

    if (count == 1) {
      setCount(0);
    }
  };


  const SelectDropdown = ({
    value,
    label,
    handleDropdownChange,
    dropdownArr,
  }) => {
    return (
      <FormControl sx={{ m: 1, minWidth: 60 }}>
        <InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={value}
          label={label}
          onChange={handleDropdownChange}
        >
          {dropdownArr.length &&
            dropdownArr.map((item, index) => {
              return (
                <MenuItem value={item.value} key={index}>
                  {item.value}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    );
  };

  return (
    <div
      className="bg-[#ffffff] rounded-[12px] md:w-[600px] shadow-[0px_3px_30px_0px_#00000029]"
      id="date-range-picker-wrapper"
    >
      <div className="my-calender-wrapper">
        <div className="w-full pt-[12px] pb-[40px] px-[20px] "> 
        <RxCross2
                    className="hover:cursor-pointer absolute left-2 text-[18px] text-[#898989]"
                    onClick={() => {setIsCalenderOpen(false)}}
                  />
          <DateRange
            ranges={[selectDate]}
            months={deviceInnerWidth > 620 ? 2 : 1}
            onChange={handleRangeChange}
            direction="horizontal"
            rangeColors={["#0a60d17c"]}
            minDate={minDate}
          />
        </div>
        <div className="flex justify-between bg-[#F5F5F5] px-[20px] py-[10px]">
          <div className="flex gap-2 items-center">
            <img src={ScheduleImg} alt="" />
            <span className="whitespace-nowrap">Pick up</span>
            <SelectDropdown
              value={startDateHour}
              label={"Hour"}
              handleDropdownChange={handleChangeStartDateHour}
              dropdownArr={optionsHour}
            />
            <span> : </span>
            <SelectDropdown
              value={startDateMinute}
              label={"Min"}
              handleDropdownChange={handleChangeStartDateMinute}
              dropdownArr={optionsMin}
            />
          </div>
          <div className="flex gap-2 items-center">
            <img src={ScheduleImg} alt="" />
            <span className="whitespace-nowrap">Drop off</span>
            <SelectDropdown
              value={endDateHour}
              label={"Hour"}
              handleDropdownChange={handleChangeEndDateHour}
              dropdownArr={optionsHour}
            />
            <span> : </span>
            <SelectDropdown
              value={endDateMinute}
              label={"Min"}
              handleDropdownChange={handleChangeEndDateMinute}
              dropdownArr={optionsMin}
            />
          </div>
        </div>
        {/* <div className="px-[60px] py-[16px]">
          <p>All dates are in the outbound station’s local time</p>
        </div> */}
      </div>
    </div>
  );
};

export default MyCustomDatePicker;
