import { createSlice } from "@reduxjs/toolkit";


const insuranceSlice = createSlice({
    name:"insuranceSlice",
    initialState:{
        insuranceArr:[],
        allEquipments:[],
    },
    reducers:{
        addInsuranceArr:(state,action)=>{
            state.insuranceArr = action.payload;
        },  
        emptyInsuranceArr:(state,action)=>{
            state.insuranceArr.length=0;
        },
        addAllEquipments:(state,action)=>{
            state.allEquipments = action.payload;
        },
        removeAllEquipments:(state,action)=>{
            state.allEquipments.length = 0;
        }

    }
})

export const {addInsuranceArr,emptyInsuranceArr,addAllEquipments,removeAllEquipments} = insuranceSlice.actions;

export default insuranceSlice.reducer;