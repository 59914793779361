import React from 'react';
import VerifiedDocumentImg from "../../images/verified_document_img.svg"
import UaeResidentImg from "../../images/uae_resident_img.svg"
import UaeVisitorsImg from "../../images/uae_visitors_img.svg"
import "./index.css"

const Documentation = () => {
    return (
        <div className='py-[4rem] px-3 bg-[#E8EDEE]'>
            <div className='w-full xl:w-[1200px] mx-auto h-full'>
                <div className='flex flex-col   gap-8 items-center w-full '>
                    <div className='bg-gradient-to-r from-[#0077B6] to-[#00B4D8] py-2 px-4 rounded-[8px]'>
                        <span className='text-[#fff] font-medium leading-[20px]'>Enjoy Our Ride</span>
                    </div>
                    <div className='flex flex-col items-center gap-4'>
                        <h2 className='text-[32px] text-center md:text-[40px] text-[#0077B6] leading-[40px] font-semibold'>Our Vehicle Fleet</h2>
                        <div>
                            <p className='text-[#6A6A6A] text-center text-[16px] leading-[24px] font-normal'>
                                Driving your dreams to reality with an exquisite fleet of versatile vehicles for <br />
                                unforgettable journeys.
                            </p>
                        </div>
                    </div>
                    <div className='flex justify-between gap-8 flex-wrap items-center w-full'>
                        <div className='grow'>
                            <div className='flex flex-col  rounded-[8px] w-full'>
                                <div className='py-2 flex justify-center items-center bg-[#ffffff] rounded-t-[8px]'>
                                    <span className='text-[32px] text-center md:text-[24px] text-[#0077B6] leading-[40px] font-semibold'>
                                        For UAE Residents
                                    </span>
                                </div>
                                <div className='flex justify-between items-center py-4 rounded-b-[8px] bg-no-repeat bg-cover bg-image bg-document-image'>
                                    <div className='grow-[1]'>
                                        <img src={UaeResidentImg} alt="" />
                                    </div>
                                    <div className='grow-[2]'>
                                        <ul className='flex flex-col gap-2'>
                                            <li className='flex gap-1 items-center'>
                                                <img src={VerifiedDocumentImg} alt="" />
                                                <span className='text-[18px] text-[#fff] font-medium leading-[24px]'>UAE Driving License</span>
                                            </li>
                                           
                                            <li className='flex gap-1 items-center'>
                                                <img src={VerifiedDocumentImg} alt="" />
                                                <span className='text-[18px] text-[#fff] font-medium leading-[24px]'>Emirates ID</span>
                                            </li>
                                            <li>
                                                <span className='text-[18px] text-[#fff] font-medium leading-[24px]'>(Residential Visa may be acceptable)</span>
                                            </li>
                                           
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='grow'>
                            <div className='flex flex-col  rounded-[8px] w-full'>
                                <div className='py-2 flex justify-center items-center bg-[#ffffff] rounded-t-[8px]'>
                                    <span className='text-[32px] text-center md:text-[24px] text-[#0077B6] leading-[40px] font-semibold'>
                                        For UAE Visitors
                                    </span>
                                </div>
                                <div className='flex justify-between items-center py-4 rounded-b-[8px] bg-no-repeat bg-cover bg-image bg-document-image'>
                                    <div className='grow-[1]'>
                                        <img src={UaeVisitorsImg} alt="" />
                                    </div>
                                    <div className='grow-[2]'>
                                        <ul className='flex flex-col gap-2'>
                                            <li className='flex gap-1 items-center'>  
                                                <img src={VerifiedDocumentImg} alt="" />
                                                <span className='text-[18px] text-[#fff] font-medium leading-[24px]'>Passport</span>
                                            </li>
                                            <li className='flex gap-1 items-center'>  
                                                <img src={VerifiedDocumentImg} alt="" />
                                                <span className='text-[18px] text-[#fff] font-medium leading-[24px]'>Visit Visa</span>
                                            </li>
                                            <li className='flex gap-1 items-center'>  
                                                <img src={VerifiedDocumentImg} alt="" />
                                                <span className='text-[18px] text-[#fff] font-medium leading-[24px]'>International Driving License</span>
                                            </li>
                                            <li className='flex gap-1 items-center'>  
                                                <img src={VerifiedDocumentImg} alt="" />
                                                <span className='text-[18px] text-[#fff] font-medium leading-[24px]'>Home Country Driving License</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Documentation
