import React from 'react'
import ContactSideImg from "../../images/contact_side_img.svg"
import ContactCallImg from "../../images/contact_call_icon.svg"
import ContactEmailImg from "../../images/contact_email_icon.svg"
import ContactPointerImg from "../../images/contact_pointer_icon.svg"

const ContactForm = () => {
    return (
        <div className='w-full py-[3rem] bg-gradient-to-tr from-[#E8EDEE] to-[#C3DCE1]  shadow-[0px_3px_20px_20px_#BABABA1F] mt-8 px-6 rounded-[12px]'>
            <div className='flex flex-wrap justify-between items-center w-full'>
                <div className='md:w-6/12'>
                    <div className='flex items-start flex-wrap flex-col w-full gap-4'>
                        <div className='w-full'>
                            <img src={ContactSideImg} className='w-[90%]' alt="" />
                        </div>
                        <div className='w-full'>
                            <ul className='flex flex-col gap-2'>
                                <li className='flex gap-2 items-center'>
                                    <img src={ContactCallImg} alt="" />
                                    <span className='text-[#707070] text-[16px] leading-[16px] font-medium'>+971 56 909 7524,</span>
                                    <span className='text-[#707070] text-[16px] leading-[24px] font-medium'> +971 50 264 1477</span>
                                </li>
                                <li className='flex gap-2 items-center'>
                                    <img src={ContactEmailImg} alt="" />
                                    <span className='text-[#707070] text-[16px] leading-[16px] font-medium'>s.mubeen@wtimobility.ae</span>
                                </li>
                                <li className='flex gap-2 items-center'>
                                    <img src={ContactPointerImg} alt="" />
                                    <span className='text-[#707070] text-[16px] leading-[24px] font-medium'>Smark 9, Zabeel Business Center Building,<br /> 302-3 Dubai United Arab Emirates</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='md:w-6/12 mt-3 md:mt-0'>
                    <div className='flex flex-col items-start gap-5'>
                        <h2 className='font-semibold leading-[44px] text-[40px] bg-clip-text bg-gradient-to-b from-[#03045E] to-[#00D4FF] text-transparent'>Lets Talk</h2>
                        <span>To request a quote or want to meet up for coffee, contact us directly or fill out the form and we will get back to you promptly.</span>
                    </div>
                    <form className='flex flex-col gap-[16px] mt-4'>
                        <div className='w-full flex flex-col gap-3'>
                            <label className='text-[#707070] text-[16px] leading-[16px] font-medium'>Your Name</label>
                            <input className='rounded-[12px] py-3 px-6 focus:outline-none' type="text" />
                        </div>
                        <div className='w-full flex flex-col gap-3'>
                            <label className='text-[#707070] text-[16px] leading-[16px] font-medium'>Your Email</label>
                            <input className='rounded-[12px] py-3 px-6 focus:outline-none' type="text" />
                        </div>
                        <div className='w-full flex flex-col gap-3'>
                            <label className='text-[#707070] text-[16px] leading-[16px] font-medium'>Your Message</label>
                            <textarea className='rounded-[12px] py-3 px-6 focus:outline-none' name="" id=""></textarea>
                        </div>
                        <button className='rounded-[12px] text-[#fff] text-[16px] font-semibold bg-gradient-to-r from-[#EB840C] to-[#EF5600] py-3 px-4 shadow-[0px_3px_12px_0px_#0000001F] mt-2'>Send Message</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ContactForm
