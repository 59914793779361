import React from "react";
import VehicleHighlights from "./VehicleHighlights";
import VehicleFeature from "./VehicleFeature";
import VehicleDescription from "./VehicleDescription";

const Vehicles = () => {
  return (
    <>
      <VehicleHighlights />
      <VehicleFeature />
      <VehicleDescription />
    </>
  );
};

export default Vehicles;
