import React from 'react'
import TopBrandsCard from './TopBrandsCard'
import Marquee from "react-fast-marquee";

const TopBrands = ({ bgColor }) => {
    return (
        <div className={`py-[4rem] bg-[${bgColor}] overflow-hidden px-3`}>
            <div className='flex flex-col items-center gap-8 w-full'>
                <div className='bg-gradient-to-r from-[#0077B6] to-[#00B4D8] py-2 px-4 rounded-[8px]'>
                    <span className='text-[#fff] font-medium leading-[20px]'>Top Brands</span>
                </div>
                <div className='flex flex-col items-center gap-4'>
                    <h2 className='text-[32px] text-center md:text-[40px] text-[#222222] leading-[40px] font-semibold'>Rent A Car From Top Brands</h2>
                    <div>
                        <p className='text-[#6A6A6A] text-center text-[16px] leading-[24px] font-normal'>
                            Driving your dreams to reality with an exquisite fleet of versatile vehicles for <br />
                            unforgettable journeys.
                        </p>
                    </div>
                </div>
                <div className='w-full'>

                    <Marquee>
                        {
                            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].map((slide, index) => {
                                return (

                                    <TopBrandsCard key={index} />
                                )
                            })
                        }
                    </Marquee>
                </div>

            </div>
        </div>
    )
}

export default TopBrands
