import React from "react";
import PAndC from "./PAndC";


function ExtraCardCont(){
    return(
        <React.Fragment>
            <div className="w-full pt-4">
                <span className="text-[#0F0A34] font-semibold text-lg leading-6">
                Choose Extras
                </span>
                <PAndC/>
            </div>


        </React.Fragment>
    )
}

export default ExtraCardCont