import React from "react";

function AboutAspire() {
  return (
    <div className="w-full flex flex-col justify-start items-center mt-20 mb-20">
      <span className="text-xl text-[#0F0A34] leading-8 font-semibold mb-4"><h4>Who we aspire to be</h4></span>
      <span className=" px-56 max-sm:px-4 text-center font-light text-sm">
        We are building a culture where personal and professional growth is just
        as necessary as business growth. We trust amazing people to do amazing
        things. At WTi, you have ownership of your own work! Enabling you to
        learn fast and grow faster!! We work hard to build a diverse and
        inclusive environment where you feel you belong.
      </span>
    </div>
  );
}

export default AboutAspire;
