import React from "react";
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import aPic from "./av.png";
import vPic from "./vl.png";
import hpic from "./hm.png";
import SimpleImageSlider from "react-simple-image-slider";

function AboutTeam() {

  const images = [
    { url: aPic },
    { url: vPic },
    { url: hpic },
   
  ];
  return (
    <div className="w-full pl-[10.27%] pr-[10.27%] max-sm:px-4 flex max-sm:flex-col-reverse max-sm:text-center justify-evenly items-center  mt-20">
      
      <div className="flex flex-col max-sm:mt-4">
        <span className="font-semibold text-2xl text-[#0F0A34] leading-8 mb-2">
        <h2>Leadership That Drives Excellence</h2>
        </span>
        <span className="text-left text-sm">
        The foresight of our seasoned leadership ensures a continuous focus on innovation,<br/>  curatingservices
that evolve with the world&#39;s commuting needs.
        </span>
        <span className=" font-semibold text-sm text-[#092C85] mt-2">Meet the leadership team <ArrowForwardOutlinedIcon className="text-sm"/></span>
      </div>
      <div className="rounded-md border">
        {/* <div className="w-[400px] h-[220px] border rounded-md bg-[#322F51] ">
            <img src={hpic} alt="" className=" object-cover  object-center w-full h-full rounded-md"/>

        </div> */}
        <SimpleImageSlider
        width={400}
        height={220}
        images={images}
        showBullets={true}
        autoPlay={true}
        // showNavs={true}
        
      />

      </div>
    </div>
  );
}

export default AboutTeam;
