import { createSlice } from "@reduxjs/toolkit";

const bookingInfoSlice = createSlice({
  name: "bookingInfoSlice",
  initialState: {
    pickupLocation: "",
    pickupMMDDYYYY: "",
    pickupTime: "",
    pickupDriverNeeded: true,
    dropLocation: "",
    dropMMDDYYYY: "",
    dropTime: "",
    dropDriverNeeded: true,
    Residency: "",
    Age: "",
    bookingObj: {},
    totalNumberOfDays: 0,
    needCarDeliveryAndPickupSerivce: true,
  },
  reducers: {
    toggleNeedCarDeliveryAndPickupSerivce: (state, action) => {
      state.needCarDeliveryAndPickupSerivce =
        !state.needCarDeliveryAndPickupSerivce;
    },
    switchOffNeedCarDeliveryAndPickupSerivce: (state, action) => {
      state.needCarDeliveryAndPickupSerivce = false;
    },
    savePickDate: (state, action) => {
      state.pickupMMDDYYYY = action.payload;
    },
    saveDropDate: (state, action) => {
      state.dropMMDDYYYY = action.payload;
    },
    saveBookingInfo: (state, action) => {
      state.pickupLocation = action.payload.pickupLocation;

      state.pickupTime = action.payload.pickupTime;
      state.dropLocation = action.payload.dropLocation;

      state.dropTime = action.payload.dropTime;
    },
    setTotalDays: (state, action) => {
      state.totalNumberOfDays = action.payload;
    },
    clearBookingData: (state, action) => {
      state.pickupLocation = "";
      state.pickupMMDDYYYY = action.payload["savePlusOne"];
      state.pickupTime = "10:00";
      state.dropLocation = "";
      state.dropMMDDYYYY = action.payload["savePlusTwo"];
      state.dropTime = "10:00";
      state.totalNumberOfDays = 0;
    },
  },
});

export const {
  saveBookingInfo,
  setTotalDays,
  clearBookingData,
  savePickDate,
  saveDropDate,
  toggleNeedCarDeliveryAndPickupSerivce,
  switchOffNeedCarDeliveryAndPickupSerivce
} = bookingInfoSlice.actions;
export default bookingInfoSlice.reducer;
