import React from 'react'
import CarBookingForSelfDrive from '../../../components/CarBooking/CarBookingForSelfDrive'

const BookingPage = () => {
    return (
        <div className='bg-[#EFEFEF]'>
            <div className='booking-page-wrapper px-3 py-7'>
                <CarBookingForSelfDrive />
            </div>
        </div>
    )
}

export default BookingPage
