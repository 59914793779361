export function getDaysInBetween(startDateStr, endDateStr) {
    // Parse the start and end dates from their string representations
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    // Calculate the difference in milliseconds
    const timeDifference = endDate - startDate;

    // Calculate the difference in days
    const daysDifference = timeDifference / (1000 * 3600 * 24);

    // Round to the nearest whole number to get the number of days
    return Math.round(daysDifference);
  }