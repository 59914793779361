import React from "react";
import PAndCCard from "./PAndCCard";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import PersonalInsurance from "./PersonalInsurance";

function PAndC(){
    const [extraObject,setExtraObject] = useState([]);
    const [personalInsurance,setPersonalInsurance] = useState([]);
    const [cdwCheck, setCdwCheck] = useState(false);
    const [personalInsuranceCheck, setPersonalInsuranceCheck] = useState(false);
    const insuranceArr = useSelector((store)=>store.insuranceSlice.insuranceArr);

      
    useEffect(()=>{

        setExtraObject([]);
        setExtraObject((prevExtraObject) => [...prevExtraObject,insuranceArr[0]]);
        setPersonalInsurance([insuranceArr[1]])
    }, [insuranceArr])
  
    
   
   
  
    return (
      <React.Fragment>
        <div className="w-full pt-4">
          <span className="font-semibold leading-5">
            Protection & Coverages
          </span>
          <div className="grid grid-cols-2 gap-4 mt-2 max-sm:grid-cols-1">
            {extraObject.map((e) => (
              <PAndCCard
                equipmentsObj={e}
                cdwCheck={cdwCheck}
                setCdwCheck={setCdwCheck}
              />
            ))}
            {personalInsurance.length > 0 && (
              <PersonalInsurance
                equipmentsObj={personalInsurance[0]}
                personalInsuranceCheck={personalInsuranceCheck}
                setPersonalInsuranceCheck={setPersonalInsuranceCheck}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
}

export default PAndC;