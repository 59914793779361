import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "userSlice",
  initialState: {
    firstName: "",
    lastName: "",
    contact: "",
    country: "",
    address: "",
    city: "",
    postalCode: "",
    emailId: "",
    userId:"",
  },
  reducers: {
    setUserId:(state,action)=>{
      state.userId = action.payload;
    },
    clearUserId:(state,action)=>{
      state.userId = "";
    },
    addFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    addLastName: (state, action) => {
      state.lastName = action.payload;
    },
    addContact: (state, action) => {
      state.contact = action.payload;
    },
    addCountry: (state, action) => {
      state.country = action.payload;
    },
    addAddress: (state, action) => {
      state.address = action.payload;
    },
    addCity: (state, action) => {
      state.city = action.payload;
    },
    addPostalCode: (state, action) => {
      state.postalCode = action.payload;
    },
    addEmailId: (state, action) => {
      state.emailId = action.payload;
    },
    clearUser:(state,action)=>{
      state.firstName = "";
      state.lastName = "";
      state.contact = "";
      state.country ="";
      state.address = "";
      state.city = "";
      state.postalCode = "";
      state.emailId = "";
    }
  },
});

export const {
  addAddress,
  addCity,
  addContact,
  addEmailId,
  addFirstName,
  addLastName,
  addPostalCode,
  addCountry,
  clearUser,
  setUserId,
  clearUserId
} = userSlice.actions;

export default userSlice.reducer;
