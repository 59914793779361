import React, { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import lexusImg from "./lexusAirport 1.svg";
import searchLogo from "./search.svg";
import calenderLogo from "./calendar.svg";
import fullNameLogo from "./user.svg";
import mailLogo from "./mail.svg";
import axios from "axios";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import phoneBannerImg from "./lexus-mobile-banner.svg";

import phoneLogo from "./phone.svg";

function AirportLexus() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const pickLoc = useRef(null);
  const dropLoc = useRef(null);
  const [selectedTime, setSelectedTime] = useState("10:00");
  const userName = useRef(null);
  const userEmail = useRef(null);
  const userPhone = useRef(null);


  useEffect(() => {
    // Function to update screen size state
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    // Add event listener to update screen size on window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //   const handleApiCall = async () => {

  //     try {
  //       let config = {
  //         method: "post",
  //         maxBodyLength: Infinity,
  //         url: `${baseUrl}/app/v1/google/:${pickLoction}`,
  //         headers: {
  //           'Access-Control-Allow-Origin': '*',
  //         },
  //       };

  //       axios
  //         .request(config)
  //         .then((response) => {

  //           let nArr = [];
  //           for (let i = 0; i < response.data.predictions.length; i++) {
  //             let str = response.data.predictions[i].description;
  //             nArr.push(str);
  //           }

  //           setArr(nArr);
  //         })
  //         .catch((error) => {

  //         });

  //     } catch (error) {
  //       console.error("Google API Error : " + error);
  //     }
  //   };
  const options = [
    { label: "00:30", value: "00:30" },
    { label: "01:00", value: "01:00" },
    { label: "01:30", value: "01:30" },
    { label: "02:00", value: "02:00" },
    { label: "02:30", value: "02:30" },
    { label: "03:00", value: "03:00" },
    { label: "03:30", value: "03:30" },
    { label: "04:00", value: "04:00" },
    { label: "04:30", value: "04:30" },
    { label: "05:00", value: "05:00" },
    { label: "05:30", value: "05:30" },
    { label: "06:00", value: "06:00" },
    { label: "06:30", value: "06:30" },
    { label: "07:00", value: "07:00" },
    { label: "07:30", value: "07:30" },
    { label: "08:00", value: "08:00" },
    { label: "08:30", value: "08:30" },
    { label: "09:00", value: "09:00" },
    { label: "09:30", value: "09:30" },
    { label: "10:00", value: "10:00" },
    { label: "10:30", value: "10:30" },
    { label: "11:00", value: "11:00" },
    { label: "11:30", value: "11:30" },
    { label: "12:00", value: "12:00" },
    { label: "12:30", value: "12:30" },
    { label: "13:00", value: "13:00" },
    { label: "13:30", value: "13:30" },
    { label: "14:00", value: "14:00" },
    { label: "14:30", value: "14:30" },
    { label: "15:00", value: "15:00" },
    { label: "15:30", value: "15:30" },
    { label: "16:00", value: "16:00" },
    { label: "16:30", value: "16:30" },
    { label: "17:00", value: "17:00" },
    { label: "17:30", value: "17:30" },
    { label: "18:00", value: "18:00" },
    { label: "18:30", value: "18:30" },
    { label: "19:00", value: "19:00" },
    { label: "19:30", value: "19:30" },
    { label: "20:00", value: "20:00" },
    { label: "20:30", value: "20:30" },
    { label: "21:00", value: "21:00" },
    { label: "21:30", value: "21:30" },
    { label: "22:00", value: "22:00" },
    { label: "22:30", value: "22:30" },
    { label: "23:00", value: "23:00" },
    { label: "23:30", value: "23:30" },
  ];

  const onFormSubmit = () => {};

  return (
    <div className="w-full  pl-[10.27%] pr-[10.27%] mt-1 bg-[#FBFBFB] py-6 max-sm:p-0">
      <div className="w-full   rounded-md drop-shadow-2xl flex bg-white my-4 py-6 max-sm:block max-sm:m-0 max-sm:p-0">
        <div className="offerImgDiv  flex justify-start items-center ">
          <img
            src={screenWidth > 640 ? lexusImg : phoneBannerImg}
            alt=""
            className=" aspect-auto w-[90%] max-sm:w-[100%]"
          />
        </div>
        <div className="offerFormDiv">
          <div className="w-full  flex flex-col items-start max-sm:flex max-sm:flex-col max-sm:items-center max-sm:bg-[#092C85] max-sm:py-4">
            <span className="font-semibold text-[#0F0A34] text-xl leading-8 max-sm:text-white max-sm:mb-2 ">
              Airport Transfer
            </span>
            <span className="font-normal text-[#0F0A34] text-sm leading-4 max-sm:text-white">
              Please provide us below details and we’ll reach out to you
            </span>
          </div>
          <div className="grid grid-cols-2 mt-6 gap-5 max-sm:flex max-sm:flex-col max-sm:items-center max-sm:px-2">
            <div className="pickInput  ">
              <div className="inputLabel flex items-center text-sm">
                Pick-up Location
              </div>
              <div className="pt-2 relative">
                <input
                  // img={searchLogo}
                  // ref={pickLoction}
                  value={pickLoc.value}
                  ref={pickLoc}
                  placeholder="Enter Pick up Location"
                  type="text"
                  className="border-[1px] border-[#C3C3C3] bg-[#EBEBEB] rounded-md h-10 w-56 pl-10 text-xs font-thin"
                ></input>
                <div
                  className="absolute inset-y-0 mt-2 left-0 pl-3  
                    flex items-center  
                    pointer-events-none "
                >
                  <img src={searchLogo} alt="" className="w-5" />
                </div>
              </div>
            </div>
            <div className="dropInput">
              <div className="inputLabel flex items-center text-sm">
                Drop Location
              </div>
              <div className="pt-2 relative">
                <input
                value={dropLoc.value}
                ref={dropLoc}
                  // img={searchLogo}
                  // ref={pickLoction}
                  placeholder="Enter Drop up Location"
                  type="text"
                  className="border-[1px] border-[#C3C3C3] bg-[#EBEBEB] rounded-md h-10 w-56 pl-10 text-xs font-thin"
                ></input>
                <div
                  className="absolute inset-y-0 mt-2 left-0 pl-3  
                    flex items-center  
                    pointer-events-none"
                >
                  <img src={searchLogo} alt="" className="w-5" />
                </div>
              </div>
            </div>
            <div className="dateInput">
              <div className="inputLabel flex items-center text-sm">
                Pick-up date
              </div>
              <div className="pt-2 relative">
                <input
                  type="date"
                  className="border-[1px] border-[#C3C3C3] bg-[#EBEBEB] rounded-md h-10 w-56 pl-10 text-xs font-thin"
                ></input>
                
                <div
                  className="absolute inset-y-0 mt-2 left-0 pl-3  
                    flex items-center  
                    pointer-events-none"
                >
                  <img src={calenderLogo} alt="" className="w-5" />
                </div>
              </div>
            </div>
            <div className="timeInput">
              <div className="inputLabel flex items-center text-sm">
                Pick-up time
              </div>
              <div className="pt-2 relative">
                <select
                  value={selectedTime} // You should use the 'value' prop to control the selected value
                  onChange={(e) => setSelectedTime(e.target.value)} // Add the onChange event handler
                  className="border-[1px] border-[#C3C3C3] bg-[#EBEBEB] rounded-md h-10 w-56 pl-10 text-xs font-thin"
                >
                  {options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <div className="absolute inset-y-0 mt-2 left-0 pl-3 flex items-center pointer-events-none">
                  <QueryBuilderIcon className="fill-[#8495C2]" />
                </div>
              </div>
            </div>

            <div className="fullNameInput">
              <div className="inputLabel flex items-center text-sm">
                Full name
              </div>
              <div className="pt-2 relative">
                <input
                  value={userName.value}
                  ref={userName}
                  placeholder="Full name"
                  type="text"
                  className="border-[1px] border-[#C3C3C3] bg-[#EBEBEB] rounded-md h-10 w-56 pl-10 text-xs font-thin"
                ></input>
                <div
                  className="absolute inset-y-0 mt-2 left-0 pl-3  
                    flex items-center  
                    pointer-events-none"
                >
                  <img src={fullNameLogo} alt="" className="w-5" />
                </div>
              </div>
            </div>
            <div className="emailInput">
              <div className="inputLabel flex items-center text-sm">Email</div>
              <div className="pt-2 relative">
                <input
                  value={userEmail.value}
                  ref={userEmail}
                  placeholder="Email address"
                  type="email"
                  className="border-[1px] border-[#C3C3C3] bg-[#EBEBEB] rounded-md h-10 w-56 pl-10 text-xs font-thin"
                ></input>
                <div
                  className="absolute inset-y-0 mt-2 left-0 pl-3  
                    flex items-center  
                    pointer-events-none"
                >
                  <img src={mailLogo} alt="" className="w-5" />
                </div>
              </div>
            </div>
            <div className="phoneInput">
              <div className="inputLabel flex items-center text-sm">Phone</div>
              <div className="pt-2 relative">
                <input
                  value={userPhone.value}
                  ref={userPhone}
                  placeholder="Phone number"
                  type="number"
                  className="border-[1px] border-[#C3C3C3] bg-[#EBEBEB] rounded-md h-10 w-56 pl-10 text-xs font-thin"
                ></input>
                <div
                  className="absolute inset-y-0 mt-2 left-0 pl-3  
                    flex items-center  
                    pointer-events-none"
                >
                  <img src={phoneLogo} alt="" className="w-5" />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6 max-sm:w-full  max-sm:flex max-sm:justify-center max-sm:my-2">
            <button
              className="bg-[#092C85] text-white px-8 py-2 rounded-md"
              onClick={onFormSubmit}
            >
              submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AirportLexus;
