import React, { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { adminUserSave } from "../../utils/loginSlices/loginSlice";
import axios from "axios";
import { baseUrl } from "../../config";

function AdminLogin() {
  const emailRef = useRef(null);
  const passRef = useRef(null);

  const [validation, setValidation] = useState(true);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleSubmit = () => {
    const data = {
      emailID: emailRef.current.value,
      password: passRef.current.value,
    };

    try {
      axios
        .post(`${baseUrl}/app/v1/user/adminLogin`, data)
        .then(function (response) {
          if (response.data.auth) {
            dispatch(adminUserSave(data.emailID));
            navigate("/admin");
          } else {
            setValidation(false);
            setTimeout(() => {
              setValidation(true);
            }, 5000);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      setValidation(false);
      setTimeout(() => {
        setValidation(true);
      }, 5000);
    }
  };

  const adminUser = useSelector((store) => store.loginSlice.adminUser);
  useEffect(() => {
    if (adminUser == "admin@wti.ae" || adminUser == "checkeradmin@wti.ae") {
      navigate("/admin");
    }
  }, []);

  return (
    <>
      <div className="bg-[#0f0a34] h-screen w-full flex justify-center items-center">
        <div className="bg-white rounded px-10 py-8 w-[30%] text-center">
          <div className="text-2xl font-bold">Admin Login</div>
          <div className="mt-3 text-left">
            <label htmlFor="email">Email Address</label>
            <input
              ref={emailRef}
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email..."
              className={`w-full outline-none focus:ring focus:ring-[#0f0a349f] rounded mt-2 p-1 border border-slate-300 ${
                validation
                  ? ""
                  : "border border-red-500 placeholder:text-red-500"
              }`}
              required
            />
          </div>
          <div className="mt-3 text-left">
            <label htmlFor="password">Password</label>
            <input
              ref={passRef}
              type="password"
              id="password"
              name="password"
              placeholder="Enter your password..."
              className={`w-full outline-none focus:ring focus:ring-[#0f0a349f] rounded mt-2 p-1 border border-slate-300 ${
                validation
                  ? ""
                  : "border border-red-500 placeholder:text-red-500"
              }`}
              required
            />
          </div>
          <div
            onClick={handleSubmit}
            className="px-3 py-2 bg-[#0f0a34] text-white rounded mt-6 cursor-pointer hover:ring hover:ring-[#0f0a349f]"
          >
            Submit
          </div>
          <div
            className={`text-sm mt-4 text-red-500 ${
              validation ? "hidden" : ""
            }`}
          >
            Invalid ID/Password
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminLogin;
