import React from 'react'
// import FeatureVehicleSlider from './FeatureVehicleSlider'
import { Link } from 'react-router-dom'
import "./index.css";
import FeatureVehicleSlider from './FeatureVehicleSlider';

const FeatureVehicle = () => {
    return (
        <div className='py-[4rem] px-3 bg-banner-image feature-vehicle-bg-img'>
            <div className='w-full xl:w-[1200px] mx-auto'>
                <div className='flex flex-col gap-[30px] items-center w-full'>
                    <div className='flex items-center flex-col md:gap-4 gap-1'>
                        <h2 className='text-[#fff] text-center font-normal text-[36px] md:text-[48px] md:leading-[48px]'>Featured Vehicles</h2>
                        <div>
                            <p className='text-[#fff] text-center font-normal text-[14px] md:text-[16px] leading-[20px] md:leading-[24px]'>Driving your dreams to reality with an exquisite fleet of versatile vehicles for <br /> unforgettable journeys.</p>
                        </div>
                    </div>
                    <div className='w-full'>
                        <FeatureVehicleSlider />
                    </div>
                    <div>
                        <Link>
                            <button className='rounded-[12px] text-[#fff] text-[16px] font-semibold bg-gradient-to-r from-[#EB840C] to-[#EF5600] py-2 px-4'>Show All</button>
                        </Link>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default FeatureVehicle
