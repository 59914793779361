import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch } from "react-redux";
import { toggleContactUs } from "../../utils/ModalSlice/modalToggleSlice";

function ContactModal() {
    const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    document.documentElement.style.overflow = "hidden";
    return () => {
      document.documentElement.style.overflow = "visible";
    };
  }, []);

  const contactToggler = ()=>{
    dispatch(toggleContactUs());
  }
  return (
    <div className="absolute w-[100%] h-screen bg-black bg-opacity-70  flex justify-center items-center mt-4">
      <div className="w-[35%] z-50 bg-[#ebebeb] border rounded-md">
        <div className=" w-full flex justify-between items-center p-4 px-6 border-b-[1px] border-slate-300 mb-6">
          <span className="font-medium text-2xl ">Contact Us</span>
          <CancelIcon onClick={contactToggler} className="cursor-pointer"/>
        </div>
        <div className="w-full flex flex-col items-center">
          <div className=" flex flex-col mb-4">
            <span className="text-base font-normal">Name*</span>
           <input type="text" className="w-[20rem] h-[2rem] border rounded-md"/>
          </div>
          <div className=" flex flex-col mb-4">
            <span className="text-base font-normal">Email*</span>
           <input type="text" className="w-[20rem] h-[2rem] border rounded-md"/>
          </div>
          <div className=" flex flex-col mb-4">
            <span className="text-base font-normal">Phone Number*</span>
           <input type="number" className=" appearance-none w-[20rem] h-[2rem] border rounded-md"/>
          </div>
          <div className=" flex flex-col mb-4">
            <span className="text-base font-normal">Message</span>
           <textarea className="border rounded-md" cols="37" rows="3"></textarea>
          </div>
          <div className=" flex flex-col mb-4">
            <button className="border rounded-md text-base font-medium bg-[#353A83] text-white hover:bg-white hover:text-[#353A83] px-4 py-2">Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactModal;
