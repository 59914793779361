import React, { useEffect, useId, useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { userSchema } from "../../validations/userValidation.js";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { tillFour } from "../../utils/stepperSlice.js";
import { addRes } from "../../utils/ReservationSlices/reservationSlice.js";
import { useNavigate } from "react-router-dom";
import { switchOffLoader, switchOnLoader } from "../../utils/loaderSlice.js";
import { addSessionID } from "../../utils/paymentSession.js";
import { togglePaymentPage } from "../../utils/paymentSession.js";
import { useRef } from "react";

import {
  addAddress,
  addCity,
  addContact,
  addCountry,
  addEmailId,
  addFirstName,
  addLastName,
  addPostalCode,
  setUserId as setUs,
} from "../../utils/userSlices/userSlice.js";
import { addTotalExtras } from "../../utils/extraSlice.js";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { allCountries } from "../../utils/countryData/allcountries.js";
import { toggleTermsAndCondition } from "../../utils/ModalSlice/modalToggleSlice.js";
import { switchOffHideHeaderFooter } from "../../utils/ModalSlice/modalToggleSlice.js";
import { addStripePaymentId } from "../../utils/paymentSession.js";
import { baseUrl } from "../../config.js";
import { addFinalAmountForPaymentFailed } from "../../utils/invoice/invoiceSlice.js";
import { FiAlertCircle } from "react-icons/fi";

//all stripe imports
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
// //component
// import CheckoutForm from "./CheckoutForm.jsx";
// import "./CheckoutForm.css";

// const stripePromise = loadStripe(
//   "pk_test_51O0jq2LW3jzjuzNowUF6BQqr82oYoPNqJA1W5WBjbvFeDkWFhiRVy4EZ3QVcyOhme1oLuzRGgPgllEtQsBjtFaYC00DBmxEhlb"
// );

//end stripe imports
function  PaymentFormUser() {
  const userDetails = useSelector((store) => store.userSlice);

  const firstNameInput = useRef(userDetails.firstName);
  const lastNameInput = useRef(userDetails.lastName);
  const emailInput = useRef(userDetails.emailId);
  const addressInput = useRef(userDetails.address);
  const cityInput = useRef(userDetails.city);
  const postalInput = useRef(userDetails.postalCode);
  const countryInput = useRef(userDetails.country);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [termsCheck, setTermsCheck] = useState(false);
  const [ageAndDocumentCheck, setAgeAndDocumentCheck] = useState(false);
  const [extraObjects, setExtraObjects] = useState([]);
  const extraArray = useSelector((store) => store.extraSlice.extraArr);
  const [userFirstNameVal, setFirstNameVal] = useState(false);
  const [userLastNameVal, setLastNameVal] = useState(false);
  const [userEmail, setUserEmail] = useState(false);
  const [userAddress, setUserAddress] = useState(false);
  const [userCity, setUserCity] = useState(false);
  const [userCountry, setUserCountry] = useState(false);
  const [userPhoneNumber, setUserPhoneNumber] = useState(false);
  const [postalCode, setPostalCode] = useState(false);
  const pickUpDes = useSelector(
    (store) => store.bookingInfoSlice.pickupLocation
  );
  const carObj = useSelector((store) => store.carDetailSlice.carDetailObj);

  const insuranceObject = useSelector(
    (store) => store.insuranceSlice.insuranceArr
  );
  const allEquipmentArray = useSelector(
    (store) => store.insuranceSlice.allEquipments
  );

  useEffect(() => {
    if (pickUpDes == "" || carObj == {}) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    firstNameInput.current.value = userDetails.firstName;
    lastNameInput.current.value = userDetails.lastName;
    emailInput.current.value = userDetails.emailId;
    addressInput.current.value = userDetails.address;
    cityInput.current.value = userDetails.city;
    postalInput.current.value = userDetails.postalCode;
    countryInput.current.value = userDetails.country;
    // setNum(userDetails.contact)
  }, []);

  useEffect(() => {
    dispatch(tillFour());
    dispatch(switchOffLoader());
    dispatch(switchOffHideHeaderFooter());
    window.scrollTo({ top: 20, left: 0, behavior: "smooth" });
    // calculateTotalExtraAmount();
    fillAllEquipments();
  }, []);

  const resStatus = useSelector(
    (store) => store.formControlSlice.reservationType
  );
  const dropLoc = useSelector((store) => store.bookingInfoSlice.dropLocation);
  const pickTime = useSelector((store) => store.bookingInfoSlice.pickupTime);
  const dropTime = useSelector((store) => store.bookingInfoSlice.dropTime);
  const totalDays = useSelector(
    (store) => store.bookingInfoSlice.totalNumberOfDays
  );

  const payNowStatus = useSelector((store) => store.carDetailSlice.payNow);
  const baseRate = useSelector((store) => store.invoiceSlice.baseRate);
  let carDeliveryCharge = useSelector(
    (store) => store.invoiceSlice.carDeliveryCharge
  );
  const excessInsurancePrice = useSelector(
    (store) => store.invoiceSlice.excessInsurancePrice
  );

  const extrasRate = useSelector((store) => store.invoiceSlice.extras);
  const currencySelected = useSelector(
    (store) => store.currencySlice.currentCurr
  );
  const conversionRate = useSelector(
    (store) => store.currencySlice.currentCurrRate
  );
  const pickupMMDDYYYY = useSelector(
    (store) => store.bookingInfoSlice.pickupMMDDYYYY
  );
  const dropMMDDYYYY = useSelector(
    (store) => store.bookingInfoSlice.dropMMDDYYYY
  );
  const resDays = useSelector(
    (store) => store.bookingInfoSlice.totalNumberOfDays
  );

  //createReservation
  const deliveryCharge = useSelector(
    (store) => store.pickDropSlice.carDropLocation.locationRate
  );
  const collectionCharge = useSelector(
    (store) => store.pickDropSlice.carPickLocation.locationRate
  );

  const currencyName = useSelector((store) => store.currencySlice.currentCurr);
  let currencyPrice = useSelector(
    (store) => store.currencySlice.currentCurrRate
  );

  const [countryCode, setCountryCode] = useState("ae");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [numFlag, setNumFlag] = useState(false);

  const openTermsModal = () => {
    dispatch(toggleTermsAndCondition());
  };

  useEffect(() => {
    if (!phoneNumber) {
      setNumFlag(false);
    }
  }, [phoneNumber]);

  const makeANewCustomer = async () => {
    try {
      let data = JSON.stringify({
        name: firstNameInput.current.value + " " + lastNameInput.current.value,
        phone: phoneNumber,
        email: emailInput.current.value,
        address: {
          line1: addressInput.current.value,
          postal_code: postalInput.current.value,
          city: cityInput.current.value,
          country: countryInput.current.value,
        },
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        // url: `http://localhost:3000/app/v1/billing/createCustomer`,
        url: `${baseUrl}/app/v1/billing/createCustomer`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      const result = await axios.request(config);

      if (result) {
        return result.data.customerID;
      } else {
        console.log("Error fetching customer id");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkoutPage = async (
    custId,
    custAmount,
    custCurr,
    reservationID,
    userID
  ) => {
    let data = JSON.stringify({
      customerId: custId,
      currency: custCurr,
      amount: custAmount,
      reservationID: reservationID,
      userID: userID,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/app/v1/billing/checkout`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios
      .request(config)
      .then((response) => {
        const sessionId = response.data.sessionID;
        dispatch(addSessionID(sessionId));
        //created session
        //session url
        //session id
        setTimeout(() => {
          dispatch(switchOffLoader());
          window.location = response.data.sessionURL;
        }, 500);
      })
      .catch((error) => {
        console.log("Error in checkout API: ", error);
      });
  };

  const givePrice = async () => {
    try {
      const isValid = await checkValidity();
      if (isValid) {
        dispatch(switchOnLoader());

        const stripeCustomerID = await makeANewCustomer();
        dispatch(addStripePaymentId(stripeCustomerID));
        dispatch(addTotalExtras(extraObjects));

        let data = JSON.stringify({
          firstName: firstNameInput.current.value,
          lastName: lastNameInput.current.value,
          contact: phoneNumber,
          country: countryInput.current.value,
          address: addressInput.current.value,
          city: cityInput.current.value,
          postalCode: postalInput.current.value,
          emailID: emailInput.current.value,
          password: "",
        });

        dispatch(addFirstName(firstNameInput.current.value));
        dispatch(addLastName(lastNameInput.current.value));
        dispatch(addContact(phoneNumber));
        dispatch(addCountry(countryInput.current.value));
        dispatch(addAddress(addressInput.current.value));
        dispatch(addCity(cityInput.current.value));
        dispatch(addPostalCode(postalInput.current.value));
        dispatch(addEmailId(emailInput.current.value));

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${baseUrl}/app/v1/user/createUser`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then(async (response) => {
            // );
            //

            // setUserId(response.data.userID);
            dispatch(setUs(response.data.userID));
            console.log("user created");
            //used to hide header and footer

            //---
            //between user creation and making reservation we need to make the payment
            // navigate(`/reservation/paymentgateway/${stripeCustomerID}`);

            if (response.data.userID) {
              await createReservation(response.data.userID, stripeCustomerID);
            } else {
              console.log("app/v1/user/createUser - UserId not found");
            }
          })
          .catch((error) => {
            console.error(error);
            //
          });
      } else {
      setNumFlag(true)
        window.scrollTo({ top: 300, left: 0, behavior: "smooth" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createReservation = async (userId, stripeCustomerID) => {
    let data = JSON.stringify({
      reservation: {
        reservationID: "",
        userID: userId,
        travelItenary: {
          reservationType: resStatus,
          pickupLocation: pickUpDes,
          dropLocation: dropLoc,
          pickupDate: pickupMMDDYYYY,
          pickupTime: pickTime,
          dropDate: dropMMDDYYYY,
          dropTime: dropTime,
          residency: "",
          age: "25",
        },
        car: {
          type: carObj.type,
          typeID: carObj.vehicleID,
          src: carObj.imageName,
          title: carObj.title,
        },
        extras: extraObjects,
        currencyInfo: {
          currency: currencyName,
          currencyRate: currencyPrice,
        },
        payment: "",
        payType: payNowStatus ? "payNow" : "payLater",
        reservationDate: "",
        reservationDays: resDays.toString(),
        reservationStatus: "",
        allocatedCarID: "",
        allocatedDriverID: "",
        promoCode: "",
        isModifiedFlag: false,
      },
      invoice: {
        invoiceID: "",
        reservationID: "",
        userID: userId,
        invoiceDate: {
          issueDate: "",
          dueDate: "",
        },
        baseRate: baseRate * totalDays,
        addOnCharges: extrasRate,
        excessInsuranceCharge: excessInsurancePrice,
        deliveryCharge: deliveryCharge,
        collectionCharge: collectionCharge,
        taxes:
          (baseRate * totalDays +
            extrasRate +
            excessInsurancePrice +
            carDeliveryCharge) *
          0.05,
        totalCharges:
          baseRate * totalDays +
          excessInsurancePrice +
          extrasRate +
          carDeliveryCharge +
          (baseRate * totalDays +
            extrasRate +
            excessInsurancePrice +
            carDeliveryCharge) *
            0.05,
        promoCodeDeductions: "",
        chargesAfterPromoCode: "",
        paymentStatus: "",
        paymentMethod: "",
      },
    });


    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/app/v1/reservation/createReservation`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      await axios
        .request(config)
        .then(async (response) => {
          //calling the checkout function
          let custTotalAmount =
            baseRate * totalDays +
            excessInsurancePrice +
            extrasRate +
            carDeliveryCharge +
            (baseRate * totalDays +
              excessInsurancePrice +
              extrasRate +
              carDeliveryCharge) *
              0.05;

          if (
            response.data.invoiceCreated &&
            response.data.reservationCreated &&
            response.data.reservationID &&
            response.data.userID
          ) {
            const resId = response.data.reservationID;

            console.log("Reservation created succesfully!");

            dispatch(addRes(resId));
            dispatch(
              addFinalAmountForPaymentFailed(custTotalAmount * conversionRate)
            );
            await checkoutPage(
              stripeCustomerID,
              custTotalAmount * conversionRate,
              currencySelected,
              response.data.reservationID,
              response.data.userID
            );
          }
        })
        .catch((error) => {
          throw new Error("Error creating reservation: ", error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const checkValidity = async () => {
    let userInfo = {
      firstName: firstNameInput.current.value,
      lastName: lastNameInput.current.value,
      email: emailInput.current.value,
      phoneNumber: phoneNumber,
      address: addressInput.current.value,
      city: cityInput.current.value,
      country: countryInput.current.value,
      postalCode: postalInput.current.value,
    };

    const vaildFirstName = await userSchema.isValid(userInfo);
    if (vaildFirstName == true) {
      return true;
    }
    const validFName = await userSchema.fields.firstName.isValid(
      firstNameInput.current.value
    );
    const validLName = await userSchema.fields.lastName.isValid(
      lastNameInput.current.value
    );
    const validEmail = await userSchema.fields.email.isValid(
      emailInput.current.value
    );
    const validAddress = await userSchema.fields.address.isValid(
      addressInput.current.value
    );
    const validcity = await userSchema.fields.city.isValid(
      cityInput.current.value
    );
    const validCountry = await userSchema.fields.country.isValid(
      countryInput.current.value
    );
    const validPhoneNumber = await userSchema.fields.phoneNumber.isValid(
      phoneNumber?.replace(countryCode, "")
    );
    const validPostalCode = await userSchema.fields.postalCode.isValid(
      postalInput.current.value
    );
    //
    if (vaildFirstName == false) {
      if (validFName) {
        setFirstNameVal(false);
      } else {
        setFirstNameVal(true);
      }

      if (validLName) {
        setLastNameVal(false);
      } else {
        setLastNameVal(true);
      }

      if (validEmail) {
        setUserEmail(false);
      } else {
        setUserEmail(true);
      }

      if (validAddress) {
        setUserAddress(false);
      } else {
        setUserAddress(true);
      }

      if (validcity) {
        setUserCity(false);
      } else {
        setUserCity(true);
      }

      if (validCountry) {
        setUserCountry(false);
      } else {
        setUserCountry(true);
      }
      if (validPhoneNumber) {
        setUserPhoneNumber(false);
      } else {
        setUserPhoneNumber(true);
      }
      if (validPostalCode) {
        setPostalCode(false);
      } else {
        setPostalCode(true);
      }
      return false;
    }
  };

  const fillAllEquipments = () => {
    let newArr = [];

    for (let i = 0; i < extraArray.length; i++) {
      if (extraArray[i].charAt(extraArray[i].length - 1) == "P") {
        newArr.push(insuranceObject[1]);
      }
      if (extraArray[i].charAt(extraArray[i].length - 1) != "C") {
        for (let j = 0; j < allEquipmentArray.length; j++) {
          if (
            extraArray[i].substring(0, 2) ==
            allEquipmentArray[j].id.substring(0, 2)
          ) {
            newArr.push(allEquipmentArray[j]);
          }
        }
      } else {
        newArr.push(insuranceObject[0]);
      }
    }
    setExtraObjects(newArr);
  };

  // const calculateTotalExtraAmount = () => {
  //   const insuranceArr = mainExtraArray[0];
  //   const equipmentArr = mainExtraArray[1];
  //   //
  //   let newArr = [];
  //   for (let i = 0; i < extraArray.length; i++) {
  //     if (extraArray[i] == "1" || extraArray[i] == "2") {
  //       for (let j = 0; j < insuranceArr.length; j++) {
  //         if (extraArray[i] == insuranceArr[j]["id"]) {
  //           newArr.push(insuranceArr[j]);
  //         }
  //       }
  //     } else {
  //       for (let j = 0; j < equipmentArr.length; j++) {
  //         if (extraArray[i] == equipmentArr[j]["id"]) {
  //           newArr.push(equipmentArr[j]);
  //         }
  //       }
  //     }
  //   }
  //   setExtraObjects(newArr);
  //   //
  // };

  const tooltipArr = [
    "Valid UAE Driving License (For UAE Residents) or International Driving License (For Tourists)",
    "An Emirates ID or Valid Password with the Visa Entry Stamp",
    "A Valid Credit Card",
  ];

  return (
    <>
      <div className="px-[10.27%] py-[2.5%]">
        <div className="font-bold text-lg ">Your Information</div>
        <div className="removeBack">
          <div className="flex justify-between w-[70%]  mt-6 max-sm:w-full max-sm:block">
            <div className="w-[49%] max-sm:w-full">
              <TextField
                fullWidth
                error={userFirstNameVal}
                size="small"
                label="First Name"
                variant="filled"
                required={true}
                // value={firstNameInput.current.value}

                inputRef={firstNameInput}
                // onChange={(e) => handleFirstName(e)}
              />
            </div>
            <div className="w-[49%] max-sm:w-full max-sm:mt-2">
              <TextField
                fullWidth
                error={userLastNameVal}
                size="small"
                label="Last Name"
                variant="filled"
                required={true}
                // value={lastName}
                // onChange={(e) => handleLastName(e)}
                inputRef={lastNameInput}
              />
            </div>
          </div>
          <div className="flex justify-between w-[70%] max-sm:w-full max-sm:block mt-6 max-sm:mt-2">
            <div className="w-[49%] max-sm:w-full">
              <TextField
                fullWidth
                error={userEmail}
                required={true}
                size="small"
                label="Email"
                variant="filled"
                // value={email}
                // onChange={(e) => handleEmail(e)}
                inputRef={emailInput}
              />
            </div>
            <div
              className={`w-[49%] max-sm:w-full max-sm:mt-2 flex justify-between ${
                phoneNumber?.replace(countryCode, "") == "" && numFlag == true
                  ? "border-b border-b-[#cc1616]"
                  : "border-b border-b-[#8B8B8B]"
              } `}
            >
              <div className="w-[100%] max-sm:w-[100%] flex flex-col">
                <PhoneInput
                  required={true}
                  country={"ae"}
                  countryCodeEditable={false}
                  value={phoneNumber}
                  onChange={(value, data, event, formattedValue) => {
                    setCountryCode(data?.dialCode);
                    setPhoneNumber(value);
                    setNumFlag(true);
                  }}
                  placeholder="Phone Number"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-between max-sm:w-full max-sm:block w-[70%] mt-6 max-sm:mt-2">
            <div className="w-[49%] max-sm:w-full">
              <TextField
                error={userAddress}
                required={true}
                fullWidth
                size="small"
                label="Address"
                variant="filled"
                // value={address}
                // onChange={(e) => handleAddress(e)}
                inputRef={addressInput}
              />
            </div>
            <div className="w-[49%] max-sm:w-full max-sm:mt-2">
              <TextField
                error={userCity}
                required={true}
                fullWidth
                size="small"
                label="City"
                variant="filled"
                // value={city}
                // onChange={(e) => handleCity(e)}
                inputRef={cityInput}
              />
            </div>
          </div>
          <div className="flex justify-between w-[70%] max-sm:w-full max-sm:block max-sm:mt-2 mt-6">
            <div className="w-[49%] max-sm:w-full">
              <FormControl variant="filled" size="small" fullWidth>
                <InputLabel
                  id="demo-simple-select-filled-label"
                  error={userCountry}
                >
                  Country
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  error={userCountry}
                  value={countryInput.current.value}
                  required={true}
                  inputRef={countryInput}
                >
                  {allCountries.map((ele) => (
                    <MenuItem value={ele?.name}>{ele?.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="w-[49%] max-sm:w-full max-sm:mt-2">
              <TextField
                error={postalCode}
                fullWidth
                size="small"
                label="Postal Code"
                variant="filled"
                // value={postalCode}
                // onChange={(e) => handlePostalCode(e)}
                inputRef={postalInput}
              />
            </div>
          </div>
          <div className="max-sm:mt-4">
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label={
                <span className="max-sm:text-[12px]">
                  Send me email promotions and offers.
                </span>
              }
            />
          </div>
        </div>
        <div className="mt-10">
          <div className="font-bold text-lg ">Terms and Conditions</div>
          <div className="mt-2">
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsCheck}
                  onChange={() => setTermsCheck(!termsCheck)}
                />
              }
            />

            <span className="max-sm:text-[14px]">
              I have read and accept the Location Policies and{" "}
              <a
                className="text-blue-950 font-semibold hover:underline hover:cursor-pointer"
                onClick={openTermsModal}
              >
                Rental Terms and Conditions.
              </a>
            </span>
          </div>
          <div className="relative flex items-center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={ageAndDocumentCheck}
                  onChange={() => setAgeAndDocumentCheck(!ageAndDocumentCheck)}
                />
              }
            />
            <span className="max-sm:text-[14px]">
              I am above 21 years of age, and I confirm that I have all the
              required documents.
            </span>

            <div className="group px-1 hover:cursor-pointer">
              {/* <!-- Tooltip Trigger Element (like a button or icon) --> */}
              <FiAlertCircle />
              {/* <!-- Tooltip Content --> */}
              <div className="absolute left-[65%] max-sm:right-[22%] transform -translate-x-1/2 bottom-[2rem] max-sm:bottom-[2.5rem] mb-2 hidden group-hover:block w-64 bg-white text-gray-600 text-sm rounded-lg p-4 shadow-lg z-10">
                <ul className=" list-inside space-y-1">
                  {tooltipArr.map((el, index) => {
                    return (
                      <span className="flex flex-nowrap" key={index}>
                        <li className="list-disc pr-1"></li>
                        <span>{el}</span>
                      </span>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-4 max-sm:mb-8">
            <div
              className={`px-8 py-2 text-white max-sm:text-sm max-sm:w-full max-sm:text-center max-sm:mt-6 cursor-pointer ${
                termsCheck && ageAndDocumentCheck
                  ? "bg-[#092C85]"
                  : "bg-slate-500"
              }  hover:animate-pulse w-fit rounded `}
              onClick={
                termsCheck && ageAndDocumentCheck ? givePrice : console.log()
              }

              // onClick={con}
              // onClick={checkValidity}
            >
              Reserve your car & Pay Now
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentFormUser;
