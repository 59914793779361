import React from "react";
import PaymentSuccess from "./PaymentSuccess";
import PaymentFailed from "./PaymentFailed";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { switchOffLoader } from "../../utils/loaderSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { switchOffHideHeaderFooter } from "../../utils/ModalSlice/modalToggleSlice";
import { baseUrl } from "../../config.js";
import { updateModifyFlag } from "../../utils/modifyBookingSlices/modifyBooking.js";

function PaymentMain() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showSuccess, setShowSuccess] = useState(null);

  const pickLoc = useSelector((store) => store.bookingInfoSlice.pickupLocation);
  const carObj = useSelector((store) => store.carDetailSlice.carDetailObj);

  const sessionId = useSelector((store) => store.paymentSession.sessionID);
  const usrId = useSelector((store) => store.userSlice.userId);

  const currencyName = useSelector((store) => store.currencySlice.currentCurr);
  const currencyPrice = useSelector(
    (store) => store.currencySlice.currentCurrRate
  );
  const reservationID = useSelector((store) => store.reservationSlice.resId);
  const modifyFlag = useSelector((store) => store.modifyBookingSlice.modifyFlag);
  const modifyBookingObj = useSelector((store) => store.modifyBookingSlice.modifyDetails);
  const modifyReservationDays = useSelector((store) => store.modifyBookingSlice.modifyReservationDays);
  const modifiedPriceWithoutCurrency = useSelector((store) => store.modifyBookingSlice.modifiedPriceWithoutCurrency);
  const userId = useSelector((store) => store.userSlice.userId);
  const calculatedTax = useSelector(
    (store) => store.invoiceSlice.calculatedTax
  );
  const finalExtrasArr = useSelector((store) => store.modifyBookingSlice.finalExtrasArr);
  const travelItenaryObj = useSelector((store) => store.modifyBookingSlice.travelItenaryObj);
  const modifyCarObj = useSelector((store) => store.modifyBookingSlice.modifyCarObj);
  const modifyInvoiceObj = useSelector((store) => store.modifyBookingSlice.modifyInvoiceObj);

  const emailID = useSelector(
    (store) => store.loginSlice.emailID
  );
  
  const firstName = useSelector((store) => store.userSlice.firstName);
  const lastName = useSelector((store) => store.userSlice.lastName);


  const modifyReservationApi = async () => {
    let data = {
      reservationID: reservationID,
      userID: userId,
      totalCharges: modifiedPriceWithoutCurrency,
      travelItenary: travelItenaryObj,
      car: {
        type: modifyCarObj.type,
        typeID: modifyCarObj.typeID,
        src: modifyCarObj.src,
        title: modifyCarObj.title,
      },
      extras: finalExtrasArr,
      reservationDays: modifyReservationDays,
      tax: calculatedTax,
      invoice: modifyInvoiceObj,
      advanceInvoiceID: modifyBookingObj.advanceInvoiceID,
      modifiedInvoiceID: modifyBookingObj.lastModifiedInvoiceID,
      modifiedReservationID: modifyBookingObj.lastModifiedReservationID,
      paymentStatus: "Completed",
      currencyInfo: { currency: currencyName, currencyRate: currencyPrice }
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/app/v1/reservation/modifyReservation`,
      data: data
    };

    await axios
      .request(config)
      .then(async (response) => {
          if(response.data.reservationUpdated == true) {
            console.log('Reservation Update Status: ', response.data.reservationUpdated)
            
            // await modifyReservationConfirmationMail();
            dispatch(switchOffLoader())
            dispatch(updateModifyFlag(false));
            navigate("/reservation/paymentstatus/success");
          } else {
          navigate("/reservation/paymentstatus/failed");
          }
      })
      .catch((error) => {
        console.log("Error from modifyReservation: ", error.message);
      });
  }


  const modifyReservationConfirmationMail = async () => {
    let data = {
      emailId: emailID,
      name: firstName+ " "+ lastName,
      reservationID: reservationID,
      currencyName: currencyName,
      currencyRate: currencyPrice,
      totalCharges: modifiedPriceWithoutCurrency,
      travelItenary: travelItenaryObj,
      extras: finalExtrasArr,
      car: {
        type: modifyCarObj.type,
        typeID: modifyCarObj.typeID,
        src: modifyCarObj.src,
        title: modifyCarObj.title,
      },
      reservationDays: modifyReservationDays,
      invoice: modifyInvoiceObj,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/app/v1/reservation/modifyReservationConfirmationMail`,
      data: data
    };

    await axios
      .request(config)
      .then((response) => {
        console.log('Reservation Mail Sent Successfully!', response?.data)
      })
      .catch((error) => {
        console.log("Error from modifyReservation: ", error.message);
      });
  }

  const paymentSuccessOrFailureChecker =  async () =>{
    try{
      let data = JSON.stringify({
        "sessionID": sessionId
      });
      
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseUrl}/app/v1/billing/retrievePaymentStatus`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      await axios.request(config)
      .then(async (response) => {
        console.log('modifyFlag', modifyFlag)
        if (response.data.paymentStatus == "paid") {
          // await sendReservation();
          if (modifyFlag) {
            // await modifyReservationApi();
            dispatch(updateModifyFlag(false));
            navigate("/reservation/paymentstatus/success");

          } else {
            // await updateReservationStatus();
            navigate("/reservation/paymentstatus/success");
          }
          
        } else {
          navigate("/reservation/paymentstatus/failed");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    

    }catch(error){
      console.log(error);
    }
  }


  useEffect(() => {
    dispatch(switchOffLoader());
    dispatch(switchOffHideHeaderFooter());
    if (modifyFlag) {
      paymentSuccessOrFailureChecker();
    } else if (pickLoc == "" || carObj == {}) {
      navigate("/");
    } else {
      paymentSuccessOrFailureChecker();
    }
  }, []);



  // const sendReservation = async () => {
    

  //   let data = JSON.stringify({
  //     reservation: {
  //       reservationID: "",
  //       userID: usrId,
  //       travelItenary: {
  //         reservationType: resStatus,
  //         pickupLocation: pickLoc,
  //         dropLocation: dropLoc,
  //         pickupDate: pickupMMDDYYYY,
  //         pickupTime: pickTime,
  //         dropDate: dropMMDDYYYY,
  //         dropTime: dropTime,
  //         residency: "",
  //         age: "25",
  //       },
  //       car: {
  //         type: carObj.type,
  //         typeID: carObj.vehicleID,
  //         src: carObj.imageName,
  //         title: carObj.title,
  //       },
  //       extras: totalSelectedExtras,
  //       currencyInfo: {
  //         currency: currencyName,
  //         currencyRate: currencyPrice,
  //       },
  //       payment: "",
  //       payType: payNowStatus ? "payNow" : "payLater",
  //       reservationDate: "",
  //       reservationDays: resDays.toString(),
  //       reservationStatus: "",
  //       allocatedCarID: "",
  //       allocatedDriverID: "",
  //       promoCode: "",
  //     },
  //     invoice: {
  //       invoiceID: "",
  //       reservationID: "",
  //       userID: usrId,
  //       invoiceDate: {
  //         issueDate: "",
  //         dueDate: "",
  //       },
  //       baseRate: baseRate * totalDays,
  //       addOnCharges: extrasRate,
  //       excessInsuranceCharge: excessInsurancePrice,
  //     deliveryCharge: deliveryCharge,
  //     collectionCharge: collectionCharge,
  //       taxes: (baseRate * totalDays + extrasRate + excessInsurancePrice + carDeliveryCharge) * 0.05,
  //       totalCharges:
  //         (baseRate * totalDays +excessInsurancePrice+
  //         extrasRate+carDeliveryCharge) +
  //         ((baseRate * totalDays + extrasRate +excessInsurancePrice+carDeliveryCharge) * 0.05),
  //       promoCodeDeductions: "",
  //       chargesAfterPromoCode: "",
  //       paymentStatus: "",
  //       paymentMethod: "",
  //     },
  //   });


  //   let config = {
  //     method: "post",
  //     maxBodyLength: Infinity,
  //     url: "https://wticarrental.ae:3000/app/v1/reservation/createReservation",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };

  //   try {
  //     axios
  //       .request(config)
  //       .then((response) => {
  //         dispatch(addRes(response.data.reservationID));
  //         // if (response.data.reservationCreated == true) {
  //         //   //   navigate("/reservation/confirmed");
  //         //   //   dispatch(switchOffLoader());
  //         // }
  //       })
  //       .catch((error) => {
  //         //
  //         console.log(error);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const updateReservationStatus = async () => {
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${baseUrl}/app/v1/reservation/updateReservationStatus/${usrId}/${reservationID}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios
      .request(config)
      .then((response) => {
        console.log("Status update: ", response.data);
      })
      .catch((error) => {
        console.log("Error updating reservation status: ", error);
      });
  };

  return (
    <React.Fragment>
      <div className="w-full pl-[10.27%] pr-[10.27%] max-sm:px-[4%] bg-[#EBEBEB] pt-6 pb-6 flex justify-center">
        {showSuccess == null ? (
          <>
            <div className="flex justify-center items-center mb-3">
              <div className="bg-red-white flex justify-center flex-col items-center px-40 py-16 p-4 rounded shadow-lg text-center">
                <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-700"></div>
                <div className="mt-4 text-xl font-semibold text-gray-700">
                  Processing Payment . . .
                </div>
              </div>
            </div>
          </>
        ) : showSuccess == true ? (
          <PaymentSuccess />
        ) : (
          <PaymentFailed />
        )}
      </div>
    </React.Fragment>
  );
}

export default PaymentMain;
