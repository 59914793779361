import { addDays } from "date-fns";

export const currentDate = () => {
  const currentDate = new Date();

  // Set the time zone offset to IST (UTC+5:30)
  currentDate.setHours(currentDate.getHours() + 5);
  currentDate.setMinutes(currentDate.getMinutes() + 30);

  // Format the updated date in ISO 8601 format
  const formattedDate = currentDate.toISOString().slice(0, 16);
  return formattedDate;
}

export const currentDatePlusOne = () => {
  const currentDate = new Date();

  // Add 1 day to the current date
  currentDate.setDate(currentDate.getDate() + 1);

  // Set the time zone offset to IST (UTC+5:30)
  currentDate.setHours(currentDate.getHours() + 5);
  currentDate.setMinutes(currentDate.getMinutes() + 30);

  // Format the updated date in ISO 8601 format
  const formattedDate = currentDate.toISOString().slice(0, 16);

  return formattedDate;
}

export const currentDatePlusTwo = () => {
  const currentDate = new Date();

  // Add 2 days to the current date
  currentDate.setDate(currentDate.getDate() + 2);

  // Set the time zone offset to IST (UTC+5:30)
  currentDate.setUTCHours(currentDate.getUTCHours() + 5);
  currentDate.setUTCMinutes(currentDate.getUTCMinutes() + 30);

  // Format the updated date in ISO 8601 format
  const formattedDate = currentDate.toISOString().slice(0, 16);

  return formattedDate;
};

export const calculateDaysInBetween = (startDate, endDate) => {
  const sDate = new Date(startDate); // Sun Nov 26 2023 00:00:00 GMT+0530 (India Standard Time) - sample
  const eDate = new Date(endDate);

  // Calculate the difference in milliseconds
  const differenceInMillis = eDate - sDate;
  // Convert milliseconds to days
  const millisecondsInADay = 1000 * 60 * 60 * 24;
  const differenceInDays = differenceInMillis / millisecondsInADay;

  return differenceInDays;
};

export const function2 = (inputDate) => {
  // Parse the input date string into a Date object
  const parsedDate = new Date(inputDate);

  // Add 1 day to the parsed date
  parsedDate.setDate(parsedDate.getDate() + 1);

  // Set the time zone offset to IST (UTC+5:30)
  parsedDate.setHours(parsedDate.getHours() + 5);
  parsedDate.setMinutes(parsedDate.getMinutes() + 30);

  // Format the updated date in ISO 8601 format
  const formattedDate = parsedDate.toISOString().slice(0, 16);

  return formattedDate;
};
//Now, function2 takes the output of function1 as input (a date string), parses it into a Date object, and then adds one day to it while also adjusting the time zone offset. It returns the resulting date in the desired format. You can call function1 to get a date, and then pass its output as input to function2 to get a date one day ahead.

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const dateRangePickerFormater = (val) => {
  const inputDate = new Date(val);
  
  const month = monthNames[inputDate.getMonth()];
  const day = inputDate.getDate();
  const year = inputDate.getFullYear();

  const formattedDate = `${month},${day},${year}`;
  return formattedDate;
};

export const checkOneDayBefore = (pickupDate, pickupTime) => {

  try {
    const [month, day, year] = pickupDate.split(","); // Parse the pickup date
    const [hours, minutes] = pickupTime.split(":").map(Number);
    
    const getMonthIndex = ((monthStr) => {
      return monthNames.indexOf(monthStr);
    })(month);
    
    const pickupDateTime = new Date(year, getMonthIndex, day, hours, minutes);
    
    const currentDateTime = new Date();
    currentDateTime.setHours(currentDateTime.getHours() + 4); // UTC to Dubai time
    
    const timeDifference = pickupDateTime - currentDateTime; // Calculate the time difference in milliseconds
    
    const hoursDifference = Number(timeDifference) / (1000 * 60 * 60); // Calculate the difference in hours
    
    return hoursDifference >= 24; // Check if the difference is less than 24 hours
  } catch (error) {
    console.log(error)
    return false
  }
};
