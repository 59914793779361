import React, { useEffect, useState } from "react";
import axios from "axios";
import { ReactComponent as AirCondIcon } from "../carSelectType/carSearchResults/images/icons/air-conditioner.svg";
import { ReactComponent as CarDoor } from "../carSelectType/carSearchResults/images/icons/car-door.svg";
import { ReactComponent as Transmission } from "../carSelectType/carSearchResults/images/icons/transmission.svg";
import CloseIcon from "@mui/icons-material/Close";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  setTrueSubscriptionCarModal,
  setFalseSubscriptionCarModal,
} from "../../utils/ModalSlice/modalToggleSlice";
import { useDispatch, useSelector } from "react-redux";
import LuggageIcon from "@mui/icons-material/Luggage";
import PersonIcon from "@mui/icons-material/Person";
import {
  setSubscriptionData,
  unsetSubscriptionData,
} from "../../utils/subscriptionSlices/subscriptionSliceMain";
import { Link, useNavigate } from "react-router-dom";
import { addSubscriptionBaseRate } from "../../utils/subscriptionSlices/subscriptionInvoiceSlice";
import { emptySubscriptionInvoiceSlice } from "../../utils/subscriptionSlices/subscriptionInvoiceSlice";
import { emptyCarDropLocation } from "../../utils/picDropSlices/pickDropSlice";
import { baseUrl } from "../../config";

function SubscriptionGrid() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [carArr, setCarArr] = useState([]);
  const [divIndex, setDivIndex] = useState(null);
  const [spaceBetween, setSpaceBetween] = useState(0);
  const [tenureHidden, setTenureHidden] = useState(false);
  const [subscriptionTenureArr, setSubscriptionTenureArr] = useState([]);
  const [tenureDivIndex, setTenureDivIndex] = useState(0);
  useEffect(() => {
    dispatch(setFalseSubscriptionCarModal());
    dispatch(unsetSubscriptionData());
    dispatch(emptySubscriptionInvoiceSlice());
    dispatch(emptyCarDropLocation());
  }, []);

  useEffect(() => {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl}/app/v1/leasingVehicles/getAllLeasingVehicles`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          // response.data.result.push({});
          // response.data.result.push({});
          // response.data.result.push({});
          setCarArr(response.data.result);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    } // Call the async function
  }, []);

  //lowest price getter

  function getMonthAndPrice(inputObject, mileageObj) {
    const transformedResult = Object.entries(inputObject)
      .filter(([key, value]) => value !== 0)
      .map(([key, value]) => ({ [key]: value }));

    let keyName = Object.keys(
      transformedResult[transformedResult.length - 1]
    )[0];

    if (keyName === "twelveMonths") {
      return {
        month: 12,
        mileage: mileageObj.twelveMonths,
        price: transformedResult[transformedResult.length - 1].twelveMonths,
      };
    } else if (keyName === "nineMonths") {
      return {
        month: 9,
        mileage: mileageObj.nineMonths,
        price: transformedResult[transformedResult.length - 1].nineMonths,
      };
    } else if (keyName === "sixMonths") {
      return {
        month: 6,
        mileage: mileageObj.sixMonths,
        price: transformedResult[transformedResult.length - 1].sixMonths,
      };
    } else if (keyName === "threeMonths") {
      return {
        month: 3,
        mileage: mileageObj.threeMonths,
        price: transformedResult[transformedResult.length - 1].threeMonths,
      };
    } else {
      return {
        month: 1,
        mileage: mileageObj.monthly,
        price: transformedResult[transformedResult.length - 1].monthly,
      };
    }
  }

  const calculateSpace = () => {
    const clickDiv = document.querySelector(".carTenureModal");
    if (clickDiv) {
      let divHeight = clickDiv.clientHeight;

      setSpaceBetween(divHeight);
    }
  };

  const closeTenureModal = () => {
    setTimeout(() => {
      //this timeout is needed because before making it null it first gives it an index.
      setDivIndex(null);
      dispatch(setFalseSubscriptionCarModal());
    }, 100);

    setSpaceBetween(0);
  };

  const setCarDivIndex = (idx) => {
    setDivIndex(idx);

    let newSubscriptionTenureArr = Object.entries(
      carArr[idx].subscriptionPrice
    ).map(([key, value]) => ({ [key]: value }));
    setSubscriptionTenureArr(newSubscriptionTenureArr);

    //making space for footer
    // Calculate the row and column of the clicked item
    const row = Math.floor(idx / 3) + 1; // Adding 1 to make the rows start from 1
    const column = (idx % 3) + 1; // Adding 1 to make the columns start from 1

    // Calculate the total number of rows
    const totalRows = Math.ceil(carArr.length / 3);

    // Check if the clicked item is in the last row
    const isLastRow = row === totalRows;

    if (isLastRow) {
      //make space for footer

      dispatch(setTrueSubscriptionCarModal(500));
    }
  };

  const saveSubscriptionCarAndMove = () => {

    let subObjectKey = Object.keys(subscriptionTenureArr[tenureDivIndex])[0];
    //first add the base rate
    dispatch(
      addSubscriptionBaseRate(
        Object.values(subscriptionTenureArr[tenureDivIndex])[0]
      )
    );
    if (subObjectKey === "twelveMonths") {
      dispatch(
        setSubscriptionData({
          subscriptionCarObj: carArr[divIndex],
          tenureWords: "twelveMonths",
          tenureNumber: 12,
        })
      );
    } else if (subObjectKey === "nineMonths") {
      dispatch(
        setSubscriptionData({
          subscriptionCarObj: carArr[divIndex],
          tenureWords: "nineMonths",
          tenureNumber: 9,
        })
      );
    } else if (subObjectKey === "sixMonths") {
      dispatch(
        setSubscriptionData({
          subscriptionCarObj: carArr[divIndex],
          tenureWords: "sixMonths",
          tenureNumber: 6,
        })
      );
    } else if (subObjectKey === "threeMonths") {
      dispatch(
        setSubscriptionData({
          subscriptionCarObj: carArr[divIndex],
          tenureWords: "threeMonths",
          tenureNumber: 3,
        })
      );
    } else {
      dispatch(
        setSubscriptionData({
          subscriptionCarObj: carArr[divIndex],
          tenureWords: "monthly",
          tenureNumber: 1,
        })
      );
    }

    let cartitleWithHyphem = carArr[divIndex].title.split(" ").join("-").toLowerCase()
    navigate(`/dubai/subscription/${cartitleWithHyphem}`);

    // window.scrollTo({
    //   top:0,
    //   behavior:"smooth"
    // })

    // window.scrollTo(0 ,0);
  };


  return (
    <>
      <div
        // className={`w-full grid grid-cols-3 max-lg:grid-cols-2  gap-y-4 mt-10 relative max-sm:static max-sm:grid-cols-1`}
        className={`flex justify-between flex-wrap gap-[24px] max-sm:px-[20px] py-7`}
      >
        {carArr.map((ele, index) => (

          <a
            href="#carTenureModalUnq"
            style={{
              marginTop:
                divIndex !== null &&
                  Math.floor(index / 3) === Math.floor(divIndex / 3) + 1
                  ? `${spaceBetween + 10}px`
                  : "0",
            }}
            className={`flex flex-col justify-between h-[460px] grow rounded-xl outline outline-gray-300 cursor-pointer hover:outline-cyan-60 relative ${divIndex != null && index == divIndex
              ? "max-sm:overflow-y-hidden"
              : ""
              }`}
            onClick={() => {
              // setDivIndex(index);
              calculateSpace();

              // window.scrollTo(0 , 1900)
              window.scrollTo({
                top: 1000,
                left: 0,
                behavior: "smooth",
              });

              setCarDivIndex(index);
            }}
          >
            <div className="flex w-full relative">
              <span className="w-[65%] text-[16px] font-extrabold py-4 px-5">
                {ele.title}
              </span>
              {/* <span className="w-[35%] text-[16px] font-medium py-4 px-3">
                Available
              </span> */}

              <span className="absolute bg-[#0891B2] rounded-bl-[20px] rounded-tl-[20px] -right-[1px] top-[15px] py-[5px] px-[10px] flex  justify-center items-center w-[88px] text-white text-[14px]">
                Available
              </span>
            </div>
            <div className="flex justify-center items-center">
              {/* <div className=" aspect-w-[309px] aspect-h-[176px]"> */}
              <img
                src={
                  `${baseUrl}/app/v1/aws/getImage/carimages/` +
                  (ele.imageName
                    ? ele.imageName.split(" ").join("")
                    : "static") +
                  ".png"
                }
                alt=""
                onError={(e) => {
                  e.target.src = "../static/staticcarimage.png";
                }}
                className="aspect-w-[309px] aspect-h-[176px]"
              />
              {/* </div> */}
            </div>
            <div className="flex flex-col p-4">
              <div className="flex flex-col bg-[#F3F3F3] rounded-[20px] ">
                <div className="flex justify-between items-center bg-[#F3F3F3] rounded-[20px]  2lx:flex-nowrap p-4">
                  <div className="flex flex-col gap-3 justify-between">
                    <div className="flex flex-col">
                      <span className="md:text-[16px] text-[14px] text-[#505050]  font-bold ">
                        {
                          getMonthAndPrice(ele.subscriptionPrice, ele.mileage)
                            .month
                        }
                        {" " + "months"}
                      </span>
                      <span className="md:text-[14px] text-[12px] font-normal text-[#505050] whitespace-nowrap">
                        Contract term
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span className="text-[16px] text-[#505050] font-bold">
                        {
                          getMonthAndPrice(ele.subscriptionPrice, ele.mileage)
                            .mileage
                        }{" "}
                        KM
                      </span>
                      <span className="text-[14px] font-normal text-[#505050]">
                        Mileage
                      </span>
                    </div>
                  </div>
                  <div className="  w-full">
                    <div className="flex flex-col">
                      <span className="md:text-[24px] text-[20px] text-right font-bold text-cyan-600">
                        AED{" "}
                        {getMonthAndPrice(ele.subscriptionPrice, ele.mileage).price}
                      </span>
                      <span className="text-[#646464] text-right  font-thin md:text-[16px] text-[14px] ">
                        Per months
                      </span>
                      <span className="md:text-[14px] text-[12px] font-normal text-right  text-[#868686]">
                        VAT Inclusive
                      </span>
                    </div>
                  </div>
                </div>
                <Link className="text-[14px] px-4 text-[#8E8E8E] font-medium leading-[16px] mb-4 inline-block">*Terms and Conditions Apply</Link>
              </div>
            </div>
          </a>
        ))}
      </div>
      {/* <div className="flex justify-center w-full py-4">
        <button className="w-full md:w-3/12 py-3 px-2 rounded bg-black text-white">
          Explore All Car
        </button>
      </div> */}

      {divIndex != null ? (
        <div
          className={`w-full bg-white  mx-auto max-sm:h-[1000px] h-auto relative max-sm:absolute top-0 left-0`}
        >
          <div
            id="carTenureModalUnq"
            className={`carTenureModal flex z-10 bg-white  border border-[#D1D5DB] text-white max-sm:w-[100%] max-sm:top-0 max-sm:h-full max-sm:overflow-y-auto  w-[97%] h-fit mt-28 mb-7 rounded-xl left-5 max-sm:block max-sm:left-0 h-full`}
          >
            <div className="bigCarImage w-[50%] max-sm:w-full flex flex-col justify-center items-center border-r bg-white rounded-l-xl border-[#D1D5DB] relative">
              <img
                src={
                  `${baseUrl}/app/v1/aws/getImage/carimages/` +
                  (carArr[divIndex].imageName
                    ? carArr[divIndex].imageName.split(" ").join("")
                    : "static") +
                  ".png"
                }
                alt=""
                onError={(e) => {
                  e.target.src = "../static/staticcarimage.png";
                }}
              />
              <CloseIcon
                className=" z-10 text-black max-sm:absolute max-sm:top-2 max-sm:right-2 hidden max-sm:block"
                onClick={closeTenureModal}
              />
              <div className="w-full px-4 items-center grid grid-cols-3 gap-3">
                <div className="flex flex-col items-center justify-start my-4">
                  <Transmission className=" text-base" />
                  <span className=" text-xs font-light text-slate-500">
                    Transmission Type
                  </span>
                  <span className="text-sm font-semibold text-black">
                    {carArr[divIndex].transmissionType}
                  </span>
                </div>
                <div className="flex flex-col items-center justify-start my-4">
                  <AirCondIcon className=" text-base" />
                  <span className=" text-xs font-light text-slate-500">
                    Air Conditioning
                  </span>
                  <span className="text-sm font-semibold text-black">
                    Available
                  </span>
                </div>
                <div className="flex flex-col items-center justify-start my-4">
                  <CarDoor className=" text-base" />
                  <span className=" text-xs font-light text-slate-500">
                    Doors
                  </span>
                  <span className="text-sm font-semibold text-black">
                    {carArr[divIndex].doors + " Doors"}
                  </span>
                </div>
                <div className="flex flex-col items-center justify-start my-4">
                  <LuggageIcon className=" text-base text-black " />
                  <span className=" text-xs font-light text-slate-500">
                    Luggage capacity
                  </span>
                  <span className="text-sm font-semibold text-black">
                    {carArr[divIndex].luggageCapacity}
                    {" " + "Suitcase"}
                  </span>
                </div>
                <div className="flex flex-col items-center justify-start my-4">
                  <PersonIcon className=" text-base text-black" />
                  <span className=" text-xs font-light text-slate-500">
                    Seats
                  </span>
                  <span className="text-sm font-semibold text-black">
                    {carArr[divIndex].seats + " seats"}
                  </span>
                </div>
              </div>
            </div>
            <div className="w-[50%] max-sm:w-full tenureDiv bg-white rounded-r-xl pb-4">
              <div className=" w-full flex justify-between items-center py-2 px-3 mb-3 bg-[#F9F9F9] rounded-tr-xl">
                <span className=" text-[28px] font-bold py-4 px-5 text-black">
                  {carArr[divIndex].title}
                </span>
                <CloseIcon
                  className=" z-10 text-black max-sm:absolute max-sm:top-2 max-sm:hidden max-sm:right-2"
                  onClick={closeTenureModal}
                />
              </div>
              <span className=" text-[20px] font-semibold text-black pl-8 pt-2">
                Subscription tenure
              </span>
              <div
                className={`w-full px-5 flex flex-wrap gap-y-4 ${subscriptionTenureArr.length == 5
                  ? "justify-around"
                  : "justify-between"
                  } gap-x-4 mt-2`}
              >
                {subscriptionTenureArr
                  .filter((ele) => Object.values(ele)[0] !== 0) // Filter out objects with value 0
                  .map((ele, index) => (
                    <div
                      key={index}
                      className={` hover:ring-2 flex w-[69.32px] flex-col items-center justify-center py-2 px-3  border-b-[2.5px] border-[#D1D2D3]  rounded-md drop-shadow-2xl ${index == tenureDivIndex ? "bg-cyan-600" : " bg-white"
                        } `}
                      onClick={() => {
                        setTenureDivIndex(index);
                      }}
                    >
                      <span
                        className={`${index == tenureDivIndex ? "text-white" : "text-black"
                          }  text-[18px] font-bold`}
                      >
                        {ele.monthly && ele.monthly !== 0
                          ? "1"
                          : ele.threeMonths && ele.threeMonths !== 0
                            ? "3"
                            : ele.sixMonths && ele.sixMonths !== 0
                              ? "6"
                              : ele.nineMonths && ele.nineMonths !== 0
                                ? "9"
                                : ele.twelveMonths && ele.twelveMonths !== 0
                                  ? "12"
                                  : ""}
                      </span>
                      <span
                        className={`${index == tenureDivIndex ? "text-white" : "text-black"
                          } text-[12px] font-semibold`}
                      >
                        {index > 0 ? "Months" : "Month"}
                      </span>
                    </div>
                  ))}
              </div>
              <div className="w-full px-6 pt-1 max-sm:mt-8 max-sm:mb-8 flex items-center max-sm:items-end ">
                <span className=" text-[32px] max-sm:text-3xl font-semibold text-black mr-1">
                  AED {Object.values(subscriptionTenureArr[tenureDivIndex])[0]}
                </span>
                <span className="text-[20px] max-sm:text-base font-normal text-black mr-1">
                  {""}
                  /month
                </span>
                <span className=" line-through max-sm:hidden text-[#656565]">
                  AED{" "}
                  {Object.values(subscriptionTenureArr[tenureDivIndex])[0] *
                    1.25}
                </span>
              </div>
              <span className="text-[20px] max-sm:text-lg max-sm:px-4 max-sm:mt-6 font-semibold px-6 text-black ml-2">
                Mileage
              </span>
              <div className="w-full flex justify-between items-center pt-2 px-6 mb-4">
                <div className=" border-[1.5px] rounded-md  border-slate-400 w-[45%] h-fit flex  justify-around items-center py-3 px-2">
                  <div className=" flex rounded-full items-center justify-center p-1 border-[1.5px] border-cyan-600">
                    <div className="px-2 rounded-full py-2 bg-cyan-600"></div>
                  </div>

                  <div className=" flex flex-col ">
                    <span className="text-black font-semibold text-[18px]">
                      {
                        carArr[divIndex].mileage[
                        Object.keys(subscriptionTenureArr[tenureDivIndex])[0]
                        ]
                      }{" "}
                      Km<span className="text-[12px]">/Month</span>
                    </span>
                    <span className=" text-slate-600 text-[12px]">
                      Included
                    </span>
                  </div>
                </div>
                {/* <div className=" border-[1.5px] rounded-md border-slate-400 w-[45%] h-fit flex  justify-around items-center py-3 px-2">
                    <div className=" flex rounded-full items-center justify-center p-1 border-[1.5px] border-cyan-600">
                      <div className="px-2 rounded-full py-2 bg-white"></div>
                    </div>

                    <div className=" flex flex-col ">
                      <span className="text-black font-semibold text-[18px]">
                        Unlimited km
                      </span>
                      <span className=" text-slate-600 text-[12px]">
                        + 230 AED
                      </span>
                    </div>
                  </div> */}
              </div>
              <div className=" w-full px-6 flex justify-center items-center">
                <div
                  className=" w-full flex justify-center items-center bg-cyan-600 rounded-md py-4 hover:cursor-pointer"
                  onClick={saveSubscriptionCarAndMove}
                >
                  <span className=" text-white font-semibold ">Proceed</span>
                  <ChevronRightIcon className=" text-white " />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}







      {/* <div className="flex justify-center w-full py-4">
        <button
          className="w-full md:w-3/12 py-3 px-2 rounded bg-black text-white"
          onClick={() => navigate("/subscription-cars")}
        >
          Explore All Cars
        </button>
      </div> */}
    </>
  );
}

export default SubscriptionGrid;
