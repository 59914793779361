import React from "react";
import global from "./global.png"
function AboutGlobal() {
  return (
    <div className="w-full pl-[10.27%] pr-[10.27%] max-sm:px-0 flex justify-evenly items-center max-sm:block mt-16">
      <div>
        <div className="p-2 rounded-md bg-white flex justify-center items-center drop-shadow-xl w-[348px] h-[250px] px-4 max-sm:m-auto">
          <img src={global} alt="" />
        </div>
      </div>
      <div className="flex flex-col max-sm:mt-4 max-sm:px-4 max-sm:text-center">
        <span className="font-semibold text-2xl text-[#0F0A34] leading-8 mb-2">
          <h2>Global Ambitions, Local Expertise</h2>
        </span>
        <span className="text-left text-sm max-sm:text-center">
          2021 marked our deepened outreach to tier 2, 3, &amp; 4 cities. By
          2023, our vision<br/> took a global flight, cementing our footprint in UAE
          with services at 17 primary <br/>airport counters. Merging technology and
          sustainability, we&#39;re navigating UAE’s <br/>dynamic streets, setting
          benchmarks in corporate commuting.
        </span>
      </div>
    </div>
  );
}

export default AboutGlobal;
