import React from "react";
import header from "./aboutBanner.png";

function AboutHeading() {
  return (
    <div className="w-full h-[260px] bg-[#322F51] flex flex-col justify-end items-center  pl-[10.27%] pr-[10.27%] relative max-sm:h-[250px] ">
      <img
        src={header}
        alt=""
        className="absolute object-fill max-sm:object-cover max-sm:object-center object-right-bottom w-full h-full "
      />
      <span className="font-medium text-white text-2xl leading-8 bottom-28  absolute max-sm:text-xl max-sm:text-center max-sm:static max-sm:mt-10 max-sm:z-10">
        <h1>Your Global Commute Partner, Driving Business Success</h1>
      </span>
      <span className="text-center text-white font-extralight text-sm bottom-14 px-56 absolute max-sm:text-xs max-sm:p-8 max-sm:static max-sm:z-10 ">
        Established in 2009, WTi Mobility has consistently reshaped the global
        commute landscape. What began as a modest operation in India has
        blossomed into a global enterprise, offering unparalleled corporate
        commute and car rental services.
      </span>
    </div>
  );
}

export default AboutHeading;
