import React, { useState } from "react";
import { useDispatch } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import { switchOffPickAndDeliveryModal } from "../../utils/ModalSlice/modalToggleSlice";

function CarPickUpNotificationModal() {
    const dispatch = useDispatch();
 
    const closeModal = ()=>{
        dispatch(switchOffPickAndDeliveryModal());
    }   


  return (
    <div className="fixed overflow-y-auto overflow-x-hidden inset-0 pt-10 max-sm:pt-0 bg-black  z-50 outline-none  bg-opacity-70 flex justify-center items-center">
      <div className="bg-white rounded-md  text-center py-[0.5rem]  w-[50%] max-sm:w-[70%]">
        <div className="w-full flex justify-end items-center px-4 mb-2 cursor-pointer">
            <CloseIcon onClick={closeModal}/>
        </div>
        <div className="w-full px-[4rem] max-sm:px-[1rem] mb-4">
        <span className="text-black font-semibold text-base  text-center leading-5">
Delivery and collection charges are applicable based on your chosen location.</span>
        </div>
        
      </div>
    </div>
  );
}

export default CarPickUpNotificationModal;
