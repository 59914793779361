import React from 'react'
import EditIcon from "../../images/edit_icom.svg"
import BookedCarImg from "../../images/booked_car_img.png"
import ConfirmIcon from "../../images/confirm_icon.svg"
import CancelIcon from "../../images/cancel_icon.svg"

const BookedCarForSelfDrive = () => {
    return (

        // <div className='flex flex-wrap justify-between items-center w-full my-8 lg:mt-0 mt-5'>
        <div className='grid lg:grid-cols-[70%_30%] grid-cols-1  w-full my-8 lg:mt-2 mt-5 '>
            <div className='w-full flex justify-center items-center pe-3 ps-3 h-full '>
                <div className='bg-[#fff] w-full flex items-center py-6 px-4 lg:px-6 lg:shadow-[0px_3px_20px_0px_#00000029] lg:rounded-b-[20px] rounded-b-[0] rounded-[20px] h-full'>
                    <div className='w-full flex flex-col gap-1 relative'>
                        <div className='w-full flex justify-between items-center '>
                            <button className='text-[#fff] absolute lg:-top-[68px] -top-[44px]  text-[14px] tracking-[1px] font-semibold leading-[16px] bg-[#DCC52A] py-3 px-4 rounded-[12px]'>
                                Pending
                            </button>
                            <h4 className='text-[#1c1c1c] whitespace-nowrap  text-[14px] font-semibold leading-[16px]'>Advance Receipt</h4>
                            <button className='flex justify-start gap-2 items-center bg-gradient-to-r from-[#0077B6] to-[#00B4D8] py-2 px-3 rounded-[12px] shadow-[0px_3px_20px_0px_#0000001F] transition-all active:scale-[1.2]'>
                                <img src={EditIcon} alt="" />
                                <span className='text-[#fff]  text-[14px] font-semibold leading-[16px] lg:block hidden'>Modify Details</span>
                            </button>
                        </div>
                        <h3 className='text-[#1c1c1c] text-[14px] lg:text-[14px] font-medium leading'>Confirmation Number: <span className='text-[#0077B6]'>RES1700123456789</span></h3>
                        <div className='flex justify-between items-center my-3'>
                            <div className='flex flex-col items-start gap-1'>
                                <h4 className='text-[#1c1c1c]  text-[14px] font-medium leading-[20px]'>Pick Up</h4>
                                <div className='w-[85%] flex items-end'>
                                    <span className='text-[#474747] text-[14px] text-left font-normal  leading-[20px]'>Dubai Marina- Dubai- United Arab Emirates
                                    </span>
                                </div>
                                <h4 className='text-[#0193C5]  text-[14px] font-semibold leading-[16px]  mt-1'>15 May 2024</h4>
                            </div>
                            <span className='inline-block py-2 px-5 md:py-1 md:px-3 rounded-[12px] text-[12px]  bg-[#727272] text-[#fff] whitespace-nowrap font-semibold'>5 Days</span>
                            <div className='flex flex-col items-end gap-1'>
                                <h4 className='text-[#1c1c1c]  text-[14px] font-medium leading-[20px]'>Drop Off</h4>
                                <div className='w-[85%] flex items-end'>
                                    <span className='text-[#474747] text-[14px] font-normal text-right leading-[20px]'>Dubai Marina- Dubai- United Arab Emirates
                                    </span>
                                </div>
                                <h4 className='text-[#0193C5]  text-[14px] font-semibold leading-[16px] mt-1'>15 May 2024</h4>
                            </div>
                        </div>
                        <div className='flex justify-between items-center'>
                            <div className='grow'>
                                <img src={BookedCarImg} className='w-[60%]' alt="" />
                            </div>
                            <div className='flex flex-col gap-4 px-1 lg:px-6  grow-[2]'>
                                <div className='flex items-baseline gap-2'>
                                    <h3 className='text-[#494949] whitespace-nowrap  text-[14px] lg:text-[28px] font-semibold leading-[16px] lg:leading-[16px]'>SUV Cars</h3>
                                    <button className='py-1 grow-[1]  px-2 border border-[#0193C5] text-[#0193C5] text-[14px] leading-[20px] rounded-[12px] lg:hidden whitespace-nowrap'>Need Help?</button>
                                </div>
                                <span className='text-[#494949]  text-[14px] font-normal leading-[14px] lg:leading-[20px]'>Kaiyi X3 Pro 1.2l or similar</span>
                            </div>
                            <button className='py-1 grow-[1] px-2 border border-[#0193C5] text-[#0193C5]  text-[14px] leading-[20px] rounded-[12px] lg:block hidden whitespace-nowrap'>Need Help?</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='px-6 w-full lg:hidden block '>
                <div className='h-[1px] bg-[#A3A3A3] w-full'></div>
            </div>
            <div className='w-full px-3 lg:-mt-[0] -mt-[0px] h-full'>
                <div className='py-6 px-6 bg-[#fff] lg:rounded-t-[20px] rounded-t-[0] rounded-[20px]  lg:shadow-[0px_3px_20px_0px_#00000029] h-full'>
                    <div className='flex flex-col gap-1 w-full  '>
                        <div className='flex justify-between items-center w-full'>
                            <h4 className='text-[#222222] text-[16px] font-medium leading-[24px]'>Payment method:</h4>
                            <h4 className='text-[#0077B6] text-[14px] font-semibold leading-[24px]'>PAY LATER</h4>
                        </div>
                        <div className='flex justify-between items-center w-full '>
                            <span className='text-[#5F5F5F] text-[16px] font-medium leading-[24px]'>Payment Status :</span>
                            <button className=' text-[#008ac1] text-[14px] tracking-[1px] font-semibold '>
                                PENDING
                            </button>
                        </div>

                        
                        <div className='flex flex-col gap-1 mt-1'>
                            <h4 className='text-[#222222] text-[16px] font-medium leading-[24px]'>Included in the rates</h4>
                            <div className='w-full flex gap-0 justify-between  flex-col'>
                                <div className='flex justify-between items-center w-full'>
                                    <h4 className='text-[#5F5F5F] text-[14px] font-normal leading-[24px]'>Base Rate</h4>
                                    <h4 className='text-[#222222] text-[14px] font-normal leading-[24px]'>₹ 228.00</h4>
                                </div>
                                <div className='flex justify-between items-center w-full'>
                                    <h4 className='text-[#5F5F5F] text-[14px] font-normal leading-[24px]'>No-Deposit Premium</h4>
                                    <h4 className='text-[#222222] text-[14px] font-normal leading-[24px]'>₹ 120.00</h4>
                                </div>
                                <div className='flex justify-between items-center w-full'>
                                    <h4 className='text-[#5F5F5F] text-[14px] font-normal leading-[24px]'>Delievery Charge</h4>
                                    <h4 className='text-[#222222] text-[14px] font-normal leading-[24px]'>₹ 20.00</h4>
                                </div>
                                <div className='flex justify-between items-center w-full'>
                                    <h4 className='text-[#5F5F5F] text-[14px] font-normal leading-[24px]'>Collection Charge</h4>
                                    <h4 className='text-[#222222] text-[14px] font-normal leading-[24px]'>₹ 100.00</h4>
                                </div>
                                <div className='flex justify-between items-center w-full'>
                                    <h4 className='text-[#5F5F5F] text-[14px] font-normal leading-[24px]'>Extras</h4>
                                    <h4 className='text-[#222222] text-[14px] font-normal leading-[24px]'>₹ 56.00</h4>
                                </div>
                                <div className='flex justify-between items-center w-full'>
                                    <h4 className='text-[#5F5F5F] text-[14px] font-normal leading-[24px]'>Taxes & Fees</h4>
                                    <h4 className='text-[#222222] text-[14px] font-normal leading-[24px]'>₹ 32.00</h4>
                                </div>
                            </div>
                            <div className='flex flex-col w-full gap-3 '>
                                <div className='flex justify-between items-center bg-[#F6F6F6] rounded-[8px] py-2 px-3'>
                                    <h4 className='text-[#222] tracking-[1px] text-[14px] font-normal leading-[24px]'>Total</h4>
                                    <h4 className='text-[#0088c0] text-[14px] font-semibold leading-[24px]'>₹ 556.00</h4>
                                </div>
                                <div className='flex justify-between items-center gap-2'>
                                    <button className='flex justify-start gap-2 items-center bg-gradient-to-r from-[#0077B6] to-[#00B4D8] py-2 px-3 rounded-[12px] shadow-[0px_3px_20px_0px_#0000001F] transition-all active:scale-[1.2]'>
                                        <img src={ConfirmIcon} alt="" />
                                        <span className='text-[#fff] text-[14px] font-medium leading-[20px]'>Confirmation</span>
                                    </button>
                                    <button className='flex justify-start gap-2 items-center border border-[#0077B6] py-2 px-3 rounded-[12px]'>
                                        <img src={CancelIcon} alt="" />
                                        <span className='text-[#0077B6] text-[14px] font-medium leading-[20px] '>Cancel</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookedCarForSelfDrive
