import React from "react";
import PhoneModal from "./PhoneModal";
import { useDispatch } from "react-redux";
import { toggleLogin } from "../../utils/loginSlices/loginSlice";

function LogMain(){
    const dispatch = useDispatch();
    const closeLoginModal = ()=>{
        dispatch(toggleLogin());
        // document.documentElement.style.overflow = "visible";
    }
    return(
        <React.Fragment>
            <div className='fixed w-[100%] h-screen bg-black z-40 bg-opacity-50 flex justify-center items-center' >
                <PhoneModal/>
            </div>

        </React.Fragment>
    )
}

export default LogMain;