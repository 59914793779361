import { createSlice } from "@reduxjs/toolkit";


const userCookie = createSlice({
    name:"userCookie",
    initialState:{
        firstName:"",
        lastName:"",
        email:"",
        phone:"",
        userId:"",
        cookieExist:false,
    },
    reducers:{
        saveCookie:(state,action)=>{
            state.cookieExist = true;
        },
        removeCookie:(state,action)=>{
            state.cookieExist = false;
        },
        saveUserCookieInfo:(state,action)=>{
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.email = action.payload.emailID;
            state.phone = action.payload.contact;
            state.userId = action.payload.userID;
        },
        removeUserCookieInfo:(state,action)=>{
            state.firstName = "";
            state.lastName = "";
            state.email = "";
            state.phone ="";
            state.userId = "";
        }

    }
})


export const {saveCookie,removeCookie,saveUserCookieInfo,removeUserCookieInfo} = userCookie.actions;

export default userCookie.reducer;