import React, { useState } from "react";
import TitleTabsContainer from "./titleBarComponents/TitleTabsContainer";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { calculateExcessInsurance } from "../../utils/commonFunction/calcExcessInsurance";
import { useDispatch } from "react-redux";
import { addExcessInsurance } from "../../utils/invoice/invoiceSlice";

function TitleBarMain() {
  const dispatch = useDispatch();
  const three = useSelector((store) => store.stepperSlice.stepThree);
  const one = useSelector((store) => store.stepperSlice.stepOne);
  const two = useSelector((store) => store.stepperSlice.stepTwo);
  const carObj = useSelector((store) => store.carDetailSlice.carDetailObj);
  const thirdStepStatus = useSelector(
    (store) => store.modalToggleSlice.thirdStepFlag
  );
  const totalDays = useSelector(
    (store) => store.bookingInfoSlice.totalNumberOfDays
  );

  const excessCheckState = useSelector((store)=>store.modalToggleSlice.excessCheckState);

  useEffect(() => {
    if (thirdStepStatus && excessCheckState==true) {
      const excessPrice = calculateExcessInsurance(
        carObj.excessInsuranceCharge,
        totalDays
      );
      dispatch(addExcessInsurance(excessPrice));
    } else if (three && excessCheckState==false) {
      dispatch(addExcessInsurance(0));
    }
  }, [totalDays, carObj?.vehicleID]);

  return (
    <React.Fragment>
      <div className="fixed top-0  w-full z-50">
        <div className="flex pl-[10.27%] pr-[10.27%] max-sm:pl-[4%] w-full bg-white  z-10 relative">
          <TitleTabsContainer />
        </div>
      </div>
      <div className="h-[50px]"></div>
    </React.Fragment>
  );
}

export default TitleBarMain;
