import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InitialCard from "../../components/addLandingPages/InitialCard";

export default function ReactSlick({ carList }) {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // Calculate the number of slides needed based on the carList length
  const numSlides = Math.ceil(carList?.length / 6);  

  return (
    <Slider {...settings}>
      {[...Array(numSlides)].map((_, slideIndex) => (
        <div key={slideIndex}>
          <div className="w-full flex flex-wrap ">
            {carList
              ?.slice(slideIndex * 6, (slideIndex + 1) * 6)
              .map((item, index) => {
                return (
                  <div
                    className="w-full lg:w-4/12 md:w-6/12 py-2 px-2 mb-3"
                    key={index}
                  >
                    <InitialCard carDetails={item} cardName="carLeaseCard" />
                  </div>
                );
              })}
          </div>
        </div>
      ))}
    </Slider>
  );
}
