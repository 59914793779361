import React, { useEffect, useState } from "react";
import logo from "./pac.png";
import { FormControlLabel, Checkbox } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addModifyExtras, removeModifyExtras, toggleCDW, toggleCDWCheck } from "../../utils/modifyBookingSlices/modifyBooking";

function PAndCCardNew() {


  const dispatch = useDispatch();
  const currencyName = useSelector((store) => store.currencySlice.currentCurr);
  const currencyPrice = useSelector(
    (store) => store.currencySlice.currentCurrRate
  );
  const modifyReservationDays = useSelector(
    (store) => store.modifyBookingSlice.modifyReservationDays
  );
  const insuranceArr = useSelector(
    (store) => store.insuranceSlice.insuranceArr
  );
  const cdwCheckState = useSelector(
    (store) => store.modifyBookingSlice.cdwCheck
  );
  const [cdwCheck, setCdwCheck] = useState(false);

  const extraObject = insuranceArr[0]
  
  useEffect(()=>{
    if (cdwCheckState) {
      setCdwCheck(true);
    }
  }, [])


  const handleInsurance = (insuranceData, insurancePrice) => {

    if (cdwCheck) {

      const cdwObj = {
        cdwPrice: 0,
        cdwState: false
      }
      dispatch(toggleCDWCheck(false));
      dispatch(toggleCDW(cdwObj));
      dispatch(removeModifyExtras(insuranceData.id));
    } else {

      const cdwObj = {
        cdwPrice: Number(insurancePrice),
        cdwState: true
      }
      dispatch(toggleCDWCheck(true));
      dispatch(toggleCDW(cdwObj));
      dispatch(addModifyExtras(insuranceData));
    }
    setCdwCheck(!cdwCheck);
  };




  return (
    <React.Fragment>
       {/* ${cdwCheck ? 'border-orange-500' : "border-white"} */}
      <div className={`shadow-xl rounded-md mb-4 cursor-pointer hover:border hover:border-black bg-white border`}>
        <div className="icon flex">
          <div className="img flex justify-center items-center  pl-4 pr-4 ">
            <img src={logo} alt="" className="border rounded-full max-w-sm " />
          </div>
          <div className="flex flex-col justify-center items-start pt-6  pb-2 pr-4 pl-4">
            <span className="font-semibold text-sm mb-2">
            Collision Damage Waiver (CDW)
            </span>
            <span className="text-[12px] font-normal leading-4 text-[#6A6A6A]">
            Your solid back-up plan. With CDW, you are protected if your personal insurance or credit card doesn't cover you for losses from a stolen or damaged rental.
            </span>
          </div>
        </div>
        <div className="check flex pl-8 pb-2">
          <FormControlLabel
            label={
              <span className="text-sm font-semibold  text-[#0077B6] leading-4">
                {
                   currencyName +
                    " " +
                    (parseFloat(Object.values(extraObject?.price)[0])
                       * currencyPrice * modifyReservationDays)?.toFixed(2) +
                    ` - ${modifyReservationDays} DAY`
                    }
              </span>
            }
            control={
              <Checkbox
                checked={
                  cdwCheck
                }
                onChange={
                      () => {
                        let clickInsurancePrice = Object.values(
                          extraObject?.price
                        );
                        clickInsurancePrice = parseFloat(clickInsurancePrice[0]) * modifyReservationDays;
                        handleInsurance(extraObject, clickInsurancePrice);
                      }
              }
              />
            }
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default PAndCCardNew;
