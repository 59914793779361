import React, { useEffect,useState } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function ReservationCard(){
    
    const navigate = useNavigate();
    const firstName = useSelector((store)=>store.userSlice.firstName);
    const email = useSelector((store)=>store.userSlice.emailId);
    const reservationId = useSelector((store)=>store.reservationSlice.resId);
    const pickUpDes = useSelector(
        (store) => store.bookingInfoSlice.pickupLocation
      );
      const carObj = useSelector((store) => store.carDetailSlice.carDetailObj);
    const moveToHome = ()=>{
        navigate("/");
    }  
    
    // useEffect(()=>{
    //     if(pickUpDes=="" || carObj=={}){
    //         navigate('/')
    //       }
    // },[])
    return(
        <React.Fragment>
            <div className="w-full flex flex-col justify-start items-center bg-white rounded-md border pt-6">
                <CheckCircleIcon className="text-5xl text-[#092C85] mb-4"/>
                <span className="font-semibold text-xl mb-4 max-sm:text-center">Thanks for booking with WTi Car Rental, {firstName}!</span>
                <span className="font-medium text-base mb-4">We have emailed your confirmation to {email}</span>
                <span className="font-medium text-base mb-4">Confirmation number: {reservationId}</span>
                <button className="py-[10px] text-white px-[35px] bg-[#092C85] rounded-md mb-4" onClick={()=>moveToHome()}>Make a new reservation</button>
            </div>

        </React.Fragment>
    )
}

export default ReservationCard;