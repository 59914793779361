import React from "react";
import chart from "./chartSvg.svg";

function AboutGraph() {
  return (
    <div className="w-full pl-[10.27%] pr-[10.27%] flex justify-evenly items-center  mt-8 max-sm:w-full max-sm:flex max-sm:flex-col">
      <div className="flex flex-col max-sm:w-full">
        <span className="font-semibold max-sm:text-center text-2xl text-[#0F0A34] leading-8 mb-2  ">
          <h2>A Decade of Distinction</h2>
        </span>
        <span className="text-left text-sm max-sm:text-center">
          WTi Mobility&#39;s growth story is nothing short of remarkable. With
          our team expanding <br/>from 84 to over 800 dedicated professionals and
          partnerships skyrocketing from 51<br/> to 2200+, our achievements resonate
          far beyond numbers. Our commitment to<br/> sustainable commuting is evident
          with the integration of Electric Vehicles (EVs)<br/> into our diverse
          service spectrum.
        </span>
      </div>
      <div>
        <div className="p-2 rounded-md bg-white flex justify-center items-center drop-shadow-xl ">
          <img src={chart} alt="" />
        </div>
      </div>
    </div>
  );
}

export default AboutGraph;
