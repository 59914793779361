import { createSlice } from "@reduxjs/toolkit";



const carDetailslice = createSlice({
    name:"carDetailslice",
    initialState:{
        payNow:false,
        payLater:false,
        carDetailObj:{}
    },
    reducers:{
        switchToPayNow:(state,action)=>{
            state.payNow = true;
            state.payLater = false;
            state.carDetailObj = action.payload;
        },
        switchToPayLater:(state,action)=>{
            state.payNow = false;
            state.payLater = true;
            state.carDetailObj = action.payload;
        },
        clearCarDetailSlice:(state,action)=>{
            state.payNow = false;
            state.payLater = false;
            state.carDetailObj = {};
        }

    }
})

export const {switchToPayLater,switchToPayNow,clearCarDetailSlice} = carDetailslice.actions

export default carDetailslice.reducer;