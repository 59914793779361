import { createSlice } from "@reduxjs/toolkit";

const modifyBookingSlice = createSlice({
  name: "modifyBookingSlice",
  initialState: {
    modifyDetails: {
      car: {},
      reservationID: "",
      reservationStatus: "",
      payType: "",
      paymentMethod: "",
      reservationDays: "",
      travelItenary: {},
      currencyInfo: "",
      baseRate: 0,
      paymentStatus: "",
      totalCharges: 0,
      deliveryCharge: 0,
      excessInsuranceCharge: 0,
      addOnCharges: 0,
      collectionCharge: 0,
      taxes: 0,
      extras: [],
      advanceInvoiceID: "",
      lastModifiedReservationID: "",
      lastModifiedInvoiceID: ""
    },
    modifyReservationDays: 0,
    modifiedPrice: 0,
    modifiedPriceWithoutCurrency: 0,
    modifyExcessInsurance: 0,
    modifyFlag: false,
    travelItenaryObj: {
      pickupLocation: "",
      pickupDate: "",
      pickupTime: "",
      dropLocation: "",
      dropDate: "",
      dropTime: "",
    },
    modifyCarObj: {
      type: "",
      typeID: 0,
      src: "",
      title: "",
      basePrice: 0
    },
    modifyBasePrice: 0,
    additionalDriverPrice: 0, 
    cdwPrice: 0,
    babySeatPrice: 0, 
    personalInsurancePrice: 0, 
    additionalDriverCheck: false, 
    cdwCheck: false,
    babySeatCheck: false, 
    personalInsuranceCheck: false,
    carId: "",
    finalExtrasArr: [],
    calculatedExtras: 0,
    modifyNoDeposit: 0,
    modifyFullDeposit: 0,
    modifyInvoiceObj: {},
    finalPriceModifyWithCurrency: 0,
    modifiedResID : "",
    modifiedInvoiceID : ""
  },
  reducers: {
    addModifyBookingDetails: (state, action) => {
      state.modifyDetails.car = action.payload.car;
      state.modifyDetails.reservationID = action.payload.reservationID;
      state.modifyDetails.reservationStatus = action.payload.reservationStatus;
      state.modifyDetails.payType = action.payload.payType;
      state.modifyDetails.paymentMethod = action.payload.paymentMethod;
      state.modifyDetails.paymentStatus = action.payload.paymentStatus;
      state.modifyDetails.reservationDays = action.payload.reservationDays;
      state.modifyDetails.travelItenary = action.payload.travelItenary;
      state.modifyDetails.currencyInfo = action.payload.currencyInfo;
      state.modifyDetails.baseRate = action.payload.baseRate;
      state.modifyDetails.totalCharges = action.payload.totalCharges;
      state.modifyDetails.deliveryCharge = action.payload.deliveryCharge;
      state.modifyDetails.excessInsuranceCharge =
        action.payload.excessInsuranceCharge;
      state.modifyDetails.addOnCharges = action.payload.addOnCharges;
      state.modifyDetails.collectionCharge = action.payload.collectionCharge;
      state.modifyDetails.taxes = action.payload.taxes;
      state.modifyDetails.extras = action.payload.extras;
      state.modifyDetails.advanceInvoiceID = action.payload.advanceInvoiceID;
      state.modifyDetails.lastModifiedReservationID = action.payload.lastModifiedReservationID;
      state.modifyDetails.lastModifiedInvoiceID = action.payload.lastModifiedInvoiceID;
    },
    clearModifyBookingDetails: (state, action) => {
      state.modifyDetails.car = {};
      state.modifyDetails.reservationID = "";
      state.modifyDetails.reservationStatus = "";
      state.modifyDetails.payType = "";
      state.modifyDetails.reservationDays = "";
      state.modifyDetails.travelItenary = {};
      state.modifyDetails.currencyInfo = "";
      state.modifyDetails.baseRate = 0;
      state.modifyDetails.paymentStatus = "";
      state.modifyDetails.paymentMethod = "";
      state.modifyDetails.totalCharges = 0;
      state.modifyDetails.deliveryCharge = 0;
      state.modifyDetails.excessInsuranceCharge = 0;
      state.modifyDetails.addOnCharges = 0;
      state.modifyDetails.collectionCharge = 0;
      state.modifyDetails.taxes = 0;
      state.modifyDetails.extras = [];
      state.modifyDetails.advanceInvoiceID = "";
      state.modifyDetails.lastModifiedReservationID = "";
      state.modifyDetails.lastModifiedInvoiceID = "";
    },
    addModifyInvoiceObj: (state, action) => {
      state.modifyInvoiceObj = action.payload;
    },
    addModifyReservationDays: (state, action) => {
      state.modifyReservationDays = action.payload;
    },
    addModifiedPrice: (state, action) => {
      state.modifiedPrice = action.payload;
    },
    addModifiedPriceWithoutCurrency: (state, action) => {
      state.modifiedPriceWithoutCurrency = action.payload;
    },
    addModifyBasePrice: (state, action) => {
      state.modifyBasePrice = action.payload;
    },
    addModifyExcessInsurance: (state, action) => {
      state.modifyExcessInsurance = action.payload;
    },
    addModifyFullDeposit: (state, action) => {
      state.modifyFullDeposit = action.payload;
    },
    addModifyNoDeposit: (state, action) => {
      state.modifyNoDeposit = action.payload;
    },
    addCalculatedExtras: (state, action) => {
      state.calculatedExtras = action.payload;
    },
    addModifyExtras: (state, action) => {
        const index = state.finalExtrasArr.length ? state?.finalExtrasArr?.findIndex(obj => obj.id == action.payload.id) : -1;
      
        if (index !== -1) {
          // If the object with the same id exists, override it
          state.finalExtrasArr[index] = action.payload;
        } else {
          // If the object with the same id does not exist, add the new object
          state.finalExtrasArr.push(action.payload);
        }
    },
    finalChargeWithCurrency: (state, action) => {
        state.finalPriceModifyWithCurrency = action.payload
    },
    removeModifyExtras: (state, action) => {
      state.finalExtrasArr = state.finalExtrasArr.length && state.finalExtrasArr?.filter(obj => obj.id !== action.payload);
    },
    emptyModifyExtras: (state, action) => {
      state.finalExtrasArr = []
    },
    updateModifyFlag: (state, action) => {
      state.modifyFlag = action.payload; // true or false
    },
    addModifyTravelItenary: (state, action) =>
       {
      state.travelItenaryObj.pickupLocation = action.payload.pickupLocation;
      state.travelItenaryObj.pickupDate = action.payload.pickupDate;
      state.travelItenaryObj.pickupTime = action.payload.pickupTime;
      state.travelItenaryObj.dropLocation = action.payload.dropLocation;
      state.travelItenaryObj.dropDate = action.payload.dropDate;
      state.travelItenaryObj.dropTime = action.payload.dropTime;
    },
    removeModifyTravelItenary: (state, action) =>
       {
      state.travelItenaryObj.pickupLocation = "";
      state.travelItenaryObj.pickupDate = "";
      state.travelItenaryObj.pickupTime = "";
      state.travelItenaryObj.dropLocation = "";
      state.travelItenaryObj.dropDate = "";
      state.travelItenaryObj.dropTime = "";
    },
    addModifyCarDetail: (state, action) => {
      state.modifyCarObj.type = action.payload.type;
      state.modifyCarObj.typeID = action.payload.typeID;
      state.modifyCarObj.src = action.payload.src;
      state.modifyCarObj.title = action.payload.title;
      state.modifyCarObj.basePrice = action.payload.basePrice;
    },
    clearModifyCarDetail: (state, action) => {
      state.modifyCarObj.type = "";
      state.modifyCarObj.typeID = 0;
      state.modifyCarObj.src = "";
      state.modifyCarObj.title = "";
      state.modifyCarObj.basePrice = 0;
    },
    toggleAdditionalDriver: (state, action) => {
      if(action.payload.additionalDriverState == true){
        state.additionalDriverPrice = action.payload.additionalDriverPrice
      } else {
        state.additionalDriverPrice = 0
      }
    },
    toggleCDW: (state, action) => {
      if(action.payload.cdwState == true){
        state.cdwPrice = action.payload.cdwPrice
      } else {
        state.cdwPrice = 0
      }
    },
    toggleBabySeat: (state, action) => {
      if(action.payload.babySeatState == true){
        state.babySeatPrice = action.payload.babySeatPrice
      } else {
        state.babySeatPrice = 0
      }
    },
    togglePersonalInsurance: (state, action) => {
      if(action.payload.personalInsuranceState == true){
        state.personalInsurancePrice = action.payload.personalInsurancePrice
      } else {
        state.personalInsurancePrice = 0
      }
    },
    toggleCDWCheck: (state, action) => {
      state.cdwCheck = action.payload
    },
    toggleAdditionalDriverCheck: (state, action) => {
      state.additionalDriverCheck = action.payload
    },
    toggleBabySeatCheck: (state, action) => {
      state.babySeatCheck = action.payload
    },
    togglePersonalInsuranceCheck: (state, action) => {
      state.personalInsuranceCheck = action.payload
    },
    addCarId: (state, action) => {
      state.carId = action.payload
    },
    addModifiedResID: (state, action) => {
      state.modifiedResID = action.payload
    },
    addModifiedInvoiceID: (state, action) => {
      state.modifiedInvoiceID = action.payload
    },
    clearTogglePrices: (state, action) => {
      state.additionalDriverCheck = false;
      state.cdwCheck = false;
      state.babySeatCheck = false;
      state.personalInsuranceCheck = false;
      state.additionalDriverPrice = 0;
      state.cdwPrice = 0;
      state.babySeatPrice = 0;
      state.personalInsurancePrice = 0;
    }, 
  },
});

export const {
  addModifiedResID,
  addModifiedInvoiceID,
  addModifyInvoiceObj,
  clearModifyCarDetail,
  addModifiedPriceWithoutCurrency,
  addModifyBookingDetails,
  toggleBabySeatCheck,
  toggleAdditionalDriverCheck,
  toggleCDWCheck,
  addCalculatedExtras,
  clearModifyBookingDetails,
  clearTogglePrices,
  addModifyReservationDays,
  addModifiedPrice,
  addModifyExcessInsurance,
  addModifyExtras,
  toggleAdditionalDriver,
  toggleCDW,
  toggleBabySeat,
  removeModifyExtras,
  removeModifyTravelItenary,
  addModifyBasePrice,
  updateModifyFlag,
  addModifyTravelItenary,
  addModifyCarDetail,
  addCarId,
  emptyModifyExtras,
  finalChargeWithCurrency,
  addModifyNoDeposit,
  addModifyFullDeposit,
  togglePersonalInsuranceCheck,
  togglePersonalInsurance
} = modifyBookingSlice.actions;
export default modifyBookingSlice.reducer;
