import React from 'react'
import FaqBar from './FaqBar'

const MyFAQ = () => {
    const faqArr = [
        {
            question: "What areas do you provide Aaveg bus services to?",
            answer: "Our shuttle bus services cover a wide range of areas, including [list specific locations or regions]. We strive to accommodate the transportation needs of various communities and organizations"
        },
        {
            question: "What types of Aaveg buses do you offer?",
            answer: "We offer a diverse fleet of shuttle buses tailored to meet different needs. Whether you require small, medium, or large capacity buses, we have options available to suit your requirements."
        },
        {
            question: "Are your Aaveg bus wheelchair accessible?",
            answer: " Yes, we prioritize accessibility and ensure that a significant portion of our shuttle buses are equipped with wheelchair ramps or lifts to accommodate passengers with mobility needs."
        },
        {
            question: "How do I book a Aaveg bus service?",
            answer: "Booking a shuttle bus service with us is easy! Simply [provide instructions on how to book, such as contacting us via phone, email, or through an online booking form] and our friendly staff will assist you in scheduling your transportation."
        },
        {
            question: "What safety measures do you have in place?",
            answer: "Absolutely! We understand that every transportation need is unique, which is why we offer customizable shuttle bus services. Whether you require specific pick-up/drop-off locations, special amenities onboard, or tailored scheduling, we're here to accommodate your requests."
        },
        {
            question: "Can I customize my Aaveg bus service?",
            answer: "Your safety is our top priority. Our shuttle buses undergo regular maintenance checks, and our drivers are trained professionals with excellent safety records. Additionally, we adhere to all safety regulations and guidelines set forth by regulatory authorities."
        },
        {
            question: "What is the cancellation policy for Aaveg bus bookings?",
            answer: "Our cancellation policy varies depending on factors such as the type of service booked, the notice period provided, and any applicable fees. Please refer to our terms and conditions or contact us directly for information regarding cancellations and refunds."
        },
        {
            question: "Do you offer shuttle bus services for special events or occasions?",
            answer: "Yes, we provide shuttle bus services for a wide range of special events and occasions, including weddings, corporate events, conferences, and more. Contact us to discuss your event's transportation needs, and we'll work with you to create a customized solution."
        },
        {
            question: "Are your Aaveg buses environmentally friendly?",
            answer: "We are committed to sustainability and operate a fleet of modern, fuel-efficient shuttle buses. By choosing our services, you can rest assured that you're contributing to environmental conservation efforts."
        },
        {
            question: "How far in advance should I book an Aaveg bus service?",
            answer: "We recommend booking your shuttle bus service as far in advance as possible to ensure availability, especially for peak times or busy seasons. However, we also strive to accommodate last-minute bookings whenever feasible."
        },
    ]



    return (
        <div className='py-[4rem] px-3 bg-[#ffffff]'>
            <div className='flex flex-col w-full xl:w-[1109px] mx-auto'>
                <div className='flex justify-center mb-8'>
                    <h2 className='font-semibold leading-[44px] text-[40px] bg-clip-text bg-gradient-to-b from-[#0077B6] to-[#00B4D8] text-transparent'>Frequently Asked Questions</h2>
                </div>
                <div className='flex flex-col gap-5'>
                    {
                        faqArr.map((faq ,index) => {
                            return (

                                <FaqBar faq = {faq} key={index}/>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default MyFAQ
