import React from "react";
import { useNavigate } from "react-router-dom";

const Shadow = {
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px"
}


const CarSpecifiRecent = () => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-center items-center lg:h-[388px] h-auto rounded-[12px] shadow-[0px_5px_15px_0px_rgba(0,0,0,0.15)]">
      <div className="flex justify-center flex-col items-center gap-[30px] py-7 px-3 w-[1019px] mx-auto">
        <div>
          <h2 className="text-center text-[#0F0A34] font-semibold text-[28px]">
            Rent Hyundai Accent 2024
          </h2>
        </div>
        <div>
          <p className="text-center text-[18px] text-[#868686] font-normal">
            Experience convenience, comfort, and dependability by choosing the
            Hyundai Accent 2023 rental with Invygo! Beyond providing a stylish
            ride, renting a Hyundai Accent 2023 guarantees a seamless and
            hassle-free driving experience, showcasing Hyundai's dedication to
            delivering top-notch vehicles. Dive into our FAQ section for more
            information or connect with our responsive customer support team via
            chat - we're committed to assisting you throughout your Hyundai
            Accent 2023 rental adventure!
          </p>
        </div>
        <div className="flex justify-center items-center">
            <button className="w-[145px] rounded-[8px] py-[18px] px-[20px] text-[#fff] bg-[#0F0A34]" onClick={()=>navigate("/")}>
                Rent Now
            </button>
        </div>
      </div>
    </div>
  );
};

export default CarSpecifiRecent;
