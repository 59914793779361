import React, { useState, useRef } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import RfpPage from "./RfpPage";
import { IoIosArrowForward } from "react-icons/io";
import { Helmet } from "react-helmet";
import BreadcrumbAll from "../../utils/breadcrumb/BreadCrumbAll";
import { auth_credentials, baseUrl } from "../../config";

function BlogTest() {
  const Navigate = useNavigate();

  const divRef = useRef(null);
  const [favData, setFavData] = useState([]);


  useEffect(() => {

    const funcData = async () => {
      try {
        const result = await fetch(
          `${baseUrl}/0auth/Blog/getFavCategoriesblog`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Basic ${auth_credentials}`
            },
          }
        );


        // let response = await axios.get("http://43.205.205.54:5000/0auth/Blog/getFavCategoriesblog")
        const resdatablog = await result.json();

        setFavData(resdatablog);
      } catch (error) {
        console.error("Error getting fav categories: ", error.message);
      }
    };

    funcData();
  }, []);


  const extractTextWithoutTags = (html) => {
    // Remove HTML tags and trim the content
    const plainText = html.replace(/<[^>]*>/g, "").trim();

    // Get the first 200 characters
    const limitedText = plainText.slice(0, 180);
    return { __html: limitedText };
  };

  const funcDate = (timestamp) => {
    try {
      const date = new Date(timestamp);

      if (isNaN(date.getTime())) {
        // Invalid timestamp or date string
        throw new Error("Invalid time value");
      }

      const formattedDate = new Intl.DateTimeFormat("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      }).format(date);

      return formattedDate.toString();
    } catch (error) {
      console.error("Error formatting timestamp:", error.message);
      return "Invalid Date";
    }
  };


  const handleClick = (slug, categoryName) => {
    const formattedCategoryName = categoryName.replace(/\s+/g, "-");
    const lowercase = formattedCategoryName.toLowerCase();
    Navigate(`/blog/${lowercase}/${slug}`);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const onCategoryHandler = (categoryName) => {
    const formattedCategoryName = categoryName.replace(/\s+/g, "-");
    const lowercase = formattedCategoryName.toLowerCase();
    Navigate(`/blog/${lowercase}`);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  function truncateText(text, limit) {
    return text && text.length > limit
      ? text.substring(0, limit) + "..."
      : text;
  }


  const handleClicks = (tags) => {
    const formattedtags = tags.replace(/\s+/g, "-");
    const lowerCaseCate = formattedtags.toLowerCase();

    Navigate(`/blog/tag/${lowerCaseCate}`);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  const categoriesList = [
    { value: "Things To Do", label: "Things To Do" },
    { value: "Best Time To Visit", label: "Best Time To Visit" },
    { value: "Explore Dubai", label: "Explore Dubai" },
    { value: "Travel", label: "Travel" },
    { value: "Rent A Car", label: "Rent A Car" },
    { value: "Airport Transfer", label: "Airport Transfer" },
    { value: "Hotels", label: "Hotels" },
    { value: "Food", label: "Food" },
    { value: "Corporate Mobility", label: "Corporate Mobility" },
    { value: "Events", label: "Events" },
  ];

  const BlogComponent = () => {
    return (
      <div className="w-full justify-center item-center max-sm:block flex flex-col md:flex-row h-[600px] md:h-[530px] gap-1 my-10">
        <div
          className="backdrop-brightness-[.90] relative h-[35%] md:h-full md:w-[50%] w-full hover:scale-[1.007] "
          onClick={() => handleClick(favData[0]?.slugs, favData[0].categories)}
        >
          <img
            role="button"
            src={favData.length === 0 ? "" : favData[0]?.image}
            className="h-[100%] top-0 left-0 cursor-pointer w-full"
          />
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="w-full cursor-pointer absolute inset-0">
            <h1 className="absolute bottom-12 text-[22px] px-4 font-bold text-white">
              {truncateText(favData[0]?.title, 50)}
            </h1>
            <div className="absolute bottom-6 flex pl-4 pt-4 max-lg:pt-2  ">
              <div className="text-[12px] text-white flex items-center justify-between ">
                <DateRangeIcon className="text-[20px] mr-1" />
                {funcDate(favData[0]?.createdAt)}
                {}
              </div>
              <div className="text-[12px] left-[7.5rem] pl-1 nflex items-center justify-between gap-1 text-white">
                <AccessTimeIcon className="text-[20px] mr-1" />
                {favData[0]?.readingtime}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:w-[50%] h-[65%] md:h-[100%] gap-1">
          <div
            className="backdrop-brightness-[.90] relative h-[100%] md:h-[50%] hover:scale-[1.007]"
            onClick={() =>
              handleClick(favData[1]?.slugs, favData[1].categories)
            }
          >
            <img
              role="button"
              src={favData.length === 0 ? "" : favData[1]?.image}
              className="top-0 left-0 h-full cursor-pointer"
            />
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className="absolute inset-0 w-full cursor-pointer">
              <h1 className="absolute bottom-12 text-[20px] pt-44 px-4 font-semibold text-white">
                {truncateText(favData[1]?.title, 30)}
              </h1>
              <div className="flex pl-4 pt-1 absolute bottom-6">
                <div className=" text-[12px] text-white flex justify-between items-center pr-3 ">
                  <DateRangeIcon className="text-[20px] mr-1 " />{" "}
                  {funcDate(favData[1]?.createdAt)}
                </div>
                <div className=" text-[12px] left-[120px] items-center flex gap-1 text-white justify-between ">
                  <AccessTimeIcon className="text-[20px] " />
                  {favData[1]?.readingtime}
                </div>
              </div>
            </div>
          </div>
          <div className=" h-[100%] md:h-[50%] flex flex-row gap-1">
            <div
              className="backdrop-brightness-[.90] relative w-[50%] hover:scale-[1.007] "
              onClick={() =>
                handleClick(favData[2]?.slugs, favData[2].categories)
              }
            >
              <img
                role="button"
                src={favData.length === 0 ? "" : favData[2]?.image}
                className="top-0 left-0 h-full cursor-pointer"
              />
              <div className="absolute inset-0 bg-black opacity-50"></div>
              <div className="absolute inset-0 w-[100%] max-sm:w-full pt-3 cursor-pointer">
                <h1 className="absolute bottom-12 text-[20px] pt-32 px-3 font-semibold text-white max-sm:text-lg max-sm:px-3 ">
                  {truncateText(favData[2]?.title, 50)}
                </h1>
                <div className="flex pt-2 absolute bottom-6">
                  <div className=" text-[12px] pl-3 text-white flex justify-between items-center pr-3 ">
                    <DateRangeIcon className="text-[20px] mr-1  " />{" "}
                    {funcDate(favData[2]?.createdAt)}
                  </div>
                  <div className=" text-[12px] left-[120px] items-center flex gap-1 text-white justify-between ">
                    <AccessTimeIcon className="text-[20px] " />{" "}
                    {favData[2]?.readingtime}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="relative w-[50%] hover:scale-[1.007] sm:backdrop-brightness-[.90]"
              onClick={() =>
                handleClick(favData[3]?.slugs, favData[3].categories)
              }
            >
              <img
                role="button"
                src={favData.length === 0 ? "" : favData[3]?.image}
                className="top-0 left-0 h-full cursor-pointer"
              />
              <div className="absolute inset-0 bg-black opacity-50"></div>
              <div className="absolute inset-0 w-full  pt-3 max-sm:w-full cursor-pointer">
                <h1 className="absolute bottom-12 text-[20px] pt-32 px-3 font-semibold text-white max-sm:text-lg max-sm:px-3">
                  {truncateText(favData[3]?.title, 50)}
                </h1>
                <div className="flex absolute bottom-6">
                  <div className=" text-[12px] pl-3 pr-3 pt-2 text-white flex justify-between items-center ">
                    <DateRangeIcon className="text-[20px] mr-1 " />{" "}
                    {funcDate(favData[3]?.createdAt)}
                  </div>
                  <div className=" text-[12px] left-[120px] items-center pt-2 flex text-white justify-between ">
                    <AccessTimeIcon className="text-[20px]  mr-1" />{" "}
                    {favData[3]?.readingtime}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          WTi Car Rental Blog | Rent A Car in Dubai | Dubai Self Drive
        </title>

        <meta
          name="description"
          content="WTi Car Rental offers a wide range of vehicles at very affordable rates. Explore the best self drive car rental deals in Dubai with extra benefits and quality services."
        />
        <meta
          name="keywords"
          content="car rental blog, rent a car blog, self drive car rental blog, dubai car rental blog, wti car rental blog, wti uae blog, wti mobility blog"
        />

        <meta name="revisit after" content="7 days" />
        <meta name="robots" content="follow, index" />
        <meta name="language" content="English" />
        <meta name="country" content="United Arab Emirates (UAE)" />
        <meta name="copyright" content="WTi Rent A Car LLC" />
        <meta name="publisher" content="wticarrental.ae" />
        <meta name="distribution" content="Global" />

        <link rel="canonical" href="https://www.wticarrental.ae/blog" />

        <meta
          property="og:title"
          content="WTi Car Rental Blog | Rent A Car in Dubai | Dubai Self Drive"
        />
        <meta
          property="og:description"
          content="WTi Car Rental offers a wide range of vehicles at very affordable rates. Explore the best self drive car rental deals in Dubai with extra benefits and quality services."
        />
        <meta property="og:image" content="" />
        <meta property="og:url" content="https://www.wticarrental.ae/blog" />
      </Helmet>
      <BreadcrumbAll />
      {favData.length ? (
        <div className="w-full h-full flex justify-center items-center  ">
          <div className="w-[95%] max-sm:w-full ">
            <div className="h-14 bg-white my-4 text-[#0F0A34] text-center text-[30px] py-2 font-semibold ">
              <h1>Blogs</h1>
            </div>

            {/* Blog Component here */}
            <BlogComponent />
            <div className="w-full h-[30vh] my-8 flex justify-center items-center ">
              <div className="w-[100%] h-[30vh] block border border-gray-400 rounded-lg max-sm:px-2 ">
                <div className="w-full">
                  <h2 className="text-[22px] text-center font-semibold pt-8 text-[#0F0A34] max-sm:pt-5 ">
                    Get a weekly dose of latest stories and useful tips directly
                    in your inbox!
                  </h2>
                </div>
                <div className="flex justify-center items-center gap-5 py-10 max-sm:py-5 max-sm:gap-2 max-sm:px-4 ">
                  <div className=" w-[30%] max-sm:w-full ">
                    <input
                      type="text"
                      className="w-full h-[6vh] rounded-lg outline-none p-2  border-[2px]  border-gray-400  "
                    />
                  </div>
                  <div className=" w-[10%] max-sm:w-[50%] ">
                    <button className="w-full h-[6vh] bg-[#0F0A34] text-white rounded-lg text-xl  ">
                      Subscribe
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-full">
              <div className="font-semibold text-[18px]">Trending Topics</div>
            </div>
            <div className="flex justify-center  items-center  ">
              <div className="grid grid-cols-3 gap-8 max-sm:grid-cols-1">
                {favData.map((items, index) => (
                  <div
                    key={index}
                    className=" rounded  "
                    onClick={() => handleClick(items?.slugs, items.categories)}
                  >
                    <div>
                      <img
                        src={items?.image}
                        alt=""
                        className=" w-full rounded bg-center bg-cover  cursor-pointer hover:scale-[1.008] h-[13rem]"
                      />
                    </div>
                    <div>
                      <h1 className="font-bold text-[18px] p-1 pt-[20px]">
                        {items?.title}
                      </h1>
                    </div>
                    <div className="flex">
                      <div className=" text-[12px] text-stone-400 pr-3 flex justify-between items-center   ">
                        <DateRangeIcon className="text-[20px] mr-1" />
                        {funcDate(items?.createdAt)}
                      </div>
                      <div className=" items-center  text-[12px] flex justify-between  text-stone-400">
                        <AccessTimeIcon className="text-[20px] mr-1" />
                        {items?.readingtime}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className=" max-sm:block mt-5 ">
              <div className="w-[60%]  max-sm:w-full max-sm:h-full ">
                <h2 className="font-bold pt-6 ">You Might Also Like</h2>
                <div className="flex  pb-3">
                  <div className="w-[18%] h-1 bg-black  "></div>
                  <div className="w-[100%] h-[1px] bg-stone-200 my-[1.5px]  "></div>
                </div>
              </div>

              <div className=" flex items-start max-sm:block justify-between">
                <div className="grid grid-rows w-[60%] gap-6 mb-4  max-sm:w-full max-sm:block max-sm:mt-2 ">
                  {favData.map((data, index) => (
                    <div
                      key={index}
                      className=" flex max-sm:block "
                      onClick={() => handleClick(data?.slugs, data.categories)}
                    >
                      <div className="w-[50%]  cursor-pointer hover:scale-[1.008]  h-[13rem] max-sm:w-full ">
                        <img
                          src={data?.image}
                          alt=""
                          className="w-full rounded h-full "
                        />
                      </div>
                      <div className="w-[50%] px-5 max-sm:w-full max-sm:py-4  relative">
                        <div className="block ">
                          <div className="">
                            <a href="" className="font-bold max-sm:w-full ">
                              {data?.title}
                            </a>
                          </div>
                          <div
                            className="w-[100%] pt-4 max-sm:full max-sm:pb-5"
                            ref={divRef}
                          >
                            <p
                              className="text-[10pt] "
                              dangerouslySetInnerHTML={extractTextWithoutTags(
                                data?.paragraph
                              )}
                            />
                          </div>
                          <div className="flex absolute bottom-[16px] max-sm:pb-3 ">
                            <div className=" text-[12px] pr-2 text-stone-400 flex justify-between items-center gap-1 ">
                              <DateRangeIcon className="text-[20px] " />
                              {funcDate(data?.createdAt)}
                            </div>
                            <div className=" text-[12px] items-center flex gap-1  text-stone-400">
                              <AccessTimeIcon className="text-[20px] " />
                              {data?.readingtime}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="w-[35%] max-sm:w-full ">
                  <div className="  mx-10  max-sm:mt-10">
                    <div className="p-4 w-[50%] max-sm:px-0 max-sm:w-full ">
                      <h2 className="text-[18px] font-bold">Category:</h2>
                    </div>
                    <div className="w-full md:w-[70%]">
                      <div className="grid grid-cols-1 w-full rounded-[12px] shadow-[1px_1px_5px_0px_rgba(48,48,48,0.43)]">
                        {categoriesList?.map((itemCategory, index) => (
                          <div
                            key={index}
                            className="rounded w-full hover:cursor-pointer"
                            onClick={() =>
                              onCategoryHandler(itemCategory.value)
                            }
                          >
                            <div className="w-full flex gap-1 items-center text-center p-1 py-3 px-3  bg-white hover:bg-indigo-950 hover:text-white text-[#1d1d1d] border rounded max-sm:w-full">
                              <IoIosArrowForward />
                              {itemCategory.label}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="  mx-10  max-sm:mt-10 mt-10">
                    <div className="p-4 w-[50%] max-sm:px-0 max-sm:w-full ">
                      <h2 className="text-[18px] font-bold  ">Tags:</h2>
                    </div>

                    <div className="grid grid-cols-2 gap-x-2 gap-y-2">
                      {favData.map((itemTags, index) => (
                        <div
                          key={index}
                          className="rounded hover:cursor-pointer"
                          onClick={() =>
                            handleClicks(itemTags.tags.split(",")[0])
                          }
                        >
                          <div className="text-center p-1 shadow-[1px_1px_2px_0px_rgba(48,48,48,0.43)]  bg-white hover:bg-indigo-950 hover:text-white text-black border rounded max-sm:w-full max-sm:mb-2">
                            {" "}
                            {itemTags.tags.split(",")[0]}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-8">
              <button className=" py-2 px-4 bg-[#0F0A34] text-white rounded-lg text-xl items-center">
              <Link to="/all-blogs">View All</Link>
              </button>
            </div>

            <RfpPage apiValue={0} />
          </div>
        </div>
      ) : (
        <div className="flex justify-center h-[400px] items-center">Loading....</div>
      )}
    </>
  );
}

export default BlogTest;
