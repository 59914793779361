import React, { useEffect } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { toggleTermsAndCondition } from "../../utils/ModalSlice/modalToggleSlice";
import { useDispatch } from "react-redux";

function TermsAndConditions() {
  const dispatch = useDispatch();

  const closeTerms = () => {
    dispatch(toggleTermsAndCondition());
  };

  return (
    <div className="w-[100%] h-full flex justify-center items-center">
      <div className="w-full max-sm:w-[100%] h-full mt-2 px-8">
        <div className="flex justify-between items-center bg-white px-4 py-1 w-full box-border cursor-default opacity-100 border-b border-slate-300 h-1/6">
          <h1 className="text-2xl max-sm:text-xl px-6 py-3">
            RENTAL TERMS AND CONDITIONS
          </h1>
        </div>
        <div className="p-6 px-8 flex flex-col relative overflow-y-visible bg-white overflow-auto h-5/6">
          <span className="text-left">
            <p>
              The Company mentioned in the front of this page is licensed for car rental business activity, hereby rents vehicle identified to Hirer subject to all the terms and conditions in the front page and overleaf. Unless inconsistent with the context hereof, the term "vehicle" shall mean the vehicle hereby hired together will all accessories thereto.
            </p>

            <p>
              1. The Hirer hereby acknowledges that the vehicle was delivered to him in good and roadworthy condition by the Company with air conditioning working properly and that the radiator and oil reservoir of the vehicle were full. The Hirer hereby undertakes:
            </p>

            <p>
              1.1 To use and maintain the vehicle, at the termination of the period of hire to handover the vehicle personally to the representative of the Company in the same condition as when delivered to the Hirer. Failing to do so, the hire charges will continue to accrue on the hired vehicle till the date and time the company&#39;s representative receives back the physical possession of the vehicle.
            </p>

            <p>
              1.2 Any damages / missing parts of the vehicle discovered by the Company while taking possession of the vehicle later, even in the absence of the Hirer will be charged at an estimated repair charge as assessed by the Company Garage which the Hirer will be bound to pay without protest.
            </p>

            <p>
              Tyres, wheel rims, wheel caps and key are not covered under insurance unless mentioned in the police report.
            </p>

            <p>
              1.3 Whilst the vehicle is in his / her possession, to supply all petrol and oil necessary to keep the vehicle operational at his own cost and expense.
            </p>

            <p>
              1.4 It is the responsibility of the hirer to return the vehicle with the same fuel level as given by the Company. If not, the Company is authorized to charge the cost of fuel plus a service charge to the hirer&#39;s credit card. Fuel amount not refundable.
            </p>

            <p>
              2. The Company will confirm only a car group and not a specific vehicle; any special request will be subject to availability. Temporarily upgraded vehicles will be swapped upon availability of booked vehicle group.
            </p>

            <p>
              3. The minimum driver&#39;s age driving the rented vehicle should be 22 years. Hirer is responsible for 10% of the repair cost/total loss cost for own vehicle/asset as well as third party vehicle/asset as applicable if vehicle is driven by driver aged below 21 years.
            </p>
            <p>
              4. The Rate quoted will be as per the expected duration of the rental. 
            </p>
            <p>
              4.1  The Hirer shall pay in advance the deposits, hire charges, agreed add-on charges set out in the schedule of charges and all charges for any extension beyond the hire agreement due date or till the time the vehicle is actually returned, whichever is later.
            </p>
            <p>
              4.2  Hirer is obliged to obtain all extensions to this hire agreement from an authorized Company representative and all extensions may be subject to rate increases.
            </p>
            <p>
              4.3  Daily / Weekly / Monthly rates are calculated 24-hour basis.
            </p>
            <p>
             4.4  Daily / Weekly / Monthly rates will be applied based on the actual tenure of agreement irrespective of what is agreed at the start of rental.
            </p>
            <p>
             4.5  In case of monthly agreement, the hirer returns the rented vehicle before the completion of one month, respective daily/weekly tariff will be applicable for the rental period. All the refunds will be processed in 21 working days from the date of vehicle off hire. Refunds on agreements wherein hirer has made payments through credit card will be processed through the respective credit cards.
            </p>
            <p>
             4.6  Daily/weekly agreement cannot be converted to Monthly rental agreement. Minimum rental period is one day.
            </p>
            <p>
            4.7. The
              Hirer irrevocably accepts to pay and settle all charges pertaining
              to the vehicle hire to the Company on or before the return of the
              vehicle. Failing which the hirer authorizes the Company to debit
              the charges on the credit cards as given by the Hirer in Card
              Absent Environment.
            </p>
            <p>
            4.8 Imprint of the hirer&#39;s credit card may
              be taken at the time of opening the contract/during the tenure of
              the contract (if any changes) and will be retained by the Company
              in the same condition as given by the Hirer. An amount will be
              blocked as decided by the Company.
            </p>
            <p>
             4.9 An official receipt has to
              be collected from a WTi Rent a Car LLC representative upon any
              cash payment.
            </p>
             

            <p>5. In the Event of an Accident:</p>
            <p>5.1 The Hirer should not remove the vehicle without informing both the police and the Company.</p>
            <p>5.2 The Hirer must obtain and furnish the following details to the Company:</p>
            <p>a. A police report on the accident, including the other party's name, address, and their insurance company's information.</p>
            <p>b. The registration number and make of the other vehicle(s) involved in the accident, the exact date and location of the accident, and the names and addresses of any witnesses, if applicable.</p>
            <p>5.3 Failure to obtain the above information, in particular, the police report will render the Hirer responsible for all damages, as the insurance company will not compensate the Company without this report.</p>
            <p>6. Hirer will be responsible to pay the Insurance Excess
              amount in the agreement for any damage or loss (including Fire
              &amp; theft) caused to the vehicle whilst it is used, operated or
              driven in conformity with this rental agreement Notwithstanding
              payment of the said fee if the vehicle is used operated or driven
              in violation of any of the provisions of this Vehicle Hire
              agreement, the Company may apply the terms and conditions.</p>
            <p>7. For
              all client at fault accidents and Hit and Run accidents where the
              fault cannot be attributed to a third party the insurance excess
              charge will be as under: a) If excess insurance option availed
              100% excess amount applicable b) If CDW (Collision Damage Waiver)
              purchased, ZERO of excess amount applicable &quot;Police report is
              mandatory for all the above cases.&quot;</p>
            <p>8. The Hirer is warned
              that all and every cost including rental loss due to accident or
              otherwise incurred whilst the hirer or any other person driving
              the vehicle is under the influence of alcohol, drugs or any other
              intoxicating substance will be to the hirer&#39;s account.</p>
            <p>9. The
              Hirer further agrees to pay all the charges as Salik &amp; Darb
              toll crossings, traffic and parking violations during the tenure
              of the agreement. In addition, 25% service charge for toll fees
              and 10% service charge will apply for traffic violations. The
              hirer irrevocably authorises the Company to charge the credit card
              even after the hire agreement is closed without notifying the
              Hirer irrespective of the time that may pass between the
              occurrence and the notification of the same. Hirer is not
              authorized to pay traffic fines and toll charges on-line or to the
              RTA directly. The hirer agrees to pay Knowledge fee as imposed by
              the government.</p>
            <p>10. The Hirer undertakes to deliver the vehicle to
              the Company after completion of every 10,000 kms for lubrication
              service or should the Hirer not do so, to satisfy the Company that
              such service has been duly carried out every 10000 kms. Should any
              mechanical fault occur to the vehicle itself during the hire
              period, the Hirer shall immediately deliver the vehicle to the
              Company for repairs. The Hirer acknowledges that he will bear the
              cost of any repair work done or put in hand by him without the
              prior consent of the Company.</p>
              <p>11. Should the vehicle be damaged in any way whatsoever during the period of this hire agreement, a police report is mandatory for all damages to the vehicle, including those caused by natural perils or natural disasters. Failing to provide a police report while the vehicle is in the Hirer's possession will result in the Company continuing to bill the Hirer for the rental amount and also charging for all repairs.</p>
              <p>11.1 The Company shall be entitled to immediately take possession of the vehicle and initiate any necessary repair work without prejudice to any right which the Company may have against the Hirer for damages. In such an event, the period of hire shall be deemed to continue, and the Hirer shall be obliged to pay the Company the basic rental until the repairs have been completed.</p>
              <p>11.2 If the damage arises from any peril against which the vehicle is insured, the Hirer shall be responsible for the amount of such damage up to a maximum amount as mentioned overleaf. However, should compensation in respect of such damage not be recoverable at law or if the Company, at its sole discretion, decides that such compensation is not economically recoverable from any other person, the Hirer will be obliged to pay the Company the full amount of such compensation.</p>
              <p>12. The
              Hirer shall indemnify the Company against all claims whatever and
              howsoever arising which may be made against the Company arising
              from the use of the vehicle or the manner of its driving while it
              is in the possession of the Hirer in terms of this hire
              agreement. </p>
              <p>13. The Hirer warrants and undertakes that:</p>
              <p>13.1 He is duly licensed to drive the vehicle and will exhibit his license to the Company whenever the Company requires.</p>
              <p>13.2 No person who is not duly licensed will drive the vehicle.</p>
              <p>13.3 Neither he nor any other person will use the vehicle for races, towing, and competition of any sort or for the transport of merchandise or the transport of passengers for hire or in the course of business. This practice is considered a violation under the (Illegal Passenger Transportation) regulation. Therefore, the violating client will be fully responsible for any procedure or fines imposed by the concerned parties in this regard.</p>
              <p>13.4 He will not sub-let the vehicle nor give up possession thereof during the hire period.</p>
              <p>13.5 The hired vehicle is for use in the U.A.E. only unless special arrangements are made in advance.</p>
              <p>13.6 No articles which might cause damage to the vehicle or its upholstery shall be transported in the vehicle. The vehicle will not be used for transporting liquor, drugs, or any other contraband items or any item prohibited as per UAE law.</p>
              <p>13.7 He is liable for the value of the vehicle should it be stolen due to his negligence (e.g., not locking the vehicle, keeping the key in the vehicle, etc.).</p>
              <p>13.8 It is the responsibility of the Hirer to provide the Company with up-to-date documents subject to UAE jurisdiction.</p>

              <p>14. The Hirer shall
              immediately advice the Company of any fault in the vehicle or any
              accident in which it is involved or if the vehicle is stolen and
              shall further in such even complete such forms as may be required
              by the Company and generally assist the Company in any claim which
              the Company may make against its insurers arising out of any such
              accident or theft.</p>
              <p>15. Should the Hirer commit any breach of any
              term of this agreement or should any person during the period of
              hire use the vehicle in a manner which the Company considers
              prejudicial to its rights and interests therein, the Company will
              be entitled to cancel this agreement immediately and retake
              possession of the vehicle without prejudice to any right which the
              Company may have under this agreement or at law and in particular
              without prejudice to the right of the Company to claim the hire
              charges due in terms of this agreement and/ or damages.</p>
              <p>16. The
              Hirer hereby chooses domicilum citandi at executandi at this
              present residential address where all notices and processes may be
              served upon him.</p>
              <p>17. The Hirer will be responsible for any loss
              and damage to the vehicle and any other third party loss or damage
              due to carrying / transporting contraband goods or other
              prohibited items by law and for committing any illegal act /
              omission under the laws of U.A.E. or the laws of other countries,
              where the vehicle is used by the hire.</p>
              <p>18. In the event of parking
              the vehicle in the Airport and the Hirer fails to give the parking
              ticket at the Company&#39;s Airport Office, he/she will be liable
              for penalty. The Hirer will be charged fixed parking fees as
              levied by the Company if the vehicle off hires at the airport
              locations.</p>
              <p>19. The Company will not be responsible for any of the
              hirer&#39;s personal belongings left in the Vehicle. I / We
              acknowledge that I/We have read and understood the terms &amp;
              Conditions Printed above and overleaf and here by acknowledge
              myself ourselves to be bound thereby.</p>


            {/* Keep formatting subsequent paragraphs in a similar manner */}
          </span>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
