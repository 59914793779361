import React from "react";
import { IoMdCheckmark } from "react-icons/io";

const vehicleFeaturArray = [
  {
    feature: "Security System",
  },
  {
    feature: "Air Conditoning",
  },
  {
    feature: "Power Stearing",
  },
  {
    feature: "Air Bags",
  },
  {
    feature: "ABS",
  },
  {
    feature: "Alloy Rims",
  },
  {
    feature: "Parking Sensors",
  },
  {
    feature: "Radio",
  },
  {
    feature: "Cruise Control",
  },
  {
    feature: "Fog Lights",
  },
  {
    feature: "Power Windows",
  },
  {
    feature: "Power Windows",
  },
  {
    feature: "Keyless Entry",
  },
  {
    feature: "Power Mirror",
  },
  {
    feature: "Aux/Audio Input",
  },
  {
    feature: "Aux/Audio Input",
  },
];

const VehicleFeature = () => {
  return (
    <div
      className="flex justify-between items-center vehicle-hightlight lg:h-[280px] h-auto rounded-[12px] shadow-[0px_5px_15px_0px_rgba(0,0,0,0.15)]"
 
    >
      <div className="flex flex-col w-full gap-5  py-7 px-3">
        <div>
          <h3 className="text-center text-[#0F0A34] font-semibold text-[28px]">
            Vehicle features
          </h3>
        </div>
        <div className="flex justify-between items-center flex-wrap mx-auto px-10">
          {vehicleFeaturArray.map((fea, index) => {
            return (
              <div
                className="feature-wrapper w-full md:w-6/12 lg:w-3/12 mb-3"
                key={index}
              >
                <div className="flex items-center gap-2 feature">
                  <div className="flex justify-center items-center rounded-[4px] h-[24px] w-[24px] bg-[#092C85]">
                    <IoMdCheckmark className="text-white text-[20px]" />
                  </div>
                  <span className="text-[#868686] text-[16px]">
                    {fea.feature}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default VehicleFeature;
