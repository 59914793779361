import React from "react";
import CalenderImg from "./images/calendar_month.png";
import Speed from "./images/speed.png";
import Seat from "./images/airline_seat_recline_extra.png";
import Automatic from "./images/auto_transmission.png";
import Price from "./images/price.png";
import Door from "./images/mdi_car-door.png";

const vehicleHighLigthArray = [
  {
    icon: CalenderImg,
    title: "2024",
    subtitle: "Model",
  },
  {
    icon: Speed,
    title: "12,000",
    subtitle: "Kilometers",
  },
  {
    icon: Seat,
    title: "04",
    subtitle: "Seats",
  },
  {
    icon: Automatic,
    title: "Automatic",
    subtitle: "Model",
  },
  {
    icon: Price,
    title: "Petrol",
    subtitle: "Fuel",
  },
  {
    icon: Door,
    title: "05",
    subtitle: "Door",
  },
];




const VehicleHighlights = () => {
  return (
    <div className="flex justify-between items-center vehicle-hightlight lg:h-[280px] h-auto rounded-[12px] shadow-[0px_5px_15px_0px_rgba(0,0,0,0.15)] ">
      <div className="flex flex-col w-full gap-5 py-7 px-3">
        <div>
          <h3 className="text-center text-[#0F0A34] font-semibold text-[28px]">
            Vehicle highlights
          </h3>
        </div>
        <div className="flex flex-wrap items-center mx-auto w-full">
        
          {vehicleHighLigthArray.map((card, index) => {
            return (
              <div className="high-light-cardWrapper w-full md:w-3/12 lg:w-2/12 p-5" key={index}>
                <div className="flex justify-center flex-col items-center w-full cardWrapper bg-[#F8FAFF] h-[140px] rounded-[12px]">
                  <div className="vh-icon">
                    <img src={card.icon} alt="icon" />
                  </div>
                  <div className="vh-title">
                    <h4 className="mt-2 mb-1 text-[24px]">{card.title}</h4>
                  </div>
                  <div className="vh-subtitle">
                    <h4 className="text-[#868686] text-[16px]">{card.subtitle}</h4>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default VehicleHighlights;
