import React, { useState, useRef,useEffect } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import BreadcrumbAll from "../../utils/breadcrumb/BreadCrumbAll";
import { baseUrl } from "../../config";

function ContactUsLanding() {
  useEffect(()=>{
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
},[])
  const navigate = useNavigate();
  const firstName = useRef(null);
  const email = useRef(null);
  const phone = useRef(null);
  const [msg, setMsg] = useState("");
  const [apiCallDone, setApiCallDone] = useState(false);

  const onSubmit = (e) => {

    e.preventDefault();
    let data = JSON.stringify({
      "fullName": firstName.current.value,
      "emailID": email.current.value,
      "phoneNumber": phone.current.value,
      "message": msg
    });
    
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/app/v1/query/generalQuery`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios.request(config)
    .then((response) => {
      if(response.data.generalQueryRecieved==true){
        navigate("/contact/thankyou");
      }
    })
    .catch((error) => {
      console.log(error);
    });
  };


  return (
    <div>
       <Helmet>
      <title>Contact Us - WTi Car Rental</title>
      <meta
        name="description"
        content="Contact us today, and our dedicated team will be happy to help you find the perfect vehicle for your journey."
      />
      <meta
        name="keywords"
        content="contact wti car rental, contact wti uae, contact wti mobility, contact wti rent a car llc"
      />
      <meta name="revisit after" content="7 days" />
      <meta name="robots" content="follow, index" />
      <meta name="language" content="English" />
      <meta name="country" content="United Arab Emirates (UAE)" />
      <meta name="copyright" content="WTi Rent A Car LLC" />
      <meta name="publisher" content="wticarrental.ae" />
      <meta name="distribution" content="Global" />
      <link rel="canonical" href="https://www.wticarrental.ae/contact-us" />

      <meta property="og:title" content="Contact Us - WTi Car Rental" />
      <meta
        property="og:description"
        content="Contact us today, and our dedicated team will be happy to help you find the perfect vehicle for your journey."
      />
      {/* Replace 'blank' with the actual image URL */}
      <meta property="og:image" content="blank" />
      <meta property="og:url" content="https://www.wticarrental.ae/contact-us" />
    </Helmet>
    <BreadcrumbAll />
    <div className="w-full pl-[10.27%] pr-[10.27%] mb-8">
      <div className="w-full flex max-sm:flex max-sm:flex-col max-sm:items-center">
        <div className="contactText flex flex-col  w-[60%]    py-24 max-sm:w-[100%] max-sm:p-0 max-sm:items-center pr-6">
          <h1 className=" text-2xl font-semibold leading mb-8 text-[#0F0A34] ">
            Contact us
          </h1>
          <span className="text-base font-medium text-left text-[#0F0A34] max-sm:text-center ">
            Need assistance with your car rental or have questions about our
            services?
            <br /> Contact us today, and our dedicated team will be happy to
            help you
            <br /> find the perfect vehicle for your journey.
          </span>
          <div className=' max-sm:w-full  my-6'>
        <div className='w-full font-medium text-base bg-[#0F0A34]  py-2 flex justify-center items-center text-white border rounded-t-lg'>
            Support 
        </div>
        <div className='w-full border rounded-b-lg '>
            <div className='pt-6 flex flex-col py-4'>
                <h2 className='font-semibold text-lg text-[#0F0A34] px-6 my-2'>Customer Service:</h2>
                <span className='px-6'>+971 56 909 7524</span>
                <h2 className='font-semibold text-lg text-[#0F0A34] px-6 my-2'> Operations:</h2>
                <span className='px-6'>+971 56 909 6864</span>
                <h2 className='font-semibold text-lg text-[#0F0A34] px-6 my-2'> Business Development:</h2>
                <span className='px-6'>+971 (04) 4489295</span>
                <h2 className='font-semibold text-lg text-[#0F0A34] px-6 my-2'> Sales Manager:</h2>
                <span className='px-6'>+971 50 264 1477</span>
            </div>
        </div>
    </div>

        </div>
        <div className="contactForm w-[40%] flex justify-center items-center pt-10 max-sm:w-[100%]">
          <div className="  py-4 px-10 rounded-md shadow-2xl">
          
              <form className=" ">
                <div className="mb-4">
                  <label
                    htmlFor="name"
                    className="block mb-1 font-semibold text-[#0F0A34]"
                  >
                    Name*
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    // value={formData.name}
                    // onChange={handleChange}
                    value={firstName.value}
                    ref={firstName}
                    className="w-full border  p-2 rounded bg-[#EBEBEB] border-[#C3C3C3]"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="block mb-1 font-semibold text-[#0F0A34]"
                  >
                    Email*
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email.value}
                    ref={email}
                    // value={formData.email}
                    // onChange={handleChange}
                    className="w-full border  p-2 rounded bg-[#EBEBEB] border-[#C3C3C3]"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="phoneNumber"
                    className="block mb-1 font-semibold text-[#0F0A34]"
                  >
                    Phone Number*
                  </label>
                  <input
                    type="tel"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={phone.value}
                    ref={phone}
                    // value={formData.phoneNumber}
                    // onChange={handleChange}
                    className="w-full border  p-2 rounded bg-[#EBEBEB] border-[#C3C3C3]"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="message"
                    className="block mb-1 font-semibold text-[#0F0A34]"
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    // value={formData.message}
                    // onChange={handleChange}
                    value={msg}
                    onChange={(e) => setMsg(e.target.value)}
                    cols="3"
                    rows="3"
                    className="w-full border p-2 rounded bg-[#EBEBEB] border-[#C3C3C3]"
                    required
                  />
                </div>
                <div className="w-full flex justify-center items-center">
                  <button
                    type="submit"
                    className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                    onClick={(e) => onSubmit(e)}
                  >
                    Submit
                  </button>
                </div>
              </form>
         
            {/* <form className=" ">
              <div className="mb-4">
                <label htmlFor="name" className="block mb-1 font-semibold text-[#0F0A34]">
                  Name*
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  // value={formData.name}
                  // onChange={handleChange}
                  value={firstName.value}
                  ref={firstName}
                  className="w-full border border-gray-300 p-2 rounded"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block mb-1 font-semibold text-[#0F0A34]">
                  Email*
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email.value}
                  ref={email}
                  // value={formData.email}
                  // onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="phoneNumber"
                  className="block mb-1 font-semibold text-[#0F0A34]"
                >
                  Phone Number*
                </label>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={phone.value}
                  ref={phone}
                  // value={formData.phoneNumber}
                  // onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="message" className="block mb-1 font-semibold text-[#0F0A34]">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  // value={formData.message}
                  // onChange={handleChange}
                  value={msg}
                  onChange={(e)=>setMsg(e.target.value)}
                  cols="3" rows="3"
                  className="w-full border border-gray-300 p-2 rounded"
                  required
                />
              </div>
              <div className="w-full flex justify-center items-center">
              <button
                type="submit"
                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                onClick={(e)=>onSubmit(e)}
              >
                Submit
              </button>
              </div>
             
            </form> */}
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default ContactUsLanding;
