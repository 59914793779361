export const currCountry = [
    {
        currencyCode: "AED",
        countryName: "United Arab Emirates (UAE)"
    },
    {
        currencyCode: "AFN",
        countryName: "Afghanistan"
    },
    {
        currencyCode: "ALL",
        countryName: "Albania"
    },
    {
        currencyCode: "AMD",
        countryName: "Armenia"
    },
    {
        currencyCode: "ANG",
        countryName: "Netherlands Antilles (Curaçao)"
    },
    {
        currencyCode: "AOA",
        countryName: "Angola"
    },
    {
        currencyCode: "ARS",
        countryName: "Argentina"
    },
    {
        currencyCode: "AUD",
        countryName: "Australia"
    },
    {
        currencyCode: "AWG",
        countryName: "Aruba"
    },
    {
        currencyCode: "AZN",
        countryName: "Azerbaijan"
    },
    {
        currencyCode: "BAM",
        countryName: "Bosnia and Herzegovina"
    },
    {
        currencyCode: "BBD",
        countryName: "Barbados"
    },
    {
        currencyCode: "BDT",
        countryName: "Bangladesh"
    },
    {
        currencyCode: "BGN",
        countryName: "Bulgaria"
    },
    {
        currencyCode: "BHD",
        countryName: "Bahrain"
    },
    {
        currencyCode: "BIF",
        countryName: "Burundi"
    },
    {
        currencyCode: "BMD",
        countryName: "Bermuda"
    },
    {
        currencyCode: "BND",
        countryName: "Brunei"
    },
    {
        currencyCode: "BOB",
        countryName: "Bolivia"
    },
    {
        currencyCode: "BRL",
        countryName: "Brazil"
    },
    {
        currencyCode: "BSD",
        countryName: "Bahamas"
    },
    {
        currencyCode: "BTC",
        countryName: "Bitcoin (Cryptocurrency)"
    },
    {
        currencyCode: "BTN",
        countryName: "Bhutan"
    },
    {
        currencyCode: "BWP",
        countryName: "Botswana"
    },
    {
        currencyCode: "BYN",
        countryName: "Belarus"
    },
    {
        currencyCode: "BYR",
        countryName: "Belarus (Obsolete)"
    },
    {
        currencyCode: "BZD",
        countryName: "Belize"
    },
    {
        currencyCode: "CAD",
        countryName: "Canada"
    },
    {
        currencyCode: "CDF",
        countryName: "Democratic Republic of the Congo"
    },
    {
        currencyCode: "CHF",
        countryName: "Switzerland"
    },
    {
        currencyCode: "CLF",
        countryName: "Chile (Funds)"
    },
    {
        currencyCode: "CLP",
        countryName: "Chile"
    },
    {
        currencyCode: "CNY",
        countryName: "China"
    },
    {
        currencyCode: "COP",
        countryName: "Colombia"
    },
    {
        currencyCode: "CRC",
        countryName: "Costa Rica"
    },
    {
        currencyCode: "CUC",
        countryName: "Cuba (Convertible Peso)"
    },
    {
        currencyCode: "CUP",
        countryName: "Cuba (Peso)"
    },
    {
        currencyCode: "CVE",
        countryName: "Cape Verde"
    },
    {
        currencyCode: "CZK",
        countryName: "Czech Republic"
    },
    {
        currencyCode: "DJF",
        countryName: "Djibouti"
    },
    {
        currencyCode: "DKK",
        countryName: "Denmark"
    },
    {
        currencyCode: "DOP",
        countryName: "Dominican Republic"
    },
    {
        currencyCode: "DZD",
        countryName: "Algeria"
    },
    {
        currencyCode: "EGP",
        countryName: "Egypt"
    },
    {
        currencyCode: "ERN",
        countryName: "Eritrea"
    },
    {
        currencyCode: "ETB",
        countryName: "Ethiopia"
    },
    {
        currencyCode: "EUR",
        countryName: "Eurozone"
    },
    {
        currencyCode: "FJD",
        countryName: "Fiji"
    },
    {
        currencyCode: "FKP",
        countryName: "Falkland Islands (Pound)"
    },
    {
        currencyCode: "GBP",
        countryName: "United Kingdom"
    },
    {
        currencyCode: "GEL",
        countryName: "Georgia"
    },
    {
        currencyCode: "GGP",
        countryName: "Guernsey (Pound)"
    },
    {
        currencyCode: "GHS",
        countryName: "Ghana"
    },
    {
        currencyCode: "GIP",
        countryName: "Gibraltar (Pound)"
    },
    {
        currencyCode: "GMD",
        countryName: "Gambia"
    },
    {
        currencyCode: "GNF",
        countryName: "Guinea"
    },
    {
        currencyCode: "GTQ",
        countryName: "Guatemala"
    },
    {
        currencyCode: "GYD",
        countryName: "Guyana"
    },
    {
        currencyCode: "HKD",
        countryName: "Hong Kong"
    },
    {
        currencyCode: "HNL",
        countryName: "Honduras"
    },
    {
        currencyCode: "HRK",
        countryName: "Croatia"
    },
    {
        currencyCode: "HTG",
        countryName: "Haiti"
    },
    {
        currencyCode: "HUF",
        countryName: "Hungary"
    },
    {
        currencyCode: "IDR",
        countryName: "Indonesia"
    },
    {
        currencyCode: "ILS",
        countryName: "Israel"
    },
    {
        currencyCode: "IMP",
        countryName: "Isle of Man (Pound)"
    },
    {
        currencyCode: "INR",
        countryName: "India"
    },
    {
        currencyCode: "IQD",
        countryName: "Iraq"
    },
    {
        currencyCode: "IRR",
        countryName: "Iran"
    },
    {
        currencyCode: "ISK",
        countryName: "Iceland"
    },
    {
        currencyCode: "JEP",
        countryName: "Jersey (Pound)"
    },
    {
        currencyCode: "JMD",
        countryName: "Jamaica"
    },
    {
        currencyCode: "JOD",
        countryName: "Jordan"
    },
    {
        currencyCode: "JPY",
        countryName: "Japan"
    },
    {
        currencyCode: "KES",
        countryName: "Kenya"
    },
    {
        currencyCode: "KGS",
        countryName: "Kyrgyzstan"
    },
    {
        currencyCode: "KHR",
        countryName: "Cambodia"
    },
    {
        currencyCode: "KMF",
        countryName: "Comoros"
    },
    {
        currencyCode: "KPW",
        countryName: "North Korea"
    },
    {
        currencyCode: "KRW",
        countryName: "South Korea"
    },
    {
        currencyCode: "KWD",
        countryName: "Kuwait"
    },
    {
        currencyCode: "KYD",
        countryName: "Cayman Islands"
    },
    {
        currencyCode: "KZT",
        countryName: "Kazakhstan"
    },
    {
        currencyCode: "LAK",
        countryName: "Laos"
    },
    {
        currencyCode: "LBP",
        countryName: "Lebanon"
    },
    {
        currencyCode: "LKR",
        countryName: "Sri Lanka"
    },
    {
        currencyCode: "LRD",
        countryName: "Liberia"
    },
    {
        currencyCode: "LSL",
        countryName: "Lesotho"
    },
    {
        currencyCode: "LTL",
        countryName: "Lithuania"
    },
    {
        currencyCode: "LVL",
        countryName: "Latvia"
    },
    {
        currencyCode: "LYD",
        countryName: "Libya"
    },
    {
        currencyCode: "MAD",
        countryName: "Morocco"
    },
    {
        currencyCode: "MDL",
        countryName: "Moldova"
    },
    {
        currencyCode: "MGA",
        countryName: "Madagascar"
    },
    {
        currencyCode: "MKD",
        countryName: "North Macedonia"
    },
    {
        currencyCode: "MMK",
        countryName: "Myanmar (Burma)"
    },
    {
        currencyCode: "MNT",
        countryName: "Mongolia"
    },
    {
        currencyCode: "MOP",
        countryName: "Macau"
    },
    {
        currencyCode: "MRO",
        countryName: "Mauritania"
    },
    {
        currencyCode: "MUR",
        countryName: "Mauritius"
    },
    {
        currencyCode: "MVR",
        countryName: "Maldives"
    },
    {
        currencyCode: "MWK",
        countryName: "Malawi"
    },
    {
        currencyCode: "MXN",
        countryName: "Mexico"
    },
    {
        currencyCode: "MYR",
        countryName: "Malaysia"
    },
    {
        currencyCode: "MZN",
        countryName: "Mozambique"
    },
    {
        currencyCode: "NAD",
        countryName: "Namibia"
    },
    {
        currencyCode: "NGN",
        countryName: "Nigeria"
    },
    {
        currencyCode: "NIO",
        countryName: "Nicaragua"
    },
    {
        currencyCode: "NOK",
        countryName: "Norway"
    },
    {
        currencyCode: "NPR",
        countryName: "Nepal"
    },
    {
        currencyCode: "NZD",
        countryName: "New Zealand"
    },
    {
        currencyCode: "OMR",
        countryName: "Oman"
    },
    {
        currencyCode: "PAB",
        countryName: "Panama"
    },
    {
        currencyCode: "PEN",
        countryName: "Peru"
    },
    {
        currencyCode: "PGK",
        countryName: "Papua New Guinea"
    },
    {
        currencyCode: "PHP",
        countryName: "Philippines"
    },
    {
        currencyCode: "PKR",
        countryName: "Pakistan"
    },
    {
        currencyCode: "PLN",
        countryName: "Poland"
    },
    {
        currencyCode: "PYG",
        countryName: "Paraguay"
    },
    {
        currencyCode: "QAR",
        countryName: "Qatar"
    },
    {
        currencyCode: "RON",
        countryName: "Romania"
    },
    {
        currencyCode: "RSD",
        countryName: "Serbia"
    },
    {
        currencyCode: "RUB",
        countryName: "Russia"
    },
    {
        currencyCode: "RWF",
        countryName: "Rwanda"
    },
    {
        currencyCode: "SAR",
        countryName: "Saudi Arabia"
    },
    {
        currencyCode: "SBD",
        countryName: "Solomon Islands"
    },
    {
        currencyCode: "SCR",
        countryName: "Seychelles"
    },
    {
        currencyCode: "SDG",
        countryName: "Sudan"
    },
    {
        currencyCode: "SEK",
        countryName: "Sweden"
    },
    {
        currencyCode: "SGD",
        countryName: "Singapore"
    },
    {
        currencyCode: "SHP",
        countryName: "Saint Helena"
    },
    {
        currencyCode: "SLE",
        countryName: "Sierra Leone"
    },
    {
        currencyCode: "SLL",
        countryName: "Sierra Leone (Leone)"
    },
    {
        currencyCode: "SOS",
        countryName: "Somalia"
    },
    {
        currencyCode: "SSP",
        countryName: "South Sudan"
    },
    {
        currencyCode: "SRD",
        countryName: "Suriname"
    },
    {
        currencyCode: "STD",
        countryName: "São Tomé and Príncipe"
    },
    {
        currencyCode: "SYP",
        countryName: "Syria"
    },
    {
        currencyCode: "SZL",
        countryName: "Eswatini"
    },
    {
        currencyCode: "THB",
        countryName: "Thailand"
    },
    {
        currencyCode: "TJS",
        countryName: "Tajikistan"
    },
    {
        currencyCode: "TMT",
        countryName: "Turkmenistan"
    },
    {
        currencyCode: "TND",
        countryName: "Tunisia"
    },
    {
        currencyCode: "TOP",
        countryName: "Tonga"
    },
    {
        currencyCode: "TRY",
        countryName: "Turkey"
    },
    {
        currencyCode: "TTD",
        countryName: "Trinidad and Tobago"
    },
    {
        currencyCode: "TWD",
        countryName: "Taiwan"
    },
    {
        currencyCode: "TZS",
        countryName: "Tanzania"
    },
    {
        currencyCode: "UAH",
        countryName: "Ukraine"
    },
    {
        currencyCode: "UGX",
        countryName: "Uganda"
    },
    {
        currencyCode: "USD",
        countryName: "United States of America"
    },
    {
        currencyCode: "UYU",
        countryName: "Uruguay"
    },
    {
        currencyCode: "UZS",
        countryName: "Uzbekistan"
    },
    {
        currencyCode: "VEF",
        countryName: "Venezuela (Obsolete)"
    },
    {
        currencyCode: "VES",
        countryName: "Venezuela"
    },
    {
        currencyCode: "VND",
        countryName: "Vietnam"
    },
    {
        currencyCode: "VUV",
        countryName: "Vanuatu"
    },
    {
        currencyCode: "WST",
        countryName: "Samoa"
    },
    {
        currencyCode: "XAF",
        countryName: "Central African CFA Franc"
    },
    {
        currencyCode: "XAG",
        countryName: "Silver Ounce (Troy)"
    },
    {
        currencyCode: "XAU",
        countryName: "Gold Ounce (Troy)"
    },
    {
        currencyCode: "XCD",
        countryName: "East Caribbean Dollar"
    },
    {
        currencyCode: "XDR",
        countryName: "International Monetary Fund (IMF) Special Drawing Rights"
    },
    {
        currencyCode: "XOF",
        countryName: "West African CFA Franc"
    },
    {
        currencyCode: "XPF",
        countryName: "French Pacific Franc"
    },
    {
        currencyCode: "YER",
        countryName: "Yemen"
    },
    {
        currencyCode: "ZAR",
        countryName: "South Africa"
    },
    {
        currencyCode: "ZMK",
        countryName: "Zambia (Obsolete)"
    },
    {
        currencyCode: "ZMW",
        countryName: "Zambia"
    },
    {
        currencyCode: "ZWL",
        countryName: "Zimbabwe"
    }
];
