import "./SkeletonCard.css";

export const SkeletonCard = () => {
  return (
      <div className="skeleton-blog animate-pulse">
        <header>
          <div className="skeleton-author w-[60%] animate-pulse"></div>
          <div className="flex flex-cols-2 animate-pulse">
            <div className="skeleton-mid w-[50%] animate-pulse"></div>
            <div className="skeleton-mid w-[30%] animate-pulse"></div>
            <div className="skeleton-author animate-pulse"></div>
            <div className="skeleton-author animate-pulse"></div>
          </div>
        </header>

        <div className="skeleton-image animate-pulse"></div>
        <div className="flex flex-cols-2 animate-pulse">
          <div className="skeleton-mid-left w-1/2"></div>
          <div className="skeleton-mid-right w-1/2"></div>
        </div>
        <div className="skeleton-footer animate-pulse"></div>
        <footer className="skeleton-footer animate-pulse"></footer>
      </div>
  );
};
