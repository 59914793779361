import React from "react";
import "./specification.css";

const popularSearchesArray = [
  {
    text: "Monthly Car rental in Dubai",
  },
  {
    text: "Car Rental Subscription Dubai",
  },
  {
    text: "Rent A Car in Dubai",
  },
  {
    text: "Self Drive Car in Dubai",
  },
  {
    text: "Airport Transfers in Dubai",
  },
  {
    text: "Corporate Car Lease in Dubai",
  },
  {
    text: "Luxury Car Rental Dubai",
  },
  {
    text: "Daily/Weekly Car Rental",
  },
];

const PopularSearches = () => {
  return (
    <div className="flex justify-between items-center h-auto bg-white  lg:h-[186px] popular-search bg-center bg-cover rounded-[12px] shadow-[0px_3px_12px_0px_#0000001F]">
      <div className="flex flex-col w-full gap-5 py-7 md:py-0">
        <div className="px-10">
          <h3 className="text-start text-[#092C85] font-semibold text-[28px]">
            Popular searches in Dubai
          </h3>
        </div>
        <div className="px-10">
          <div className="flex flex-wrap">
            {popularSearchesArray.map((singlePopu, index) => {
              return (
                <div className="w-full md:w-6/12 lg:w-3/12" key={index}>
                  <div className="mb-4">
                    <h4 className="text-[#707070] text-[16px] ">
                      {singlePopu.text}
                    </h4>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopularSearches;
