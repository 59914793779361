import React from 'react'
import FarrierLogoImg from "../../images/farrier_logo_img.png"

const TopBrandsCard = () => {
    return (
        <div className='w-[260px]  mx-[20px] py-2'>
            <div className='flex flex-col items-center gap-2 shadow-[0px_3px_20px_0px_#0000001F] bg-[#fff] py-8 rounded-[20px]'>
                <div>
                    <img src={FarrierLogoImg} alt="" />
                </div>
                <h4 className='text-[#222222] text-[20px] leading-[16px] font-semibold'>Ferrari</h4>
            </div>
        </div>
    )
}

export default TopBrandsCard
