import { createSlice } from "@reduxjs/toolkit";


const addSliceOne =  createSlice({
    name:"addSliceOne",
    initialState:{
        addSliceOne:false,
        searchOnAddPage:false,
        addContactModal:false,
    },
    reducers:{
        switchOnAddContactModal:(state,action)=>{
            state.addContactModal = true;
        },
        switchOffAddContactModal:(state,action)=>{
            state.addContactModal = false;
        },
        switchOnSearchOnAdd:(state,action)=>{
            state.searchOnAddPage = true;
        },
        switchOffSearchOnAdd:(state,action)=>{
            state.searchOnAddPage = false;
        },
        openAddSliceOne:(state,action)=>{
            state.addSliceOne = true;
        },
        closeAddSliceOne:(state,action)=>{
            state.addSliceOne = false;
        },
    }
})

export const {openAddSliceOne,closeAddSliceOne,switchOnSearchOnAdd,switchOffSearchOnAdd,switchOnAddContactModal,switchOffAddContactModal} = addSliceOne.actions;
export default addSliceOne.reducer;