import { createSlice } from "@reduxjs/toolkit";


const loaderSlice = createSlice({
    name:"loaderSlice",
    initialState:{
        loaderStatus:false
    },
    reducers:{
        switchOnLoader:(state,action)=>{
            state.loaderStatus = true;
        },
        switchOffLoader:(state,action)=>{
            state.loaderStatus = false;
        }

    }
})


export const {switchOnLoader,switchOffLoader  } = loaderSlice.actions

export default loaderSlice.reducer;