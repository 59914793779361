import { configureStore } from "@reduxjs/toolkit";
import bookingInfoSlice from "./BookingPageSlices/bookingInfoSlice";
import formControlSlice from "./BookingPageSlices/formControlSlice";
import carDetailSlice from "./CarSlices/carDetailSlice";
import stepperSlice from "./stepperSlice";
import extraSlice from "./extraSlice";
import invoiceSlice from "./invoice/invoiceSlice";
import userSlice from "./userSlices/userSlice";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from "@reduxjs/toolkit";
import reservationSlice from "./ReservationSlices/reservationSlice";
import currencySlice from "./currencySlice";
import modalToggleSlice from "./ModalSlice/modalToggleSlice";
import loginSlice from "./loginSlices/loginSlice";
import userCookie from "./userCookie";
import allBookingSlice from "./bookingHistorySlices/allBookingSlice";
import loaderSlice from "./loaderSlice";
import paymentSession from "./paymentSession";
import insuranceSlice from "./insuranceSlice";
import addSliceOne from "./addSlices/addSliceOne";
import pickDropSlice from "./picDropSlices/pickDropSlice";
import subscriptionSliceMain from "./subscriptionSlices/subscriptionSliceMain";
import subscriptionInvoiceSlice from "./subscriptionSlices/subscriptionInvoiceSlice";
import subscriptionTripDetailSlice from "./subscriptionSlices/subscriptionTripDetailSlice";
import modifyBookingSlice from "./modifyBookingSlices/modifyBooking"

const persistConfig = {
  key: 'root',
  version:1,
  storage
}

const reducer = combineReducers({
  bookingInfoSlice:bookingInfoSlice,
  formControlSlice:formControlSlice,
  carDetailSlice:carDetailSlice,
  stepperSlice:stepperSlice,
  extraSlice:extraSlice,
  invoiceSlice:invoiceSlice,
  userSlice:userSlice,
  reservationSlice:reservationSlice,
  currencySlice:currencySlice,
  modalToggleSlice:modalToggleSlice,
  loginSlice:loginSlice,
  userCookie:userCookie,
  allBookingSlice:allBookingSlice,
  loaderSlice:loaderSlice,
  paymentSession:paymentSession,
  insuranceSlice:insuranceSlice,
  addSliceOne:addSliceOne,
  pickDropSlice:pickDropSlice,
  subscriptionSliceMain:subscriptionSliceMain,
  subscriptionInvoiceSlice:subscriptionInvoiceSlice,
  subscriptionTripDetailSlice:subscriptionTripDetailSlice,
  modifyBookingSlice: modifyBookingSlice
  
})


const persistedReducer = persistReducer(persistConfig,reducer)




const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
});

export const persistor = persistStore(store);

export default store;


