import React from "react";
import { useEffect } from "react";
import AboutHeading from "./AboutHeading";
import AboutGraph from "./AboutGraph";
import AboutTeam from "./AboutTeam";
import AboutNumbers from "./AboutNumbers";
import AboutPoints from "./AboutPoints";
import AboutContact from "./AboutContact";
import AboutValues from "./AboutValues";
import AboutAspire from "./AboutAspire";
import AboutGlobal from "./AboutGlobal";
import { Helmet } from "react-helmet";
import BreadcrumbAll from "../../utils/breadcrumb/BreadCrumbAll";


function AboutMainPage(){

    useEffect(()=>{
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },[])

    return(
        <div className="w-full ">
         
            <Helmet>
      <title>About Us - WTi Car Rental</title>
      <meta
        name="description"
        content="WTi Mobility has consistently reshaped the global commute landscape. What began as a modest operation in India has blossomed into a global enterprise, offering unparalleled corporate commute and car rental services."
      />
      <meta
        name="keywords"
        content="about wti car rental, about wti uae, about wti mobility, about wti rent a car llc"
      />
      <meta name="revisit after" content="7 days" />
      <meta name="robots" content="follow, index" />
      <meta name="language" content="English" />
      <meta name="country" content="United Arab Emirates (UAE)" />
      <meta name="copyright" content="WTi Rent A Car LLC" />
      <meta name="publisher" content="wticarrental.ae" />
      <meta name="distribution" content="Global" />
      <link rel="canonical" href="https://www.wticarrental.ae/about-us" />

      <meta property="og:title" content="About Us - WTi Car Rental" />
      <meta
        property="og:description"
        content="WTi Mobility has consistently reshaped the global commute landscape. What began as a modest operation in India has blossomed into a global enterprise, offering unparalleled corporate commute and car rental services."
      />
      <meta
        property="og:image"
        content="https://www.wticarrental.ae/static/media/aboutBanner.8a1b8889415e321bdc65.png"
      />
      <meta property="og:url" content="https://www.wticarrental.ae/about-us" />
    </Helmet>
           
            <BreadcrumbAll />
            <AboutHeading/>
            <AboutGraph/>
            <AboutGlobal/>
            <AboutTeam/>
            <AboutNumbers/>
            <AboutPoints/>
            <AboutContact/>
            <AboutValues/>
            <AboutAspire/>
        </div>
    )
}

export default AboutMainPage;