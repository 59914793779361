import React from 'react'
import Marquee from 'react-fast-marquee'
import VehicleFleetCard from './VehicleFleetCard'
import "./index.css"


const VehicleFleet = () => {
    return (
        <div className='py-[4rem] px-3 bg-vehicle-fleet-image bg-image'>
            <div className='w-full xl:w-[1200px] mx-auto h-full'>
                <div className='flex flex-col gap-8 items-center w-full'>
                    <div className='backdrop-filter backdrop-blur-[12px] bg-[#FFFFFF33] py-2 px-4 rounded-[8px]'>
                        <span className='text-[#fff] font-medium leading-[20px]'>Enjoy Our Ride</span>
                    </div>
                    <div className='flex flex-col items-center gap-4'>
                        <h2 className='text-[32px] text-center md:text-[40px] text-[#FFFFFF] leading-[40px] font-semibold'>Our Vehicle Fleet</h2>
                        <div>
                            <p className='text-[#FFFFFF] text-center text-[16px] leading-[24px] font-normal'>
                                Driving your dreams to reality with an exquisite fleet of versatile vehicles for <br />
                                unforgettable journeys.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full mt-8'>
                <Marquee>
                    <VehicleFleetCard />
                    <VehicleFleetCard />
                    <VehicleFleetCard />
                    <VehicleFleetCard />
                </Marquee>
            </div>

        </div>
    )
}

export default VehicleFleet
