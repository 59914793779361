import React, { useState } from 'react'
// import OpenFaqImg from "../../images/open_faq_img.png"
// import CloseFaqImg from "../../images/close_faq_img.png"
import OpenFaqImg from "../../images/open_faq_img.png"
import CloseFaqImg from "../../images/close_faq_img.png"

const FaqBar = ({faq}) => {
    const [toggleFaqBar, setToggleFaqBar] = useState(false)

    return (
        <div className='bg-white rounded-[12px]'>
            <div className={`flex justify-between py-3 px-3 shadow-[0px_3px_12px_0px_#0000001F] rounded-[12px] hover:cursor-pointer ${toggleFaqBar ? "bg-gradient-to-b from-[#0077B6] to-[#00B4D8]" : "bg-white"} rounded-[12px]`}>
                <h4 className={`${toggleFaqBar ? "text-white" : "text-[#3E3E3E]"} `}>{faq?.question}</h4>
                <div className={`${toggleFaqBar ? "bg-white" : "bg-gradient-to-b from-[#0077B6] to-[#00B4D8]"}  p-1 flex justify-center items-center w-[24px] h-[24px] rounded-[4px]`} onClick={() => setToggleFaqBar(!toggleFaqBar)}>
                    <img src={toggleFaqBar ? CloseFaqImg  : OpenFaqImg} alt="" />
                </div>
            </div>
            {
                toggleFaqBar ? (<>
                    <div className='py-2 px-3'>
                        <p>{faq?.answer}</p>
                    </div>
                </>) : (<></>)
            }
        </div>
    )
}

export default FaqBar
