import React from "react";




const VehicleDescription = () => {
  return (
    <div
      className="flex justify-between items-center vehicle-hightlight lg:h-[280px] h-auto rounded-[12px] shadow-[0px_5px_15px_0px_rgba(0,0,0,0.15)]"
    >
      <div className="flex flex-col w-full gap-5 py-7 px-3">
        <div className="px-10 ">
          <h3 className="text-start text-[#0F0A34] font-semibold text-[28px]">
            Vehicle Description
          </h3>
        </div>
        <div className="px-10">
          <p className="text-[18px] text-[#868686]">
            <span className="text-[#0F0A34]">Hyundai Accent 2024</span><br /> Digital Cluster Eco boost Premium <br /> Mileage
            45000Mile With 2024 Model Shelby Kit Blind spot, radar, cool seats
            options, <br /> and more... In Perfect Condition Unique Design
          </p>
        </div>
      </div>
    </div>
  );
};

export default VehicleDescription;
