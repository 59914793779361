import React from "react";
import logo from "./Wtirentalicon.svg";
import { useNavigate } from "react-router-dom";


function CompanyLogo() {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="w-fit h-fit ">
        <img  src={logo} alt="wti car rental logo" className=" w-[165px] h-[30px] cursor-pointer max-sm:w-[120px]" onClick={()=>navigate("/")}/>
      </div>
    </React.Fragment>
  );
}

export default CompanyLogo;
