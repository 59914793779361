import React from "react";
import  bookLogo from "./bookLogo.svg";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LogoutSharpIcon from '@mui/icons-material/LogoutSharp';
import Cookies from "js-cookie";
import { removeCookie,removeUserCookieInfo } from "../../../utils/userCookie";
import { useDispatch,useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { switchOffUserAccount, switchOnUserAccount } from "../../../utils/ModalSlice/modalToggleSlice";


import { removeUserBookings } from "../../../utils/bookingHistorySlices/allBookingSlice";
function ProfileDropDown(){
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userID = useSelector((store)=>store.userCookie.userId);

    const logOutUser = ()=>{
        Cookies.remove("jwtToken");
        dispatch(removeCookie());
        dispatch(removeUserCookieInfo());
        navigate("/");
    }

    const goToBooking = ()=>{
        dispatch(removeUserBookings());
        dispatch(switchOnUserAccount());
        navigate(`/profile/${userID}`)
    

    }
    
    const goToUserAccount = ()=>{
        dispatch(switchOffUserAccount());   
        navigate(`/profile/${userID}`);
    }
    return(
        <React.Fragment>
            
            <div className="absolute bg-white top-10 right-[0.25px] border-[1.8px] border-[#D4D4D4] z-20 w-[135px]  rounded-md cursor-pointer">
                <div className="w-full flex py-3 justify-start   items-center cursor-pointer rounded-t-md border-b-[1.5px] border-[#D4D4D4] hover:bg-[#D4D4D4]" onClick={goToBooking} >
                    <img src={bookLogo} alt="" className="mr-4 ml-4" />
                    <span className="text-sm font-normal leading-4 text-[#0F0A34]">Bookings</span>
                </div>
                <div className="w-full flex justify-start py-3 items-center cursor-pointer  border-b-[1.5px] border-[#D4D4D4] hover:bg-[#D4D4D4]" onClick={goToUserAccount}>
                    <PersonOutlineOutlinedIcon  className="mr-4 ml-4"/>
                    <span className="text-sm font-normal leading-4 text-[#0F0A34]">Account</span>
                </div>
                {/* <div className="w-full h-[25%] flex justify-start  items-center cursor-pointer border-b-[1.5px] border-[#D4D4D4] hover:bg-[#D4D4D4] ">
                    <HelpOutlineSharpIcon  className="mr-4 ml-4"/>
                    <span className="text-sm font-normal leading-4 text-[#0F0A34]">Help</span>
                </div> */}
                <div className="w-full  flex justify-start py-3 items-center cursor-pointer rounded-b-md border-b-[1.5px] border-[#D4D4D4] hover:bg-[#D4D4D4] " onClick={logOutUser}>
                    <LogoutSharpIcon  className="mr-4 ml-4"/>
                    <span className="text-sm font-normal leading-4 text-[#0F0A34]">Log out</span>
                </div>

            </div>

        </React.Fragment>
    )
}

export default ProfileDropDown;