import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import axios from "axios";
import { Helmet } from "react-helmet";
import { baseUrl } from "../../config";

const Auther = () => {
  const { authorName } = useParams();
  const [allBlogsByAuthor, setAllBlogsByAuthor] = useState([]);
  const authorNameWithSpace = authorName.split("-").join(" ");
  const Navigate = useNavigate();
  const getAllBlogByAuthor = async () => {
    try {
      const res = await axios.post(
        `${baseUrl}/0auth/Blog/getAllBlogofParticularAuthor`,
        {
          authorName: authorNameWithSpace,
        }
      );
      if (res.status == 200) {
        setAllBlogsByAuthor(res.data.blogs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const extractTextWithoutTags = (html) => {
    // Remove HTML tags and trim the content
    const plainText = html.replace(/<[^>]*>/g, "").trim();

    // Get the first 200 characters

    const limitedText = plainText.slice(0, 200);
    return { __html: limitedText };
    // return limitedText;
  };

  const funcDate = (timestamp) => {
    try {
      const date = new Date(timestamp);

      if (isNaN(date.getTime())) {
        // Invalid timestamp or date string
        throw new Error("Invalid time value");
      }

      const formattedDate = new Intl.DateTimeFormat("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      }).format(date);

      return formattedDate;
    } catch (error) {
      console.error("Error formatting timestamp:", error.message);
      return "Invalid Date";
    }
  };

  const handleClick = (slug, categoryName) => {
    const formattedCategoryName = categoryName.replace(/\s+/g, "-");
    let lowerCase = formattedCategoryName.toLowerCase();
    Navigate(`/blog/${lowerCase}/${slug}`);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    getAllBlogByAuthor();
  }, []);

  function capitalizeFirstLetter(sentence) {
    return sentence.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  return (
    <div>
      <Helmet>
        <title>Author - {capitalizeFirstLetter(authorName)}</title>
        <link rel="canonical" href={`https://www.wticarrental.ae/blog/author/${authorName}`} data-react-helmet="true"></link>
      </Helmet>

      <div className="flex max-sm:block ">
        <div className="w-[60%]  px-10  max-sm:w-full max-sm:h-full ">
          <h1 className="font-bold pt-6 ">Author: {authorName} </h1>
          <div className="flex  pb-3">
            <div className="w-[18%] h-1 bg-black  "></div>
            <div className="w-[100%] h-[1px] bg-stone-200 my-[1.5px]  "></div>
          </div>
          <div className="w-full mt-2">
            <div className=" ">
              {allBlogsByAuthor?.map((data, index) => (
                <div
                  className="flex  max-sm:block max-sm:pt-4"
                  key={index}
                  onClick={() => handleClick(data?.slugs, data.categories)}
                >
                  <div className="w-[50%] cursor-pointer hover:scale-[1.008]  h-[13rem] mb-7 max-sm:w-full ">
                    <img
                      src={data?.image}
                      alt=""
                      className="w-full rounded h-full "
                    />
                  </div>
                  <div className="w-[50%]  px-4 max-sm:w-full max-sm:px-0  ">
                    <a href="" className="font-bold cursor-pointer  ">
                      {data?.title}
                    </a>
                    <div className="w-[100%] pt-4">
                      <p
                        className="text-[10pt]"
                        dangerouslySetInnerHTML={extractTextWithoutTags(
                          data?.paragraph
                        )}
                      />
                    </div>
                    <div className="flex mt-11">
                      <p className=" text-[12px] pr-2 text-stone-400 flex justify-between items-center gap-1 ">
                        <DateRangeIcon className="text-[20px] " />
                        {funcDate(data?.createdAt)}
                      </p>
                      <div className=" text-[12px] items-center flex gap-1  text-stone-400">
                        <AccessTimeIcon className="text-[20px] " />
                        {data?.readingtime}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auther;
