export const calculateExcessInsurance = (totalRentalDeposit, numberOfDays) => {
    if (numberOfDays >= 1 && numberOfDays <= 3) {
      // 1-3 Days: Surety Fee is 2.4% of the Rental Deposit.
      return 0.024 * totalRentalDeposit;
    } else if (numberOfDays >= 4 && numberOfDays <= 12) {
      // 4-12 Days: Surety Fee is 0.8% of the Rental Deposit value multiplied by the number of days.
      return 0.008 * totalRentalDeposit * numberOfDays;
    } else if (numberOfDays >= 13 && numberOfDays <= 42) {
      // 13-42 Days: Surety Fee is a fixed 10% of the Rental Deposit.
      return 0.1 * totalRentalDeposit;
    } else {
      // 43 Days or More: Surety Fee is 10% of the Rental Deposit for each consecutive 30-day period.
      const numberOfPeriods = Math.ceil(numberOfDays / 30);
      return 0.1 * totalRentalDeposit * numberOfPeriods;
    }
  };