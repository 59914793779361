import * as Yup from "yup";



export const userSchema = Yup.object({
    firstName: Yup.string()
    .required('Input is required')
    .matches(/^[^0-9]*$/, 'Input should not contain numbers'),
  
    lastName: Yup.string()
    .required('Input is required')
    .matches(/^[^0-9]*$/, 'Input should not contain numbers'),
  
    email:  Yup.string()
    .required('Email is required')
    .matches(/^[^@]+@[^@]+\.[^@]+$/, 'Invalid email format'),
  
    phoneNumber: Yup.string()
    .required('Phone Number is required')
    .min(6, 'Phone Number must be at least 6 characters')
    .max(14, 'Phone Number cannot exceed 10 characters')
    .matches(/^[0-9]*$/, 'Phone Number must contain only digits'),
    address: Yup.string().required('Address is required'),
  
    city: Yup.string().required('City is required').matches(/^[A-Za-z\s]+$/, 'City must not contain numbers'),
  
    country: Yup.string().required('Country is required').matches(/^[A-Za-z\s]+$/, 'City must not contain numbers'),
  
    // postalCode: Yup.string()
    //   .required('Postal Code is required')
    //   .matches(/^[0-9]{6}$/, 'Postal Code must be exactly 6 digits'),
    postalCode: Yup.string()
    .required('Postal Code is required')
    .test('min', 'Postal Code must be greater than or equal to 3', value => {
      return Number(value) >= 3; // Ensure the number is greater than or equal to 3
    }),
  });
