import React from 'react'
import HeroBackgroundBanner from '../../components/HeroBackgroundBanner/HeroBackgoundBanner'
import Navbar from '../../components/Navbar/Navbar'
import PromotionBanner from '../../components/StripBanner/PromotionBanner'
import TextContentStrip from '../../components/TextContentStrip/TextContentStrip'
import FeatureVehicle from '../../components/FeatureVehicle/FeatureVehicle'
import TopBrands from '../../components/TopBrands/TopBrands'
import Documentation from '../../components/Documentation/Documentation'
import Testimonial from '../../components/Testimnial/Testimnial'
import MyFAQ from "../../components/MyFAQ/MyFAQ"
import Blogs from '../../components/Blogs/Blogs'
import MyFooter from '../../components/MyFooter/MyFooter'
import SearchEngine from '../../components/SearchEngine/SearchEngine'

const HomePage = () => {
    return (
        <div>
            <Navbar />
            <HeroBackgroundBanner searchEngine={<SearchEngine />} />
            <PromotionBanner />
            <TextContentStrip />
            <FeatureVehicle />
            <TopBrands bgColor={"#fff"} />
            <Documentation />
            <Testimonial />
            <MyFAQ />
            <Blogs />
            <MyFooter />
        </div>
    )
}

export default HomePage
