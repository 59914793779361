import React, { useEffect } from "react";
import ReservationCard from "./ReservationCard";
import PaymentSuccess from "./PaymentSuccess";
import PaymentFailed from "./PaymentFailed";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";


function ReservationMain(){
    const navigate = useNavigate();
    const pickLoc = useSelector((store) => store.bookingInfoSlice.pickupLocation);
    const carObj = useSelector((store) => store.carDetailSlice.carDetailObj);

    // useEffect(()=>{
        
    // },[])
    
    // useEffect(()=>{
    //     if(pickLoc=="" || carObj=={}){
    //         navigate('/')
    //     }
    // },[])

    return(
        <React.Fragment>
            <Helmet>
            <title>Payment Confirmed</title>

            <link
            rel="canonical"
            href="https://www.wticarrental.ae/reservation/confirmed"
            />
            
        </Helmet>
            <div className="w-full pl-[10.27%] pr-[10.27%] max-sm:px-[4%] bg-[#EBEBEB] pt-6 pb-6 flex justify-center">
                <ReservationCard/>
            
             
            </div>
        </React.Fragment>
    )
}

export default ReservationMain;