import { createSlice } from "@reduxjs/toolkit";

const modalToggleSlice = createSlice({
  name: "modalToggleSlice",
  initialState: {
    carDetail: false,
    updateItinerary: false,
    moreDetailSelectedCar: {},
    openCarModal: false,
    cancelModal: false,
    selectedCancelCard: "",
    selectedCancelModifiedCard: "",
    termsAndConditionModal: false,
    contactUsModal: false,
    signUpModal: false,
    hideHeaderAndFooter: false,
    userAccount: false,
    addQueryModalOne: false,
    pickupAndDeliveryModal:false,
    thirdStepFlag:false,
    excessCheckState:false,
    googlePopOneModal:false,
    subscriptionCarModal:false,
    footerMarginSubscription:0,
  },
  reducers: {
    setTrueSubscriptionCarModal:(state,action)=>{
      state.subscriptionCarModal = true;
      state.footerMarginSubscription = action.payload;
    },
    setFalseSubscriptionCarModal:(state,action)=>{
      state.subscriptionCarModal = false;
      state.footerMarginSubscription = 0;
    },
    switchOnGooglePopOneModal:(state,action)=>{
      state.googlePopOneModal = true;
    },  
    switchOffGooglePopOneModal:(state,action)=>{
      state.googlePopOneModal = false;
    },
    switchOffExcessCheckState:(state,action)=>{
      state.excessCheckState = false
    },
    toggleExcessCheckState:(state,action)=>{
      state.excessCheckState = !state.excessCheckState;
    },
    switchOnThirstStepFlag:(state,action)=>{
      state.thirdStepFlag= true;
    },
    switchOffThirstStepFlag:(state,action)=>{
      state.thirdStepFlag= false;
    },
    switchOnPickAndDeliveryModal:(state,action)=>{
      state.pickupAndDeliveryModal = true
    },
    switchOffPickAndDeliveryModal:(state,action)=>{
      state.pickupAndDeliveryModal = false;
    },
    switchOnAddQueryModalOne: (state, action) => {
      state.addQueryModalOne = true;
    },
    switchOffAddQueryModalOne: (state, action) => {
      state.addQueryModalOne = false;
    },

    switchOnUserAccount: (state, action) => {
      state.userAccount = true;
    },
    switchOffUserAccount: (state, action) => {
      state.userAccount = false;
    },
    switchOnHideHeaderFooter: (state, action) => {
      state.hideHeaderAndFooter = true;
    },
    switchOffHideHeaderFooter: (state, action) => {
      state.hideHeaderAndFooter = false;
    },
    openSignUpModal: (state, action) => {
      state.signUpModal = true;
    },
    closeSignUpModal: (state, action) => {
      state.signUpModal = false;
    },

    toggleContactUs: (state, action) => {
      state.contactUsModal = !state.contactUsModal;
    },
    toggleTermsAndCondition: (state, action) => {
      state.termsAndConditionModal = !state.termsAndConditionModal;
    },
    toggleCarDetail: (state, action) => {
      state.carDetail = !state.carDetail;
    },
    toggleUpdateItinerary: (state, action) => {
      state.updateItinerary = !state.updateItinerary;
    },
    switchCarModal: (state, action) => {
      state.openCarModal = !state.openCarModal;
    },
    addCarDetail: (state, action) => {
      state.moreDetailSelectedCar = action.payload;
    },
    clearCar: (state, action) => {
      state.moreDetailSelectedCar = {};
    },
    clearCarDetail: (state, action) => {
      state.carDetail = false;
      state.updateItinerary = false;
      state.updateCar = false;
      state.moreDetailSelectedCar = {};
    },
    toggleCancelModal: (state, action) => {
      state.cancelModal = !state.cancelModal;
    },
    fillSelectedCancelCard: (state, action) => {
      state.selectedCancelCard = action.payload.reservationID;
      state.selectedCancelModifiedCard = action.payload.modifiedReservationID;
    },
    emptySelectedCancelCard: (state, action) => {
      state.selectedCancelCard = "";
      state.selectedCancelModifiedCard = "";
    },
  },
});

export const {
  toggleCarDetail,
  toggleUpdateItinerary,
  addCarDetail,
  clearCarDetail,
  clearCar,
  switchCarModal,
  toggleCancelModal,
  fillSelectedCancelCard,
  emptySelectedCancelCard,
  toggleTermsAndCondition,
  toggleContactUs,
  openSignUpModal,
  closeSignUpModal,
  switchOnHideHeaderFooter,
  switchOffHideHeaderFooter,
  switchOffUserAccount,
  switchOnUserAccount,
  switchOnAddQueryModalOne,
  switchOffAddQueryModalOne,
  switchOnPickAndDeliveryModal,
  switchOffPickAndDeliveryModal,
  switchOnThirstStepFlag,
  switchOffThirstStepFlag,
  toggleExcessCheckState,
  switchOffExcessCheckState,
  switchOnGooglePopOneModal,
  switchOffGooglePopOneModal,
  setTrueSubscriptionCarModal,
  setFalseSubscriptionCarModal
} = modalToggleSlice.actions;

export default modalToggleSlice.reducer;
