export const firstQuestionList = [
  {
    question: "What documents do I need to rent a car in Dubai?",
    answer: `To rent a car in Dubai, you will typically require the following documents:
  
      A valid passport copy.
      A copy of your UAE visa (for tourists/visitors).
      A valid UAE driving license for residents or an International Driving Permit (IDP) accompanied by your home country’s driving license for tourists.`,
  },
  {
    question: "Can I rent a car in Dubai with a foreign driver's license?",
    answer: `To rent a car in Dubai, you will typically require the following documents:
  
      A valid passport copy.
      A copy of your UAE visa (for tourists/visitors).
      A valid UAE driving license for residents or an International Driving Permit (IDP) accompanied by your home country’s driving license for tourists.`,
  },
  {
    question: "Is there a security deposit when renting a car in Dubai?",
    answer: `Yes, most car rental agencies in Dubai require a security deposit. This deposit is refundable and is returned to the renter once the vehicle is returned without any damages.`,
  },
  {
    question: "What types of rental insurance are available in Dubai?",
    answer: `In Dubai, there are primarily three types of rental insurances available:
  
      Comprehensive Insurance: Covers damages to the rental car and third-party vehicles or property.
      Collision Damage Waiver (CDW): Limits your financial liability for damage to the rented car unless caused by negligence or violations.
      Personal Accident Insurance (PAI): Provides medical coverage for the driver and passengers in the event of an accident.`,
  },
];

export const secondQuestionList = [
  {
    question: "What Vehicles are available for rent?",
    answer: `WTi Car Rental offers a wide range of vehicles to cater to diverse needs, from compact cars for solo travelers to luxury sedans for business trips and SUVs for family vacations. Our fleet includes brands such as Toyota, Nissan, BMW, Mercedes, and more. Please visit our "Vehicles" section for a detailed list.`,
  },
  {
    question: "Difference between Car Renting and Leasing?",
    answer: `Car renting typically refers to short-term usage, ranging from a day to a few months, and is ideal for tourists or temporary needs. Leasing, on the other hand, is a longer-term commitment where you use the car for an extended period, usually a year or more. It is more like a long-term rental and is often chosen by businesses or individuals staying in Dubai for a longer duration.`,
  },
  {
    question: "What are the modes of payments available for renting a car?",
    answer: `We accept various payment methods for your convenience. This includes major credit cards, debit cards, bank transfers, and cash. Always ensure you have a valid ID and relevant documentation when making a payment.`,
  },
  {
    question: "Who will take care of toll gate charges?",
    answer: `All toll gate charges (Salik) incurred during your rental period will be added to your final bill. Make sure to keep track of the toll gates you pass through to cross-check the amount during the final billing.`,
  },
];

export const allFaqData = [
  {
    question: "What documents do I need to rent a car in UAE?",
    answer: `To rent a car in UAE, you will typically require the following documents:

      A valid passport copy.
      A copy of your UAE visa (for tourists/visitors).
      A valid UAE driving license for residents or an International Driving Permit (IDP) accompanied by your home country’s driving license for tourists.`,
  },
  {
    question: "Can I rent a car in UAE with a foreign driver's license?",
    answer: `To rent a car in UAE, you will typically require the following documents:

      A valid passport copy.
      A copy of your UAE visa (for tourists/visitors).
      A valid UAE driving license for residents or an International Driving Permit (IDP) accompanied by your home country’s driving license for tourists.`,
  },
  {
    question: "Is there a security deposit when renting a car in UAE?",
    answer: `Yes, most car rental agencies in UAE require a security deposit. This deposit is refundable and is returned to the renter once the vehicle is returned without any damages.`,
  },
  {
    question: "What types of rental insurance are available in UAE?",
    answer: `In UAE, there are primarily three types of rental insurances available:

      Comprehensive Insurance: Covers damages to the rental car and third-party vehicles or property.
      Collision Damage Waiver (CDW): Limits your financial liability for damage to the rented car unless caused by negligence or violations.
      Personal Accident Insurance (PAI): Provides medical coverage for the driver and passengers in the event of an accident.`,
  },
  {
    question: "What Vehicles are available for rent?",
    answer: `WTi UAE offers a wide range of vehicles to cater to diverse needs, from compact cars for solo travelers to luxury sedans for business trips and SUVs for family vacations. Our fleet includes brands such as Toyota, Nissan, BMW, Mercedes, and more. Please visit our "Vehicles" section for a detailed list.`,
  },
  {
    question: "Difference between Car Renting and Leasing?",
    answer: `Car renting typically refers to short-term usage, ranging from a day to a few months, and is ideal for tourists or temporary needs. Leasing, on the other hand, is a longer-term commitment where you use the car for an extended period, usually a year or more. It is more like a long-term rental and is often chosen by businesses or individuals staying in UAE for a longer duration.`,
  },
  {
    question: "What are the modes of payments available for renting a car?",
    answer: `We accept various payment methods for your convenience. This includes major credit cards, debit cards, bank transfers, and cash. Always ensure you have a valid ID and relevant documentation when making a payment.`,
  },
  {
    question: "Who will take care of toll gate charges?",
    answer: `All toll gate charges (Salik) incurred during your rental period will be added to your final bill. Make sure to keep track of the toll gates you pass through to cross-check the amount during the final billing.`,
  },
  {
    question: "What is Salik and how is it charged?",
    answer: `Salik is UAE's automatic toll collection system. Every time you pass a Salik toll gate, the toll amount is automatically deducted from the registered Salik tag on the vehicle. For our rental cars, the toll charges incurred during the rental period will be calculated and added to your final bill upon returning the vehicle.`,
  },
  {
    question: "What should be done in case of an accident/breakdown of a car?",
    answer: `In the unfortunate event of an accident, ensure everyone's safety first, then inform the police immediately. Once you have the police report, please contact our support team to guide you further. In the case of a breakdown, call our 24/7 roadside assistance number provided at the time of rental, and we'll assist you promptly.`,
  },
  {
    question: "Can tints be added to the rented cars?",
    answer: `As per our policy, renters are not allowed to make any modifications, including adding tints, to the rented vehicles. However, many of our cars come with pre-installed tints as per UAE regulations. Please specify your preference at the time of booking, and we'll do our best to accommodate your request.`,
  },
];


export const allAdditionalData = [
  {
    question: "What self-drive options does WTi Mobility offer in UAE?",
    answer: `WTi Mobility provides a wide array of self-drive options in UAE, tailored to individual needs. From economy and compact cars for solo travelers to luxury SUVs for those seeking a premium experience, our diverse fleet ensures that every customer finds the perfect vehicle for their journey.`,
  },
  {
    question: "Can I avail of airport transfer services with WTi Mobility?",
    answer: `Absolutely! WTi Mobility offers reliable airport transfer services in UAE, ensuring a seamless commute to and from the airport. Whether it's a business trip or a vacation, our professional chauffeurs will ensure you reach your destination punctually and comfortably.`,
  },
  {
    question: "How do WTi Mobility's airport transfer services stand out in UAE's market?",
    answer:   `WTi Mobility's airport transfer services are renowned for their punctuality, professionalism, and comfort. With dedicated customer support, easy online booking, and a fleet of well-maintained vehicles, we ensure travelers have a hassle-free journey, making us a preferred choice in UAE.`,
  },
  {
    question: "How does WTi Mobility comply with transportation regulations in UAE?",
    answer: `Compliance is a cornerstone of our operations. WTi Mobility strictly adheres to all transportation regulations set by UAE's governing authorities. We ensure all our vehicles meet the required standards, our drivers possess valid permits, and we maintain transparency in our transactions, ensuring a trustworthy relationship with our clientele.`,
  },
  {
    question: "Are there any specific documentation requirements for availing self-drive services with WTi Mobility in UAE?",
    answer: `Yes, to avail our self-drive services, customers need to provide valid identification, a driver's license (international or UAE-issued for non-residents), and relevant rental documentation. Our team can guide you through the specifics during the booking process.`,
  },
  {
    question: "Does WTi Mobility accept advance bookings for its services in UAE?",
    answer: `Yes, WTi Mobility encourages advance bookings to ensure we can provide the best service. It not only guarantees vehicle availability but also allows us to prepare and ensure the car is in impeccable condition for your use.`,
  },
  {
    question: "How much in advance do I need to book a vehicle with WTi Mobility?",
    answer: `To guarantee the best service and vehicle availability, we recommend that customers book at least 4 hours in advance.`,
  },
  {
    question: "Is there a penalty for last-minute bookings or changes to reservations with WTi Mobility?",
    answer: `While we strive to accommodate all customer requests, we highly recommend booking at least 4 hours in advance. Last-minute bookings or changes are subject to vehicle availability, and there might be additional charges depending on the circumstances. It's always best to book in advance or get in touch with our customer service for specific queries.`,
  },
  {
    question: "What is WTi Mobility's advance payment policy for reservations in UAE?",
    answer: `At WTi Mobility, while we recommend an advance payment to secure your reservation, we also offer a "pay later" option for the convenience of our clients.`,
  },
  {
    question: `If I opt for the "pay later" choice, when is my payment due?`,
    answer: `If you choose the "pay later" option, your payment will be due upon completion of the service. Our team will provide you with a detailed invoice and guide you through the payment process.`,
  },
  {
    question: `What is WTi Mobility's refund policy, especially concerning cancellations or changes?`,
    answer: ` We have a customer-centric refund policy. If you've made an advance payment and need to cancel, we offer a full or partial refund depending on the cancellation notice provided. Last-minute cancellations may involve a fee. Please refer to our terms or consult our team for specifics.`,
  },
  {
    question: "How long does it take for a refund to be processed?",
    answer: `Typically, refunds are processed within 7-14 working days. While we initiate the refund immediately, the duration for the amount to appear in your account can depend on your bank's processing times.`,
  },
  {
    question: "If I change my plans, can I adjust my advance payment towards a different booking instead of a refund?",
    answer: `Absolutely! If you wish to modify your reservation or choose a different service, we can apply your advance payment to the new booking, provided it fits our criteria for advance payments.`,
  },
];
