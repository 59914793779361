import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import { useSelector } from "react-redux";

function SubscriptionPaymentSuccess() {
  const subscriptionReservationId =  useSelector((store)=>store.subscriptionTripDetailSlice.subReservationId);
  const subDate = useSelector(state => state.subscriptionTripDetailSlice.subDeliveryDate);
  
  return (
    <div className="w-full flex justify-center items-center mt-4">
      <div className="w-[70%] max-sm:w-[85%]">
        <div className="w-full  py-6 flex justify-center items-center">
          <div className=" flex max-sm:flex max-sm:flex-col max-sm:items-center ">
            <div className="mr-2">
              <CheckCircleIcon className="text-[100px] text-[#0891B2] " />
            </div>
            <div className="w-full justify-center items-center max-sm:text-center">
              <p className="p-1 text-[25px] font-bold text-[#0891B2]">
                {" "}
                YOUR BOOKING IS COMPLETE
              </p>
              <p className="p-1 text-[20px] font-bold">
                Thank you for booking with us
              </p>
              <p className="p-1 text-[18px] font-semibold text-green-700">
                You will soon receive an email confirmation{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="w-full py-8">
          <div className="flex items-center justify-center">
            <div className="w-[30%] max-sm:w-[70%] p-1 border-2 border-dotted text-center ">
              <div className="text-slate-500 text-[20px]">
                Booking Reference
              </div>
              <div className="font-bold text-[18px]">{subscriptionReservationId}</div>
            </div>
          </div>
          <div className="flex items-center justify-center text-slate-500 text-[18px] p-3">
            Date Booked: <span className="pl-1">{subDate}</span>
          </div>
        </div>

        <div className="w-[100%] flex justify-center items-center mt-5  mb-6">
          <div className="w-[75%] max-sm:w-[85%]  max-sm:px-0 px-12  ">
            <div className="text-justify">
              <span className=" text-sm">Thank you for successfully completing the payment for your car
              subscription plan with WTi Rent a Car LLC. To expedite the process
              and ensure a seamless experience, we kindly request you to upload
              scanned copies of your UAE Driving License and Emirates ID to the
              email sent to your registered email ID.</span>
              
            </div>
          </div>
          <div className="w-[25%] px-6">
            <div>
              <PrintOutlinedIcon className="text-[#72cc04] text-[60px]" />
              <button className="text-white p-1 bg-[#72cc04] ml-2 font-semibold">
                Print this page
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionPaymentSuccess;
