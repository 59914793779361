import React from "react";


import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";

import { toggleCarDetail } from "../../../utils/ModalSlice/modalToggleSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { clearCar } from "../../../utils/ModalSlice/modalToggleSlice";

import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { ReactComponent as CarDoorIcon } from "../carSearchResults/images/icons/car-door.svg";
import { ReactComponent as AirCondIcon } from "../carSearchResults/images/icons/air-conditioner.svg";
import { ReactComponent as TransmissionIcon } from "../carSearchResults/images/icons/transmission.svg";

function CarFeaturesModal() {
  const carObj = useSelector(
    (store) => store.modalToggleSlice.moreDetailSelectedCar
  );

  const modalStatus = useSelector((store) => store.modalToggleSlice.carDetail);
  const dispatch = useDispatch();

  const handleDetail = () => {
    dispatch(clearCar());
    dispatch(toggleCarDetail());
    document.documentElement.style.overflow = "visible";
  };
  return (
    <>
      {modalStatus ? (
        <>
          <div className="modal max-sm:m-14">
            <div className="absolute bg-slate-500 opacity-50 left-0 w-full h-full"></div>
            <div className="absolute top-10 h-screen w-[70%] flex flex-col">
              <div className="flex justify-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-20 mx-auto max-w-5xl">
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                      <h3 className="text-2xl font=semibold">Car Features</h3>
                      <button
                        className="bg-transparent border-0 text-black float-right"
                        onClick={() => handleDetail()}
                      >
                        <CloseIcon className="text-white text-lg bg-slate-600 rounded-full" />
                      </button>
                    </div>
                    <div className="relative p-6 flex-auto">
                      {/* start  */}
                      <div className="mt-4 grid grid-cols-3 gap-10 pb-8 border-b border-slate-300">
                        <div className=" w-[50%] m-auto  flex justify-evenly items-center">
                          <div className="mr-2 flex justify-start items-center">
                            <PersonIcon className="text-lg" />
                          </div>
                          <div className="text-base">
                            {carObj?.seats + " Passenger"}
                          </div>
                        </div>
                        <div className="w-[50%] m-auto  flex justify-evenly items-center">
                          <div className="mr-2 flex justify-start items-center">
                            <BusinessCenterIcon className="text-lg" />
                          </div>
                          <div className="text-base">
                            {carObj?.luggageCapacity + " Suitcases"}
                          </div>
                        </div>
                        <div className=" w-[50%] m-auto  flex justify-evenly items-center">
                          <div className="mr-2 flex justify-start items-center">
                            <CarDoorIcon className="text-lg text-black" />
                          </div>
                          <div className="text-base">
                            {carObj?.doors + " Doors"}
                          </div>
                        </div>
                        <div className="w-[50%] m-auto  flex justify-evenly items-center">
                          <div className="mr-2 flex justify-start items-center">
                            <AirCondIcon className="text-lg" />
                          </div>
                          <div className="text-base">
                            {carObj?.ac == "yes" ? "Air Condition" : "No A/C"}
                          </div>
                        </div>
                        <div className="w-[50%] m-auto flex justify-evenly items-center">
                          <div className="mr-2 flex justify-start items-center">
                            {" "}
                            <TransmissionIcon className="text-lg" />
                          </div>
                          <div className="text-base">
                            {carObj?.transmissionType.charAt(0).toUpperCase() +
                              carObj?.transmissionType.slice(1)}
                          </div>
                        </div>
                      </div>
                      <div className="p-4">
                        <ul>
                          <li>
                            The displayed vehicles are for illustrative purposes
                            only. Actual makes and models within a car category
                            may differ in availability and may vary in terms of
                            passenger seating, luggage capacity, equipment, and
                            mileage
                          </li>
                          <li>
                            For vehicles equipped with third-row seating, the
                            luggage capacity may vary depending on whether the
                            third row is in an upright position or folded down.
                          </li>
                          <li>
                            In 15-passenger vehicles, the luggage capacity may
                            be less than that of 12-passenger vehicles.
                          </li>
                        </ul>
                      </div>
                      {/* end  */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default CarFeaturesModal;
