import { createSlice } from "@reduxjs/toolkit";


const pickDropSlice = createSlice({
    name:"pickDropSlice",
    initialState:{
        carDropLocation:{
            location:"",
            locationRate:0
        },
        carPickLocation:{
            location:"",
            locationRate:0
        },
        dubaiRate:35,
        abuDhabiRate:150,
        sharjahRate:50,

    },
    reducers:{
        updateCarDropLocation:(state,action)=>{
            state.carDropLocation.location = action.payload.location;
            state.carDropLocation.locationRate = action.payload.locationRate;
        },
        emptyCarDropLocation:(state,action)=>{
            state.carDropLocation.location = "";
            state.carDropLocation.locationRate = 0;
        },
        updateCarPickLocation:(state,action)=>{
            state.carPickLocation.location = action.payload.location;
            state.carPickLocation.locationRate = action.payload.locationRate;
        },
        emptyCarPickLocation:(state,action)=>{
            state.carPickLocation.location = "";
            state.carPickLocation.locationRate = 0;
        },
    }
})

export const {updateCarDropLocation,updateCarPickLocation,emptyCarDropLocation,emptyCarPickLocation} = pickDropSlice.actions;
export default pickDropSlice.reducer;