import React, { useEffect } from "react";
import carImg from "./loginCar.svg";
import logo from "./Wtirentalicon.svg";
import CloseIcon from "@mui/icons-material/Close";
import { allCountries } from "../../utils/countryData/allcountries";
import "react-phone-input-2/lib/style.css";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
import { useRef } from "react";
import { useState } from "react";
import {
  toggleLogin,
  savePhoneNumber,
  addUserEmail,
} from "../../utils/loginSlices/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { saveCookie, saveUserCookieInfo } from "../../utils/userCookie";
import { userSchema } from "../../validations/userValidation";
import { openSignUpModal,closeSignUpModal } from "../../utils/ModalSlice/modalToggleSlice";
import { switchOnLoader,switchOffLoader } from "../../utils/loaderSlice";
import { baseUrl } from "../../config";
import { Link } from "react-router-dom";

function PhoneModal() {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [isOtp, setIsOtp] = useState(false);
  const dispatch = useDispatch();
  const phoneNum = useSelector((store) => store.loginSlice.otpPhoneNumber);
  const signupModalStatus = useSelector(
    (store) => store.modalToggleSlice.signUpModal
  );
  const [number, setNumber] = useState("us");
  const setPhoneNumber = (val) => {
    setNumber(val);
  };
  const[userFirstNameVal,setFirstNameVal] = useState(false);
  const[userLastNameVal,setLastNameVal] = useState(false);
  const[userEmail,setUserEmail] = useState(false);
  const[userAddress,setUserAddress] = useState(false);
  const[userCity,setUserCity] = useState(false);
  const[userCountry,setUserCountry] = useState(false);
  const[userSignUp,setUserSignUp] = useState(false);
  const[userExist,setUserExist] = useState(false);
  const[isValidOtp,setIsValidOtp] = useState(true);

  useEffect(()=>{
    setUserSignUp(false);
  },[])

  const closeLoginModal = () => {
    dispatch(toggleLogin());
    dispatch(closeSignUpModal());
    document.documentElement.style.overflow = "visible";
  };

  const sendOtp = () => {
    dispatch(savePhoneNumber());
  };


  const firstNameInput = useRef(null);
  const lastNameInput = useRef(null);
  const emailInput = useRef(null);
  const phoneInput = useRef(null);
  const addressInput = useRef(null);
  const cityInput = useRef(null);
  const postalInput = useRef(null);
  const countryInput = useRef(null);
  const inputsRef  = useRef([]);

   // Handle change in OTP input
   const handleOtpChange = (element, index) => {
    if (!isNaN(element.value) && element.value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = element.value;
      setOtp(newOtp);

      // Move focus to the next input or previous on backspace
      if (element.value && index < otp.length - 1) {
        inputsRef.current[index + 1].focus();
      } else if (!element.value && index > 0) {
        inputsRef.current[index - 1].focus();
      }
    }
  };
  
  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && !otp[index]) {
      inputsRef.current[index - 1].focus();
    }
  };

  const otpVerify = () => {
    dispatch(switchOnLoader());

    let data = JSON.stringify({
      emailID: `${number.toLowerCase()}`,
      otp: parseInt(otp.join("")),
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/app/v1/user/verify`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        if (response.data.auth) {
          const decodedData = jwtDecode(response.data.jwt);
          Cookies.set("jwtToken", response.data.jwt, { expires: 40 / 1440 });
          dispatch(saveCookie());

          dispatch(saveUserCookieInfo(decodedData));

          closeLoginModal();
          dispatch(addUserEmail(number.toLowerCase()));
          dispatch(switchOffLoader());
        } else {
          dispatch(switchOffLoader());
          setIsValidOtp(false)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendUser = () => {
    let data = JSON.stringify({
      emailID: `${number.toLowerCase()}`,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/app/v1/user/login`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        
        if (response.data.userAssociated) {
          setIsOtp(true);
          dispatch(addUserEmail(number.toLowerCase()))
        }else{
          setUserSignUp(true);
          // dispatch(openSignUpModal());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const signupUser = async ()=>{
    const isValid = await checkValidity();
    if(isValid){
      let data = JSON.stringify({
        "firstName": firstNameInput.current.value,
        "lastName": lastNameInput.current.value,
        "contact": phoneInput.current.value,
        "country": countryInput.current.value,
        "address": addressInput.current.value,
        "city": cityInput.current.value,
        "postalCode": postalInput.current.value,
        "emailID": emailInput.current.value,
        "password": "",
        "otp": {
          "code": null,
          "otpExpiry": null
        }
      });
      
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseUrl}/app/v1/user/signup`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios.request(config)
      .then((response) => {
        if(response.data.userAssociated==true){
          setOtp(true);
        }else if(response.data.userExist==true){
          setUserExist(true);
          // dispatch(closeSignUpModal());
        }
        
      })
      .catch((error) => {
        console.log(error);
      });
      

    }
  }

  const checkValidity = async () => {

    let userInfo = {
      firstName: firstNameInput.current.value,
      lastName: lastNameInput.current.value,
      email: emailInput.current.value,
      phoneNumber: number,
      address: addressInput.current.value,
      city: cityInput.current.value,
      country: countryInput.current.value,
      // postalCode: postalInput.current.value,
    };

    const vaildFirstName = await userSchema.isValid(userInfo);
    if (vaildFirstName == true) {
      return true;
    } 
    const validFName = await userSchema.fields.firstName.isValid(firstNameInput.current.value);
    const validLName = await userSchema.fields.lastName.isValid(lastNameInput.current.value);
    const validEmail = await userSchema.fields.email.isValid(emailInput.current.value);
    const validAddress = await userSchema.fields.address.isValid(addressInput.current.value);
    const validcity = await userSchema.fields.city.isValid(cityInput.current.value);
    const validCountry = await userSchema.fields.country.isValid(countryInput.current.value);

    if(vaildFirstName==false){
      if(validFName){
        setFirstNameVal(false);
      }else{
        setFirstNameVal(true);
      }

      if(validLName){
        setLastNameVal(false);
      }else{
        setLastNameVal(true);
      }

      if(validEmail){
        setUserEmail(false);
      }else{
        setUserEmail(true);
      }

      if(validAddress){
        setUserAddress(false);
      }else{
        setUserAddress(true);
      }

      if(validcity){
        setUserCity(false);
      }else{
        setUserCity(true);
      }

      if(validCountry){
        setUserCountry(false);
      }else{
        setUserCountry(true);
      }
      return false;
      
    }
  };

  const onSignUpClick = ()=>{
    setUserExist(false);
    dispatch(openSignUpModal());  
  }

  const onLoginClick = ()=>{
    dispatch(closeSignUpModal());
  }

  return (
    <React.Fragment>
      <div className="w-fit h-fit rounded-md flex max-sm:w-full max-sm:justify-center max-sm:h-screen max-sm:overflow-y-auto max-sm:mt-10 max-sm:rounded-md ">
        {signupModalStatus ? (
          <div className="flex mt-10  ">
            <div
              className="bg-cover w-[304px] h-[488px] bg-center rounded-l-lg relative max-sm:hidden"
              style={{ backgroundImage: `url(${carImg})` }}
            >
              <div className="absolute w-full h-full  bg-[#0F0A34] bg-opacity-80 rounded-l-lg flex justify-center items-center">
                <img src={logo} alt="" />
              </div>
            </div>
            <div className="w-[641px] h-fit rounded-r-lg max-sm:w-full">
              <div className="w-full flex justify-between items-center bg-white rounded-tr-lg py-4 px-6">
                <span className="text-[#0F0A34] font-semibold text-base leading-5">
                  Sign Up
                </span>
                <CloseIcon
                  className="bg-black rounded-full bg-opacity-30 p-1 text-2xl cursor-pointer "
                  onClick={closeLoginModal}
                />
              </div>
              {isOtp ? (
                <div className="w-full flex flex-col rounded-br-lg justify-start  items-center bg-white px-6 py-4">
                  <span className="font-normal text-sm leading-4">
                    Please enter the OTP sent to
                  </span>
                  <span className="font-bold text-sm text-[#092C85] leading-4 text-left mb-4">
                    {emailInput.current.value}
                  </span>
                  <div className="flex mb-6">
                    {otp.map((data, index) => {
                      return (
                        <input
                          className="otp-field w-[50px] h-[56px] border-[1px] border-[#C3C3C3] bg-[#EBEBEB] mr-2 rounded-md  text-center"
                          type="text"
                          name="otp"
                          maxLength="1"
                          key={index}
                          value={data}
                          onChange={(e) => handleOtpChange(e.target, index)}
                          onFocus={(e) => e.target.select()}
                        />
                      );
                    })}
                  </div>

                  <div className="mb-6">
                    <button
                      className="py-[17px] px-[60px] text-white font-semibold bg-[#092C85] rounded-md border felx justify-center items-center"
                      onClick={otpVerify}
                    >
                      Verify
                    </button>
                  </div>
                </div>
              ) : (
                <div className="w-full grid grid-cols-2  justify-between items-start bg-white px-6 py-4 max-sm:block">
                  <div>
                    <div>
                      <span className="font-semibold text-sm text-[#0F0A34] mb-2">
                        Full Name
                      </span>
                    </div>
                    <div className="mb-4 max-sm:w-full">
                      <input
                      error={userFirstNameVal}
                      ref={firstNameInput}
                        type="text"
                        className={`h-10 w-[17rem] max-sm:w-full border-[1px] rounded-md ${userFirstNameVal ? 'border-red-500' : 'border-black'}`}

                        
                      />
                    </div>
                  </div>
                  <div>
                    <div>
                      <span className="font-semibold text-sm text-[#0F0A34] mb-2">
                        Last Name
                      </span>
                    </div>
                    <div className="mb-4 max-sm:w-full">
                      <input
                      ref={lastNameInput}
                        type="text"
                        className={`h-10 w-[17rem] max-sm:w-full border-[1px] rounded-md ${userLastNameVal ? 'border-red-500' : 'border-black'}`}
                      
                      />
                    </div>
                  </div>
                  <div>
                    <div>
                      <span className="font-semibold text-sm text-[#0F0A34] mb-2">
                        Email
                      </span>
                    </div>
                    <div className="mb-4 max-sm:w-full">
                      <input
                      ref={emailInput}
                        type="text"
                        className={`h-10 w-[17rem] max-sm:w-full border-[1px] rounded-md ${userEmail ? 'border-red-500' : 'border-black'}`}
                        
                      />
                    </div>
                  </div>
                  <div>
                    <div>
                      <span className="font-semibold text-sm text-[#0F0A34] mb-2">
                        Phone number
                      </span>
                    </div>
                    <div className="mb-4 max-sm:w-full">
                      <input
                      ref={phoneInput}
                        type="number"
                        className="h-10 w-[17rem] max-sm:w-full border-[1px] border-black rounded-md"
                        
                      />
                    </div>
                  </div>
                  <div>
                    <div>
                      <span className="font-semibold text-sm text-[#0F0A34] mb-2">
                        Address
                      </span>
                    </div>
                    <div className="mb-4 max-sm:w-full">
                      <input
                      ref={addressInput}
                        type="text"
                        className={`h-10 w-[17rem] max-sm:w-full border-[1px] rounded-md ${userAddress ? 'border-red-500' : 'border-black'}`}
                        
                      />
                    </div>
                  </div>
                  <div>
                    <div>
                      <span className="font-semibold text-sm text-[#0F0A34] mb-2">
                        City
                      </span>
                    </div>
                    <div className="mb-4 max-sm:w-full">
                      <input
                      ref={cityInput}
                        type="text"
                        className={`h-10 w-[17rem] max-sm:w-full border-[1px] rounded-md ${userCity ? 'border-red-500' : 'border-black'}`}
                        
                      />
                    </div>
                  </div>
                  <div>
                    <div>
                      <span className="font-semibold text-sm text-[#0F0A34] mb-2">
                        Country
                      </span>
                    </div>
                    <div className="mb-4 max-sm:w-full w-[92%] ">
                      <div className="border border-black rounded-md w-full">
                        <select
                          name=""
                          id=""
                          className="h-10 w-full max-sm:w-full  rounded-md px-2"
                          ref={countryInput}
                          value={countryInput.value}
                        >
                          {allCountries.map((ele) => (
                            <option  value={ele?.name}>{ele?.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <span className="font-semibold text-sm text-[#0F0A34] mb-2">
                        Postal Code
                      </span>
                    </div>
                    <div className="mb-4 max-sm:w-full">
                      <input
                      ref={postalInput}
                        type="text"
                        className="h-10 w-[17rem] max-sm:w-full border-[1px] border-black rounded-md"
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className={`w-full  flex ${userExist?"justify-between":"justify-end"} items-center pt-4 bg-white rounded-br-lg pr-10 pb-2`}>
              {userExist?<span className=" mt-4 w-full flex justify-center text-red-600 font-thin text-sm">User already exist <span className="text-black hover:underline ml-2 cursor-pointer" onClick={onLoginClick}>Login</span></span>:<></>}
                <button
                  className="bg-[#092C85] text-white font-semibold text-sm py-[17px] px-[40px] rounded-md"
                  onClick={signupUser}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        ) : (
          <>
            {" "}
            <div
              className={`bg-cover w-[304px] ${userSignUp ?"h-[355px]" :isOtp?"h-[348px]":"h-[319px]" }  bg-center rounded-l-lg relative max-sm:hidden`}
              style={{ backgroundImage: `url(${carImg})` }}
            >
              <div className="absolute w-full h-full  bg-[#0F0A34] bg-opacity-80 rounded-l-lg flex justify-center items-center">
                <img src={logo} alt="" />
              </div>
            </div>
            <div className="w-[441px] h-fit rounded-r-lg max-sm:w-full">
              <div className="w-full flex justify-between items-center bg-white rounded-tr-lg py-10 px-6">
                <span className="text-[#0F0A34] font-semibold text-base leading-5">
                  Log In / Sign Up
                </span>
                <CloseIcon
                  className="bg-black rounded-full bg-opacity-30 p-1 text-2xl cursor-pointer "
                  onClick={closeLoginModal}
                />
              </div>
              {isOtp ? (
                <div className="w-full flex flex-col rounded-br-lg justify-start  items-center bg-white px-6 py-4">
                  <span className="font-normal text-sm leading-4">
                    Please enter the OTP sent to
                  </span>
                  <span className="font-bold text-sm text-[#092C85] leading-4 text-left mb-4">
                    {number}
                  </span>
                  <div className="flex mb-6">
                    {otp.map((data, index) => {
                      return (
                        <input
                          ref={(ref) => (inputsRef.current[index] = ref)}
                          className="otp-field w-[50px] h-[56px] border-[1px] border-[#C3C3C3] bg-[#EBEBEB] mr-2 rounded-md  text-center"
                          type="text"
                          name="otp"
                          maxLength="1"
                          key={index}
                          value={data}
                          onChange={(e) => handleOtpChange(e.target, index)}
                          onFocus={(e) => e.target.select()}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                        />
                      );
                    })}
                  </div>
                  {!isValidOtp ? <div className="text-red-600 font-medium text-[18px] mb-2">Incorrect OTP</div> : ""}
                  <div className="mb-6 flex flex-col">
                    <span className="pb-1 text-sm">OTP is valid for 5 minutes</span>
                    <button
                      className="py-[17px] px-[60px] text-white font-semibold bg-[#092C85] rounded-md border felx justify-center items-center"
                      onClick={otpVerify}
                    >
                      Verify
                    </button>
                  </div>
                </div>
              ) : (
                <div className="w-full flex flex-col rounded-br-lg  justify-between items-start bg-white px-6 py-4">
                  <div>
                    <span className="font-semibold text-sm text-[#0F0A34] mb-2">
                      Email
                    </span>
                  </div>
                  <div className="mb-4 max-sm:w-full">
                    <input
                      type="text"
                      className="pl-1 h-12 w-[24rem] max-sm:w-full border-2 border-black rounded-md"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                  <span className="text-xs font-medium leading-4">
                    By continuing, you agree to WTi Car Rental’s{" "}
                    <Link onClick={() => closeLoginModal()} to={"/terms-of-use"} target={"_blank"}><b>Terms of Use</b></Link> and <br />
                    <b>Privacy Policy.</b>
                  </span>
                  {userSignUp?<span className=" mt-4 w-full flex justify-center text-red-600 font-thin text-sm">User does not exist <span className="text-black hover:underline ml-2 cursor-pointer" onClick={onSignUpClick}>Sign up</span></span>:<></>}
                  
                  <div className="w-full  flex justify-end items-center pt-2  max-sm:justify-center">
                    <button
                      className="bg-[#092C85] text-white font-semibold text-sm py-[17px] px-[40px] rounded-md"
                      onClick={sendUser}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
}

export default PhoneModal;
