import { createSlice } from "@reduxjs/toolkit";


const reservationSlice = createSlice({
    name:"reservationSlice",
    initialState:{
        reservationStatus:true,
        resId:"",
    },
    reducers:{
        addRes:(state,action)=>{
            state.reservationStatus = true;
            state.resId = action.payload;
        },
        clearRes:(state,action)=>{
            state.reservationStatus = false;
            state.resId = "";
        }
    }
})

export const {addRes,clearRes} = reservationSlice.actions;

export default reservationSlice.reducer;