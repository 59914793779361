import { createSlice } from "@reduxjs/toolkit";



const stepperSlice = createSlice({
    name:"stepperSlice",
    initialState:{
        stepOne:true,
        stepTwo:true,
        stepThree:true,
        stepFour:true
    },
    reducers:{
        switchOnStepTwo:(state,action)=>{
            state.stepTwo = true;
        },
        switchOnStepThree:(state,action)=>{
            state.stepThree = true;
        },
        switchOnStepFour:(state,action)=>{
            state.stepFour = true;
        },
        tillOne:(state,action)=>{
            state.stepOne = true;
            state.stepTwo = false;
            state.stepThree = false;
            state.stepFour = false;
        },
        tillTwo:(state,action)=>{
            state.stepOne = true;
            state.stepTwo = true;
            state.stepThree = false;
            state.stepFour = false;

        },
        tillThree:(state,action)=>{
            state.stepOne = true;
            state.stepTwo = true;
            state.stepThree = true;
            state.stepFour = false;

        },
        tillFour:(state,action)=>{
            state.stepOne = true;
            state.stepTwo = true;
            state.stepThree = true;
            state.stepFour = true;

        },

    }
})


export const {switchOnStepTwo,switchOnStepThree,switchOnStepFour,tillOne,tillTwo,tillThree,tillFour} = stepperSlice.actions
 
export default stepperSlice.reducer;
