import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { Search } from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import { useSelector } from "react-redux";
import { pickUpDropArr } from "./pickUpLocations";
import Paper from "@mui/material/Paper";
import ClearIcon from "@mui/icons-material/Clear";
import { toggleDifferentLocation } from "../../../utils/BookingPageSlices/formControlSlice";
import { useDispatch } from "react-redux";
import { baseUrl } from "../../../config";
const CustomPaper = React.forwardRef(function CustomPaper(props, ref) {
  return (
    <Paper
      {...props}
      ref={ref}
      style={{
        // Add your custom styles for the dropdown here
        marginTop: "8px",
        borderRadius: "8px",
        borderColor: "#000000",
        boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
        // backgroundColor:"#F1F7FC"
        backgroundColor: "#FFFFFF",
      }}
    />
  );
});
function TextFieldInput(props) {
  const inputRef = useRef(null);
  const [cross, setCross] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const locationStatus = useSelector(
    (store) => store.formControlSlice.differentLocation
  );
  const resType = useSelector(
    (store) => store.formControlSlice.reservationType
  );
  const pickUpDropStatus = useSelector(
    (store) => store.bookingInfoSlice.needCarDeliveryAndPickupSerivce
  );
  const [arr, setArr] = useState([]);
  const [text, setText] = useState("");
  const [divFocused, setDivFocused] = useState(false);
  const [citySelected, setCitySelected] = useState("");
  const mainPickCity =
    "Zabeel Business Centre (Smark 9) - Umm Hurair Road - Dubai - United Arab Emirates";
 
  useEffect(() => {
    const delay = 500;
    const debounce = setTimeout(() => {
      handleApiCall();
      //
    }, delay);
    return () => {
      clearTimeout(debounce);
    };
  }, [props.value, text]);
  //inputRef?.current?.innerText
  const handleOptionClick = (value) => {
    props.handlePickValue(value);
    setSelectedOption(value);
    setIsOpen(false);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 300); // Adjust the delay as needed
  };

  const handleApiCall = async () => {
    try {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseUrl}/app/v1/google/:${text}`,
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      };
      if(text){
        axios
          .request(config)
          .then((response) => {
            let nArr = [];
            for (let i = 0; i < response.data.predictions.length; i++) {
              let str = response.data.predictions[i].description;
              nArr.push(str);
            }
  
            nArr.unshift(mainPickCity);
            setArr(nArr);
          })
          .catch((error) => {});
      }
    } catch (error) {
      console.error("Google API Error : " + error);
    }
  };

  const handleInputChange = (value) => {
    setSelectedOption(value);
  };

  useEffect(() => {
    if(citySelected!=""){
      if (inputRef && inputRef.current) {
        inputRef.current.innerText = citySelected;
      }
    }
   
  }, [citySelected]);

  const showName = (name) => {

    if (name == citySelected) {
      setArr([]);
      setText("");
      return;
    }
    setArr([]);

    setText("");
    if (inputRef && inputRef.current) {
      inputRef.current.innerText = "";
    }
    setCitySelected(name);
    props.handlePickValue(name);
    setDivFocused(false);
  };

  const emptyInputContainer = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.innerText = "";
    }
    setCitySelected("");
    setText("");
    setDivFocused(false);
    setArr([]);
    
    props.handlePickValue("");
  };



  return (
    <React.Fragment>
      <div id="locSearch" className="w-fit h-fit removeBack max-sm:w-full">
        <div className="flex justify-start items-center pb-2">
          {resType == 0 ? (
            locationStatus == true ? (
              <span className="text-xs font-thin leading-4">
                Pick-up Location
              </span>
            ) : (
              <span className="text-xs font-thin leading-4">
                Pick-up & Return Location
              </span>
            )
          ) : (
            <span className="text-xs font-thin leading-4">
              Pick-up Location
            </span>
          )}
        </div>
        {pickUpDropStatus ? (
          <div>
            {resType == 0 ? (
              locationStatus == true ? (
                <div
                  className="mainInputContianer sm:w-[27.3rem] max-sm:w-[16.5rem] md:w-[18.5rem] lg:w-[12rem] xl:w-[10rem] h-[3.14rem] bg-[#EBEBEB]  rounded relative"
                  onMouseEnter={() => setCross(true)}
                  onMouseLeave={() => setCross(false)}
                >
                  <div className="inputCompContainer flex items-center w-full h-full">
                    <div className="iconImg w-[15%] pl-4 flex justify-center items-center  h-full ">
                      <Search />
                    </div>
                    <div
                      ref={inputRef}
                      className={`inputCont overflow-x-hidden  whitespace-nowrap pr-2 w-[70%] h-[30px] flex items-center 
                     
                     focus:outline-none pl-4  cursor-text text-black  `}
                      contentEditable
                      onInput={(e) => setText(e.target.innerText)}
                      onFocus={() => {
                        setDivFocused(true);
                      }}
                      onBlur={() => {
                        setTimeout(() => {
                          setDivFocused(false);
                        }, 500);
                      }}
                      suppressContentEditableWarning={true}
                    >
                      {divFocused == false &&
                      citySelected == "" &&
                      text == "" ? (
                        <span className="text-slate-400 text-xs font-thin leading-4 ">
                          Search Here
                        </span>
                      ) : (
                        <span className="text-black font-light text-base">
                          {citySelected}
                        </span>
                      )}
                    </div>
                    <div className="w-[15%] flex pr-2 justify-center items-center h-full rounded-r">
                      {cross ? (
                        <ClearIcon
                          className=" rounded-full p-1 cursor-pointer hover:bg-gray-300 box-content text-base"
                          onClick={emptyInputContainer}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  {arr.length > 1 && divFocused == true ? (
                    <div className="absolute rounded bg-white w-[27rem] h-[20rem] py-4  overflow-y-auto mt-1 z-30 max-sm:w-full">
                      {arr.map((ele) => (
                        <div
                          className="clr w-full rounded flex justify-start px-2 py-4 items-center hover:bg-[#F1F7FC] cursor-pointer z-50"
                          onClick={() => showName(ele)}
                        >
                          <div className="flex flex-col-reverse items-center ">
                            {ele == mainPickCity ? (
                              <div
                                className={` mt-2 w-fit px-1 py-2 h-fit bg-red-600  flex justify-center rounded items-center ${
                                  ele == mainPickCity ? " px-3" : "px-6"
                                } py-[0.5px] whitespace-nowrap`}
                              >
                                <span className="text-white font-normal text-[10px]">
                                  {`No Delivery Charge`}
                                </span>
                              </div>
                            ) : (
                              <></>
                            )}

                            <div
                              className={` w-fit h-fit ${
                                ele == mainPickCity
                                  ? "bg-green-600"
                                  : "bg-blue-900"
                              }  flex justify-center rounded items-center ${
                                ele == mainPickCity ? " px-3" : "px-6"
                              } py-[0.5px] whitespace-nowrap`}
                            >
                              <span className="text-white font-normal text-sm ">
                                {ele == mainPickCity ? "WTi Pickup Hub" : "Places"}
                              </span>
                            </div>
                          </div>

                          <span className=" font-light text-sm ml-8 text-black">
                            {ele}
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <div
                  className="mainInputContianer w-[22rem] lg:w-[18rem] xl:w-[20rem] sm:w-[27.3rem] max-sm:w-[16.5rem] h-[3.18rem] bg-[#EBEBEB]  rounded relative"
                  onMouseEnter={() => setCross(true)}
                  onMouseLeave={() => setCross(false)}
                >
                  <div className="inputCompContainer flex items-center w-full h-full">
                    <div className="iconImg w-[15%] flex justify-center items-center  h-full ">
                      <Search />
                    </div>
                    <div
                      ref={inputRef}
                      className={`inputCont overflow-x-hidden  whitespace-nowrap pr-2 w-[70%] h-[30px] flex items-center 
                       focus:outline-none  cursor-text text-black`}
                      contentEditable
                      onInput={(e) => setText(e.target.innerText)}
                      onFocus={() => {
                        setDivFocused(true);
                        
                      }}
                      onBlur={() => {
                        setTimeout(() => {
                          setDivFocused(false);
                        }, 500);
                      }}
                      suppressContentEditableWarning={true}
                    >
                      {(divFocused == false &&
                      citySelected == "" &&
                      text == "") ? (
                        <span className="text-slate-400 text-xs font-thin leading-4 ">
                          Search Here
                        </span>
                      ) : (
                        <span className="text-black font-light text-base">
                          {citySelected}
                        </span>
                      )}
                    </div>
                    <div className="w-[15%] flex justify-center items-center h-full rounded-r">
                      {cross ? (
                        <ClearIcon
                          className=" rounded-full p-1 cursor-pointer hover:bg-gray-300 box-content text-base"
                          onClick={emptyInputContainer}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  {arr.length > 1 && divFocused == true ? (
                    <div className="placesDropdown absolute rounded bg-white w-[30.5rem] h-[20rem] py-4  overflow-y-auto mt-1 z-30 max-sm:w-full">
                      {arr.map((ele) => (
                        <div
                          className="clr w-full rounded flex justify-start px-2 py-4 items-center hover:bg-[#F1F7FC] cursor-pointer z-50"
                          onClick={() => showName(ele)}
                        >
                          <div className="flex flex-col-reverse">
                            {ele == mainPickCity ? (
                              <div
                                className={`  mt-2 w-fit px-1 py-2 h-fit border-2 border-red-400  flex justify-center rounded items-center ${
                                  ele == mainPickCity ? " px-3" : "px-6"
                                } py-[0.5px] whitespace-nowrap`}
                              >
                                <span className="text-red-400 font-semibold text-[10px] text-center">
                                  No Delivery and
                                  <br />
                                  Collection Charge
                                </span>
                              </div>
                            ) : (
                              <></>
                            )}

                            <div
                              className={` w-fit h-fit ${
                                ele == mainPickCity
                                  ? "bg-green-600"
                                  : "bg-blue-900"
                              }  flex justify-center rounded items-center ${
                                ele == mainPickCity ? " px-3" : "px-6"
                              } py-[0.5px] whitespace-nowrap`}
                            >
                              <span className="text-white font-normal text-sm ">
                                {ele == mainPickCity ? "WTi Pickup Hub" : "Places"}
                              </span>
                            </div>
                          </div>
                          <span className=" font-light text-[14px] ml-11 flex  text-black">
                            {ele}
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                // <Autocomplete
                //   freeSolo
                //   value={props.value}
                //   onInputChange={(e, v) => {
                //     props.handlePickValue(v);
                //   }}
                //   className="bg-[#EBEBEB] rounded w-[350px] max-sm:w-full"
                //   // PaperComponent={CustomPaper}
                //   componentsProps={{
                //     popper: {
                //       modifiers: [
                //         {
                //           name: "flip",
                //           enabled: false,
                //         },
                //         {
                //           name: "preventOverflow",
                //           enabled: false,
                //         },
                //       ],
                //     },
                //   }}
                //   id="combo-box-demo"
                //   options={arr}
                //   renderInput={(params) => (
                //     <TextField
                //       {...params}
                //       placeholder="Search here"
                //       InputProps={{
                //         ...params.InputProps,
                //         startAdornment: (
                //           <InputAdornment position="start">
                //             {" "}
                //             <Search style={{ fill: "#8495C2" }} />
                //           </InputAdornment>
                //         ),
                //         disableUnderline: true,
                //       }}
                //       size="medium"
                //       className="text-[#555555] text-normal text-sm leading-4"
                //     />
                //   )}
                //   renderOption={(props, option) => (
                //     <div className="bg-red-400" {...props}>
                //       {option}
                //     </div>
                //   )}
                // />
              )
            ) : (
              <Autocomplete
                freeSolo
                value={props.value}
                onInputChange={(e, v) => props.handlePickValue(v)}
                className="bg-[#EBEBEB] rounded w-[160px]  max-sm:w-full"
                componentsProps={{
                  popper: {
                    modifiers: [
                      {
                        name: "flip",
                        enabled: false,
                      },
                      {
                        name: "preventOverflow",
                        enabled: false,
                      },
                    ],
                  },
                }}
                id="combo-box-demo"
                options={arr}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search here"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          {" "}
                          <Search style={{ fill: "#8495C2" }} />
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                    }}
                    size="medium"
                    className="text-[#555555] text-normal text-sm leading-4"
                  />
                )}
              />
            )}
          </div>
        ) : (
          <div className="flex justify-start ">
            <div className="h-[3.15rem] w-[45px] flex justify-center items-center border-[#EBEBEB] bg-[#EBEBEB] rounded-l">
              <Search style={{ fill: "#8495C2" }} />
            </div>
            <div className="relative max-sm:w-full">
              <input
                value={selectedOption}
                onChange={(e) => handleInputChange(e.target.value)}
                onFocus={() => setIsOpen(true)}
                onBlur={() => handleBlur()}
                placeholder={
                  locationStatus
                    ? "Select pickup point"
                    : "Select pickup and drop point"
                }
                name=""
                id="pickLocId"
                className={`bg-[#EBEBEB] h-[3.15rem] ${
                  locationStatus ? "w-[125px]" : "w-[305px]"
                } border-[#EBEBEB]  rounded-r text-black text-normal text-sm leading-4 outline-none max-sm:w-full`}
              />
              {isOpen && (
                <div className="absolute mt-2 w-full rounded-md bg-white shadow-lg z-10">
                  <ul className="py-1">
                    {pickUpDropArr.map((ele) => (
                      <li
                        key={ele.value}
                        onClick={() => handleOptionClick(ele.value)}
                        className="cursor-pointer px-4 py-2 text-black hover:bg-slate-300"
                      >
                        {ele.value}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
export default TextFieldInput;