import React, { useEffect, useState } from "react";
import ExtraCardCont from "./ExtraCardCont";
import EquipmentCarCont from "./EquipmentCarCont";
import { switchOnStepFour } from "../../utils/stepperSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { tillThree } from "../../utils/stepperSlice";
//-- clear state imports
import { clearUser } from "../../utils/userSlices/userSlice";
import { switchOffLoader, switchOnLoader } from "../../utils/loaderSlice";
import { useSelector } from "react-redux";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Deposit from "./Deposit";

function ExtraMain() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pickUpDes = useSelector(
    (store) => store.bookingInfoSlice.pickupLocation
  );
  const carObj = useSelector((store) => store.carDetailSlice.carDetailObj);
  const invoiceObj = useSelector((store) => store.invoiceSlice.depositChargesBoolean);
  
  const [depositcheck, setDepositcheck] = useState(false);
  const [count, setCount] = useState(0);
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  


  const moveToPayment = () => {
    // if (depositcheck || !depositcheck) {
    //   dispatch(switchOnStepFour());
    //   dispatch(switchOnLoader());
    //   navigate("/reservation/review-and-book");
    //   return;
    // }
    if (depositcheck == true) {
      dispatch(switchOnStepFour());
      dispatch(switchOnLoader());
      navigate("/reservation/review-and-book");
      return;
    }else{
      setOpen(true);
    }

    if (depositcheck == null) {
      setDepositcheck(false);
    }

    // alert("Select One Deposit Type")
  };

  //--> this use effect will disable all the stepper other than three
  useEffect(() => {
    if (pickUpDes == "" || carObj == {}) {
      navigate("/");
    }
    dispatch(tillThree());
    dispatch(clearUser());
    dispatch(switchOffLoader());
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);


  return (
    <React.Fragment>
      <div className="w-full  pl-[10.27%] pr-[10.27%] ">
        <ExtraCardCont />
        <EquipmentCarCont />
        <Deposit
          carObj={carObj}
          count={count}
          setCount={setCount}
          setDepositcheck={setDepositcheck}
          depositcheck={depositcheck}
        />
        <div className="flex justify-end items-center mt-8 mb-8">
          <div
            className="w-fit max-sm:w-full max-sm:text-center rounded px-8 py-2 text-white cursor-pointer bg-[#092C85]"
            onClick={moveToPayment}
          >
            Proceed to Review & Book
          </div>
        </div>
      </div>
      {/* <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Please Select any one of the deposit type"
        action={action}
      /> */}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Oops! It seems you forgot to select a deposit option.
        </Alert>
      </Snackbar>
      
    </React.Fragment>
  );
}

export default ExtraMain;
