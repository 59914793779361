import React from 'react'
import CarBookingForSubscription from '../../../components/CarBooking/CarBookingForSubscription'

const SubscriptionBookingPage = () => {
    return (
        <div>
            <CarBookingForSubscription />
        </div>
    )
}

export default SubscriptionBookingPage
