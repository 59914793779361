import React from 'react'
import BlogImg from "../../images/blog_img.png";
import CalenderMonthImg from "../../images/calender_month_img.png"

const BlogCard = () => {
    return (
        <div className='md:w-4/12 sm:w-6/12 w-full px-3 mt-6 md:mt-0'>
            <div className='w-full rounded-[12px] shadow-[0px_3px_20px_0px_#0000001F]'>
                <div className='w-full '>
                    <img src={BlogImg} alt="" className='w-full rounded-t-[12px]' />
                </div>
                <div className='flex flex-col items-start w-full  pb-5 pt-12 px-4 gap-4 bg-[#fff] rounded-b-[12px] relative'>
                    <div className=' justify-center items-center  mx-auto w-full h-[50px] -mt-[72px] rounded-[8px] flex bg-gradient-to-b from-[#0077B6] to-[#00B4D8]'>
                        <img src={CalenderMonthImg} alt="" />
                        <p className='text-[20px] text-nowrap text-[#ffffff] leading-[] font-semibold' >12 April, 2024</p>
                    </div>
                    <h4 className='title text-[#0077B6] underline  text-[24px] leading-[32px] font-normal'>Top Destinations to Visit in Sharjah</h4>
                    <span className='desc text-[#767676] text-[16px] leading-[24px] font-normal'>
                        Highlight popular destinations in your city that are easily accessible by cab. Include information
                    </span>
                    <button className='text-[#0193C5] text-[20px] leading-[24px] font-normal underline'>Read More</button>
                </div>
            </div>
        </div>
    )
}

export default BlogCard
