import React from "react";



function AboutContact(){
    return(
        <div className="w-full pl-[10.27%] pr-[10.27%] max-sm:px-4 flex justify-center items-center mt-20">
            <div className=" flex flex-col items-center justify-start bg-[#092C850D]  rounded-md p-6 px-14">
                <span className="text-[#0F0A34] font-semibold text-xl leading-6 mb-4">Want to know more?</span>
                <span className="text-sm mb-4 text-center px-10 max-sm:px-2">Join us at WTi Mobility and experience a journey redefined, as we lead the way in <br/>global commute solutions, now from the heart of UAE.</span>
                <button className="bg-[#0F0A34] text-white flex justify-center items-center rounded-full py-[7px] px-[22px]">Contact us</button>
            </div>

        </div>
    )
}

export default AboutContact;