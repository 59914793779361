import { createSlice } from "@reduxjs/toolkit";


const allBookingSlice = createSlice({
    name:"allBookingSlice",
    initialState:{
        upcoming:[],
        complete:[],
    },
    reducers:{
        addUserUpcomingBookings:(state,action)=>{
            state.upcoming.push(action.payload);
        },
        addUserCompletedBookings:(state,action)=>{
            state.complete.push(action.payload);
        },
        removeUserBookings:(state,action)=>{
            state.upcoming.length = 0;
            state.complete.length = 0;
        }

    }
})

export const {addUserUpcomingBookings,addUserCompletedBookings,removeUserBookings} = allBookingSlice.actions
export default allBookingSlice.reducer;