import React, { useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import pf from "./paymnetFail.jpg";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { onPaymentFailed } from "../../utils/paymentSession";
import { useDispatch } from "react-redux";
import axios from "axios";
import { addSessionID } from "../../utils/paymentSession";
import { switchOffLoader } from "../../utils/loaderSlice";
import { Helmet } from "react-helmet";
import { baseUrl } from "../../config";



function PaymentFailed() {
  const dispatch = useDispatch();
  // const custId = useSelector((store)=>store.paymentSession.stripeCustomerID);
  const navigate = useNavigate();
  const [showFail, setShowFail] = useState(true);
  const stripeCustomerID = useSelector((store)=>store.paymentSession.stripeCustomerID);
  const reservationID = useSelector((store)=>store.reservationSlice.resId);
  const userID = useSelector((store)=>store.userSlice.userId);
  const finalAmountPaymentFailed = useSelector((store) => store.invoiceSlice.finalAmountPaymentFailed);
  const currencySelected = useSelector(
    (store) => store.currencySlice.currentCurr
  );
  const finalPriceModifyWithCurrency = useSelector(
    (store) => store.modifyBookingSlice.finalPriceModifyWithCurrency
  );
  const modifyFlag = useSelector((store) => store.modifyBookingSlice.modifyFlag);
  const modifiedResID = useSelector((store) => store.modifyBookingSlice.modifiedResID);
  const modifiedInvoiceID = useSelector((store) => store.modifyBookingSlice.modifiedInvoiceID);
  const modifyBookingObj = useSelector((store) => store.modifyBookingSlice.modifyDetails);


  // let custTotalAmount =
  // ((baseRate * totalDays) +(excessInsurancePrice)+
  // (extrasRate) + (carDeliveryCharge))+
  // (((baseRate * totalDays) +(excessInsurancePrice) + (extrasRate) + (carDeliveryCharge)) * 0.05);

  const [totalAmountPayable, setTotalAmountPayable] = useState(finalAmountPaymentFailed) 

  const moveToHome = () => {
    navigate("/");
  };

  // useEffect(() => {
    // dispatch(switchOffLoader());
      // if(modifyFlag){
      //   setShowFail(true);
      // } else {
      //   if (pickUpDes == "" || carObj == {}) {
      //     setShowFail(true);
      //   } else {
      //     setShowFail(true);
      //     dispatch(onPaymentFailed());
      //   }
      // }

  //   if (showFail) {
  //     const timeout = setTimeout(() => {
  //       if(modifyFlag){
  //         navigate("/modify-booking");
  //       } else {
  //         navigate("/reservation/review-and-book");
  //       }
  //     }, 5000);

  //     return () => {
  //       clearTimeout(timeout); // Clean up the timeout if the component unmounts before the timeout finishes
  //     };
  //   }
  // }, []);

  const handleClick = () => {
    if(modifyFlag){
      navigate("/modify-booking");
    } else {
      navigate("/reservation/review-and-book");
    }
  }

  useEffect(() => {
    if(modifyFlag){
      setTotalAmountPayable(finalPriceModifyWithCurrency)
    }
  }, [])

  const checkoutPage = (custId,custAmount,custCurr)=>{

    let mResID = null
    let mInvoiceID = null
    let isModifiedFlag = false
    if(modifiedInvoiceID && modifiedResID){
      isModifiedFlag = true
      mResID = modifiedResID
      mInvoiceID = modifiedInvoiceID
    }

    let data = JSON.stringify({
      customerId: custId,
      currency: custCurr,
      amount: custAmount,
      userID: userID,
      reservationID: reservationID,
      modifiedReservationID: mResID,
      modifiedInvoiceID: mInvoiceID,
      isModifiedFlag: Boolean(isModifiedFlag),
      lastModifiedReservationID: modifyBookingObj?.lastModifiedReservationID,
      lastModifiedInvoiceID: modifyBookingObj?.lastModifiedInvoiceID
    });
    
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/app/v1/billing/checkout`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios.request(config)
    .then((response) => {
      dispatch(addSessionID(response.data.sessionID));
      //created session 
      //session url 
      //session id 
      window.location = response.data.sessionURL;
    })
    .catch((error) => {
      console.log(error);
    });
  }

  // const retryPayment = ()=>{
  //    //between user creation and making reservation we need to make the payment
  //    navigate(`/reservation/paymentgateway/${stripeCustomerID}`);
  // }

  return (
    <React.Fragment>
      <Helmet>
        <title>Payment Failed</title>

        <link
          rel="canonical"
          href="https://www.wticarrental.ae/reservation/paymentstatus/failed"
        />
        
      </Helmet>
      {showFail ? (
        <div className="w-full  flex justify-center my-6">
          <div className="w-full flex justify-center my-6">
            <div className="w-[50%] flex flex-col justify-start items-center bg-white rounded-md border pt-6">
              <img src={pf} alt="" className="w-[30%] h-[40%]" />
              <span className="font-semibold text-xl mb-4 max-sm:text-center">
                Payment Failed
              </span>

              <span className="font-medium text-base mb-10">
                Please try again!
              </span>
              <button className="mb-6 border-[1px] border-[#092C85] hover:bg-[#092C85] hover:text-white px-6 py-1 rounded-md" onClick={()=>checkoutPage(stripeCustomerID,totalAmountPayable,currencySelected)}>Try Again</button>
              <button className="font-medium text-base  border-[1px] border-[#092C85] hover:bg-[#092C85] hover:text-white px-6 py-1 rounded-md" onClick={handleClick}>
                Click to Go Back
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full flex justify-center my-6">
          <div className="w-[50%] flex flex-col justify-center items-center bg-white rounded-md border pt-6 py-16  my-14">
            <span className=" text-2xl text-[#0F0A34] font-bold mb-6">
              Please make a reservation!
            </span>
            <button
              className="py-[10px] text-white px-[35px] bg-[#092C85] rounded-md mb-4"
              onClick={() => moveToHome()}
            >
              Make a new reservation
            </button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default PaymentFailed;
