import React,{useEffect} from "react";
import NewInputContainer from "./NewInputContainer";
import { openAddSliceOne } from "../../utils/addSlices/addSliceOne";
import { useDispatch } from "react-redux";
import RadioBtnCont from "../bookingPanel/RadioBtnCont";
import NewSearchCarResult from "./NewSearchCarResult";
import { switchOnAddQueryModalOne } from "../../utils/ModalSlice/modalToggleSlice";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";


function CarRentalFew() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(()=>{
    dispatch(openAddSliceOne());
  },[])

  useEffect(() => {
    const hasModalClosed = Cookies.get('modalClosed');

    if (!hasModalClosed) {
      const timer = setTimeout(() => {
        dispatch(switchOnAddQueryModalOne());
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, []);
  return (
    <div className="w-full">
      
      <div className="relative w-full bg-[#0F0A34] pl-[10.27%] pr-[10.27%] py-20 ">
    <div className="absolute top-0 left-0  h-full  pointer-events-none">
      <img src="../hero-section-bg.png" className="w-full h-full object-cover opacity-60" alt="" />
    </div>
    
    <NewInputContainer />
    <div className=" max-sm:hidden ">
    <RadioBtnCont/>
    </div>
        
      
      </div>
      <div className="w-full">
        <div className="w-full pl-[10.27%] pr-[10.27%] my-8">
          <span className=" font-semibold text-3xl text-[#0F0A34]">Best prices for daily, weekly, and monthly car rentals</span>
        </div>
        
        <NewSearchCarResult CarRentalFew = {"CarRentalFew"}/>
        <button onClick={()=>navigate("/car-rental")}>View More</button>
      </div>
    </div>
  );
}

export default CarRentalFew;
