export function capitalizeWords(str) {
    // Split the string into an array of words
    let words = str?.split(' ');
    // Iterate over each word
    for (let i = 0; i < words?.length; i++) {
        // Capitalize the first letter of each word
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    // Join the words back into a string and return
    return words.join(' ');
}