import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch } from "react-redux";

import { switchOffAddContactModal } from "../../utils/addSlices/addSliceOne";

function AddContactModal() {
    const dispatch = useDispatch();
//   useEffect(() => {
//     window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
//     document.documentElement.style.overflow = "hidden";
//     return () => {
//       document.documentElement.style.overflow = "visible";
//     };
//   }, []);

  const closeAddContactModal = ()=>{
    dispatch(switchOffAddContactModal());
  }
  return (
    <div className="fixed w-screen h-[100%] bg-black bg-opacity-70  flex justify-center items-center z-50">
      <div className="w-[50%] z-50 bg-white border rounded-md mb-8">
        <div className=" w-full flex justify-end items-center p-4 px-6 border-slate-300 ">
          <CancelIcon onClick={closeAddContactModal} className="cursor-pointer"/>
        </div>
        <div className="  w-full flex flex-col items-center justify-start">
        <span className="font-medium text-2xl mb-1">Order Your Rental Car in minutes</span>
        <span className=" font-normal text-xl mx-10 text-center">Fill out the form for a seamless ordering experience. We'll be in touch!</span>
        </div>
        <div className="w-full flex flex-col items-center">
        <div className=" flex flex-col mb-4">
            <span className="text-base font-normal">Phone Number*</span>
           <input type="number" className=" appearance-none w-[25rem] h-[3rem] border bg-[#F5F8FA] rounded-md"/>
          </div>
          
          <div className=" flex flex-col mb-4">
            <span className="text-base font-normal">Email*</span>
           <input type="text" className="w-[25rem] h-[3rem] border rounded-md bg-[#F5F8FA]"/>
          </div>
        
          <div className=" flex flex-col mb-4">
            <span className="text-base font-normal">How long would you like to rent for?*</span>
            <select name="" id="" className=" w-[25rem] h-[3rem] border rounded-md px-2 text-slate-400 bg-[#F5F8FA]" placeholder="Please Select">
                <option value="Please Select" disabled>Please Select</option>
                <option value="Between 1 - 6 Days">Between 1 - 6 Days</option>
                <option value="Between 7 Days - 29 Days">Between 7 Days - 29 Days</option>
                <option value="Between 1-3 Months">Between 1-3 Months</option>
                <option value="More Than 3 Months">More Than 3 Months</option>
            </select>
          </div>
          <div className=" flex flex-col mb-4">
            <button className="border rounded-md text-base font-medium bg-[#353A83] text-white hover:bg-white hover:text-[#353A83] px-4 py-2">Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddContactModal;
