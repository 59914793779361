import React, { useEffect, useState } from "react";
import axios from "axios";

import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { switchOnLoader,switchOffLoader } from "../../../utils/loaderSlice";
import { useDispatch } from "react-redux";
import { baseUrl } from "../../../config";
import { addEmailId, addFirstName, addLastName } from "../../../utils/userSlices/userSlice";
import { userSchema } from "../../../validations/userValidation";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { allCountries } from "../../../utils/countryData/allcountries";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AccountMain() {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({});
  const [open, setOpen] = React.useState(false);
  const [editState, setEditState] = useState(false);


  
  const [userFirstNameVal, setFirstNameVal] = useState(false);
  const [userLastNameVal, setLastNameVal] = useState(false);
  const [userAddress, setUserAddress] = useState(false);
  const [userCity, setUserCity] = useState(false);
  const [userCountry, setUserCountry] = useState(false);
  const [userPhoneNumber, setUserPhoneNumber] = useState(false);
  const [postalCode, setPostalCode] = useState(false);
  const [userEmail, setUserEmail] = useState(false);


  const currentURL = window.location.href;
  const findUserID = currentURL.split("/");

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  let data = JSON.stringify({
    userID: findUserID[findUserID.length - 1],
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}/app/v1/user/getUserDetails`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  useEffect(() => {
    dispatch(switchOnLoader());
    try {
      axios
        .request(config)
        .then((response) => {
          setUserData(response.data.result);
          dispatch(addFirstName(response.data.result?.firstName));
          dispatch(addLastName(response.data.result?.lastName));
          dispatch(switchOffLoader());
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log("Error While calling the API : " + error);
    }
  }, []);

  const handleFirstName = (e) => {
    setUserData((prevPerson) => ({
      ...prevPerson,
      firstName: e.target.value,
    }));
  };

  const handleLastName = (e) => {
    setUserData((prevPerson) => ({
      ...prevPerson,
      lastName: e.target.value,
    }));
  };

  const handleContact = (e) => {
    setUserData((prevPerson) => ({
      ...prevPerson,
      contact: e.target.value,
    }));
  };

  const handleAddress = (e) => {
    setUserData((prevPerson) => ({
      ...prevPerson,
      address: e.target.value,
    }));
  };

  const handleCity = (e) => {
    setUserData((prevPerson) => ({
      ...prevPerson,
      city: e.target.value,
    }));
  };

  const handleCountry = (e) => {
    setUserData((prevPerson) => ({
      ...prevPerson,
      country: e.target.value,
    }));
  };

  const handlePostalCode = (e) => {
    setUserData((prevPerson) => ({
      ...prevPerson,
      postalCode: e.target.value,
    }));
  };

  const checkValidity = async () => {
    let userInfo = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.emailID,
        phoneNumber: userData.contact,
        country: userData.country,
        address: userData.address,
        city: userData.city,
        postalCode: userData.postalCode,
    };

    // const vaildFirstName = await userSchema.isValid(userInfo);
    // console.log('vaildFirstName', vaildFirstName)

    // if (vaildFirstName == true) {
    //   return true;
    // }
    const validFName = await userSchema.fields.firstName.isValid(
      userData.firstName
    );
    
    const validLName = await userSchema.fields.lastName.isValid(
      userData.lastName
    );
    
    const validAddress = await userSchema.fields.address.isValid(
      userData.address
    );

    const validcity = await userSchema.fields.city.isValid(
      userData.city
    );

    const validCountry = await userSchema.fields.country.isValid(
      userData.country
    );

    const validPhoneNumber = await userSchema.fields.phoneNumber.isValid(
      userData.contact
    );

    const validPostalCode = await userSchema.fields.postalCode.isValid(
      userData.postalCode
    );

    const validEmail = await userSchema.fields.email.isValid(
      userData.emailID
    );


    //
    // if (vaildFirstName == false) {
      if (validFName) {
        setFirstNameVal(false);
      } else {
        setFirstNameVal(true);
        return true
      }

      if (validLName) {
        setLastNameVal(false);
      } else {
        setLastNameVal(true);
        return true
      }

      if (validAddress) {
        setUserAddress(false);
      } else {
        setUserAddress(true);
        return true
      }

      if (validcity) {
        setUserCity(false);
      } else {
        setUserCity(true);
        return true
      }

      if (validEmail) {
        setUserEmail(false);
      } else {
        setUserEmail(true);
        return true
      }

      if (validCountry) {
        setUserCountry(false);
      } else {
        setUserCountry(true);
        return true
      }
      if (validPhoneNumber) {
        setUserPhoneNumber(false);
      } else {
        setUserPhoneNumber(true);
        return true
      }
      if (validPostalCode) {
        setPostalCode(false);
      } else {
        setPostalCode(true);
        return true
      }
      // return false;
    // }
  };


  const handleEditApi = async() => {
    const isValid = await checkValidity();

    if (
      !isValid
    ) {
      let data = JSON.stringify({
        userID: userData.userID,
        firstName: userData.firstName,
        lastName: userData.lastName,
        contact: userData.contact,
        country: userData.country,
        address: userData.address,
        city: userData.city,
        postalCode: userData.postalCode,
        emailID: userData.emailID,
      });

      // if(!userData.firstName || !userData.lastName || !userData.contact || !userData.country || !userData.address || !userData.city || !userData.postalCode){
      //     console.log("Missing fields!")
      // } else {
      let config = {
        method: "patch",
        maxBodyLength: Infinity,
        url: `${baseUrl}/app/v1/user/updateUserDetails`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      try {
        await axios
          .request(config)
          .then((response) => {
            setEditState(true);
            handleClick();
          })
          .catch((error) => {
            setEditState(false);
            handleClick();
            console.log(error);
          });
      } catch (error) {
        setEditState(false);
        handleClick();
        console.log("Error While Edit API : " + error);
      }
      // }
    }

  };

  return (
    <>
      <div>
        <div className="w-[70%] max-sm:w-[100%] px-3">
          <div className="text-base w-full flex">
            <div className="w-[50%] flex items-center">First Name</div>
            <div className="w-[50%]">
              <input
                type="text"
                placeholder="First Name"
                value={userData?.firstName}
                onChange={handleFirstName}
                className={`rounded p-2 bg-slate-300 ${!userFirstNameVal ? 'outline-none' : 'border border-red-400'} w-[100%] focus:ring`}
              />
            </div>
          </div>
          <div className="text-base w-full flex mt-2">
            <div className="w-[50%] flex items-center">Last Name</div>
            <div className="w-[50%]">
              <input
                type="text"
                placeholder="Last Name"
                value={userData?.lastName}
                onChange={handleLastName}
                className={`rounded p-2 bg-slate-300 ${!userLastNameVal ? 'outline-none' : 'border border-red-400'} w-[100%] focus:ring`}
              />
            </div>
          </div>
          <div className="text-base w-full flex mt-2">
            <div className="w-[50%] flex items-center">Email ID</div>
            <div className="w-[50%]">
              <input
                type="text"
                placeholder="Email ID"
                value={userData?.emailID}
                readOnly
                className="rounded p-2 bg-slate-300 w-[100%] outline-none focus:ring focus:ring-orange-300"
              />
            </div>
          </div>
          <div className="text-base w-full flex mt-2">
            <div className="w-[50%] flex items-center">Contact No.</div>
            <div className="w-[50%]">
              <input
                type="text"
                placeholder="Contact No."
                value={userData?.contact}
                onChange={handleContact}
                className={`rounded p-2 bg-slate-300 ${!userPhoneNumber ? 'outline-none' : 'border border-red-400'} w-[100%] focus:ring`}
              />
            </div>
          </div>
          <div className="text-base w-full flex mt-2">
            <div className="w-[50%] flex items-center">Address</div>
            <div className="w-[50%]">
              <input
                type="text"
                placeholder="Address"
                value={userData?.address}
                onChange={handleAddress}
                className={`rounded p-2 bg-slate-300 ${!userAddress ? 'outline-none' : 'border border-red-400'} w-[100%] focus:ring`}
              />
            </div>
          </div>
          <div className="text-base w-full flex mt-2">
            <div className="w-[50%] flex items-center">City</div>
            <div className="w-[50%]">
              <input
                type="text"
                placeholder="City"
                value={userData?.city}
                onChange={handleCity}
                className={`rounded p-2 bg-slate-300 ${!userCity ? 'outline-none' : 'border border-red-400'} w-[100%] focus:ring`}
              />
            </div>
          </div>
          <div className="text-base w-full flex mt-2">
            <div className="w-[50%] flex items-center">Country</div>
            <div className="w-[50%]">
              <input
                type="text"
                placeholder="Country"
                value={userData?.country}
                onChange={handleCountry}
                className={`rounded p-2 bg-slate-300 ${!userCountry ? 'outline-none' : 'border border-red-400'} w-[100%] focus:ring`}
              />
            </div>
          </div>
          <div className="text-base w-full flex mt-2">
            <div className="w-[50%] flex items-center">Postal Code</div>
            <div className="w-[50%]">
              <input
                type="text"
                placeholder="Postal Code"
                value={userData?.postalCode}
                onChange={handlePostalCode}
                className={`rounded p-2 bg-slate-300 ${!postalCode ? 'outline-none' : 'border border-red-400'} w-[100%] focus:ring`}
              />
            </div>
          </div>
        </div>
        <div className="w-[70%] flex justify-end items-center mt-4">
          <div
            onClick={handleEditApi}
            className="px-4 py-2 cursor-pointer rounded text-sm text-white bg-[#0f0a34] hover:bg-white hover:text-[#0f0a34] border border-[#0f0a34] transition-all duration-300"
          >
            Edit Details
          </div>
        </div>
      </div>
      <Stack spacing={2} sx={{ width: "100%", color: "white" }}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={editState ? "success" : "error"}
            sx={{ width: "100%", color: "white" }}
          >
            {editState
              ? "Details Edited Successfully"
              : "Error Occured. Try Again !"}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default AccountMain;
