import { createSlice } from "@reduxjs/toolkit";


const subscriptionInvoiceSlice = createSlice({
    name:'subscriptionInvoiceSlice',
    initialState:{
        subscriptionBaseRate:0,
        subscriptionExtrasTotal:0,
        allAddOnNames:[]
    },
   reducers:{
    addSubscriptionBaseRate:(state,action)=>{
        state.subscriptionBaseRate = action.payload;
    },
    addSubscriptionExtras:(state,action)=>{
        state.subscriptionExtrasTotal+=action.payload;
    },
    addExtrasOnTenureChange:(state,action)=>{
        state.subscriptionExtrasTotal = action.payload;
    },
    addAllAddOnNames:(state,action)=>{
        state.allAddOnNames = action.payload;
    },
    emptySubscriptionInvoiceSlice:(state,action)=>{
        state.subscriptionBaseRate = 0;
        state.subscriptionExtrasTotal = 0;
        state.allAddOnNames.length=0;
    }
   }
})

export const {addSubscriptionBaseRate,addSubscriptionExtras,addAllAddOnNames,emptySubscriptionInvoiceSlice,addExtrasOnTenureChange} = subscriptionInvoiceSlice.actions;

export default subscriptionInvoiceSlice.reducer;