import React from 'react';
import LogoImg from "../../images/logo_in_footer_img.svg";
import FooterCallIcon from "../../images/footer_call_icon.svg"
import FooterEmailIcon from "../../images/footer_email_icon.svg"
import FooterAddressIcon from "../../images/footer_address_icon.svg"
import FacebookImg from "../../images/facebook_img.png"
import TwitterImg from "../../images/twitter_img.png"
import InstagramImg from "../../images/instagram_img.png"
import LinkedInImg from "../../images/linkedin_img.png"
import YoutbeImg from "../../images/youtube_img.png"
import { Link } from 'react-router-dom';
import "./index.css"

const MyFooter = () => {

    const companyLinkArr = [
        {
            to: "",
            text: "About Us"
        },
        {
            to: "",
            text: "Case Studies"
        },
        {
            to: "",
            text: "Feedback"
        },
        {
            to: "",
            text: "News and Events"
        },
        {
            to: "",
            text: "Newsletter"
        },
    ]

    const userLinkArr = [
        {
            to: "",
            text: "Login"
        },
        {
            to: "",
            text: "Sign up"
        },
        {
            to: "",
            text: "Manage Booking"
        },
    ]

    const vehicleLinkArr = [
        {
            to: "",
            text: "Brands"
        },
        {
            to: "",
            text: "Collection"
        },
        {
            to: "",
            text: "Popular Cars"
        },
    ]


    const useFullLinkArr = [
        {
            to: "",
            text: "Privacy Policy"
        },
        {
            to: "",
            text: "Terms of Use"
        },
        {
            to: "",
            text: "FAQ"
        },
    ]





    return (
        <>
            <div className='py-[4rem] px-3 bg-footer-image bg-image '>
                <div className='w-full xl:w-[1200px] mx-auto h-full'>
                    <div className='flex flex-wrap items-start w-full'>
                        <div className='md:w-3/12 w-full '
                        >
                            <div className='flex flex-col gap-6 left-gradient-side py-6 px-4 rounded-[12px]'>
                                <div className='flex flex-col gap-3 items-start'>
                                    <img src={LogoImg} className='h-[70px]' alt="" />
                                    <span className='text-[14px] text-[#ffffff] leading-[16px] font-light'>ISO 9001: 2015 certified company</span>
                                </div>
                                <div className='flex items-center gap-2'>
                                    <img src={FooterCallIcon} alt="" />
                                    <div className='flex flex-col gap-[4px]'>
                                        <span className='text-[14px] text-[#ffffff] leading-[16px] font-light'>+971 56 909 7524</span>
                                        <span className='text-[14px] text-[#ffffff] leading-[16px] font-light'>+971 50 264 1477</span>
                                    </div>
                                </div>
                                <div className='flex items-center gap-2'>
                                    <img src={FooterEmailIcon} alt="" />
                                    <div className='flex flex-col gap-[4px]'>
                                        <span className='text-[14px] text-[#ffffff] leading-[20px] font-light'>s.mubeen@wtimobility.ae</span>
                                    </div>
                                </div>
                                <div className='flex items-center gap-2'>
                                    <img src={FooterAddressIcon} alt="" />
                                    <div className='flex flex-col gap-[4px]'>
                                        <span className='text-[14px] text-[#ffffff] leading-[16px] font-light'>Smark 9, Zabeel Business <br /> Center Building, 302-3 Dubai <br /> United Arab Emirates</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='md:w-9/12 w-full'
                        >
                            <div className='flex flex-wrap w-full py-6 md:px-[70px]'>
                                <div className='md:w-3/12 sm:w-6/12 w-full'>
                                    <h4 className='text-[#ffffff] text-[24px] font-medium leading-[32px] footer-link-heading mb-6'>Company</h4>
                                    <ul className='flex flex-col gap-1'>
                                        {
                                            companyLinkArr.map((link, i) => {
                                                return (
                                                    <li key={i}><Link className='text-[16px] text-[#fff] font-light leading-[20px]'>{link.text}</Link></li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className='md:w-3/12 sm:w-6/12 w-full'>
                                    <h4 className='text-[#ffffff] text-[24px] font-medium leading-[32px] footer-link-heading mb-6'>User</h4>
                                    <ul className='flex flex-col gap-1'>
                                        {
                                            userLinkArr.map((link, i) => {
                                                return (
                                                    <li key={i}><Link className='text-[16px] text-[#fff] font-light leading-[20px]'>{link.text}</Link></li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className='md:w-3/12 sm:w-6/12 w-full'>
                                    <h4 className='text-[#ffffff] text-[24px] font-medium leading-[32px] footer-link-heading mb-6'>Vehicles</h4>
                                    <ul className='flex flex-col gap-1'>
                                        {
                                            vehicleLinkArr.map((link, i) => {
                                                return (
                                                    <li key={i}><Link className='text-[16px] text-[#fff] font-light leading-[20px]'>{link.text}</Link></li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className='md:w-3/12 sm:w-6/12 w-full'>
                                    <h4 className='text-[#ffffff] text-[24px] font-medium leading-[32px] footer-link-heading mb-6'>Useful Links</h4>
                                    <ul className='flex flex-col gap-1'>
                                        {
                                            useFullLinkArr.map((link, i) => {
                                                return (
                                                    <li key={i}><Link className='text-[16px] text-[#fff] font-light leading-[20px]'>{link.text}</Link></li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className='flex gap-8 items-start py-6 md:px-[70px]'>
                                <h4 className='text-[#ffffff] text-[24px] font-medium leading-[32px] footer-link-heading mb-6'>Locations</h4>
                                <div className='flex flex-wrap gap-[8px] items-center mt-1'>
                                    <Link className='text-[16px] text-[#fff] font-light leading-[20px]'>Dubai</Link>  <span className='text-[#fff] font-bold'> |</span>
                                    <Link className='text-[16px] text-[#fff] font-light leading-[20px]'>Sharjah</Link> <span className='text-[#fff] font-bold'> |</span>
                                    <Link className='text-[16px] text-[#fff] font-light leading-[20px]'>Sharjah</Link> <span className='text-[#fff] font-bold'> |</span>
                                    <Link className='text-[16px] text-[#fff] font-light leading-[20px]'>Sharjah</Link> <span className='text-[#fff] font-bold'> |</span>
                                    <Link className='text-[16px] text-[#fff] font-light leading-[20px]'>Sharjah</Link> <span className='text-[#fff] font-bold'> |</span>
                                    <Link className='text-[16px] text-[#fff] font-light leading-[20px]'>Sharjah</Link> <span className='text-[#fff] font-bold'> |</span>
                                    <Link className='text-[16px] text-[#fff] font-light leading-[20px]'>Sharjah</Link> <span className='text-[#fff] font-bold'> |</span>
                                    <Link className='text-[16px] text-[#fff] font-light leading-[20px]'>Sharjah</Link>

                                </div>
                            </div>
                            <div className='flex justify-end gap-2 items-center'>
                                <img src={FacebookImg} alt="" />
                                <img src={TwitterImg} alt="" />
                                <img src={InstagramImg} alt="" />
                                <img src={LinkedInImg} alt="" />
                                <img src={YoutbeImg} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full py-3 flex justify-center items-center bg-[#01012B]'>
                <h4 className='text-[#fff] text-[16px] leading-[24px] font-light'>Copyright © 2024. Powered by Wise Travel India</h4>
            </div>
        </>
    )
}

export default MyFooter
