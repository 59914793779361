import React, { useState, useRef } from "react";
import { useEffect } from "react";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { auth_credentials, baseUrl } from "../../config";

function TagsPage() {
  const[relatedTag,setrelatedTag]=useState([]);

  const divRef = useRef(null);

  const Navigate=useNavigate();



  const extractTextWithoutTags = (html) => {
    // Remove HTML tags and trim the content
    const plainText = html.replace(/<[^>]*>/g, "").trim();

    // Get the first 200 characters

    const limitedText = plainText.slice(0, 200);
    return { __html: limitedText };
    // return limitedText;
  };

  const{tag}=useParams();

  useEffect(() => {
    const funcData = async () => {
      const formattedtags =  tag.replace(/-/g, ' ');
 
      try {
        const result = await fetch(
          `${baseUrl}/0auth/Blog/getBlogforParticularTag/${formattedtags}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Basic ${auth_credentials}`
            },
          }
        );
  
        if (!result.ok) {
          throw new Error(`HTTP error! Status: ${result.status}`);
        }
  
        const resdatablog = await result.json();
        setrelatedTag(resdatablog);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error or set a default state
      }
    };
  
    funcData();
  }, [tag]);
  

  const funcDate = (timestamp) => {
    try {
      const date = new Date(timestamp);

      if (isNaN(date.getTime())) {
        // Invalid timestamp or date string
        throw new Error("Invalid time value");
      }

      const formattedDate = new Intl.DateTimeFormat("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      }).format(date);

      return formattedDate;
    } catch (error) {
      console.error("Error formatting timestamp:", error.message);
      return "Invalid Date";
    }
  };


  const handleClick = (slug,categoryName) => {
    const formattedCategoryName = categoryName.replace(/\s+/g, '-');
    const lowerCaseCate = formattedCategoryName.toLowerCase();
    Navigate(`/blog/${lowerCaseCate}/${slug}`)
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    
  };

  function capitalizeFirstLetter(sentence) {
    return sentence.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }
  

  return (
    <div>
      <Helmet>
        <title>Tag - {capitalizeFirstLetter(tag)}</title>

        <link rel="canonical" href={`https://www.wticarrental.ae/blog/tag/${tag}`} />

        <meta property="og:url" content="https://www.wticarrental.ae" />
      </Helmet>
      <div className="flex max-sm:block ">
        <div className="w-[60%]  px-10  max-sm:w-full max-sm:h-full ">
          <h1 className="font-bold pt-6 ">Tag: {tag} </h1>
          <div className="flex  pb-3">
            <div className="w-[18%] h-1 bg-black  "></div>
            <div className="w-[100%] h-[1px] bg-stone-200 my-[1.5px]  "></div>
          </div>
          <div className="w-full mt-2">
            <div className=" ">
              {relatedTag?.map((data, index) => (
                <div
                  className="flex  max-sm:block max-sm:pt-4"
                  key={index}
                  onClick={() => handleClick(data?.slugs, data.categories)}
                >
                  <div className="w-[50%] cursor-pointer hover:scale-[1.008]  h-[13rem] mb-7 max-sm:w-full ">
                    <img
                      src={data?.image}
                      alt=""
                      className="w-full rounded h-full "
                    />
                  </div>
                  <div className="w-[50%]  px-4 max-sm:w-full max-sm:px-0  ">
                    <a href="" className="font-bold cursor-pointer  ">
                      {data?.title}
                    </a>
                    <div className="w-[100%] pt-4" ref={divRef}>
                      {/* <a href="" className=' cursor-pointer' >{data.para}</a> */}
                      {/* <p className="" dangerouslySetInnerHTML={createMarkup(data?.paragraph.slice(0,300))}/> */}
                      <p
                        className="text-[10pt]"
                        dangerouslySetInnerHTML={extractTextWithoutTags(
                          data?.paragraph
                        )}
                      />
                    </div>
                    <div className="flex mt-11">
                      <p className=" text-[12px] pr-2 text-stone-400 flex justify-between items-center gap-1 ">
                        <DateRangeIcon className="text-[20px] " />
                        {funcDate(data?.createdAt)}
                      </p>
                      <div className=" text-[12px] items-center flex gap-1  text-stone-400">
                        <AccessTimeIcon className="text-[20px] " />
                        {data?.readingtime}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* <div className="w-full h-auto">
                                <Stack spacing={2}>
                                  <Pagination count={10} />
                                </Stack>
                              </div> */}
          </div>
        </div>
        {/* <div className="w-[40%] max-sm:w-full ">
                            <div className="  mx-10 mt-20 max-sm:mt-10">
                              <div className="p-4 w-[50%] max-sm:px-0 max-sm:w-full ">
                                <h1 className="text-[14px] font-bold  ">Tags:</h1>
                              </div>
                              
                                
                      <div className="grid grid-cols-3 gap-1  ">
                      {favData.map((itemTags, index) => (
                            <div key={index} className=" rounded" >
                            <div className="text-center p-1  bg-white hover:bg-indigo-950 hover:text-white text-black border rounded mt-[10px] max-sm:w-full max-sm:mb-2"> {itemTags.tags.split(',')[0]}</div>
                            </div>
                          ))}
                      </div>  
                            </div>
                          </div> */}
      </div>
    </div>
  );
}

export default TagsPage;