import React from 'react'
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";

import { switchOffLoader } from '../../utils/loaderSlice';
import { useDispatch } from "react-redux";

function SubPaySuccess() {
       
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSuccess, setShowSuccess] = useState(true);
  const [redirectCount, setRedirectCount] = useState(5);
  const pickUpDes = useSelector(
    (store) => store.bookingInfoSlice.pickupLocation
  );

  const subscriptionObject = useSelector(
    (store) => store.subscriptionSliceMain.subscriptionCarObj
  );
  const subscriptionTripDetails = useSelector(state => state.subscriptionTripDetailSlice);

  // Now, you can access individual properties
  const { subDeliveryLocation } = subscriptionTripDetails;
  

  useEffect(()=>{
    dispatch(switchOffLoader());
    if(Object.keys(subscriptionObject).length==0 || subDeliveryLocation==""){
      setShowSuccess(false);
    }else{
      setShowSuccess(true);
    }
  },[])



  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setRedirectCount(prevCount => prevCount - 1);
    }, 1000);

    // Clean up the interval and call a function when count reaches 0
    if (redirectCount === 0 && showSuccess == true) {
        clearInterval(countdownInterval);
        navigate("/subscription/paymentstatus/success");
    }

    // Clean up the interval when the component unmounts
    return () => clearInterval(countdownInterval);
}, [redirectCount]); // Include count in the dependency array to track changes



  return (
    <div className="w-full flex justify-center my-6">
    {showSuccess ? (
      <div className="w-[50%] flex flex-col justify-start items-center bg-white rounded-md border pt-6 py-6 ">
        <CheckCircleIcon className="text-7xl text-[#16A34A] mb-4" />
        <span className="font-semibold text-xl mb-4 max-sm:text-center">
          Payment Done
        </span>

        <span className="font-medium text-base mb-4">
          Thank you for completing your secure online payment.
        </span>
        <span className="font-medium text-base mb-10">
          Have a great day!
        </span>
        <span className="font-medium text-base mb-10">
          You will be redirected in {" " + redirectCount}
        </span>
        {/* <button className="mb-6 border-[1px] border-[#092C85] hover:bg-[#092C85] hover:text-white px-6 py-1 rounded-md">
          Close
        </button> */}
      </div>
    ) : (
      <div className="w-[50%] flex flex-col justify-center items-center bg-white rounded-md border pt-6 py-16  my-14">
        <span className=" text-2xl text-[#0F0A34] font-bold mb-6">
          Please make a reservation!
        </span>
        <button
          className="py-[10px] text-white px-[35px] bg-[#092C85] rounded-md mb-4"
          
        >
          Make a new reservation
        </button>
      </div>
    )}
  </div>
  )
}

export default SubPaySuccess