import React, { lazy } from "react";
import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import TitleBarMain from "./components/titleBar/TitleBarMain";
import Footer from "./components/footer/Footer";
//--> car result page imports
import StepperCustom from "./components/carSelectType/horizontalStepper/StepperCustom";
import SearchResultsCar from "./components/carSelectType/carSearchResults/SearchResultsCar";
//--> extras page imports
import ExtraMain from "./components/extras/ExtraMain";
//--> final page imports
import PaymentFormUser from "./components/paymentForm/PaymentFormUser";
import { useState, useEffect } from "react";
import ReservationMain from "./components/reservationConfirmed/ReservationMain";
import ProfileMain from "./components/profile/ProfileMain";
// import loginMain from './components/login/loginMain';
import LogMain from "./components/login/LogMain";
import { useSelector } from "react-redux/es/hooks/useSelector";
import Loader from "./components/loader/Loader";
import AboutMainPage from "./components/about/AboutMainPage";
import CancelConfirmation from "./components/profile/bookings/CancelConfirmation";
import PaymentMain from "./components/reservationConfirmed/PaymentMain";
import TermsAndC from "./components/termsConditions/TermsAndC";
import CurrencySearchPage from "./components/currency/CurrencySearchPage";
import ContactModal from "./components/contact/ContactModal";
import FaqMain from "./components/faq/FaqMain";
import AdminMain from "./components/admin/AdminMain";
import AdminLogin from "./components/admin/AdminLogin";
import Error from "./components/loader/Error404";
import CarSegmentDivide from "./components/carSelectType/carSearchResults/CarSegmentDivide.jsx";
import AirportLexus from "./components/airportTransferPages/AirportLexus";
import ContactUsLanding from "./components/contact/ContactUsLanding.jsx";
import ContactThanku from "./components/contact/ContactThanku.jsx";
import PrivacyPolicy from "./components/privacyPolicy/PrivacyPolicy.jsx";
import PaymentSuccess from "./components/reservationConfirmed/PaymentSuccess.jsx";
import PaymentFailed from "./components/reservationConfirmed/PaymentFailed.jsx";
import AddLandingOne from "./components/addLandingPages/AddLandingOne.jsx";
import AddContactModal from "./components/addLandingPages/AddContactModal.jsx";
import AdminBlog from "./components/admin/AdminMui.jsx";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import Form from "./components/admin/components/TestPage.jsx";
import AddPopUpOne from "./components/addPopups/AddPopUpOne.jsx";
import CarPickUpNotificationModal from "./components/notificationModals/CarPickUpNotificationModal.jsx";
import { HelmetProvider } from "react-helmet-async";
import SubscriptionMain from "./components/subscription/SubscriptionMain.jsx";
import GoogleAddOne from "./components/addPopups/GoogleAddOne.jsx";
import {
  switchOnGooglePopOneModal,
  switchOffGooglePopOneModal,
} from "./utils/ModalSlice/modalToggleSlice.js";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import BlogTest from "./components/blog/BlogTest.jsx";
import SubscriptionLast from "./components/subscription/SubscriptionLast.jsx";
import SubscriptionPaymentSuccess from "./components/subscription/SubscriptionPaymentSuccess.jsx";
import SubscriptionPaymentMain from "./components/subscription/SubscriptionPaymentMain.jsx";
import SubPaySuccess from "./components/subscription/SubPaySuccess.jsx";
import SubscriptionPaymentFailed from "./components/subscription/SubscriptionPaymentFailed.jsx";
import Blog from "./components/blog/Blog.jsx";
import TagsPage from "./components/blog/TagsPage.jsx";
import CarSpecifications from "./components/CarSpecifications/CarSpecifications.jsx";
import CarRentalFew from "./components/addLandingPages/CarRentalFew.jsx";
import SubscriptionPage from "./components/subscription/SubscriptionPage.jsx";
import BrandsPage from "./components/subscription/BrandsPage.jsx";
import BlogCategory from "./components/blog/BlogCategory.jsx";
import Auther from "./components/blog/Auther.jsx";
import { Helmet } from "react-helmet";
import axios from "axios";
import ReactGA from 'react-ga4'
import Loadable from "./components/loader/lazy/Loadable.js";
import { auth_credentials, baseUrl } from "./config.js";
import HomePage from "../src/pages/HomePage/HomePage.jsx"
import NewSubscriptionPage from "./pages/NewSubscriptionPage/NewSubscriptionPage.jsx";
import BookingPage from "./pages/BookingPage/SelfDriveBookingPage/BookingPage.jsx";
import SubscriptionBookingPage from "./pages/BookingPage/SubscriptionBookingPage/SubscriptionBookingPage.jsx";
import ModifyBooking from "./components/profile/bookings/ModifyBooking.jsx"
import TermsAndConditions from "./components/termsConditions/TermsAndConditions.jsx";


const IntelligentMobility  = Loadable(lazy(() => import("./components/intelligentMobility/Main")));
const DubaiCarLease  = Loadable(lazy(() => import("./components/dubai-final/car-lease/DubaiCarLease.jsx")));
const CarRentalLocationApi  = Loadable(lazy(() => import("./components/locationPages/carRental/CarRentalLocationApi.jsx")));
const AirportTransferLocationApi  = Loadable(lazy(() => import("./components/locationPages/airportTransfer/AirportTransferLocationApi.jsx")));
const SelfDriveLocationApi  = Loadable(lazy(() => import("./components/locationPages/selfDrive/SelfDriveLocationApi.jsx")));
const ChauffeurServiceLocationApi  = Loadable(lazy(() => import("./components/locationPages/chauffeurService/ChauffeurServiceLocationApi.jsx")));
const CarSubscriptionLocationApi  = Loadable(lazy(() => import("./components/locationPages/CarSubscription/CarSubscriptionLocationApi.jsx")));
const NewCarRentalApi  = Loadable(lazy(() => import("./components/addLandingPages/NewCarRentalApi.jsx")));
const SelfDriveCarsApi  = Loadable(lazy(() => import("./components/locationPages/selfDrive/SelfDriveCarsApi.jsx")));
const AirportTransferApi  = Loadable(lazy(() => import("./components/locationPages/airportTransfer/AirportTransferApi.jsx")));
const ChauffeurServiceApi  = Loadable(lazy(() => import("./components/locationPages/chauffeurService/ChauffeurServiceApi.jsx")));
const SubscriptionInfoPageApi  = Loadable(lazy(() => import("./components/subscription/SubscriptionInfoPage/SubscriptionInfoPageApi.jsx")));
const DubaiCarRentalCorporate  = Loadable(lazy(() => import("./components/dubai-final/car-rental-corporate/DubaiCarRentalCorporate.jsx")));;
const AllBlogs  = Loadable(lazy(() => import("./components/blog/AllBlogs.jsx")));
const MainLandingPage = Loadable(lazy(() => import("./components/addLandingPages/MainLandingPage.jsx")));


axios.interceptors.request.use(
  config => {
    config.headers.Authorization = `Basic ${auth_credentials}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

function App() {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker
        .register("/sw.js")
        .then((registration) => {
          console.log(
            "Service Worker registered with scope:",
            registration.scope
          );
        })
        .catch((error) => {
          console.error("Service Worker registration failed:", error);
        });
    });
  }

  ReactGA.initialize("G-59VLBR0TEQ");
  ReactGA.initialize("G-F0LTWDHDDC");


  

  // Now, render your React app or perform other setup
  const dispatch = useDispatch();
  const userID = useSelector((store) => store.userCookie.userId);
  const loginStatus = useSelector((store) => store.loginSlice.loginModal);
  const loaderStatus = useSelector((store) => store.loaderSlice.loaderStatus);
  const cancelModalStatus = useSelector(
    (store) => store.modalToggleSlice.cancelModal
  );
  const toggleModalStatus = useSelector(
    (store) => store.modalToggleSlice.termsAndConditionModal
  );
  const contactModalStatus = useSelector(
    (store) => store.modalToggleSlice.contactUsModal
  );
  const adminUserName = useSelector((store) => store.loginSlice.adminUser);
  const addContactModalStatus = useSelector(
    (store) => store.addSliceOne.addContactModal
  );
  const addQueryModalOneStatus = useSelector(
    (store) => store.modalToggleSlice.addQueryModalOne
  );
  const [modal, setModal] = React.useState(false);

  const showHeaderFooterStatus = useSelector(
    (store) => store.modalToggleSlice.hideHeaderAndFooter
  );

  const pickUpDropStatus = useSelector(
    (store) => store.modalToggleSlice.pickupAndDeliveryModal
  );

  const googlePopStatus = useSelector(
    (store) => store.modalToggleSlice.googlePopOneModal
  );

  const url = window.location.href.split("/");

  const [googlePopFirstTime, setGooglePopFirstTime] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const popStatus = checkAndLogGooglePopCookieStatus();
      if (popStatus) {
        if (Cookies.get("googlePopModal") == false) {
          dispatch(switchOnGooglePopOneModal());
          setGooglePopFirstTime(true);
        } else {
          dispatch(switchOffGooglePopOneModal());
          setGooglePopFirstTime(false);
        }
      } else {
        dispatch(switchOnGooglePopOneModal());
        setGooglePopFirstTime(true);
      }
    }, 20000); // Adjust the timeout duration as needed (in milliseconds)

    // Cleanup function to clear the timeout on component unmount
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  // useEffect(() => {
  //   // Initial check for the cookie

  //   // Set up interval to check the cookie every 2-3 seconds

  //   const intervalId = setInterval(() => {
  //     checkAndLogGooglePopCookieStatus();
  //   }, 2000); // Adjust the interval duration as needed (in milliseconds)

  //   // Cleanup function to clear the interval on component unmount
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);

  const getGeoInfo = (type) => {
    axios
      .get("https://ipapi.co/json/")
      .then(async (response) => {
        let responseData = response.data;
        let data = JSON.stringify({
          date: "",
          location: responseData,
          fromPage: 1,
          type: type.toString(),
        });
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${baseUrl}/app/v1/track/tracking`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config)
          .then(() => {
            console.log("tracking entry");
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const checkAndLogGooglePopCookieStatus = () => {
    const isCookiePresent = Cookies.get("googlePopModal");

    if (isCookiePresent) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      <HelmetProvider>
        <BrowserRouter>
          <div className={`w-full relative ${modal ? "overflow-hidden" : ""}`}>
            {url[url.length - 1] == "admin" ? (
              <></>
            ) : (
              <>
                <div
                  onClick={() => {
                    const mobileNumber = "+971 56 909 7524";

                    let number = mobileNumber
                      .replace(/[^\w\s]/gi, "")
                      .replace(/ /g, "");

                    // Appending the phone number to the URL
                    let url = `https://wa.me/${number}`;
                    window.open(url, "_blank");
                  }}
                  className="fixed hidden bottom-6 right-6 z-50 max-sm:flex justify-center items-center"
                >
                  <WhatsAppIcon className=" text-white bg-green-600 text-[50px] p-2 rounded-full cursor-pointer hover:outline outline-green-600 outline-offset-2" />
                </div>
                <div
                  onClick={() => {
                    let callType = "whatsapp";
                    getGeoInfo(callType);
                    // const mobileNumber = "+971 56 909 7524";

                    // let number = mobileNumber
                    //   .replace(/[^\w\s]/gi, "")
                    //   .replace(/ /g, "");

                    // Appending the phone number to the URL
                    // let url = `https://web.whatsapp.com/send?phone=${number}`;
                    let url =
                      "https://api.whatsapp.com/send?phone=971527687206&text=Hi";

                    window.open(url);
                  }}
                  className="fixed bottom-6 right-6 z-50 flex max-sm:hidden justify-center items-center"
                >
                  <WhatsAppIcon className=" text-white bg-green-600 text-[50px] p-2 rounded-full cursor-pointer hover:outline outline-green-600 outline-offset-2" />
                </div>
                <div
                  onClick={() => {
                    let callType = "telegram";
                    getGeoInfo(callType);
                    window.open("https://t.me/WTi_2023", "_blank").focus();
                  }}
                  className="fixed bottom-20 right-6 z-50 flex justify-center items-center"
                >
                  <TelegramIcon className=" text-white bg-blue-400 text-[50px] p-2 pr-3 rounded-full cursor-pointer hover:outline outline-blue-400 outline-offset-2" />
                </div>
              </>
            )}

            {loginStatus ? <LogMain /> : <></>}
            {loaderStatus ? <Loader /> : <></>}
            {cancelModalStatus ? <CancelConfirmation /> : <></>}
            {toggleModalStatus ? <TermsAndC /> : <></>}
            {contactModalStatus ? <ContactModal /> : <></>}

            {showHeaderFooterStatus ? <></> : <TitleBarMain />}
            {addContactModalStatus ? <AddContactModal /> : <></>}
            {addQueryModalOneStatus ? <AddPopUpOne /> : <></>}
            {pickUpDropStatus ? <CarPickUpNotificationModal /> : <></>}
            {googlePopStatus ? <GoogleAddOne /> : <></>}

            <Routes>
              {/* <Route path="/" element={ <Navigate to="/home" /> } /> */}
              <Route
                path="/"
                element={
                    <MainLandingPage />
                }
              />
              <Route
                path="/home"
                element={
                    <HomePage />
                }
              />
              <Route
                path="/my-subscription"
                element={
                    <NewSubscriptionPage />
                }
              />
              {/* <Route path="/home" element={<><BannerRootContainer/><SliderAdd/><PreferMain/><MainLocationCont/></>}/> */}

              <Route
                path="/car-specification"
                element={<CarSpecifications />}
              />

              <Route path="/terms-of-use" element={<TermsAndConditions />} />

              <Route path="/faqs" element={<FaqMain />} />
              <Route path="/contact/thankyou" element={<ContactThanku />} />
              <Route
                path="/airport-lexus-rental"
                element={
                  <>
                    <AirportLexus />
                  </>
                }
              />
              <Route
                path="/contact-us"
                element={
                  <>
                    <ContactUsLanding />
                  </>
                }
              />
              <Route
                path="/reservation/vehicles"
                element={
                  <>
                    <Helmet>
                      <title>Select A Car</title>
                      <link
                        rel="canonical"
                        href="https://www.wticarrental.ae/reservation/vehicles"
                      />
                    </Helmet>
                    <StepperCustom />
                    <SearchResultsCar />
                  </>
                }
              />
              <Route
                path="/reservation/extras"
                element={
                  <>
                    <Helmet>
                      <title>Choose Extras</title>
                      <link
                        rel="canonical"
                        href="https://www.wticarrental.ae/reservation/vehicles/reservation/extras"
                      />
                    </Helmet>
                    <StepperCustom />
                    <ExtraMain />
                  </>
                }
              />
              <Route
                path="/reservation/review-and-book"
                element={
                  <>
                    <Helmet>
                      <title>Review And Book</title>
                      <link
                        rel="canonical"
                        href="https://www.wticarrental.ae/reservation/vehicles/reservation/review-and-book"
                      />
                    </Helmet>
                    <StepperCustom />
                    <PaymentFormUser />
                  </>
                }
              />
              {/* <Route
                path="/reservation/paymentgateway/:customerID"
                element={
                  <>
                    <PaymentMainStripe />
                  </>
                }
              /> */}
              <Route
                path="/privacy-policy"
                element={
                  <>
                    <PrivacyPolicy />
                  </>
                }
              />
              <Route
                path="/reservation/confirmed"
                element={
                  <>
                    <ReservationMain />
                  </>
                }
              />
              <Route
                path="/reservation/paymentstatus"
                element={
                  <>
                    <PaymentMain />
                  </>
                }
              />
              <Route
                path="/reservation/paymentstatus/success"
                element={
                  <>
                    <PaymentSuccess />
                  </>
                }
              />
              <Route
                path="/subscription/paymentstatus"
                element={
                  <>
                    <SubscriptionPaymentMain />
                  </>
                }
              />

              <Route
                path="/subscription/paymentstatus/success"
                element={
                  <>
                    <SubscriptionPaymentSuccess />
                  </>
                }
              />

              <Route
                path="/subscription/paymentstatus/paymentcomplete"
                element={
                  <>
                    <SubPaySuccess />
                  </>
                }
              />
              <Route
                path="/subscription/paymentstatus/paymentincomplete"
                element={
                  <>
                    <SubscriptionPaymentFailed />
                  </>
                }
              />

              <Route
                path="/reservation/paymentstatus/failed"
                element={
                  <>
                    <PaymentFailed />
                  </>
                }
              />
              {/* <Route path='/' element={<Protected Component={ProfileMain}/>}/> */}
              <Route
                path="/profile/:userID"
                element={
                  <>
                    <ProfileMain />
                  </>
                }
              />
              <Route
                path="/modify-booking"
                element={
                  <>
                    <Helmet>
                      <title>Modify And Book</title>
                      <link
                        rel="canonical"
                        // href="https://www.wticarrental.ae/modify-booking"
                      />
                    </Helmet>
                    <ModifyBooking />
                  </>
                }
              />
              <Route
                path="/self-drive-booking-page"
                element={
                  <>
                    <BookingPage />
                  </>
                }
              />
              <Route
                path="/subscription-booking-page"
                element={
                  <>
                    <SubscriptionBookingPage />
                  </>
                }
              />
              <Route
                path="/corporate-mobility"
                element={
                  <>
                    <IntelligentMobility />
                  </>
                }
              />
              <Route
                path="/about-us"
                element={
                  <>
                    <AboutMainPage />
                  </>
                }
              />
              <Route
                path="/currencysearch"
                element={
                  <>
                    <CurrencySearchPage />
                  </>
                }
              />
              <Route
                path="/testing"
                element={
                  <>
                    <CarSegmentDivide />
                  </>
                }
              />

              <Route
                path="/brands"
                element={
                  <>
                    <BrandsPage />
                  </>
                }
              />

              <Route
                path="/car-rental"
                element={
                  <>
                    <AddLandingOne CarRentalFew={"CarRentalFew"} />
                  </>
                }
              />

              <Route
                path="/car-rental-service"
                element={
                  <>
                    <NewCarRentalApi />
                  </>
                }
              />

              <Route
                path="/:city/car-rental-service"
                element={<CarRentalLocationApi />}
              />

              <Route
                path="/:city/self-drive-car-rental"
                element={
                  <>
                    <SelfDriveLocationApi />
                  </>
                }
              />

              <Route
                path="/:city/chauffeur-service"
                element={
                  <>
                    <ChauffeurServiceLocationApi />
                  </>
                }
              />

              <Route
                path="/:city/airport-transfers"
                element={
                  <>
                    <AirportTransferLocationApi />
                  </>
                }
              />

              <Route
                path="/:city/car-subscription"
                element={
                  <>
                    <CarSubscriptionLocationApi />
                  </>
                }
              />

              <Route
                path="/car-rental-few"
                element={
                  <>
                    <CarRentalFew />
                  </>
                }
              />

              {/* <Route path="/blog/:slug" element={<BlogMain />} /> */}
              <Route path="/blog" element={<BlogTest />} />
              {/* <Route path="/blog/:slug" element={<Blog2 />} /> */}
              <Route
                path={"/blog/:category/:slug"}
                element={
                  <>
                    <Blog />
                  </>
                }
              />

              <Route
                path={"/blog/author/:authorName"}
                element={
                  <>
                    <Auther />
                  </>
                }
              />

              <Route
                path={"/blog/:categoryName"}
                element={
                  <>
                    <BlogCategory />
                  </>
                }
              />
              <Route
                path={"/all-blogs"}
                element={
                  <>
                    <AllBlogs />
                  </>
                }
              />
              <Route path={"/blog/tag/:tag"} element={<TagsPage />} />

              <Route
                path="/testing/blog/admin"
                element={
                  <>
                    <AdminBlog />
                  </>
                }
              />

              <Route
                path="/self-drive-car-rental"
                element={<SelfDriveCarsApi />}
              />
              <Route
                path="/airport-transfer-service"
                element={<AirportTransferApi />}
              />
              <Route
                path="/chauffeur-service"
                element={<ChauffeurServiceApi />}
              />
              <Route
                path="/subscription-cars"
                element={
                  <>
                    <SubscriptionPage />
                  </>
                }
              />

              <Route
                path="/car-subscription-service"
                element={
                  <>
                    <SubscriptionInfoPageApi />
                  </>
                }
              />

              <Route
                path="/car-subscription-fleet"
                element={
                  <>
                    <SubscriptionMain />
                  </>
                }
              />

              <Route
                path="/car-subscription-info"
                element={<SubscriptionInfoPageApi />}
              />

              <Route
                path="/dubai/subscription/:carname"
                element={
                  <>
                    <SubscriptionLast />
                  </>
                }
              />

              {adminUserName == "admin@wti.ae" ||
              adminUserName == "checkeradmin@wti.ae" ? (
                <Route
                  path="/admin"
                  element={
                    <>
                      <AdminMain modal={modal} setModal={setModal} />
                    </>
                  }
                />
              ) : (
                <></>
              )}

              <Route
                path="/login/admin"
                element={
                  <>
                    <AdminLogin />
                  </>
                }
              />

              <Route
                path="/testingForm"
                element={
                  <>
                    <Form />
                  </>
                }
              />
              <Route
                path="/corporate-car-rental"
                element={
                  <>
                    <DubaiCarRentalCorporate CarRentalFew={"CarRentalFew"} />
                  </>
                }
              />
              <Route
                path="/corporate-car-lease"
                element={
                  <>
                    <DubaiCarLease />
                  </>
                }
              />
              <Route
                path="*"
                element={
                  <>
                    <Error />
                  </>
                }
              />
            </Routes>

            {url[url.length - 1] == "admin" || showHeaderFooterStatus ? (
              <></>
            ) : (
              <>
                <Footer />
              </>
            )}
          </div>
        </BrowserRouter>
      </HelmetProvider>
    </React.Fragment>
  );
}

export default App;
