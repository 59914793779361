import React, { useState } from "react";
import pf from "./paymnetFail.jpg";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { onPaymentFailed } from "../../utils/paymentSession";
import { useDispatch } from "react-redux";
import axios from "axios";
import { addSessionID } from "../../utils/paymentSession";
import { switchOffLoader } from "../../utils/loaderSlice";
import { baseUrl } from "../../config";



function SubscriptionPaymentFailed() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showFail, setShowFail] = useState(true);
    const stripeCustomerID = useSelector((store)=>store.paymentSession.stripeCustomerID);
    const conversionRate = useSelector(
      (store) => store.currencySlice.currentCurrRate
    );

    const subscriptionObject = useSelector(
        (store) => store.subscriptionSliceMain.subscriptionCarObj
      );
      const subscriptionTripDetails = useSelector(state => state.subscriptionTripDetailSlice);
    
      // Now, you can access individual properties
      const { subDeliveryLocation } = subscriptionTripDetails;
  
      const subscriptionTenureNumber = useSelector(
        (store) => store.subscriptionSliceMain.tenureNumber
      );

      const totalBaseRate = useSelector(
        (store) => store.subscriptionInvoiceSlice.subscriptionBaseRate
      );
      const totalExtrasRate = useSelector(
        (store) => store.subscriptionInvoiceSlice.subscriptionExtrasTotal
      );
      const carDropObj = useSelector(
        (store) => store.pickDropSlice.carDropLocation
      );

      const subReservationId = useSelector((store)=>store.subscriptionTripDetailSlice.subReservationId);
      const userID = useSelector((store)=>store.userSlice.userId);
      
  const currencyName = useSelector((store) => store.currencySlice.currentCurr);
  
  
    const moveToHome = () => {
      navigate("/");
    };
  
    useEffect(() => {
      dispatch(switchOffLoader());
        if (Object.keys(subscriptionObject).length==0 || subDeliveryLocation == "") {
          setShowFail(false);
        } else {
          setShowFail(true);
          dispatch(onPaymentFailed());
        }

    }, []);
  
    
    const checkoutPage = () => {
        let data = JSON.stringify({
          "customerID": stripeCustomerID,
          "carType": subscriptionObject.title,
          "subscriptionDuration": subscriptionTenureNumber,
          "amount": (totalBaseRate + totalExtrasRate+carDropObj.locationRate) * 1.05 * conversionRate,
          "currency": currencyName,
          "userID": userID,
          "subscriptionID": subReservationId
        });
        
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${baseUrl}/app/v1/billing/subscriptionCheckout`,
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
    
        axios
          .request(config)
          .then((response) => {
            dispatch(addSessionID(response.data.sessionID));
            //created session
            //session url
            //session id
            dispatch(switchOffLoader());
            window.location = response.data.sessionURL;
          })
          .catch((error) => {
            console.log(error);
          });
      };
  return (
    <React.Fragment>
    {showFail ? (
      <div className="w-full  flex justify-center my-6">
        <div className="w-full flex justify-center my-6">
          <div className="w-[50%] flex flex-col justify-start items-center bg-white rounded-md border pt-6">
            <img src={pf} alt="" className="w-[30%] h-[40%]" />
            <span className="font-semibold text-xl mb-4 max-sm:text-center">
              Payment Failed
            </span>

            <span className="font-medium text-base mb-10">
              Please try again!
            </span>
            <button className="mb-6 border-[1px] border-[#092C85] hover:bg-[#092C85] hover:text-white px-6 py-1 rounded-md" onClick={()=>checkoutPage()}>Try Again</button>
          </div>
        </div>
      </div>
    ) : (
      <div className="w-full flex justify-center my-6">
        <div className="w-[50%] flex flex-col justify-center items-center bg-white rounded-md border pt-6 py-16  my-14">
          <span className=" text-2xl text-[#0F0A34] font-bold mb-6">
            Please make a reservation!
          </span>
          <button
            className="py-[10px] text-white px-[35px] bg-[#092C85] rounded-md mb-4"
            onClick={moveToHome}
          >
            Make a new reservation
          </button>
        </div>
      </div>
    )}
  </React.Fragment>
  )
}

export default SubscriptionPaymentFailed