import React, { useEffect, useState } from "react";
import logo from "./pac.png";
import { FormControlLabel, Checkbox } from "@mui/material";
import { useSelector } from "react-redux";
import { addExtra, removeExtra } from "../../utils/extraSlice";
import { useDispatch } from "react-redux";
import {
  addExtras,
  removeExtras,
} from "../../utils/invoice/invoiceSlice";

function ProtectionCard(props) {


  let {
    personalInsuranceCheck,
    setPersonalInsuranceCheck,
    equipmentsObj,
  } = props
  const dispatch = useDispatch();
  const currencyName = useSelector((store) => store.currencySlice.currentCurr);
  const currencyPrice = useSelector(
    (store) => store.currencySlice.currentCurrRate
  );
  const tNumDays = useSelector(
    (store) => store.bookingInfoSlice.totalNumberOfDays
  );
  const extraObj = useSelector((store) => store.extraSlice.allExtraBooleans);


  useEffect(()=>{
    if(extraObj.personalInsuranceBoolean == true){
        setPersonalInsuranceCheck(true);
    }
  },[])

  const handleInsurance = (equipmentsObj, insurancePrice) => {
    if (personalInsuranceCheck) {
      dispatch(removeExtras(insurancePrice));
      dispatch(removeExtra(equipmentsObj.id + "P"));
    } else {
      dispatch(addExtras(insurancePrice));
      dispatch(addExtra(equipmentsObj.id + "P"));
    }
    setPersonalInsuranceCheck(!personalInsuranceCheck);
  };


  return (
    <React.Fragment>
      <div className="shadow-xl rounded-md mb-4 cursor-pointer hover:border hover:border-black">
        <div className="icon flex">
          <div className="img flex justify-center items-center  pl-4 pr-4 ">
            <img src={logo} alt="" className="border rounded-full max-w-sm " />
          </div>
          <div className="flex flex-col justify-center items-start pt-6  pb-2 pr-4 pl-4">
            <span className="font-semibold text-sm mb-2">
              {equipmentsObj?.title} ({equipmentsObj?.name})
            </span>
            <span className="text-[12px] font-normal leading-4">
              {equipmentsObj?.description}
            </span>
          </div>
        </div>
        <div className="check flex pl-8 pb-2">
          <FormControlLabel
            label={
              <span className="text-sm font-semibold  text-[#092C85] leading-4">
                {currencyName +
                  " " +
                  Number(
                    (
                      Number(
                        Object.values(
                          equipmentsObj.price
                        )[0]
                      ) *
                      currencyPrice *
                      tNumDays
                    )?.toFixed(2)
                  ) +
                  ` - ${tNumDays} DAY`}
              </span>
            }
            control={
              <Checkbox
                checked={personalInsuranceCheck}
                onChange={() => {
                  let clickInsurancePrice = Object.values(
                    equipmentsObj?.price
                  );
                  clickInsurancePrice =
                    parseFloat(clickInsurancePrice[0]) * tNumDays;
                  handleInsurance(equipmentsObj, clickInsurancePrice);
                }}
              />
            }
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default ProtectionCard;
