import React from "react";


const BrandCard = ({ brand }) => {
  const { brandImg, brandName, modelName, offers, alt } = brand;
  return (
    <div className="w-full sm:w-6/12 md:w-4/12 lg:w-3/12 p-2 ">
      <div className="flex flex-col items-center gap-0 border hover:cursor-pointer hover:border-[#ff7373] bg-white rounded-[5px] shadow-[0_1px_3px_rgba(0,0,0,.16)] py-5">
        <div>
          <img src={brandImg} alt={alt} loading="lazy"/>
        </div>
        <div className="brand-text flex items-center gap-1 flex-col">
          <h3 className="text-[24px]">{brandName}</h3>
          {/* <h5 className="text-[16px]">Stelvio</h5> */}
          <h4 className="text-[18px] text-[#0f0a34]">{offers} Car Rental Offers</h4>
        </div>
      </div>
    </div>
  );
};

export default BrandCard;
