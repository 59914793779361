import React from "react";
import { Box, FormControlLabel, Checkbox } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toggleDifferentLocation } from "../../../utils/BookingPageSlices/formControlSlice";

function LocationBtn() {
  const itineraryModalStatus = useSelector(
    (store) => store.modalToggleSlice.updateItinerary
  );

  const locationStatus = useSelector(
    (store) => store.formControlSlice.differentLocation
  );
  const dispatch = useDispatch();
  const handleToggle = () => {
    dispatch(toggleDifferentLocation());
  };
  return (
    <React.Fragment>
      <Box className="mr-[1rem]">
        <FormControlLabel
        
          label={
            <span
              className={`text-xs font-thin leading-4  ${
                !itineraryModalStatus ? "text-white" : "text-black"
              }`}
            >
              Drop car off at different location
            </span>
          }
          control={
            <Checkbox checked={locationStatus} className="fill-[#8495c]"   onClick={handleToggle} />
          }
        />
      </Box>
    </React.Fragment>
  );
}

export default LocationBtn;
