import React, { useEffect, useState } from "react";
import axios from "axios";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import BlogUpdateForm from "./BlogUpdateForm";
import { baseUrl } from "../../../config";

function AllBlogs() {
  const [blogs, setBlogs] = useState([]);
  const [editBlogID, setEditBlogID] = useState('');
  useEffect(() => {
    const apiCall = async () => {
      let result = await axios.get(
        `${baseUrl}/0auth/Blog/allBlog`
      );
      setBlogs(result.data);
    };
    apiCall();
  }, []);

  const convertDate = (val) => {
    let date = new Date(val);
    const options = { year: "numeric", month: "short", day: "numeric" };

    // Format options for time
    const timeOptions = { hour: "numeric", minute: "numeric" };

    const formattedDateString = `${date.toLocaleDateString(
      undefined,
      options
    )} ${date.toLocaleTimeString(undefined, timeOptions)}`;

    return formattedDateString;
  };

  const findObj = (id) => {
    let blog = blogs.filter((blog) => blog._id == id);
    return blog[0];
  } 

  return (
    <>
      {
        editBlogID == '' ? 
        <>
          <div className="w-full flex justify-center items-center">
        <div className="table w-[100%]">
          <table className="border border-black w-full">
            <tr>
              <th className="border border-black p-2">Title</th>
              <th className="border border-black p-2">Categories</th>
              <th className="border border-black p-2">Slugs</th>
              <th className="border border-black p-2">Created At</th>
              <th className="border border-black p-2">Updated At</th>
              <th className="border border-black p-2">Actions</th>
            </tr>
            {blogs.map((blog) => (
              <tr>
                <td className="border border-black p-2">{blog.title}</td>
                <td className="border border-black p-2">{blog.categories}</td>
                <td className="border border-black p-2">{blog.slugs}</td>
                <td className="border border-black p-2 text-center">
                  {convertDate(blog.createdAt)}
                </td>
                <td className="border border-black p-2 text-center">
                  {convertDate(blog.updatedAt)}
                </td>
                <td className="border-t border-black p-2 flex justify-around items-center h-full">
                  <Tooltip title="Edit">
                    <IconButton onClick={() => setEditBlogID(blog._id)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>{" "}
                  <Tooltip title="Delete">
                    <IconButton onClick={() => findObj("655b411ec8e5d1821ecf9d73")}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
        </> 
        : 
        <>
          <BlogUpdateForm blog={findObj(editBlogID)} setEditBlogID={setEditBlogID} />
        </>
      }
      
    </>
  );
}

export default AllBlogs;
