import { createSlice } from "@reduxjs/toolkit";


const subscriptionTripDetailSlice = createSlice({
    name:"subscriptionTripDetailSlice",
    initialState:{
        subDeliveryLocation:"",
        subDeliveryDate:"",
        subDeliveryTime:"",
        subFirstName:"",
        subLastName:"",
        subPhone:"",
        subEmail:"",
        subAddress:"",
        subCity:"",
        subCountry:"",
        subReservationId:"",
    },

    reducers:{
        addSubDetails:(state,action)=>{
            state.subDeliveryLocation = action.payload.subDeliveryLocation;
            state.subDeliveryDate = action.payload.subDeliveryDate;
            state.subDeliveryTime = action.payload.subDeliveryTime;
            state.subFirstName = action.payload.subFirstName;
            state.subLastName = action.payload.subLastName;
            state.subPhone = action.payload.subPhone;
            state.subEmail = action.payload.subEmail;
            state.subAddress = action.payload.subAddress;
            state.subCity = action.payload.subCity;
            state.subCountry = action.payload.subCountry;
        },
        removeSubDetails:(state,action)=>{
            state.subDeliveryLocation = "";
            state.subDeliveryDate = "";
            state.subDeliveryTime = "";
            state.subFirstName = "";
            state.subLastName = "";
            state.subPhone = "";
            state.subEmail = "";
            state.subAddress = "";
            state.subCity = "";
            state.subCountry = "";
        },
        addSubReservationId:(state,action)=>{
            state.subReservationId = action.payload;
        },
        removeSubReservationId:(state,action)=>{
            state.subReservationId = "";
        },

    }
})

export const {addSubDetails,removeSubDetails,addSubReservationId,removeSubReservationId} = subscriptionTripDetailSlice.actions;

export default subscriptionTripDetailSlice.reducer;