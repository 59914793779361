export function formatCurrency(amount) {
    // Convert amount to a string
    if(amount){
        amount = amount.toString();
    
        // Split the amount into parts before and after the decimal point
        var parts = amount.split(".");
        var integerPart = parts[0];
        var decimalPart = parts.length > 1 ? "." + parts[1] : "";
    
        // Add commas to separate thousands
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return integerPart + decimalPart;
    }
}