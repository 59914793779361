import React from "react";
import CarSpecifiHero from "./CarSpecifiHero";
import CarSpecifiRecent from "./CarSpecifiRecent";
import Vehicles from "./Vehicles";
import PopularSearches from "./PopularSearches";

const CarSpecifications = () => {
  return (
    <div className="car-specification pt-10 pb-24">
      <div className="w-[85%] mx-auto flex flex-col gap-16">
        <CarSpecifiHero />
        <CarSpecifiRecent />
        <Vehicles />
        <PopularSearches />
      </div>
    </div>
  );
};

export default CarSpecifications;
