import React from "react";

import EventIcon from "@mui/icons-material/Event";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import format from "date-fns/format";

import {
  toggleCalender,
  closeCalender,
} from "../../../utils/BookingPageSlices/formControlSlice";
import { useDispatch } from "react-redux";

function PickDateTime(props) {

  const dispatch = useDispatch();
  const handleCalenderModal = () => {
    dispatch(toggleCalender());
  };


  const closeCal = () => {
    dispatch(closeCalender());
  };

  const handlePickDropToggle = ()=>{
    props.togglePick(true);
    props.toggleDrop(false);
  }

  return (
    <React.Fragment>
      <div className="w-fit h-fit flex md:w-[100%] max-sm:w-[16.5rem] lg:w-[97%] xl:w-full max-sm:justify-between gap-2">
        <div className="max-sm:w-[60%] ">
          <div className="flex justify-start items-center mb-2">
            <span className="text-xs font-thin leading-4">Pick-up Date</span>
          </div>
          <div className="removeBack flex " onClick={handleCalenderModal}>
            <div className="xl:w-[128px] lg:w-full md:w-full h-[51px] max-sm:w-full bg-[#ebebeb] rounded flex justify-center max-sm:justify-start max-sm:p-2 items-center   border-2" onClick={handlePickDropToggle} >
              <EventIcon className="fill-[#8495C2] mr-1" />
              <span className="text-sm font-medium text-black">
                {format(props.stDate, "MMM,dd,yyyy")}
              </span>
            </div>
          </div>
        </div>
        <div className="max-sm:w-[40%]">
          <div className="flex justify-start items-center mb-2">
            <span className="text-xs font-thin leading-4">Pick-up Time</span>
          </div>
          <div className="removeBack flex">
            <div
              className="xl:w-[100px] lg:w-full md:w-full h-[51px] max-sm:w-full bg-[#ebebeb] rounded flex justify-start items-center p-2"
              onClick={closeCal}
            >
              <QueryBuilderIcon className="fill-[#8495C2] mr-2" />
              <select
                name=""
                className="bg-[#ebebeb] w-[70%] text-black"
                id=""
                onChange={(event) => props.setTimePick(event.target.value)}
              >
                {props.options.map((option, index) => (
                  <option
                    value={option.value}
                    selected={option.value == "10:00"}
                    key={index}
                  >
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PickDateTime;