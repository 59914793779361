import React from 'react'
import CarBookingStatus from './CarBookingStatus'
import BookedCarForSelfDrive from './BookedCarForSelfDrive'

const CarBookingForSelfDrive = () => {
    return (
        <div className='w-full xl:w-[1200px] mx-auto h-full'>
            <CarBookingStatus />
            <BookedCarForSelfDrive />
        </div>
    )
}

export default CarBookingForSelfDrive
