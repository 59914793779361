import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import InitialCard from "./InitialCard";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { emptyBaseRate, emptyInvoice } from "../../utils/invoice/invoiceSlice";
import { clearUser } from "../../utils/userSlices/userSlice";
import { emptyExtraArr } from "../../utils/extraSlice";
import ReactSlick from "../../utils/slider/SliderSlick";
import { SkeletonCard } from "../loader/skeleton/SkeletonCard";
import { baseUrl } from "../../config";

function NewSearchCarResult({ CarRentalFew }) {
  const dispatch = useDispatch();
  const addSliceOneSearchStatus = useSelector(
    (store) => store.addSliceOne.searchOnAddPage
  );
  const totalDays = useSelector(
    (store) => store.bookingInfoSlice.totalNumberOfDays
  );
  const [carList, setCarList] = useState([]);
  const [originalCarList, setOriginalCarList] = useState([]);
  const [limitedCarCard, setLimitedCarCard] = useState(6);

  useEffect(() => {
    dispatch(emptyInvoice());
    dispatch(emptyBaseRate());
    dispatch(clearUser());
    dispatch(emptyExtraArr());

    if (!addSliceOneSearchStatus) {
      try {
        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `${baseUrl}/app/v1/vehicles/getSelfVehiclesWithAllPrices/2`,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            setCarList(response.data.result);
            setOriginalCarList(response.data.result);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url:
            `${baseUrl}/app/v1/vehicles/getSelfVehicles/` +
            totalDays,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            setCarList(response.data.result);
            setOriginalCarList(response.data.result);
            //   setActiveVehicle(response.data.result?.filter((vehicle) => !vehicle.isActive));
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error + "Car Get API ");
      }
    }
  }, [addSliceOneSearchStatus]);


  const filterCarOnName = (e) => {
    if (e.target.value == "") {
      setCarList(originalCarList);
    } else {
      const lowerCaseCarName = e.target.value.toLowerCase();
      const newCarList = carList.filter((car) =>
        car.title.toLowerCase().includes(lowerCaseCarName)
      );
      setCarList(newCarList);
    }
  };

  const sortByRelevance = () => {
    setCarList(originalCarList);
  };

  const sortLowHigh = () => {
    const sortByLowToHigh = (a, b) => {
      return addSliceOneSearchStatus
        ? Object.values(a?.payNowPrice)[0] - Object.values(b?.payNowPrice)[0]
        : a?.payNowPrice?.daily - b?.payNowPrice?.daily;
    };

    const lowToHighCars = carList.slice().sort(sortByLowToHigh);
    // setCarList(highToLowCars);
    setCarList(lowToHighCars);
  };

  const sortHighLow = () => {
    const sortByHighToLow = (a, b) => {
      return addSliceOneSearchStatus
        ? Object.values(b?.payNowPrice)[0] - Object.values(a?.payNowPrice)[0]
        : b?.payNowPrice?.daily - a?.payNowPrice?.daily;
    };

    const highToLowCars = carList.slice().sort(sortByHighToLow);
    // setCarList(highToLowCars);
    setCarList(highToLowCars);
  };

  const handleSorting = (e) => {
    let sortName = e.target.value;
    if (sortName === "Sort low to high") {
      sortLowHigh();
    } else if (sortName == "Sort high to low") {
      sortHighLow();
    } else {
      sortByRelevance();
    }
  };

  const getAllLimitedCarCard = () => {
    setLimitedCarCard(carList.length);
  };

  if (CarRentalFew == "CarLease") {
    return (
      <div className="w-full">
        <div className="w-full">
          <div className="w-full flex justify-start mt-6 max-sm:flex max-sm:flex-col mb-7 pl-[10px]">
            <input
              type="text"
              className=" w-[30%] h-12 border-[1.5px] rounded-md border-slate-400 bg-slate-100 px-2 max-sm:w-full placeholder:text-black"
              placeholder="Search Car"
              onChange={(e) => filterCarOnName(e)}
              onInput={(e) => filterCarOnName(e)}
            />
            <select
              name=""
              id="addSelectPage"
              className=" ml-4 h-12 border-[1.5px] rounded-md border-slate-400 bg-slate-100 px-2 max-sm:w-full max-sm:ml-0 max-sm:mt-2"
              onChange={(e) => handleSorting(e)}
            >
              <option value="Sort by relevance">Sort by relevance</option>
              <option value="Sort low to high">Sort low to high</option>
              <option value="Sort high to low">Sort high to low</option>
            </select>
          </div>
          {carList?.length ? (
            <div>
              <ReactSlick carList={carList} />
            </div>
          ) : (
            <div className="w-full ">
              <div className="w-full flex flex-wrap mx-1">
                {[0, 1, 2, 3, 4, 5].map((item, index) => {
                  return (
                    <div className="w-full lg:w-4/12 px-4 md:w-6/12 py-4 mb-3 rounded-md shadow-xl hover:ring-2" key={index}>
                      <SkeletonCard />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="w-full flex justify-start mt-6 max-sm:flex max-sm:flex-col mb-7 pl-[10px]">
        <input
          type="text"
          className=" w-[30%] h-12 border-[1.5px] rounded-md border-slate-400 bg-slate-100 px-2 max-sm:w-full placeholder:text-black"
          placeholder="Search Car"
          onChange={(e) => filterCarOnName(e)}
          onInput={(e) => filterCarOnName(e)}
        />
        <select
          name=""
          id="addSelectPage"
          className=" ml-4 h-12 border-[1.5px] rounded-md border-slate-400 bg-slate-100 px-2 max-sm:w-full max-sm:ml-0 max-sm:mt-2"
          onChange={(e) => handleSorting(e)}
        >
          <option value="Sort by relevance">Sort by relevance</option>
          <option value="Sort low to high">Sort low to high</option>
          <option value="Sort high to low">Sort high to low</option>
        </select>
      </div>
    <div>
        <div className="w-full">
          {
            CarRentalFew == "CarRentalFew" && carList?.length ? (
              <div className="w-full flex flex-wrap ">
                {carList?.slice(0, limitedCarCard).map((item, index) => {
                  return (
                    <div
                      className="w-full lg:w-4/12 md:w-6/12 py-2 px-2 mb-3"
                      key={index}
                    >
                      <InitialCard carDetails={item} />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="w-full">
              <div className="w-full flex flex-wrap mx-1">
                {[0, 1, 2, 3, 4, 5].map((item, index) => {
                  return (
                    <div className="w-full lg:w-4/12 px-4 md:w-6/12 py-4 mb-3 rounded-md shadow-xl hover:ring-2" key={index}>
                      <SkeletonCard />
                    </div>
                  );
                })}
                </div>
              </div>
            )
          }
        </div>
        {carList?.length > 6 && (
          <div className="flex justify-center items-center w-full mt-5 px-[10px]">
            {limitedCarCard > 6 ? (
              <button
                className="lg:w-2/12 md:w-3/12 w-full bg-[#3E5FB4] text-white py-4 rounded-[12px] mx-auto"
                onClick={() => setLimitedCarCard(6)}
              >
                Show Less
              </button>
            ) : (
              <button
                className="lg:w-2/12 md:w-3/12 w-full bg-[#3E5FB4] text-white py-4 rounded-[12px] mx-auto"
                onClick={() => getAllLimitedCarCard()}
              >
                Show More
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default NewSearchCarResult;
