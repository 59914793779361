import React, { useState } from "react";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { switchOffAddQueryModalOne } from "../../utils/ModalSlice/modalToggleSlice";
import Cookies from "js-cookie";
import axios from "axios";
import { baseUrl } from "../../config";

function AddPopUpOne() {
  const dispatch = useDispatch();
  const getEmail = useRef();
  const [addMailStatus,setAddMailStatus] = useState(true);

  const handleSaveForm = () => {
    
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let mailStatus = regex.test(getEmail.current.value);
    if(mailStatus==true){
        setAddMailStatus(true);
        submitForm();
    }else{
        setAddMailStatus(false);
    }
  };

  const submitForm = () => {

    try {
      let data = JSON.stringify({
        emailID: getEmail.current.value,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseUrl}/app/v1/query/popupEmail`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          closeModal();
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const closeModal = () => {
    dispatch(switchOffAddQueryModalOne());
    Cookies.set("modalClosed", "true", { expires: 7 }); // Set a cookie that expires in 365 days
  };

  return (
    <div className="fixed overflow-y-auto overflow-x-hidden inset-0 pt-10 max-sm:pt-0 bg-black  z-50 outline-none  bg-opacity-70 flex justify-center items-center">
      <div className="  w-full h-full flex justify-center item-center   bg-transparent max-sm:mt-0 ">
        <div className=" mainModal w-[80%] flex max-sm:w-full max-sm:block h-fit ">
          <div className="w-[50%]  max-sm:w-full rounded-l-md">
            <img
              src="../images/wtilogo.png"
              alt=""
              className="hidden w-[50%] mx-20 max-sm:block"
            />
            <img
              src="../images/offersimg.jpg"
              alt=""
              className="max-sm:hidden"
            />
          </div>
          <div className="w-[50%] rounded-r-md  bg-gradient-to-b from-[#759AAA] via-[#BFA4B5] to-[#CC9B94] max-sm:w-full ">
            <h1 className="text-[40px] max-sm:text-[50px] text-white font-bold px-20 py-4 drop-shadow-lg leading-tight max-sm:px-5 ">
              Get Exclusive Dubai Itineraries And Offers Delivered Right To Your
              Inbox
            </h1>
            <p className="text-[24px] text-white px-20 max-sm:px-5 ">
              Sign up to get exclusive offers
            </p>
            <div className=" px-20 mt-3 max-sm:px-5 ">
              <input
                ref={getEmail}
                type="email"
                placeholder="Email"
                className={`w-full p-3 border-2 ${addMailStatus?"border-white":"border-red-500"}  bg-transparent text-white ${addMailStatus?"placeholder-white":"placeholder-red-500"}  rounded outline-none `}
              />
              <button
                onClick={handleSaveForm}
                className="w-full bg-white mt-3 p-2 rounded text-[25px] shadow-md font-bold "
              >
                Send Me Offers
              </button>
              <button
                className="w-full bg-transparent text-white text-[22px] font-semibold p-3"
                onClick={closeModal}
              >
                No, Thanks
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddPopUpOne;
