import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import BookingsMain from "./bookings/Main";
import AccountMain from "./account/Main";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { toggleLogin } from "../../utils/loginSlices/loginSlice";
import { useDispatch } from "react-redux";
import { UseSelector, useSelector } from "react-redux/es/hooks/useSelector";
import { switchOffLoader, switchOnLoader } from "../../utils/loaderSlice";
import { addFirstName, addLastName } from "../../utils/userSlices/userSlice";
import axios from "axios";
import { baseUrl } from "../../config";


function ProfileMain() {
  const userAccountStatus = useSelector((store) => store.modalToggleSlice.userAccount);
  const [value, setValue] = React.useState(userAccountStatus ? "2" : "1");
  const dispatch = useDispatch();
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const currentURL = window.location.href;
  const findUserID = currentURL.split("/");


  useEffect(() => {
    // Function to update screen size state
    const jwtPresent = Cookies.get("jwtToken");
    if (jwtPresent == undefined) {
      navigate("/");
    }
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    // Add event listener to update screen size on window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  useEffect(() => {
    if(userAccountStatus){
      setValue(1)
    } else{
      setValue(2)
    }
  }, [userAccountStatus])
  

  useEffect(() => {
    dispatch(switchOnLoader());
    try {
      
    let data = JSON.stringify({
      userID: findUserID[findUserID.length - 1],
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/app/v1/user/getUserDetails`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

      axios
        .request(config)
        .then((response) => {
          dispatch(addFirstName(response.data.result?.firstName));
          dispatch(addLastName(response.data.result?.lastName));
          dispatch(switchOffLoader());
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log("Error While calling the API : " + error);
    }
  }, []);


  return (
    <>
      <div>
        <div className="w-full bg-[#EBEBEB]"></div>
        <div className="pl-[8.27%] pr-[8.27%] bg-[#E6E6E6] py-8 max-sm:px-[4%]">
          <Box sx={{ width: "100%", typography: "body1" }}>
            {/* <TabContext value={value}>
              <Box>
                {screenWidth > 640 ? (
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="Bookings" value="1" />
                    <Tab label="Account" value="2" />
                  </TabList>
                ) : (
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    centered
                  >
                    <Tab label="Bookings" value="1" />
                    <Tab label="Account" value="2" />
                  </TabList>
                )}



               
              </Box>
              <TabPanel value="1">
                <BookingsMain />
              </TabPanel>
              <TabPanel value="2">
                <AccountMain />{" "}
              </TabPanel>
            </TabContext> */}

            <div className="w-full">
              <div className='flex items-start gap-3 px-3'>
                <button className={`flex justify-start gap-2 items-center ${value == 1 ? "bg-gradient-to-r from-[#0077B6] to-[#00B4D8]" : "bg-[#fff]"}  shadow-[0px_3px_8px_0px_#00000033] py-3 px-6 rounded-[12px]`} onClick={()=>setValue(1)}>
                  <span className={` ${value == 1 ? "text-[#fff]" : "text-[#1d1d1d]"} text-[20px] font-medium tracking-[1px] leading-[20px]`}>Bookings</span>
                </button>
                <button className={`flex justify-start gap-2 items-center ${value == 2 ? "bg-gradient-to-r from-[#0077B6] to-[#00B4D8]" : "bg-[#fff]"} py-3 px-6 rounded-[12px]`}  onClick={()=>setValue(2)}>
                  <span className={` ${value == 2 ? "text-[#fff]" : "text-[#1d1d1d]"} text-[20px] font-medium tracking-[1px] leading-[20px]`}>Accounts</span>
                </button>
              </div>
              <div>
                {
                  
                  value == 1 ? (<>
                   <BookingsMain />
                  </>) : (<>
                    <AccountMain />
                  </>)

                }
              </div>

            </div>







          </Box>
        </div>
      </div>
    </>
  );
}

export default ProfileMain;
