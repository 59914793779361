import { createSlice } from "@reduxjs/toolkit";





const currencySlice = createSlice({
    name:"currencySlice",
    initialState:{
        currentCurrency:{
            currency:"AED",
            rate:1
        },
        India:{
            currName:"INR",
            selectStatus:false,
            rate:22.62
        },
        Uae:{
            currName:"AED",
            selectStatus:true,
            rate:1
        },
        Usa:{
            currName:"USD",
            selectStatus:false,
            rate:0.27
        },
        currentCurr:"AED",
        currentCurrRate:1,
        currencyArr:["AED","INR","USD"]
    
    },
    reducers:{
        selectIndia:(state,action)=>{
            state.India.selectStatus = true;
            state.Uae.selectStatus = false;
            state.Usa.selectStatus = false;
            let newObj = {...state.currentCurrency,currency:"INR"};
            state.currentCurrency = newObj
            state.currentCurrency.rate = state.India.rate;
        },
        selectUae:(state,action)=>{
            state.Uae.selectStatus = true;
            state.India.selectStatus = false;
            state.Usa.selectStatus = false;
            let newObj = {...state.currentCurrency,currency:"AED"};
            state.currentCurrency = newObj
            state.currentCurrency.rate = state.Uae.rate;
        },
        selectUsa:(state,action)=>{
            state.Uae.selectStatus = false;
            state.India.selectStatus = false;
            state.Usa.selectStatus = true;
            let newObj = {...state.currentCurrency,currency:"USD"};
            state.currentCurrency = newObj
            state.currentCurrency.rate = state.Usa.rate;
        },
        clearCurrency:(state,action)=>{
            let newObj = {...state.currentCurrency,currency:"AED",rate:1};
            state.currentCurrency = newObj
        },
        changeCurrency:(state,action)=>{
            state.currentCurr = action.payload.currentCurr;
            state.currentCurrRate = action.payload.currentCurrRate;
        }
        
    }
})

export const {selectIndia,selectUae,selectUsa,clearCurrency,changeCurrency} = currencySlice.actions;

export default currencySlice.reducer;