import React, { useState, useEffect } from "react";
import axios from "axios";
import Cars from "../carSearchResults/Cars";
import {} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { useDispatch,useSelector } from "react-redux";
import {switchCarModal } from "../../../utils/ModalSlice/modalToggleSlice";
import { baseUrl } from "../../../config";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
};

function ModifyCarModal() {
  const dispatch = useDispatch();
  const totalDays = useSelector((store)=>store.bookingInfoSlice.totalNumberOfDays );
  const [carList, setCarList] = useState([]);
  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/app/v1/vehicles/getSelfVehicles/`+totalDays,
    };

    axios
      .request(config)
      .then((response) => {
        
        setCarList(response.data.result);
      })
      .catch((error) => {
        
      });
  }, []);

  const closeCarModal = ()=>{
    dispatch(switchCarModal());
    document.documentElement.style.overflow='visible';
  }
  return (
    <>
      <div className="absolute top-0 left-0 h-screen w-full flex justify-center items-center" >
        <div className="absolute w-full top-0 left-0 h-screen bg-slate-800 opacity-60 z-10"></div>
        <div className="border h-2/3 w-3/4 bg-white p-4 overflow-y-auto rounded-xl z-30">
            <div className="flex justify-between ">
                <div className="text-lg font-bold">
                    Modify Car
                </div>
                <div className="p-1 bg-slate-300 flex justify-center items-center rounded-full cursor-pointer" onClick={closeCarModal}>
                    <CloseOutlined />
                </div>
            </div>
          <div className="grid grid-cols-2 gap-x-5 gap-y-6 pt-4">
            {carList.map((e) => (
              <Cars carDetails={e} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default ModifyCarModal;
