import React, { useEffect, useState } from "react";
import logo from "./pac.png";
import { FormControlLabel, Checkbox } from "@mui/material";
import { useSelector } from "react-redux";
import { addExtra, removeExtra } from "../../utils/extraSlice";
import { useDispatch } from "react-redux";
import {
  addExcessInsurance,
  addExtras,
  removeExtras,
} from "../../utils/invoice/invoiceSlice";
import { calculateExcessInsurance } from "../../utils/commonFunction/calcExcessInsurance";
import { toggleExcessCheckState } from "../../utils/ModalSlice/modalToggleSlice";

function PAndCCard(props) {


  let {
    cdwCheck,
    setCdwCheck
  } = props
  const dispatch = useDispatch();
  const currencyName = useSelector((store) => store.currencySlice.currentCurr);
  const currencyPrice = useSelector(
    (store) => store.currencySlice.currentCurrRate
  );
  const tNumDays = useSelector(
    (store) => store.bookingInfoSlice.totalNumberOfDays
  );

  const extraObj = useSelector((store) => store.extraSlice.allExtraBooleans);

  const excessCheckState = useSelector((store)=>store.modalToggleSlice.excessCheckState);

  useEffect(()=>{
    if(extraObj.cdwBoolean == true){
      setCdwCheck(true);
    }
  },[])


  const addExcessInsuranceOne = (price) => {
   
    if (excessCheckState) {
      //make it false
      dispatch(addExcessInsurance(0));
    } else {
      //make it true
      dispatch(addExcessInsurance(price));
    }
    // setExcessCheckState(!excessCheckState);
    dispatch(toggleExcessCheckState());
    
  };

  const carObj = useSelector((store) => store.carDetailSlice.carDetailObj);



  const handleInsurance = (insuranceData, insurancePrice) => {
 
    if (cdwCheck) {
      dispatch(removeExtras(insurancePrice));
      dispatch(removeExtra(insuranceData.equipmentsObj.id + "C"));
    } else {
      dispatch(addExtras(insurancePrice));
      dispatch(addExtra(insuranceData.equipmentsObj.id + "C"));
    }
    setCdwCheck(!cdwCheck);
  };

  return (
    <React.Fragment>
      <div className="shadow-xl rounded-md mb-4 cursor-pointer hover:border hover:border-black">
        <div className="icon flex">
          <div className="img flex justify-center items-center  pl-4 pr-4 ">
            <img src={logo} alt="" className="border rounded-full max-w-sm " />
          </div>
          <div className="flex flex-col justify-center items-start pt-6  pb-2 pr-4 pl-4">
            <span className="font-semibold text-sm mb-2">
              {props?.equipmentsObj?.title}
            </span>
            <span className="text-[12px] font-normal leading-4">
              {props?.equipmentsObj?.description}
            </span>
          </div>
        </div>
        <div className="check flex pl-8 pb-2">
          <FormControlLabel
            label={
              <span className="text-sm font-semibold  text-[#092C85] leading-4">
                {props?.equipmentsObj?.title == "No-Deposit Premium"
                  ? currencyName +
                    " " +
                    Number((
                      calculateExcessInsurance(
                        carObj.excessInsuranceCharge,
                        tNumDays
                      ) * currencyPrice
                    )?.toFixed(2)) +
                    ` - ${tNumDays} DAY`
                  : currencyName +
                    " " +
                    Number((Number(Object.values(carObj?.
                      insuranceAndDamageCovers[0].price)[0])
                       * currencyPrice * tNumDays)?.toFixed(2)) +
                    ` - ${tNumDays} DAY`}
              </span>
            }
            control={
              <Checkbox
                checked={
                  cdwCheck
                }
                onChange={() => {
                  props?.equipmentsObj?.title == "No-Deposit Premium"
                    ? (() =>{
                      addExcessInsuranceOne(
                        calculateExcessInsurance(
                          carObj.excessInsuranceCharge,
                          tNumDays
                        )
                      )
                    })()
                    : (() => {
                        let clickInsurancePrice = Object.values(
                          props?.equipmentsObj?.price
                        );
                        clickInsurancePrice =
                          parseFloat(clickInsurancePrice[0]) * tNumDays;
                        handleInsurance(props, clickInsurancePrice);
                      })();
                }}
              />
            }
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default PAndCCard;
