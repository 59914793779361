import React from 'react';
import bg from './404.png';

function Error() {
  return (  
    <>
        <div className='h-screen w-full flex justify-center items-center overflow-hidden'>
            <div className='rounded bg-white z-10 px-6 py-4' >
                <div className='text-3xl font-bold'>
                    Error 404
                </div>
                <div className='mt-4'>
                    Page does'nt Exist 
                </div>
            </div>
            <div className='absolute top-0 w-[90%] overflow-hidden z-0'> 
            <img src={bg} alt="" className='w-full object-cover' />
            </div>
        </div>
    </>
  )
}

export default Error