import React, {useState} from 'react';
import { changeCurrency } from '../../utils/currencySlice';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { currCountry } from '../currencyModal/utils/array';
import { useNavigate } from 'react-router';
import { baseUrl } from '../../config';

function CurrencySearchPage() {
    const [typeCurr, setTypeCurr] = useState("");
    const [currChange, setCurrChange] = useState("");
    const [filterData,setFilterData] = useState(currCountry);

    const navigate = useNavigate();

    let curMoney = useSelector(
        (store) => store.currencySlice.currentCurr
      );

    const dispatch = useDispatch();

    const payCheck = async (value) => {
        try{
          
          let res = await getCurrValue(value);
          
          let curObj = {
            currentCurr: value,
            currentCurrRate:parseFloat(res.data),
          };
          dispatch(changeCurrency(curObj));
    
        }catch(error){
          
        }
    
      };

      const getCurrValue = async (curr) => {
    
        try {
          let config = {
            method: "get",
            maxBodyLength: Infinity,
            url:  `${baseUrl}/app/v1/currency/${curr}`,
          };
      
          const response = await axios.request(config);
          let data = response.data;
          
          return data; // Return the data
        } catch (error) {
          console.error(error);
          throw error; // You can choose to throw the error or handle it differently
        }
      };

      

      const typeCurrChange = (e) => {
        const inputValue = e.target.value;
        setCurrChange(inputValue);
      
        const filteredData = currCountry.filter((data) =>
          data.currencyCode.toLowerCase().includes(inputValue.toLowerCase())
        );
      
        // Now, you can use filteredData and log it
        
        setFilterData(filteredData);
      };
      
  return (
    <>
        <div className="flex justify-center w-full inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full my-6 mx-auto max-w-5xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-center justify-between p-3 border-b border-solid border-gray-300 rounded-t ">
              <input
                type="text"
                className="w-[49%] h-8 py-4 p-2 rounded-md outline-none focus:ring"
                placeholder="Search Currency"
                onChange={(e) => typeCurrChange(e)}
              />
              <div className='w-[49%] flex items-center justify-center'>
                    <span>Selected Currency: {curMoney}</span>
              </div>
            </div>
            <div className="relative p-6 flex-auto">
              <div className="grid grid-cols-4 gap-x-6 max-sm:grid-cols-1 gap-y-4 mt-4 overflow-y-auto">
                {filterData.map((data) => (
                  <div
                    onClick={() => 
                      {
                        payCheck(data.currencyCode);
                        navigate(-1);

                      }}
                    className="hover:font-bold p-2 hover:border-b border-slate-300"
                    key={data.currencyCode}
                  >
                    <div className="text-sm">{data.countryName}</div>
                    <div className="text-xs">{data.currencyCode}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CurrencySearchPage