import React, { useEffect, useState } from "react";
import { currCountry } from "./utils/array";
import CloseIcon from "@mui/icons-material/Close";

function CurrencyModal(props) {
  const [filterData,setFilterData] = useState(currCountry);


  const typeCurrChange = (e) => {
    const inputValue = e.target.value;
  
    const filteredData = currCountry.filter((data) =>
      data.currencyCode.toLowerCase().includes(inputValue.toLowerCase())
    );
  
    // Now, you can use filteredData and log it
    
    setFilterData(filteredData);
  };
  



  
 

  return (
    <>
      <div className="flex justify-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-5xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-3 border-b border-solid border-gray-300 rounded-t ">
              <input
                type="text"
                className="w-[50%] h-8 border border-slate-400 py-4 p-2 rounded-md"
                placeholder="Select Currency"
                onChange={(e) => typeCurrChange(e)}
              />
              <button
                className="bg-transparent border-0 text-black float-right"
                onClick={() => props.close()}
              >
                <CloseIcon className="text-white text-lg bg-slate-600 rounded-full" />
              </button>
            </div>
            <div className="relative p-6 flex-auto">
              <div className="grid grid-cols-4 gap-x-6 gap-y-4 mt-4 overflow-y-auto">
                {filterData.map((data) => (
                  <div
                    onClick={() => 
                      {
                        props.setCurrency(data.currencyCode);
                        props.close();
                      }}
                    className="hover:font-bold p-2 hover:border-b border-slate-300"
                    key={data.currencyCode}
                  >
                    <div className="text-sm">{data.countryName}</div>
                    <div className="text-xs">{data.currencyCode}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CurrencyModal;
