import React from "react";
import clients from "./clients.svg";
import people from "./users.svg";
import cars from "./vehicles.svg";
import star from "./years.svg";


function AboutNumbers(){
    return(
        <div className="w-full pt-12">
            <div className=" bg-white w-full pl-[10.27%] pr-[10.27%] flex justify-center items-center py-12">
                <span className="text-3xl font-bold leading-8"><h3>Our numbers speak</h3></span>
            </div>
            <div className="bg-[#F3F4F9] pl-[10.27%] pr-[10.27%] max-sm:px-4 w-full flex max-sm:flex-wrap justify-evenly items-center relative py-8 pb-12">
                <div className="flex flex-col justify-start items-center max-sm:w-[50%]">
                    <img src={clients} alt="" className="" />
                    <span className="font-bold leading-9 text-3xl absolute top-32 max-sm:static">700+</span>
                    <span className="font-normal text-base leading-6 mt-6">Clients</span>
                </div>
                <div className="flex flex-col justify-start items-center max-sm:w-[50%]">
                    <img src={cars} alt="" className="" />
                    <span className="font-bold leading-9 text-3xl absolute top-32  max-sm:static">6,000+</span>
                    <span className="font-normal text-base leading-6 mt-6">Vehicles</span>
                </div>
                <div className="flex flex-col justify-start items-center max-sm:w-[50%]">
                    <img src={people} alt="" className="" />
                    <span className="font-bold leading-9 text-3xl absolute top-32  max-sm:static">125,000+</span>
                    <span className="font-normal text-base leading-6 mt-6">Users</span>
                </div>
                <div className="flex flex-col justify-start items-center max-sm:w-[50%]">
                    <img src={star} alt="" className="" />
                    <span className="font-bold leading-9 text-3xl absolute top-32  max-sm:static">25+</span>
                    <span className="font-normal text-base leading-6 mt-6">Years of Experience</span>
                </div>
            </div>
        </div>
    )
}


export default AboutNumbers;